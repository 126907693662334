import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
// @ts-ignore
import immutableTransform from 'redux-persist-transform-immutable'

import rootSagas from './rootSagas'
import reducers from './rootReducer'

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage,
  whitelist: ['auth'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

const store = createStore(persistedReducer, applyMiddleware(...middlewares))

sagaMiddleware.run(rootSagas)

export default {
  store,
  persistor: persistStore(store),
}
