import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { AppState } from 'store/rootReducer'
import SearchActions from 'store/search/action'
import { InputEvent } from 'types/helps'
import { IList, ICity } from 'types/models'

import Loader from 'components/Loader'
import Button from 'components/Button'
import Input from 'components/Input'
import InputSelect from 'components/Input/InputSelect'
import SavedSearch from '../components/SavedSearch'
import { NothingFound } from '../components/NothingFound'

import {
  Wrap,
  Container,
  Block,
  ButtonBlock,
  SaveWrap,
  SavedSearches,
  SavedThisSearch,
  ContainerCard,
  CompaniesCard,
  CompanyAvatar,
  CompanyName,
  CompanyCity,
  CompanyWebsite,
  JobPositions,
  SearchCount,
} from './styled'

type Props = {
  onChangeInput: (e: InputEvent, type?: string) => void
  onChangeSelect: (e: ICity[] | IList[], type: string) => void
  searchCompanies: typeof SearchActions.searchCompanies
  saveCompanies: typeof SearchActions.saveCompanies
  getSearchList: typeof SearchActions.getSearchList
  setValueFromSavedSearch: typeof SearchActions.setValueFromSavedSearch
  clearInputValue: typeof SearchActions.clearInputValue
  search: any
  isFetching: boolean
}

const Companies: React.FC<Props & RouteComponentProps> = ({
  onChangeInput,
  onChangeSelect,
  searchCompanies,
  search,
  saveCompanies,
  getSearchList,
  setValueFromSavedSearch,
  clearInputValue,
  history,
  isFetching,
}) => {
  const onChangeCity = (e: ICity[]) => onChangeSelect(e, 'companiesCity')
  const onChangeProjectTypes = (e: IList[]) => onChangeSelect(e, 'companiesProjectTypes')
  const redirectToEmployerCard = (id: number) => history.push(`/live-jobs/employer/${id}`)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // const params = new URLSearchParams(location.search)
    // const page = Number(params.get('page')) || 1
    // console.log('this.props.match.params.id');
    searchCompanies()
    getSearchList('companies')
  }, [])

  const handleSaveCompanies = () => saveCompanies()

  const handleSaveSearches = () => setIsOpen(true)
  const closeSaveSearches = () => setIsOpen(false)

  const apply = (el: any) => {
    if (el.jobTitle) setValueFromSavedSearch('companiesJobTitle', el.jobTitle)
    if (el.cityIds) setValueFromSavedSearch('companiesCity', el.cityIds)
    if (el.sectorIds) setValueFromSavedSearch('companiesProjectTypes', el.sectorIds)
    searchCompanies()
    clearInputValue()
    closeSaveSearches()
  }

  const sortByCompanyName = (a: any, b: any) => {
    const nameA = a.companyName.toUpperCase()
    const nameB = b.companyName.toUpperCase()

    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }

  const renderCompanies = () => {
    return search
      .get('searchCompanies')
      .sort(sortByCompanyName)
      .map((data: any) => {
        let website
        if (data.website) {
          website = data.website.substr(data.website.indexOf('//') + 2)
        } else {
          website = ''
        }
        return (
          <CompaniesCard smooth key={data.id}>
            <CompanyAvatar item={data} onClick={() => redirectToEmployerCard(data.id)} />
            <CompanyName>{data.companyName}</CompanyName>
            <CompanyCity>{data.City.name}</CompanyCity>
            <CompanyWebsite>
              <a href={data.website} target="_blank" rel="noopener noreferrer">
                {website}
              </a>
            </CompanyWebsite>
            <JobPositions>
              {data.JobPositionCount < 2 ? `${data.JobPositionCount} job position` : `${data.JobPositionCount} job positions`}
            </JobPositions>
          </CompaniesCard>
        )
      })
  }

  const searchCount = search.get('searchCompanies')

  return (
    <Container>
      <Wrap>
        <Block minWidth={154}>
          <Input type="text" height={50} placeholder="Client name or keyword" noBorder br onChange={onChangeInput} name="companiesJobTitle" />
        </Block>
        <Block width={183} minWidth={183}>
          <InputSelect placeholder="Location" optionsType="cities" isMulti isSearchComponent isCustom onChange={onChangeCity} />
        </Block>
        <Block minWidth={213}>
          <InputSelect placeholder="Project type" optionsType="projectTypes" isMulti isSearchComponent isCustom onChange={onChangeProjectTypes} />
        </Block>
        <ButtonBlock>
          <Button width="138px" height="50px" title="Search" type="secondary" onClick={() => searchCompanies()} />
        </ButtonBlock>
      </Wrap>
      <SaveWrap>
        <SavedSearches>
          <span onClick={handleSaveSearches}>Saved searches ({search.get('companies').total})</span>
          {searchCount && searchCount.length > 0 && (
            <SearchCount s left opacity="0.6">{`${
              searchCount.length > 1 ? `${searchCount.length} employers` : `${searchCount.length} employer`
            } in total`}</SearchCount>
          )}
          <SavedSearch isOpen={isOpen} closeSaveSearches={closeSaveSearches} docs={search.get('companies').docs} apply={apply} />
        </SavedSearches>
        <SavedThisSearch secondColor onClick={() => handleSaveCompanies()}>
          Save this search
        </SavedThisSearch>
      </SaveWrap>
      {isFetching ? (
        <Loader height="50vh" withoutBg />
      ) : (
        <>{!search.get('searchCompanies').length ? <NothingFound /> : <ContainerCard>{renderCompanies()}</ContainerCard>}</>
      )}
    </Container>
  )
}

const mapStateToProps = (state: AppState) => ({
  search: state.search,
  isFetching: state.search.get('isFetching'),
})

export default connect(
  mapStateToProps,
  {
    searchCompanies: SearchActions.searchCompanies,
    saveCompanies: SearchActions.saveCompanies,
    getSearchList: SearchActions.getSearchList,
    setValueFromSavedSearch: SearchActions.setValueFromSavedSearch,
    clearInputValue: SearchActions.clearInputValue,
  }
)(withRouter(Companies))
