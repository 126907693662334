import Api from './index'

class Messenger {
  static getSignature() {
    return Api.get('/chat/signature/')
  }

  static setSignature(data: { text: string; image: File }) {
    const body = new FormData()
    body.append('text', data.text)
    body.append('image', data.image)
    return Api.post('/chat/signature/', body)
  }

  static getChatList() {
    return Api.get('/chat/list/')
  }

  static sendMessage(body: any) {
    return Api.post('/chat/send/', body)
  }

  static sendInterview(appointemtData: any) {
    return Api.post('/chat/appointment/', appointemtData)
  }

  static getMessages(user: any) {
    const data: any = {}
    if (user.type === 'candidate') data.CandidateId = `${user.id}`
    if (user.type === 'employerContact') data.EmployerContactId = `${user.id}`
    return Api.post('chat/messages/', data)
  }
}

export default Messenger
