import React, { useCallback, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'

import ApiList from 'api/list'

import { AppState } from 'store/rootReducer'
import ListsActions from 'store/lists/actions'
import { IList } from 'types/models'

import theme from 'styles/theme'
import { Box, BoxFill, Label, Text } from 'styles'

const colourStyles = (error: any) => ({
  container: (styles: any) => ({
    ...styles,
    width: '100%',
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    height: '100%',
    '&:hover': null,
    border: `1px solid ${theme.main.borderInputColor}`,
    borderColor: `${state.isFocused ? theme.main.accentColor : error ? theme.main.red : null} !important`,
    boxShadow: 'inset 0px 1px 5px rgba(188, 196, 208, 0.2)',
  }),
  menu: (styles: any) => ({ ...styles, zIndex: '10', boxShadow: '0px 3px 17px rgba(150, 170, 180, 0.3)' }),
  valueContainer: (styles: any, state: any) => ({
    ...styles,
    width: '100%',
    padding: `${state.selectProps.isSearchComponent ? '7px 8px 6px 8px' : '11px 8px'}`,
    flexWrap: `${state.selectProps.isCustom ? 'nowrap' : 'wrap'}`,
  }),
  multiValue: (styles: any) => ({
    ...styles,
    borderRadius: '6',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  placeholder: (styles: any, state: any) => ({
    ...styles,
    fontWeight: '300',
    color: `${state.selectProps.isSearchComponent || state.selectProps.isRelocateComponent ? theme.main.text : theme.main.inputLabelTextColor}`,
    fontSize: `${state.selectProps.isSearchComponent ? '15' : '18'}`,
  }),
})

type Props = {
  optionsType:
    | 'states'
    | 'jobs'
    | 'projectTypes'
    | 'projectTypesById'
    | 'cities'
    | 'weeks'
    | 'searchWeeks'
    | 'status'
    | 'priority'
    | 'itPackages'
    | 'projectValues'
    | 'publicEmployers'
  value?: any
  placeholder?: string
  label?: string
  name?: string
  isMulti?: boolean
  isSearchable?: boolean
  onChange?: (e: any) => void
  error?: string | false | object
  isShowError?: boolean
  width?: string
  height?: string
  isCustom?: boolean
  closedMenu?: boolean
  defaultValue?: string
  isSearchComponent?: boolean
  isRelocateComponent?: boolean
  recruiterId?: string
  components?: any
}

type FromStoreProps = {
  lists: {
    states: IList[]
    jobs: IList[]
    projectTypes: IList[]
    projectTypesById: IList[]
    weeks: IList[]
    searchWeeks: IList[]
    status: IList[]
    priority: IList[]
    itPackages: IList[]
    projectValues: IList[]
    publicEmployers: IList[]
  }
}

/* TODO:! NEED SOME REFACTORING ALL COMPONENT */
const InputSelect: React.FC<Props & FromStoreProps> = ({
  value,
  onChange,
  label,
  name,
  isMulti,
  isSearchable,
  placeholder,
  error,
  isShowError,
  optionsType,
  lists,
  width,
  height,
  isSearchComponent,
  isRelocateComponent,
  isCustom,
  closedMenu,
  defaultValue,
  recruiterId,
  ...props
}) => {
  const dispatch = useDispatch()
  const getCities = useCallback(() => dispatch(ListsActions.getCities()), [dispatch])
  const getStates = useCallback(() => dispatch(ListsActions.getStates()), [dispatch])
  const getJobs = useCallback(() => dispatch(ListsActions.getJobPositions()), [dispatch])
  const getProjectTypes = useCallback(() => dispatch(ListsActions.getProjectTypes()), [dispatch])
  const getProjectTypesById = useCallback((id: number) => dispatch(ListsActions.getProjectTypesById(id)), [dispatch])
  const getItPackages = useCallback(() => dispatch(ListsActions.getItPackages()), [dispatch])
  const getProjectValues = useCallback(() => dispatch(ListsActions.getProjectValues()), [dispatch])
  const getPublicEmployers = useCallback((id: number) => dispatch(ListsActions.getPublicEmployers(id)), [dispatch])
  const getPublicEmployersWithoutId = useCallback(() => dispatch(ListsActions.getPublicEmployersWithoutId()), [dispatch])

  useEffect(() => {
    switch (optionsType) {
      case 'cities':
        getCities()
        break
      case 'states':
        getStates()
        break
      case 'jobs':
        getJobs()
        break
      case 'projectTypesById':
        if (recruiterId) {
          getProjectTypesById(+recruiterId)
        }
        break
      case 'projectTypes':
        getProjectTypes()
        break
      case 'itPackages':
        getItPackages()
        break
      case 'projectValues':
        getProjectValues()
        break
      case 'publicEmployers':
        if (recruiterId) {
          getPublicEmployers(+recruiterId)
        } else {
          getPublicEmployersWithoutId()
        }
        break
      default:
        return
    }
  }, [])

  const filterCities = (inputValue: string, options: IList[]) =>
    options.filter(i => i.name.toLowerCase().includes(inputValue.toLowerCase())).sort((a, b) => (a.name > b.name ? 1 : -1))

  /* TODO:! maybe need rebuild for common use this func, not only for cities */
  const promiseOptions = (inputValue: string = '') =>
    new Promise<any[]>(resolve => ApiList.searchCities(inputValue).then(({ data }) => resolve(filterCities(inputValue, data))))

  const renderMessageError = () => {
    return error && !isShowError ? null : (
      <Box pt="5px">
        <Text red s>
          {error}
        </Text>
      </Box>
    )
  }

  const closedMenuOnSelect =
    optionsType === 'jobs' || optionsType === 'searchWeeks' || optionsType === 'priority' || optionsType === 'publicEmployers'

  let currentPriority: any
  if (defaultValue === 'high priority') currentPriority = lists['priority'][0]
  if (defaultValue === 'medium priority') currentPriority = lists['priority'][1]
  if (defaultValue === 'any time' || defaultValue === 'Any time') currentPriority = lists['priority'][2]

  const Menu = (props: any) => {
    const optionSelectedLength = props.getValue().length || 0
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 5 ? props.children : <div style={{ margin: '10px', textAlign: 'center' }}>Max limit achieved</div>}
      </components.Menu>
    )
  }

  const sortByName = (a: any, b: any) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()

    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }

  const sortByCompanyName = (a: any, b: any) => {
    const nameA = a.companyName.toUpperCase()
    const nameB = b.companyName.toUpperCase()

    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }

  if (optionsType === 'itPackages' || optionsType === 'projectTypes' || optionsType === 'projectTypesById' || optionsType === 'jobs')
    lists[optionsType] = lists[optionsType].sort(sortByName)

  if (optionsType === 'publicEmployers') {
    let changedpublicEmployers: any[] = []
    lists[optionsType].map((el: any) => {
      let name
      if (el.City) {
        name = `${el.companyName || el.name} (${el.City.name})`
      } else {
        name = `${el.companyName || el.name}`
      }
      changedpublicEmployers.push({
        id: el.id,
        companyName: name,
        logoFile: el.logoFile ? el.logoFile : null,
      })
    })
    lists[optionsType] = changedpublicEmployers.sort(sortByCompanyName)
  }

  return (
    <BoxFill column width={width} height={height}>
      <Label htmlFor={`${label}`}>{label}</Label>
      {optionsType === 'cities' ? (
        <AsyncSelect
          components={{ ...components, Menu, IndicatorSeparator: null }}
          value={value}
          name={name}
          styles={colourStyles(error)}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          cacheOptions
          defaultOptions
          loadOptions={promiseOptions}
          placeholder={placeholder}
          onChange={onChange}
          isMulti={isMulti}
          isSearchComponent={isSearchComponent}
          isRelocateComponent={isRelocateComponent}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          isCustom={isCustom}
        />
      ) : (
        <Select
          value={value}
          defaultValue={
            optionsType === 'weeks'
              ? lists[optionsType][0]
              : optionsType === 'priority' && currentPriority
              ? currentPriority
              : optionsType === 'priority'
              ? lists[optionsType][3]
              : null
          }
          name={name}
          id={label}
          styles={colourStyles(error)}
          placeholder={placeholder}
          components={{ Menu, IndicatorSeparator: null }}
          getOptionLabel={option => option.name || option.companyName}
          getOptionValue={option => option.id}
          isMulti={isMulti}
          isSearchable={isSearchable}
          options={value && value.length === 5 ? [] : lists[optionsType]}
          onChange={onChange}
          isSearchComponent={isSearchComponent}
          closeMenuOnSelect={closedMenu || closedMenuOnSelect || false}
          hideSelectedOptions={false}
          isCustom={isCustom}
          {...props}
        />
      )}

      {renderMessageError()}
    </BoxFill>
  )
}

InputSelect.defaultProps = {
  isMulti: false,
  isShowError: true,
  isSearchable: true,
  placeholder: '',
}

const mapStateToProps = (state: AppState) => ({
  isFetching: state.lists.get('isFetching'),
  lists: {
    states: state.lists.get('states').toJS(),
    jobs: state.lists.get('jobs').toJS(),
    projectTypes: state.lists.get('projectTypes').toJS(),
    projectTypesById: state.lists.get('projectTypesById').toJS(),
    itPackages: state.lists.get('itPackages').toJS(),
    projectValues: state.lists.get('projectValues').toJS(),
    weeks: state.lists.get('weeks').toJS(),
    searchWeeks: state.lists.get('searchWeeks').toJS(),
    status: state.lists.get('status').toJS(),
    priority: state.lists.get('priority').toJS(),
    publicEmployers: state.lists.get('publicEmployers').toJS(),
  },
})

export default connect(
  mapStateToProps,
  {}
)(InputSelect)
