import React from 'react'

import { Box, Span, Image } from 'styles'
import { AddItemIconContainer, ImageWrapper } from './styles'

type Props = {
  onClick: () => void
  label: string
}

const AddItemIcon: React.FC<Props> = ({ onClick, label }) => {
  return (
    <AddItemIconContainer onClick={onClick}>
      <ImageWrapper>
        <Image pointer src={require('assets/icons/plus.svg')} />
      </ImageWrapper>
      <Box pointer ml="6px">
        <Span secondColor>{label}</Span>
      </Box>
    </AddItemIconContainer>
  )
}

export default AddItemIcon
