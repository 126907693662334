import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { IJobPositionsWithMathcedCandidates } from 'types/models'

import Avatar from 'components/Avatar'

import { formatCurrency } from 'utils'
import { BoxFill, Box, BoxCol, Text, Bold, BoxCenterContent, SectorsTypeBox, Image } from 'styles'

const ItemBox = styled(Box).attrs(props => ({
  smooth: true,
  bc: props.theme.main.borderInputColor,
  padding: '5px 0px',
}))`
  p {
    color: ${props => props.theme.main.inputLabelTextColor};
    padding: 5px 10px;
  }
  :hover .popUpBox {
    display: block;
    margin-top: 6px;
    div {
      padding: 4px 10px;
      margin-top: 6px;
    }
  }
`
const ItemText = styled(Text).attrs(props => ({
  whiteSpace: true,
  textOverflow: true,
  overflow: 'hidden',
  fz: '14px',
  padding: '0 8px',
}))``

const PopUpBox = styled(BoxCol).attrs({
  whiteBC: true,
  absolute: true,
  shadow2: true,
  smooth: true,
  zIndex: '10',
  display: 'none',
  padding: '10px',
  top: '40px',
})``

const TopWrap = styled(Box).attrs({})`
  @media (max-width: 1400px) {
    flex-direction: column;
    margin: 5px 0 0 0;
    div {
      margin: 0 0 5px 0;
    }
  }
`

const BoldStyled = styled(Bold).attrs({})`
  img {
    margin: 0 6px -3px 0;
  }
`

type Props = {
  job: IJobPositionsWithMathcedCandidates
  match: (JobPositionId: number) => void
  onClick: (job: IJobPositionsWithMathcedCandidates) => void
}

const PotentialJobCard: React.FC<Props> = ({ job, match, onClick }) => {
  const abbrJob = job.Employer.companyName.split(' ').map(char => char[0])
  const setJobId = () => onClick(job)
  const matchCandidate = (e: React.MouseEvent) => {
    e.stopPropagation()
    match(job.id)
  }

  const renderItems = (items: Array<{ name: string }>) => {
    const firstItem = items.length ? items[0].name : false
    const secondItem = items.length >= 2 ? items[1].name : false
    const isShowNumberOfItems = items.length > 2
    const maxWidth = secondItem ? '200px' : '100%'
    if (!items.length)
      return (
        <Box>
          <ItemBox>
            <ItemText>Any</ItemText>
          </ItemBox>
        </Box>
      )
    return (
      <TopWrap>
        {!firstItem || (
          <ItemBox maxWidth={maxWidth}>
            <ItemText>{firstItem}</ItemText>
          </ItemBox>
        )}
        {!secondItem || (
          <ItemBox maxWidth={maxWidth} ml="12px">
            <ItemText>{secondItem}</ItemText>
          </ItemBox>
        )}
        {!isShowNumberOfItems || (
          <ItemBox pointer justifyCenter relative ml="12px">
            <ItemText pointer>{items.length - 2}</ItemText>
            <PopUpBox className="popUpBox">
              {items.slice(2).map(item => (
                <ItemBox key={item.name}>
                  <ItemText>{item.name}</ItemText>
                </ItemBox>
              ))}
            </PopUpBox>
          </ItemBox>
        )}
      </TopWrap>
    )
  }

  return (
    <BoxFill allPointer defaultBorder smooth column shadow2 whiteBC mb="10px" padding="20px" onClick={setJobId}>
      <BoxFill spaceBetween mb="16px">
        <Box alignCenter>
          <Box>
            <Box zIndex="5">
              <Avatar chars={abbrJob} width="49px" />
            </Box>
            <BoxCenterContent whiteBC width="49px" height="49px" ml="-10px" zIndex="5" br="50%" shadow2>
              <Text xs black>
                {job.complianceDegree}%
              </Text>
            </BoxCenterContent>
          </Box>
          <BoxCol ml="10px">
            <BoldStyled l black left>
              {job.public && <Image src={require('assets/icons/user-icon.svg')} />}
              {job.Position.name}
            </BoldStyled>
            <Box>
              <Box pr="8px" borderRight="1px solid #E3E8F1">
                <Text black>{job.Employer.companyName}</Text>
              </Box>
              <Box ml="8px">
                <Text>
                  {job.City.name} {job.City.State.name}
                </Text>
              </Box>
            </Box>
          </BoxCol>
        </Box>
        <Box alignCenter>
          <SectorsTypeBox>{job.Sectors && job.Sectors.length > 0 && <>{renderItems(job.Sectors)}</>}</SectorsTypeBox>
          <Box ml="7px" smooth bc="#D8F0E9" padding="10px 14px">
            <Text s secondColor>
              ${formatCurrency(job.salary)}
            </Text>
          </Box>
        </Box>
      </BoxFill>
      {!job.description || (
        <BoxFill maxHeight="32px" overflow="hidden" mb="16px">
          <Text lh="16px" black s left>
            {job.description}
          </Text>
        </BoxFill>
      )}
      <BoxFill alignCenter spaceBetween>
        <Box alignCenter>
          <BoxCenterContent onClick={matchCandidate} smooth bc="#D8F0E9" padding="12px 40px">
            <Text secondColor>MATCH</Text>
          </BoxCenterContent>
          <Box ml="16px">
            <Text s>Added {moment(job.createdAt).fromNow()}</Text>
          </Box>
        </Box>
        <Box>
          {job.Candidates.map(candidate => (
            <Box key={candidate.id} ml="-10px">
              <Avatar item={candidate} width="38px" />
            </Box>
          ))}
        </Box>
      </BoxFill>
    </BoxFill>
  )
}

export default PotentialJobCard
