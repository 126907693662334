import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'

import { IInvoice, IMatched } from 'types/models'

import Avatar from 'components/Avatar'

import { numberWithCommas } from 'utils'
import { Text } from 'styles'
import theme from 'styles/theme'
import { Wrap, TABLE, THEAD, TR, TD, TBODY, TDoneText, AvaText, Invoice, Circle, Create } from './styles'

type Props = {
  invoices: IInvoice[]
  onOpenModal: (matched: IMatched) => void
}

const MoneyTracker: React.FC<Props & RouteComponentProps> = ({ invoices, onOpenModal, history }) => {
  const gotToCandidate = (id: number) => history.push(`/candidates/candidate/${id}`)
  const gotToEmployer = (id: number) => history.push(`/live-jobs/employer/${id}`)
  const gotToJobPosition = (id: number, jobId: number) => history.push(`/live-jobs/employer/${id}?job=${jobId}`)
  return (
    <Wrap>
      <TABLE>
        <THEAD>
          <TR>
            <TD>Position/Employer</TD>
            <TD>Candidate</TD>
            <TD>Start date</TD>
            <TD>Salary</TD>
            <TD>Commission</TD>
            <TD>Status</TD>
          </TR>
        </THEAD>
        <TBODY>
          {invoices.map(invoice => {
            const lastName = get(invoice, 'Matched.Candidate.lastName', '')
            const firstName = get(invoice, 'Matched.Candidate.firstName', '')
            // const minSalary = get(invoice, 'Matched.Candidate.minSalary', '')
            const annualSalary = invoice.annualSalary

            const employerId = get(invoice, 'Matched.JobPosition.EmployerId', 0)
            const jobPositionId = get(invoice, 'Matched.JobPositionId')

            return (
              <TR key={invoice.id}>
                <TD>
                  <TDoneText s left color={theme.main.colors.grey3} pointer onClick={() => gotToJobPosition(employerId, jobPositionId)}>
                    {invoice.jobTitle}
                  </TDoneText>
                  <Text xs left pointer onClick={() => gotToEmployer(employerId)}>
                    {invoice.companyName}
                  </Text>
                </TD>
                <TD>
                  <Avatar item={invoice.Matched!.Candidate!} width="36px" onClick={() => gotToCandidate(invoice.Matched!.Candidate!.id)} />
                  <AvaText onClick={() => gotToCandidate(invoice.Matched!.Candidate!.id)}>
                    <Text s left color={theme.main.colors.grey3} pointer>
                      {firstName}
                    </Text>
                    <Text s left color={theme.main.colors.grey3} pointer>
                      {lastName}
                    </Text>
                  </AvaText>
                </TD>
                <TD>
                  <Text s left color={theme.main.colors.grey3}>
                    {moment(new Date(invoice.startDate)).calendar()}
                  </Text>
                </TD>
                <TD>
                  <Text s left color={theme.main.colors.grey3}>
                    {/* ${numberWithCommas(minSalary)} */}${annualSalary}
                  </Text>
                </TD>
                <TD>
                  <Text s left color={theme.main.colors.primary}>
                    ${numberWithCommas(parseInt(invoice.commissionSum, 10))}
                  </Text>
                  <Text s left>
                    {invoice.commissionPercent}%
                  </Text>
                </TD>
                <TD>
                  {invoice.accepted ? (
                    <Invoice s left color={theme.main.colors.primary}>
                      <Circle bg={theme.main.colors.primary} />
                      Invoice confirmed
                    </Invoice>
                  ) : invoice.declined ? (
                    <>
                      <Invoice s left color={theme.main.colors.red}>
                        <Circle bg={theme.main.colors.red} />
                        Invoice denied
                      </Invoice>
                      <Create
                        xs
                        left
                        color={theme.main.colors.secondary}
                        onClick={() => {
                          onOpenModal(invoice.Matched)
                        }}
                      >
                        Create new invoice >
                      </Create>
                    </>
                  ) : invoice.sent ? (
                    <Invoice s left color={theme.main.colors.secondary}>
                      <Circle bg={theme.main.colors.secondary} />
                      Invoice sent
                    </Invoice>
                  ) : null}
                </TD>
              </TR>
            )
          })}
        </TBODY>
      </TABLE>
    </Wrap>
  )
}

export default withRouter(MoneyTracker)
