import * as React from 'react'
import Employers from 'api/employers'

export interface EmployerOption {
  value: string
  label: string
  photo: string
}

export function useEmployersOptions(): {
  employers: EmployerOption[]
  getOptions: () => void
} {
  const [employers, setEmployers] = React.useState<EmployerOption[]>([])

  const getOptions = React.useCallback(async () => {
    try {
      const employersList = await Employers.getEmployersOptions()
      setEmployers(
        employersList.map(employer => ({
          label: employer.name,
          value: `${employer.id}`,
          photo: employer.photo,
        }))
      )
    } catch (e) {
      console.log({ e })
    }
  }, [])

  return {
    getOptions,
    employers,
  }
}
