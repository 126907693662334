import React from 'react'

import { ISelectListItem } from 'types/forms'
import { ICandidateWithLastWorkPlace } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import CityStateSelect from 'components/Input/cityStateSelect'
import InputCheckbox from 'components/Input/inputCheckbox'
import InputSelect from 'components/Input/InputSelect'

import { BoxFill, BoxCol, Text } from 'styles'

type Props = {
  form: Partial<ICandidateWithLastWorkPlace>
  onChangeField: (e: InputEvent & ISelectListItem & any, type?: string) => void
  errors: Partial<ICandidateWithLastWorkPlace>
}

const First: React.FC<Props> = ({ onChangeField, form, errors }) => {
  const onChangeCity = (e: InputEvent & ISelectListItem) => onChangeField(e, 'City')
  const onChangeRelocateCity = (e: InputEvent & ISelectListItem) => onChangeField(e, 'RelocateCity')
  return (
    <BoxFill column>
      <BoxFill mb="20px">
        <Input value={form.firstName} error={errors.firstName} onChange={onChangeField} name="firstName" label="First name" />
      </BoxFill>
      <BoxFill mb="20px">
        <Input value={form.lastName} error={errors.lastName} onChange={onChangeField} name="lastName" label="Last name" />
      </BoxFill>
      <BoxFill mb="20px">
        <Input value={form.birthday} error={errors.birthday} onChange={onChangeField} type="date" name="birthday" label="Date of birth (optional)" />
      </BoxFill>
      <BoxFill>
        <CityStateSelect error={errors.City} value={form.City!} onChange={onChangeCity} />
      </BoxFill>
      <BoxFill mb="20px">
        <InputCheckbox onChange={onChangeField} value={form.relocate} title="The candidate is ready to relocate" name="relocate" />
      </BoxFill>
      {form.relocate ? (
        <BoxCol mb="20px">
          <InputSelect
            optionsType="cities"
            placeholder="Choose cities"
            onChange={onChangeRelocateCity}
            label="City to relocate (optional)"
            isMulti
            isRelocateComponent
          />
          <Text s>Leave empty if candidate is ready to relocate to any city</Text>
        </BoxCol>
      ) : null}
      <BoxFill mb="20px">
        <Input value={form.phone} error={errors.phone} onChange={onChangeField} type="tel" name="phone" label="Phone number (optional)" />
      </BoxFill>
      <BoxFill>
        <Input value={form.email} error={errors.email} onChange={onChangeField} name="email" label="Email address (optional)" maxLength={70} />
      </BoxFill>
    </BoxFill>
  )
}

export default First
