import { createAction, ActionsUnion } from 'types/helps'
import { IDataAuth } from 'types/responseData'
import { IUserForm } from 'types/forms'
import { IUser } from 'types/models'
import { INotValidFieldsErr } from 'types/errors'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION'
export const COMPLETE_REGISTRATION_SUCCESS = 'COMPLETE_REGISTRATION_SUCCESS'
export const COMPLETE_REGISTRATION_FAIL = 'COMPLETE_REGISTRATION_FAIL'

export const REFRESH_LOGIN = 'REFRESH_LOGIN'
export const REFRESH_LOGIN_SUCCESS = 'REFRESH_LOGIN_SUCCESS'
export const REFRESH_LOGIN_FAIL = 'REFRESH_LOGIN_FAIL'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'

export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD'
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS'
export const SET_NEW_PASSWORD_FAIL = 'SET_NEW_PASSWORD_FAIL'

export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS'

export const LOGOUT = 'LOGOUT'

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

const AuthActions = {
  login: (email: string, pass: string) => createAction(LOGIN, { email, pass }),
  loginSuccess: (data: IDataAuth) => createAction(LOGIN_SUCCESS, data),
  loginFail: (error: INotValidFieldsErr) => createAction(LOGIN_FAIL, error),

  createUser: (user: Partial<IUserForm>) => createAction(CREATE_USER, user),
  createUserSuccess: () => createAction(CREATE_USER_SUCCESS),
  createUserFail: (error: INotValidFieldsErr) => createAction(CREATE_USER_FAIL, error),

  updateUser: (user: Partial<IUser>, type?: string) => createAction(UPDATE_USER, { user, type }),
  updateUserSuccess: (user: Partial<IUser>) => createAction(UPDATE_USER_SUCCESS, user),
  updateUserFail: (error: INotValidFieldsErr) => createAction(UPDATE_USER_FAIL, error),

  completeRegistrarion: (hash: string, pass: string) => createAction(COMPLETE_REGISTRATION, { hash, pass }),
  completeRegistrarionSuccess: (data: IDataAuth) => createAction(COMPLETE_REGISTRATION_SUCCESS, data),
  completeRegistrarionFail: (error: INotValidFieldsErr) => createAction(COMPLETE_REGISTRATION_FAIL, error),

  refreshLogin: () => createAction(REFRESH_LOGIN),
  refreshLoginSuccess: ({ token, user }: { token: string; user: IUser }) => createAction(REFRESH_LOGIN_SUCCESS, { token, user }),
  refreshLoginFail: (error?: INotValidFieldsErr) => createAction(REFRESH_LOGIN_FAIL, error),

  resetPassword: (email: string) => createAction(RESET_PASSWORD, email),
  resetPasswordSuccess: () => createAction(RESET_PASSWORD_SUCCESS),
  resetPasswordFail: (error: INotValidFieldsErr) => createAction(RESET_PASSWORD_FAIL, error),

  setNewPassword: (hash: string, pass: string) => createAction(SET_NEW_PASSWORD, { hash, pass }),
  setNewPasswordSuccess: (data: IDataAuth) => createAction(SET_NEW_PASSWORD_SUCCESS, data),
  setNewPasswordFail: (error: INotValidFieldsErr) => createAction(SET_NEW_PASSWORD_FAIL, error),

  uploadPhotoSuccess: (user: IUser) => createAction(UPLOAD_PHOTO_SUCCESS, user),

  logout: () => createAction(LOGOUT),

  clearNotification: () => createAction(CLEAR_NOTIFICATION),
}

export type AuthTypes = ActionsUnion<typeof AuthActions>

export default AuthActions
