const TEXT_OF_STEP = {
  firstStep: {
    title: 'Enter basic information about job position',
    subTitle: 'This information will be used on the job listing visible to all users',
    faq: {
      title: 'Please fill in all fields to proceed to the next step',
      text: `This information will be used on the job listing`,
    },
  },
  secondStep: {
    title: 'Enter hiring manager info',
    subTitle: 'Not all the fields are mandatory but we would recommend adding as much information as you can',
    faq: {
      title: 'Add more info about manager',
      text: `The more information you add the easier it will be to keep track of all your employers information.`,
    },
  },
  thirdStep: {
    title: 'Enter detailed job description (optional)',
    subTitle: `Please add as much details about your company and the role you 
      are recruiting for. Connect with the ideal candidate`,
    faq: {
      title: 'Last step',
      text: `Professional candidate’s details are very important.
      System use that information to perfectly match the
      candidate with the job position.`,
    },
  },
}

export default function(currentStep: number) {
  switch (currentStep) {
    case 1:
      return TEXT_OF_STEP.firstStep
    case 2:
      return TEXT_OF_STEP.secondStep
    case 3:
      return TEXT_OF_STEP.thirdStep
    default:
      return null
  }
}
