import styled from 'styled-components'

import { Text } from 'styles'

export const Wrap = styled.div`
  width: 100%;
`

export const TABLE = styled.table`
  width: 100%;
  border: none;
  td:first-child {
    border-radius: 6px 0 0 6px;
  }
  td:last-child {
    border-radius: 0 6px 6px 0;
  }
  tbody {
    td:nth-child(2) {
      display: flex;
      align-items: center;
    }
  }
`

export const THEAD = styled.thead`
  background: #f5f6f8;
  color: #9caab8;
  tr {
    height: 48px;
    font-size: 14px;
    td:first-child {
      padding: 5px 5px 5px 20px;
    }
  }
`

export const TR = styled.tr``

export const TD = styled.td`
  border: none;
`

export const TBODY = styled.tbody`
  tr {
    border-top: 1px solid #e3e8f1;
    td {
      height: 78px;
    }
    td:first-child {
      padding: 5px 5px 5px 20px;
    }
  }
  tr:first-child {
    border-top: none;
  }
`

export const TDoneText = styled(Text).attrs({})`
  font-weight: 500;
`

export const AvaText = styled.div`
  margin: 0 0 0 6px;
`

export const Invoice = styled(Text).attrs({})`
  font-weight: 500;
`

export const Create = styled(Text).attrs({})`
  font-weight: 500;
  cursor: pointer;
`

export const Circle = styled.span<{ bg: string }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 6px 2px 0;
  background: ${props => props.bg};
  display: inline-block;
`
