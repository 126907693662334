import React from 'react'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Button from 'components/Button'

import { BoxCol, Text } from 'styles'

import autosignatureModalImg from 'assets/images/autosignatureModal.png'

const Wrap = styled(BoxCol)`
  width: 389px;
  height: 461px;
  align-items: center;
  justify-content: center;
  z-index: 999;
`

const Img = styled.img`
  margin: 0 0 34px 0;
`

const Title = styled(Text)`
  font-weight: bold;
  margin: 0 0 10px 0;
`

const SubTitle = styled(Text)`
  color: #9caab8;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin: 0 0 24px 0;
`

const StyledButton = styled(Button)`
  text-transform: uppercase;
`

const Later = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #3cb593;
  margin: 22px 0 0 0;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

type Props = {
  autosignature: boolean
  handleCloseAutosignatureModal: () => void
  addSignature: () => void
  showAutosignature: string | null
}

export const AutosignatureModal: React.FC<Props> = ({ autosignature, handleCloseAutosignatureModal, addSignature, showAutosignature }) => {
  return (
    <Modal zIndex="999" isOpen={autosignature && !showAutosignature} hideCloseIcon onRequestClose={handleCloseAutosignatureModal}>
      <Wrap>
        <Img src={autosignatureModalImg} />
        <Title xl3 lh="28px" black>
          Autosignature
        </Title>
        <SubTitle>
          Would you like to add your <br /> autosignature for messaging?
        </SubTitle>
        <StyledButton width="180px" height="52px" title="ADD A SIGNATURE" onClick={addSignature} />
        <Later onClick={handleCloseAutosignatureModal}>Set up later</Later>
      </Wrap>
    </Modal>
  )
}
