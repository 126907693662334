import styled from 'styled-components'

import Button from 'components/Button'
import { BoxFullFill, BoxCol, Box, Text, BoxFill } from 'styles'

export const Wrap = styled.div`
  width: 100%;
  min-height: 90.5vh;
  padding: 0 0 50px 0;
`
export const Header = styled.div`
  height: 196px;
  background: ${props => props.theme.main.backgroundColor};
  padding: 40px 80px 0 80px;
  display: flex;
  justify-content: space-between;
`
export const Title = styled.p`
  font-weight: bold;
  font-size: 42px;
  line-height: 46px;
  color: #fff;
`

export const Main = styled.div`
  min-height: 70.5vh;
  padding: 0 80px;
  margin: -80px 0 0 0;
`

export const NCWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: inherit;
`
export const NCImg = styled.img`
  margin: 0 0 22px 0;
`

export const NCTitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${props => props.theme.main.colors.grey3};
  margin: 0 0 16px 0;
`

export const NCSubtitle = styled.p`
  line-height: 20px;
  text-align: center;
  color: ${props => props.theme.main.text};
  span {
    color: ${props => props.theme.main.colors.primary};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`

export const ContainerCard = styled(BoxFullFill).attrs({
  height: 'auto !important',
})`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
  width: 100%;
  margin-top: 30px;
  height: auto !important;
`

export const ModalWrap = styled(BoxCol).attrs({
  width: '570px',
  padding: '10px',
})``

export const NewTask = styled(Text).attrs({
  fw: 'bold',
})`
  margin: 0 0 10px 0;
`

export const NTSubtitle = styled(Text).attrs({})`
  margin: 0 0 16px 0;
`

export const InputBlock = styled(Box).attrs({
  width: '100%',
})`
  margin: 0 0 15px 0;
`
export const InputTwoBlocks = styled(BoxFill).attrs({
  width: '100%',
})`
  justify-content: space-between;
  margin: 0 0 15px 0;
  div {
    width: 267px;
  }
`

export const CreateTaskWrap = styled(Box).attrs({
  width: '100%',
  margin: '20px 0 10px 0',
})``

export const CreateTask = styled(Button).attrs({
  width: '165px',
  height: '60px',
})`
  margin: 0 auto;
`

export const NewCompany = styled(Text).attrs({
  fz: '14px',
})`
  margin: 4px 0 0 0;
  span {
    color: ${props => props.theme.main.colors.primary};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`

export const SelectAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

export const ScrollToTop = styled.img`
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
  cursor: pointer;
  border-radius: 50%;
  padding: 9px 8px;
  position: fixed;
  right: 15px;
  bottom: 9%;
  &:hover {
    opacity: 0.8;
  }
`

export const CloseModalContainer = styled(Box).attrs({
  pointer: true,
  absolute: true,
  top: '-2px',
  right: '-63px',
  whiteBC: true,
  padding: '14px',
  br: '50%',
  zIndex: '200',
})`
  &:hover {
    opacity: 0.9;
  }
`

export const Error = styled.span`
  color: red;
`
