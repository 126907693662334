import { fromJS, Map } from 'immutable'

import {
  CandidatesTypes,
  FETCH_ALL_CANDIDATES_STARTED,
  FETCH_ALL_CANDIDATES_SUCCESS,
  FETCH_ALL_CANDIDATES_FAIL,
  DELETE_CANDIDATE_SUCCESS,
  ON_SET_STATUS_CANDIDATE_SUCCESS,
  SET_CANDIDATE_DATA,
  CANDIDATE__CHANGE_NOTES_COUNT,
  CREATE_CANDIDATE_STARTED,
  CREATE_CANDIDATE_SUCCESS,
  CREATE_CANDIDATE_FAIL,
  DELETE_CV,
  DELETE_CV_SUCCESS,
  DELETE_CV_FAIL,
  DELETE_LICENSE,
  DELETE_LICENSE_SUCCESS,
  DELETE_LICENSE_FAIL,
  CREATE_PUBLIC_CANDIDATE,
  CREATE_PUBLIC_CANDIDATE_SUCCESS,
  CREATE_PUBLIC_CANDIDATE_FAIL,
  CREATE_PUBLIC_CANDIDATEWITHOUT_ID,
  CREATE_PUBLIC_CANDIDATEWITHOUT_ID_SUCCESS,
  CREATE_PUBLIC_CANDIDATEWITHOUT_ID_FAIL,
} from './actions'

import { ICandidatesState } from 'types/reducerStates'
import { ICandidate } from 'types/models'

import { convertArrayToMapByIdAsKey } from 'utils'
import { validateField } from 'services/validation'

const setInDay = (path: string[]) => ['day', ...path]
const setInMonth = (path: string[]) => ['month', ...path]
const setInYear = (path: string[]) => ['year', ...path]

const initialState: ICandidatesState = fromJS({
  isFetching: false,
  query: {
    page: null,
    perPage: null,
  },
  items: Map(),
  candidatesQty: null,
  pagesQty: null,
  error: null,
  day: {
    value: '',
    error: '',
  },
  month: {
    value: '',
    error: '',
  },
  year: {
    value: '',
    error: '',
  },
})

export default (state = initialState, action: CandidatesTypes): ICandidatesState => {
  const items = state.get('items')
  switch (action.type) {
    case FETCH_ALL_CANDIDATES_STARTED:
      return state.set('isFetching', true)
    case CANDIDATE__CHANGE_NOTES_COUNT:
      return state.set(
        'items',
        Map(
          convertArrayToMapByIdAsKey(
            state.get('items').map((item: ICandidate) => {
              if (item.id === action.payload.id) {
                item.notesCount += action.payload.increase
              }
              return item
            })
          )
        )
      )
    case FETCH_ALL_CANDIDATES_SUCCESS:
      return state
        .set('isFetching', false)
        .set('items', Map(convertArrayToMapByIdAsKey(action.payload.candidates)))
        .set('query', action.payload.query)
        .set('candidatesQty', action.payload.candidatesQty)
        .set('pagesQty', action.payload.pagesQty)
        .set('error', null)
    case DELETE_CANDIDATE_SUCCESS:
      return state.set('items', items.delete(action.payload))
    case FETCH_ALL_CANDIDATES_FAIL:
      return state.set('isFetching', false).set('error', action.payload)

    case CREATE_CANDIDATE_STARTED:
      return state.set('isFetching', true)
    case CREATE_CANDIDATE_SUCCESS:
      return state.set('isFetching', false)
    case CREATE_CANDIDATE_FAIL:
      return state.set('error', action.payload).set('isFetching', false)
    case CREATE_PUBLIC_CANDIDATE:
      return state.set('isFetching', true)
    case CREATE_PUBLIC_CANDIDATE_SUCCESS:
      return state.set('isFetching', false)
    case CREATE_PUBLIC_CANDIDATE_FAIL:
      return state.set('error', action.payload).set('isFetching', false)
    case CREATE_PUBLIC_CANDIDATEWITHOUT_ID:
      return state.set('isFetching', true)
    case CREATE_PUBLIC_CANDIDATEWITHOUT_ID_SUCCESS:
      return state.set('isFetching', false)
    case CREATE_PUBLIC_CANDIDATEWITHOUT_ID_FAIL:
      return state.set('error', action.payload).set('isFetching', false)
    case ON_SET_STATUS_CANDIDATE_SUCCESS:
      return state.set('items', items.set(action.payload.id, action.payload))
    case SET_CANDIDATE_DATA:
      if (action.payload.name === 'day') {
        return state
          .setIn(setInDay(['value']), action.payload.value)
          .setIn(setInDay(['error']), validateField(action.payload.name, action.payload.value) || '')
      }
      if (action.payload.name === 'month') {
        return state
          .setIn(setInMonth(['value']), action.payload.value)
          .setIn(setInMonth(['error']), validateField(action.payload.name, action.payload.value) || '')
      }
      if (action.payload.name === 'year') {
        return state
          .setIn(setInYear(['value']), action.payload.value)
          .setIn(setInYear(['error']), validateField(action.payload.name, action.payload.value) || '')
      }
      return state
    case DELETE_CV:
      return state.set('isFetching', true)
    case DELETE_CV_SUCCESS:
      return state.set('isFetching', false)
    case DELETE_CV_FAIL:
      return state.set('isFetching', false)
    case DELETE_LICENSE:
      return state.set('isFetching', true)
    case DELETE_LICENSE_SUCCESS:
      return state.set('isFetching', false)
    case DELETE_LICENSE_FAIL:
      return state.set('isFetching', false)
    default:
      return state
  }
}
