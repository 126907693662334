import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { ValidationError } from 'yup'

import { getUserByHash } from 'api/auth'

import AuthActions from 'store/auth/actions'
import { AppState } from 'store/rootReducer'
import { INotValidFieldsErr } from 'types/errors'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import { Logo } from 'components/Icons'
import PrimaryButton from 'components/Button'
import { RenderNotValidFields } from 'components/Others'

import { setPasswordSchema, formatErrors } from 'services/validation'

import { H1, H2, Box, BoxCenterContent, Image, Text } from 'styles'

type Props = {
  error: INotValidFieldsErr
  completeRegistration: typeof AuthActions.completeRegistrarion
  successRegistered: boolean
}

const LastStep: React.FC<Props & RouteComponentProps> = ({ location, completeRegistration, error, successRegistered, history }) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [email, setEmail] = useState('')
  const [percent, setPercent] = useState(0)
  const [errors, setErrors] = useState<Partial<{ password: string; confirmPassword: string }>>({})
  const HASH = location.search.split('=')[1]

  useEffect(() => {
    getUserByHash(HASH).then(({ data }) => {
      setEmail(data.email)
      setPercent(data.total_percent)
    })
  }, [])

  useEffect(() => {
    if (successRegistered) {
      history.replace('/')
    }
  }, [successRegistered])

  const onChangePassword = (e: InputEvent) => setPassword(e.target.value)
  const onChangeConfirmPassword = (e: InputEvent) => setConfirmPassword(e.target.value)

  const onFinishRegistrarion = () => {
    return setPasswordSchema
      .validate({ password, confirmPassword }, { abortEarly: false })
      .then(() => completeRegistration(HASH, password))
      .catch((err: ValidationError) => setErrors(formatErrors(err)))
  }

  return (
    <Box fixed>
      <Box column alignCenter mainBC width="65vw" height="100vh">
        <Box mt="75px">
          <Logo />
        </Box>
        <Box column alignCenter width="65vw">
          <Image width="50vw" height="60vh" src={require('assets/images/signupSlider.png')} />
          <Box>
            <H1>Finish your registration!</H1>
          </Box>
          <Box>
            <Text width="35vw">You are almost ready to start, join thousands of recruiters 10x their cashflow by using RecruitersLife.</Text>
          </Box>
        </Box>
      </Box>
      <BoxCenterContent column padding="0 5vw" height="100vh">
        <BoxCenterContent column height="100vh">
          <H2 fw="bold" black>
            Last step
          </H2>
          <Box margin="10px 0 40px 0" column>
            <Text>
              Congratulations! Now you can use full RecruitersLife site. You will be paid {percent}% in total for your successful deals.
              <br />
              Please create a password for your account.
            </Text>
          </Box>
          <Box fill>
            <Input
              disabled
              rightElement={<Image src={require('assets/icons/successCheckbox.svg')} />}
              value={email}
              type="email"
              label="Email address"
              maxLength={70}
            />
          </Box>
          <Box fill margin="15px 0">
            <Input value={password} onChange={onChangePassword} error={errors.password} type="password" label="Create password" />
          </Box>
          <Box fill>
            <Input
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              error={errors.confirmPassword}
              type="password"
              label="Confirm password"
            />
          </Box>
          <RenderNotValidFields errors={error} />
          <Box height="60px" fill margin="40px 0">
            <PrimaryButton onClick={onFinishRegistrarion} title="REGISTER" />
          </Box>
        </BoxCenterContent>
      </BoxCenterContent>
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  successRegistered: state.auth.get('successRegistered'),
  error: state.auth.get('error'),
})

export default connect(
  mapStateToProps,
  { completeRegistration: AuthActions.completeRegistrarion }
)(LastStep)
