import styled from 'styled-components'

import { Box, BoxFill } from 'styles'

export const Container = styled(Box).attrs({
  whiteBC: true,
  mb: '30px',
  column: true,
  width: '45vw',
  ml: '40px',
  br: '6px',
})`
  @media (max-width: 768px) {
    align-items: center;
    width: auto;
    max-width: 100%;
    margin: 0 20px;
    padding: 0;
  }
`

export const Footer = styled(BoxFill).attrs({
  alignCenter: true,
  spaceBetween: true,
  mt: '40px',
  padding: '10px 0',
  bt: '1px solid #E1E3EE',
})``

export const Header = styled(Box).attrs({
  column: true,
  padding: '40px',
})`
  @media (max-width: 768px) {
    padding: 20px;
  }
`

export const Wrap = styled(BoxFill).attrs({})`
  padding: 0 40px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

export const WrapBack = styled(Box).attrs({})`
  @media (max-width: 768px) {
    padding: 0 20px 0 30px;
  }
`

export const WrapButton = styled(Box).attrs({})`
  @media (max-width: 768px) {
    padding: 0 20px 0 10px;
  }
`
