import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { AppState } from 'store/rootReducer'

import Sidebar from './components/Sidebar'
import Content from './components/Content'

import { Text } from 'styles'
import { Wrap, Header, Title, Main, WrapNoJob, TitleNoJob, SubtitleNoJob } from './styles'

import noJobPositionsIcon from 'assets/images/no-job-positions-.png'

type Props = {
  allEmployers: any
  allJobPositions: any
  jobs: any
}

const LiveJobsNew: React.FC<Props & RouteComponentProps> = ({ allEmployers, allJobPositions, jobs, history }) => {
  const employersRoute = () => history.push('/live-jobs')
  const employersWithJobsCount = allEmployers && allEmployers.filter((el: any) => el.jobPositionCount > 0)
  const [selectedCompanyId, setSelectedCompanyId] = React.useState<Nullable<number>>(null)

  const employersCount = React.useMemo(() => {
    return `${employersWithJobsCount ? employersWithJobsCount.length : 0}
       ${employersWithJobsCount && employersWithJobsCount.length === 1 ? 'employer' : 'employers'}`
  }, [employersWithJobsCount])

  return (
    <Wrap>
      <Header>
        <Title>Live Jobs</Title>
        <Text left s opacity="0.6">
          {`${employersCount} in total`}
        </Text>
      </Header>
      {!jobs && allJobPositions && !allJobPositions.length ? (
        <WrapNoJob column>
          <img width="213px" height="74px" src={noJobPositionsIcon} alt="you have no job position" />
          <TitleNoJob>You have no job positions</TitleNoJob>
          <SubtitleNoJob>
            Start by adding a company at the <span onClick={() => employersRoute()}>employers section</span>, then add a job position at the employer
            profile
          </SubtitleNoJob>
        </WrapNoJob>
      ) : (
        <Main>
          <Sidebar allEmployers={allEmployers} allJobsAmount={allJobPositions && allJobPositions.length} onCompanySelect={setSelectedCompanyId} />
          <Content allJobPositions={allJobPositions} jobs={jobs} selectedCompanyId={selectedCompanyId} />
        </Main>
      )}
    </Wrap>
  )
}

const mapStateToProps = (state: AppState) => ({
  allEmployers: state.employers.get('allEmployers'),
  allJobPositions: state.employers.get('allJobPositions'),
  jobs: state.currentInnerPage.get('liveJobsAllPositions'),
})

export default connect(
  mapStateToProps,
  {}
)(withRouter(LiveJobsNew))
