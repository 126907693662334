import React from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { Map } from 'immutable'
import moment from 'moment'
import { RouteComponentProps } from 'react-router-dom'

import AdminApi from 'api/admin'

import { AppState } from 'store/rootReducer'
import AuthActions from 'store/auth/actions'
import { IUser } from 'types/models'
import { IQueryParams, InputEvent } from 'types/helps'

import Input from 'components/Input'
import Button from 'components/Button'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'

import { convertArrayToMapByIdAsKey } from 'utils'
import { BoxFill, Box, Text, Span, Image } from 'styles'

type query = {
  page: number
  perPage: number
}

type Props = {
  user: IUser
  isLoggedIn: boolean
  isFetching: boolean
  error: any
  login: (email: string, password: string) => void
  logout: typeof AuthActions.logout
}

type State = {
  query: query
  pagesQty: number
  users: Map<number, IUser>
  usersQty: number
  email: string
  password: string
}

class AdminPanel extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    query: {
      page: 0,
      perPage: 24,
    },
    pagesQty: 0,
    users: Map(),
    usersQty: 0,
    email: '',
    password: '',
  }

  componentDidMount() {
    const page = this.props.location.search.split('=')[1]
    this.fetchUsers({ page })
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.user !== nextProps.user) {
      const page = this.props.location.search.split('=')[1]
      this.fetchUsers({ page })
    }
  }

  fetchUsers = (query: IQueryParams) => {
    AdminApi.getUsers(query).then(({ data }: any) =>
      this.setState({
        query: data.query,
        pagesQty: data.pagesQty,
        users: convertArrayToMapByIdAsKey(data.users),
        usersQty: data.usersQty,
      })
    )
  }

  deleteUser = (id: number) => {
    Alert({
      titleText: 'Are you sure you want to delete the user?',
      text: ``,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#F95565',
      cancelButtonColor: '#64707D',
    }).then(result => {
      if (result.value) {
        AdminApi.deleteUser(id).then(() => this.setState({ users: this.state.users.delete(id) }))
        Swal.fire('User deleted!', undefined, 'success')
      }
    })
  }

  approveUser = (id: number) => {
    AdminApi.approveUser(id)
      .then(() => {
        const page = this.props.location.search.split('=')[1]
        this.fetchUsers({ page })
      })
      .then(() => Swal.fire('User approved!', 'Email sent', 'success'))
  }

  fetchPage = (query: IQueryParams) => this.fetchUsers(query)

  onChangeField = (e: InputEvent) => {
    const { name, value } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  loginFlow = () => this.props.login(this.state.email, this.state.password)

  logout = () => {
    localStorage.removeItem('persist:root')
    this.props.logout()
  }

  render() {
    const { users, query, pagesQty, email, password } = this.state
    const { user, isLoggedIn, isFetching, error } = this.props
    if (isFetching) return <Loader />
    if (!isLoggedIn)
      return (
        <Box justifyCenter alignCenter column>
          <Box margin="3% 0">
            <Image width="30vw" src={require('assets/images/Logo-RL_black.png')} />
          </Box>
          <Box column width="300px">
            <BoxFill mb="15px">
              <Input value={email} onChange={this.onChangeField} name="email" label="Email address" maxLength={70} />
            </BoxFill>
            <BoxFill>
              <Input value={password} type="password" onChange={this.onChangeField} name="password" label="Enter your password" />
            </BoxFill>
            <BoxFill height="60px" mt="15px">
              <Button title="LOGIN" onClick={this.loginFlow} />
            </BoxFill>
          </Box>
          {error && (
            <Box mt="10px">
              <Text red>{error.message}</Text>
            </Box>
          )}
        </Box>
      )
    if (user.id !== 1)
      return (
        <BoxFill justifyCenter alignCenter column>
          <Text black l>
            You are not admin
          </Text>
          <Button type="load" title="LOGOUT" onClick={this.logout} />
        </BoxFill>
      )
    if (users.isEmpty())
      return (
        <BoxFill>
          <BoxFill column justifyCenter alignCenter minHeight="100vh">
            <Box margin="3% 0">
              <Image width="30vw" src={require('assets/images/Logo-RL_black.png')} />
            </Box>
            <Text>No Users</Text>
            <BoxFill justifyCenter alignCenter margin="15px">
              <Pagination fetchPage={this.fetchPage} pagesQty={pagesQty} currentPage={query.page} />
            </BoxFill>
          </BoxFill>
          <Box absolute width="60px" height="30px" top="25px" right="25px">
            <Button type="load" title="LOGOUT" onClick={this.logout} />
          </Box>
        </BoxFill>
      )
    return (
      <BoxFill>
        <BoxFill column justifyCenter alignCenter minHeight="100vh">
          <Box margin="3% 0">
            <Image width="30vw" src={require('assets/images/Logo-RL_black.png')} />
          </Box>
          <table>
            <tr>
              <th>id</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>LinkedIn</th>
              <th>Activated</th>
              <th>Created</th>
              <th>Active</th>
              <th>Approved</th>
              <th colSpan={2}>Actions</th>
            </tr>
            {users.valueSeq().map((user, index) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>
                  {user.firstName} {user.lastName}
                </td>
                <td>
                  <Span onClick={() => window.open(`mailto:${user.email}`)}>{user.email}</Span>
                </td>
                <td>{user.phone}</td>
                <td>
                  <Span onClick={() => window.open(user.linkedIn)}>{user.linkedIn}</Span>
                </td>
                <td>{user.activated ? moment(user.activated).format('LLL') : 'Not activated'}</td>
                <td>{moment(user.createdAt).format('LLL')}</td>
                <td>{user.active ? 'yes' : 'no'}</td>
                <td>{user.approved ? moment(user.approved).format('LLL') : 'Not approved'}</td>
                {user.approved ? null : (
                  <td>
                    <Box width="60px" height="30px">
                      <Button title="Approve" onClick={() => this.approveUser(user.id)} />
                    </Box>
                  </td>
                )}
                <td>
                  <Box width="60px" height="30px">
                    <Button type="danger" title="Delete" onClick={() => this.deleteUser(user.id)} />
                  </Box>
                </td>
              </tr>
            ))}
          </table>
          <BoxFill justifyCenter alignCenter margin="15px">
            <Pagination fetchPage={this.fetchPage} pagesQty={pagesQty} currentPage={query.page} />
          </BoxFill>
        </BoxFill>
        <Box absolute width="60px" height="30px" top="25px" right="25px">
          <Button type="load" title="LOGOUT" onClick={this.logout} />
        </Box>
      </BoxFill>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.auth.get('user'),
  isLoggedIn: state.auth.get('isLoggedIn'),
  isFetching: state.auth.get('isFetching'),
  error: state.auth.get('error'),
})

export default connect(
  mapStateToProps,
  {
    login: AuthActions.login,
    logout: AuthActions.logout,
  }
)(AdminPanel)
