import { fromJS, List } from 'immutable'

import {
  SearchTypes,
  CHANGE_INPUT_VALUE,
  CHANGE_SELECT_VALUE,
  SET_VALUE_FROM_SAVED_SEARCH,
  CLEAR_INPUT_VALUE,
  SEARCH_CANDIDATES,
  SEARCH_CANDIDATES_SUCCESS,
  SEARCH_CANDIDATES_FAIL,
  SEARCH_JOBS,
  SEARCH_JOBS_SUCCESS,
  SEARCH_JOBS_FAIL,
  SEARCH_COMPANIES,
  SEARCH_COMPANIES_SUCCESS,
  SEARCH_COMPANIES_FAIL,
  SEARCH_CONTACTS,
  SEARCH_CONTACTS_SUCCESS,
  SEARCH_CONTACTS_FAIL,
  GET_SEARCH_LIST,
  GET_SEARCH_LIST_SUCCESS,
  GET_SEARCH_LIST_FAIL,
} from './action'

import { ISearchState } from 'types/reducerStates'

const initialState: ISearchState = fromJS({
  isFetching: false,
  searchCandidates: List([]),
  searchJobs: List([]),
  searchCompanies: List([]),
  searchContacts: List([]),
  canidates: List([]),
  jobs: List([]),
  companies: List([]),
  contacts: List([]),
  error: null,
  candidatesKeywords: '',
  candidatesJobTitle: '',
  jobsJobTitle: '',
  jobsCompanyName: '',
  companiesJobTitle: '',
  contactsKeywords: '',
  contactsJobTitle: '',
})

export default (state = initialState, action: SearchTypes): ISearchState => {
  switch (action.type) {
    case CHANGE_INPUT_VALUE:
      return state.set(action.payload.name, action.payload.value)
    case CHANGE_SELECT_VALUE:
      return state.set(action.payload.name, action.payload.value)
    case SEARCH_CANDIDATES:
      return state.set('isFetching', true)
    case SEARCH_CANDIDATES_SUCCESS:
      return state
        .set('isFetching', false)
        .set('searchCandidates', action.payload)
        .set('error', null)
    case SEARCH_CANDIDATES_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case SEARCH_JOBS:
      return state.set('isFetching', true)
    case SEARCH_JOBS_SUCCESS:
      return state
        .set('isFetching', false)
        .set('searchJobs', action.payload)
        .set('error', null)
    case SEARCH_JOBS_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case SEARCH_COMPANIES:
      return state.set('isFetching', true)
    case SEARCH_COMPANIES_SUCCESS:
      return state
        .set('isFetching', false)
        .set('searchCompanies', action.payload)
        .set('error', null)
    case SEARCH_COMPANIES_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case SEARCH_CONTACTS:
      return state.set('isFetching', true)
    case SEARCH_CONTACTS_SUCCESS:
      return state
        .set('isFetching', false)
        .set('searchContacts', action.payload)
        .set('error', null)
    case SEARCH_CONTACTS_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case GET_SEARCH_LIST:
      return state.set('isFetching', true)
    case GET_SEARCH_LIST_SUCCESS:
      return state
        .set('isFetching', false)
        .set(action.payload.type, action.payload.data)
        .set('error', null)
    case GET_SEARCH_LIST_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case SET_VALUE_FROM_SAVED_SEARCH:
      return state.set(action.payload.name, action.payload.value)
    case CLEAR_INPUT_VALUE:
      return state
        .set('candidatesKeywords', '')
        .set('candidatesJobTitle', '')
        .set('candidatesCity', '')
        .set('candidatesAvailability', '')
        .set('candidatesProjectTypes', '')
        .set('jobsJobTitle', '')
        .set('jobsCompanyName', '')
        .set('jobsCity', '')
        .set('jobsStatus', '')
        .set('jobsProjectTypes', '')
        .set('companiesJobTitle', '')
        .set('companiesCity', '')
        .set('companiesProjectTypes', '')
        .set('contactsKeywords', '')
        .set('contactsJobTitle', '')
        .set('contactsCity', '')
        .set('contactsProjectTypes', '')
    default:
      return state
  }
}
