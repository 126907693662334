import React from 'react'

import { Box, BoxFill, Text, H2 } from 'styles'

type Props = {
  image: React.ReactNode
  title: string
  subTitle: string
}

const BodyComponent: React.FC<Props> = ({ image, title, subTitle }) => (
  <BoxFill minHeight="calc(100vh - 285px)">
    <BoxFill column alignCenter>
      <Box mt="112px" mb="40px">
        {image}
      </Box>
      <Box maxWidth="250px">
        <H2 black fw="bold">
          {title}
        </H2>
      </Box>
      <Box mb="30px" mt="10px" maxWidth="350px">
        <Text>{subTitle}</Text>
      </Box>
    </BoxFill>
  </BoxFill>
)

export default BodyComponent
