import * as React from 'react'
import styled from 'styled-components'

import { IThemes } from 'styles/interfaces'

type ISetColor = {
  theme: IThemes
  type: string | null
}

function setColor(props: ISetColor) {
  const { secondary, primary, load, lightGreen, danger } = props.theme.main.buttonsColor
  switch (props.type) {
    case 'secondary':
      return secondary
    case 'load':
      return load
    case 'lightGreen':
      return lightGreen
    case 'danger':
      return danger
    default:
      return primary
  }
}

type ContainerProps = {
  type: string | null
  width?: string
  height?: string
  padding?: string
  disabled?: boolean
}

const Container = styled.div<ContainerProps>`
  background-color: ${props => setColor(props)};
  border-radius: 6px;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '100%'};
  padding: ${props => props.padding};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  :hover {
    filter: brightness(90%);
  }
`

const Text = styled.p<{ black: boolean; green: boolean }>`
  color: ${props => (props.green ? props.theme.main.buttonsColor.primary : props.black ? props.theme.main.inputLabelTextColor : '#ffffff')};
  margin: 0;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 500;
`

type Props = {
  title: string
  type?: 'secondary' | 'load' | 'lightGreen' | 'danger'
  width?: string
  height?: string
  padding?: string
  onClick: () => void
  disabled?: boolean
}

const Button: React.FunctionComponent<Props> = ({ title, onClick, type = null, width, height, padding, disabled = false }) => (
  <Container type={type} width={width} height={height} padding={padding} onClick={disabled ? () => {} : onClick} disabled={disabled}>
    <Text green={type === 'lightGreen'} black={type === 'load'}>
      {title}
    </Text>
  </Container>
)

export default Button
