import React, { useState } from 'react'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'

import WorkPlace from './components/workPlace'
import WorkPlaceForm from './components/workPlaceForm'

import { BoxFill, Span } from 'styles'

type Props = {
  workPlaces: any
  candidateId: number
}

const WorkPlaces: React.FC<Props> = ({ workPlaces, candidateId }) => {
  const [isAddingWorkPlace, setIsAddingWorkPlace] = useState(false)
  const toggleIsAddingWorkPlace = () => setIsAddingWorkPlace(!isAddingWorkPlace)
  return (
    <BoxFill column>
      {workPlaces.valueSeq().map((work: any, i: number) => (
        <WorkPlace key={i} work={work} candidateId={candidateId} />
      ))}
      {isAddingWorkPlace && <WorkPlaceForm candidateId={candidateId} onCancel={toggleIsAddingWorkPlace} />}
      {!isAddingWorkPlace && (
        <BoxFill>
          <Span onClick={toggleIsAddingWorkPlace}>Add new work experience +</Span>
        </BoxFill>
      )}
    </BoxFill>
  )
}

const mapStateToProps = (state: AppState) => ({
  workPlaces: state.currentInnerPage.get('workPlaces'),
})

export default connect(mapStateToProps)(WorkPlaces)
