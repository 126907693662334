import styled from 'styled-components'

import { NoImage } from 'components/NoImage'
import Button from 'components/Button'

type ChangeStatusProps = {
  isHovering?: boolean
}

type PopupProps = {
  isOpen?: boolean
  isFirstColumnId?: boolean
  isLastColumnId?: boolean
  optionVerticalPosition: number
  optionHorizontalPosition: number
  isHovering?: boolean
}

export const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 15px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  box-shadow: 0px 2px 6px rgba(231, 236, 245, 0.5);
  border-radius: 6px;
  background: #fff;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 4px 0;
`

export const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const DotWrapper = styled.div`
  width: 40px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: -10px;
  padding-right: 10px;
`

export const Dots = styled.img`
  padding: 5px 0;
  cursor: pointer;
`

export const Center = styled.div`
  margin-bottom: 10px;
`

export const Company = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #9caab8;
  border-right: 1px solid #e3e8f1;
  height: 18px;
  padding: 0 6px 0 0;
  margin: 0 6px 0 0;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const Price = styled.span`
  background: #d8f0e9;
  border-radius: 6px;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #3cb593;
  padding: 4px 8px;
`
export const Bottom = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const Avatar = styled.img`
  width: 42px;
  height: 42px;
  margin: 0 6px 0 0;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
`

export const NoImageBlock = styled(NoImage).attrs({
  width: '42px',
  height: '42px',
})``

export const Name = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 0 6px;
`

export const Create = styled(Button).attrs({
  width: '100%',
  height: '40px',
})``

export const TopWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const TitleModal = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #292c38;
  padding: 20px 0 10px 0;
`

export const CloseModal = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloseModalIcon = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #9caab8;
  width: 350px;
  margin: 0 auto 30px auto;
`

export const InputBlock = styled.div`
  width: 545px;
  margin: 0 0 20px 0;
`

export const IBHeader = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 5px;
  color: #292c38;
`

export const IBDescription = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #9caab8;
  margin: 5px 0 0 0;
`

export const OptionsPopUp = styled.div<PopupProps>`
  position: absolute;
  top: ${props => `${props.optionVerticalPosition}px`};
  left: ${props =>
    props.isFirstColumnId ? '0px' : props.isLastColumnId ? `${props.optionHorizontalPosition - 400}px` : `${props.optionHorizontalPosition - 305}px`};
  width: ${props => (props.isHovering ? '430px' : '160px')};
  height: ${props => (props.isHovering ? '280px' : '130px')};
  display: ${props => (props.isOpen ? 'flex' : 'none')};
`

export const PopUpWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`

export const OptionsBlock = styled.div<{ isFirstColumnId?: boolean }>`
  position: absolute;
  top: 0px;
  left: ${props => (props.isFirstColumnId ? '75px' : 0)};
  width: 160px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0px 3px 17px rgba(150, 170, 180, 0.3);
  border-radius: 6px;
`

export const TopBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
`

export const TopTitle = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: #9caab8;
`

export const CloseIcon = styled.div`
  position: absolute;
  right: 15px;
  width: 10px;
  height: 10px;
  display: flex;
  img {
    width: 10px;
    height: 10px;
  }
`

export const SeparatorBlock = styled.div`
  width: 140px;
  height: 1px;
  background: #e3e8f1;
`

export const BottomBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ChangeStatus = styled.div<ChangeStatusProps>`
  width: 100%;
  display: flex;
  align-items: center;
  background: ${props => (props.isHovering ? ' #F5F6F8' : 'transparent')};
  padding: 10px;
  justify-content: space-between;
  cursor: pointer;
  img {
    width: 6px;
    height: 10px;
  }
`

export const CloseDeal = styled.span`
  font-size: 16px;
  line-height: 20px;
  color: #f95565;
`

export const CloseChanges = styled.span`
  font-size: 16px;
  line-height: 20px;
`

export const StatusBlock = styled.div<{ isFirstColumnId?: boolean }>`
  position: absolute;
  bottom: 0;
  right: ${props => (props.isFirstColumnId ? '-75px' : 0)};
  width: 270px;
  height: 236px;
  display: flex;
  background: #fff;
  box-shadow: 0px 3px 17px rgba(150, 170, 180, 0.3);
  border-radius: 6px;
  z-index: 1;
`

export const StatusWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 5px 0 0 0;
`

export const StatusBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  :hover {
    background: #f5f6f8;
  }
`

export const Status = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.color};
  padding: 10px;
`

export const Checkbox = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => props.color};
`

export const DownloadInvoiceBlock = styled.div`
  width: 545px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 10px 0;
  background: #3cb593;
  color: #fff;
  font-family: Mukta;
  letter-spacing: 1px;
  font-size: 12px;
  cursor: pointer;
`

export const TextError = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: red;
  margin: 5px 0 0 0;
`

export const DownloadedBlock = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 10px 0;
  background: #d8f0e9;
  color: #3cb593;
  font-family: Mukta;
  letter-spacing: 1px;
  font-size: 12px;
  border-radius: 6px;
`
