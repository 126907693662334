import { fromJS } from 'immutable'

import {
  GET_SIGNATURE,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_FAIL,
  SET_SIGNATURE,
  SET_SIGNATURE_SUCCESS,
  SET_SIGNATURE_FAIL,
  CLEAR_SIGNATURE_ERROR,
  CHANGE_INPUT_VALUE,
  CLEAR_INPUT_VALUE,
  CLEAR_ERROR,
  ADD_NEW_CANDIDATE_OR_EMPLOYER,
  GET_CHAT_LIST,
  GET_CHAT_LIST_SUCCESS,
  GET_CHAT_LIST_FAIL,
  MESSENGER__CLOSE_DIALOG,
  MESSENGER__SET_ACTIVE_USER,
  MESSENGER__TOGGLE_PREVIEW,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
  SEND_INTERVIEW,
  SEND_INTERVIEW_SUCCESS,
  SEND_INTERVIEW_FAIL,
  MESSENGER__SET_ACTIVE_USER__SUCCESS,
  MESSENGER__GET_PREVIEW__SUCCESS,
  MESSENGER__ON_CHANGE_MESSENGER_MODAL,
  MessengerTypes,
} from './actions'

import { IMessengerState } from 'types/reducerStates'

const initialState: IMessengerState = fromJS({
  isFetching: false,
  signature: null,
  signatureIsFetching: false,
  messageIsFetching: false,
  signatureTextarea: '',
  modal: 'none',
  isOpenPreview: false,
  isOpenDialog: false,
  showNotificationDot: false,
  message: '',
  file: null,
  previewList: [],
  messages: null,
  dialogUser: {},
  modalsData: {
    candidate: {},
    jobPosition: {},
    interview: {},
  },
  autosignature: false,
  error: '',
  currrentUser: false,
})

export default (state = initialState, action: MessengerTypes): IMessengerState => {
  switch (action.type) {
    case CHANGE_INPUT_VALUE:
      return state.set(action.payload.name, action.payload.value)
    case CLEAR_INPUT_VALUE:
      return state.set('message', '').set('attachmentChatFile', null)
    case CLEAR_ERROR:
      return state
        .set('error', '')
        .set('isOpenPreview', !state.get('isOpenPreview'))
        .set('isOpenDialog', !state.get('isOpenDialog'))
    case GET_SIGNATURE:
      return state.set('signatureIsFetching', true)
    case GET_SIGNATURE_SUCCESS:
      return state
        .set('signatureIsFetching', false)
        .set('signature', action.payload)
        .set('signatureTextarea', action.payload.text)
    case GET_SIGNATURE_FAIL:
      return state.set('signatureIsFetching', false)
    case SET_SIGNATURE:
      return state.set('signatureIsFetching', true)
    case SET_SIGNATURE_SUCCESS:
      return state
        .set('signatureIsFetching', false)
        .set('signature', action.payload)
        .set('signatureTextarea', action.payload.text)
    case SET_SIGNATURE_FAIL:
      return state.set('signatureIsFetching', false).set('error', action.payload)
    case CLEAR_SIGNATURE_ERROR:
      return state.set('error', '')
    case ADD_NEW_CANDIDATE_OR_EMPLOYER:
      const previewList = state.get('previewList')
      if (previewList && previewList.size) {
        const checkId = Array.from(
          previewList.map(
            (el: any) =>
              (el.get('data') && el.get('data').get('EmployerContactId') === action.payload.id) ||
              (el.get('data') && el.get('data').get('CandidateId') === action.payload.id && action.payload.type !== 'employerContact') ||
              (el.get('id') && el.get('id') === action.payload.id)
          )
        ).find((el: any) => el)
        if (checkId) {
          return state.set('isOpenPreview', !state.get('isOpenPreview'))
        } else {
          return state
            .set('previewList', fromJS([...fromJS([action.payload]), ...state.get('previewList')]))
            .set('isOpenPreview', !state.get('isOpenPreview'))
        }
      } else {
        return state
          .set('previewList', fromJS([action.payload]))
          .set('isOpenPreview', !state.get('isOpenPreview'))
          .set('currentUser', true)
      }
    case GET_CHAT_LIST:
      return state.set('isFetching', true)
    case GET_CHAT_LIST_SUCCESS:
      let notification = false
      action.payload.data.forEach((el: any) => {
        if (el.newMessagesCount) notification = true
      })
      return state
        .set('isFetching', false)
        .set('previewList', fromJS(action.payload.data))
        .set('showNotificationDot', notification)
    case GET_CHAT_LIST_FAIL:
      return state.set('isFetching', false)
    case MESSENGER__TOGGLE_PREVIEW:
      return state
        .set('isOpenPreview', !state.get('isOpenPreview'))
        .set('isOpenDialog', false)
        .set('message', '')
        .set('autosignature', !state.get('autosignature'))
        .set('attachmentChatFile', null)
    case MESSENGER__CLOSE_DIALOG:
      return state.set('isOpenDialog', false).set('message', '')
    case MESSENGER__SET_ACTIVE_USER:
      let dialogUser
      if (action.payload.get('type') === 'candidate' || action.payload.get('type') === 'employerContact') {
        dialogUser = action.payload
      } else {
        dialogUser = action.payload.get('data')
      }
      return state
        .set('isOpenDialog', true)
        .set('dialogUser', dialogUser)
        .set('message', '')
        .set('messages', null)
        .set('attachmentChatFile', null)
    case MESSENGER__SET_ACTIVE_USER__SUCCESS:
      return state.set('messages', fromJS(action.payload))
    case MESSENGER__ON_CHANGE_MESSENGER_MODAL:
      return state.set('modal', action.payload)
    case MESSENGER__GET_PREVIEW__SUCCESS:
      return state.set('previewList', fromJS(action.payload))
    case SEND_MESSAGE:
      return state.set('error', '').set('messageIsFetching', true)
    case SEND_MESSAGE_SUCCESS:
      return state.set('message', '').set('messageIsFetching', false)
    case SEND_MESSAGE_FAIL:
      return state
        .set('error', action.payload.error)
        .set('message', '')
        .set('messageIsFetching', false)
    case SEND_INTERVIEW:
      return state.set('error', '').set('messageIsFetching', true)
    case SEND_INTERVIEW_SUCCESS:
      return state.set('messageIsFetching', false)
    case SEND_INTERVIEW_FAIL:
      return state
        .set('error', action.payload.error)
        .set('messageIsFetching', false)
        .set('modal', 'none')
    default:
      return state
  }
}
