import React from 'react'
// @ts-ignore
import Spinner from 'react-loader-spinner'

import { BoxCenterContent } from 'styles'

type Props = {
  width?: string
  height?: string
  fill?: boolean
  withoutBg?: boolean
}

export const SmallLoader: React.FC<Props> = ({ width, height, fill = true }) => (
  <BoxCenterContent width={width} height={height} fill={fill}>
    <Spinner type="Oval" color="#3CB593" height={height} width={width} />
  </BoxCenterContent>
)

const Loader: React.FC<Props> = ({ width = '100wh', height = '100vh', fill = true, withoutBg = false }) => {
  return withoutBg ? (
    <BoxCenterContent width={width} height={height} fill={fill}>
      <Spinner type="Oval" color="#3CB593" height="100" width="100" />
    </BoxCenterContent>
  ) : (
    <BoxCenterContent width={width} height={height} fill={fill} whiteBC>
      <Spinner type="Oval" color="#3CB593" height="100" width="100" />
    </BoxCenterContent>
  )
}

export default Loader
