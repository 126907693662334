import { fromJS } from 'immutable'

import {
  NotesActionsTypes,
  NOTES__TOGGLE_MODAL,
  NOTES__FETCH_ALL_SUCCESS,
  NOTES__ADD_NOTE_SUCCESS,
  NOTES__EDIT_NOTE_SUCCESS,
  NOTES__DELETE_NOTE_SUCCESS,
} from './actions'

import { INotesState } from 'types/reducerStates'
import { INote } from 'types/models'

const initialState: INotesState = fromJS({
  isShow: false,
  showLoading: true,
  itemId: undefined,
  type: null,
  list: [],
})

export default (state = initialState, action: NotesActionsTypes): INotesState => {
  switch (action.type) {
    case NOTES__TOGGLE_MODAL:
      return state
        .set('isShow', action.payload.isShow ? action.payload.isShow : !state.get('isShow'))
        .set('itemId', action.payload.id)
        .set('showLoading', true)
        .set('type', action.payload.isShow ? action.payload.type : null)
    case NOTES__FETCH_ALL_SUCCESS:
      return state.set('showLoading', false).set('list', action.payload)
    case NOTES__ADD_NOTE_SUCCESS:
      return state.set('list', [action.payload, ...state.get('list')])
    case NOTES__EDIT_NOTE_SUCCESS:
      return state.set('list', state.get('list').map((item: INote) => (item.id === action.payload.id ? action.payload : item)))
    case NOTES__DELETE_NOTE_SUCCESS:
      return state.set('list', state.get('list').filter((item: INote) => item.id !== action.payload))
    default:
      return state
  }
}
