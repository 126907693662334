import styled from 'styled-components'

import { BoxFill, BoxFullFill, Text } from 'styles'

interface IBlockProps {
  width?: number
  minWidth?: number
  maxWidth?: number
}

export const Wrap = styled(BoxFill).attrs({
  spaceBetween: true,
})``

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ButtonBlock = styled.div`
  width: 138px;
  height: 50px;
  padding: 5px 0 0 0;
  margin: 0 0 0 5px;
`

export const Block = styled.div<IBlockProps>`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  min-width: ${props => `${props.minWidth}px`};
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  height: 50px;
  margin: 0 5px;
  &:nth-child(1) {
    margin: 0 5px 0 0;
  }
`

export const SaveWrap = styled(BoxFill).attrs({
  spaceBetween: true,
  margin: '10px 0 0 0',
  padding: '0 20px 0 0',
})``

export const SavedSearches = styled.div`
  color: ${props => props.theme.main.colors.primary};
  position: relative;
  span {
    cursor: pointer;
    text-decoration-line: underline;
    &:hover {
      opacity: 0.8;
    }
  }
`
export const SavedThisSearch = styled(Text)`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const ContainerCard = styled(BoxFullFill).attrs({
  height: 'auto !important',
})`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
  width: 100%;
  margin-top: 30px;
  height: auto !important;
`

export const ContactBox = styled.div``

export const SearchCount = styled(Text).attrs({})`
  margin: 10px 0 -20px 0;
`
