import React from 'react'
import { components, OptionProps } from 'react-select'

import { ISelectListItem } from 'types/forms'
import { IJobPosition } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import InputSelect from 'components/Input/InputSelect'
import CityStateSelect from 'components/Input/cityStateSelect'

import { BoxFill, Image } from 'styles'
import { Select } from 'components/Select'
import { useProjectTypesCRUD } from 'hooks/useProjectTypesCRUD'

import { EmployerOption } from 'hooks/useEmployersOptions'
import Avatar from 'components/Avatar'

const ICON_DOLLAR = <Image src={require('assets/icons/$.svg')} />

type Props = {
  form: Partial<IJobPosition>
  onChangeField: (e: InputEvent & ISelectListItem, type?: string) => void
  errors: Partial<IJobPosition>
  recruiterId: string
  changeFormField: (name: string, value: any) => void
}

export const FirstStep: React.FC<Props> = ({ onChangeField, errors, form, recruiterId, changeFormField }) => {
  const onChangeCompanies = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Companies')
  const onChangePosition = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Position')
  const onChangeCity = (e: InputEvent & ISelectListItem) => onChangeField(e, 'City')
  const onChangeItPackages = (e: InputEvent & ISelectListItem) => onChangeField(e, 'ItPackages')
  const onChangeProjectValues = (e: InputEvent & ISelectListItem) => onChangeField(e, 'ProjectValues')

  const { getLinkFormOptions: getProjects, linkFormOptions: projects } = useProjectTypesCRUD()

  React.useEffect(() => {
    if (recruiterId) {
      getProjects(recruiterId)
    } else {
      getProjects()
    }
  }, [recruiterId])

  const OptionComponent: React.FC<OptionProps<EmployerOption, false>> = React.memo(props => {
    const { isSelected, data, label } = props
    if (isSelected) {
      return null
    }
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar item={data} width="36px" textSize="14px" />
          <div style={{ marginLeft: '10px' }}>{label}</div>
        </div>
      </components.Option>
    )
  })

  const Menu = (props: any) => {
    const optionSelectedLength = props.getValue().length || 0
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 5 ? props.children : <div style={{ margin: '10px', textAlign: 'center' }}>Max limit achieved</div>}
      </components.Menu>
    )
  }

  return (
    <BoxFill column>
      <BoxFill mb="20px" column>
        <InputSelect
          value={form.Companies}
          error={errors.Companies}
          onChange={onChangeCompanies}
          placeholder="Choose a company"
          label="Company"
          optionsType="publicEmployers"
          recruiterId={recruiterId}
          components={{ Option: OptionComponent }}
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.Position}
          error={errors.Position}
          onChange={onChangePosition}
          placeholder="Choose job position title"
          label="Job position title"
          optionsType="jobs"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <Select
          value={form.Sectors}
          error={!!errors.Sectors}
          options={projects}
          onChange={options => changeFormField('Sectors', options)}
          placeholder="Choose project type"
          label="Project type (optional)"
          isMulti
          isClearable={false}
          components={{ Menu }}
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.ItPackages}
          isMulti
          onChange={onChangeItPackages}
          placeholder="Choose"
          label="Qualifications / IT Packages (optional)"
          optionsType="itPackages"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.ProjectValues}
          isMulti
          onChange={onChangeProjectValues}
          placeholder="Choose"
          label="Project values (optional)"
          optionsType="projectValues"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <CityStateSelect error={errors.City} value={form.City!} onChange={onChangeCity} />
      </BoxFill>
      <BoxFill mb="20px">
        <Input
          value={form.salary}
          error={errors.salary}
          onChange={onChangeField}
          leftElement={ICON_DOLLAR}
          pl="30px"
          name="salary"
          label="Approx. annual salary"
          type="text"
          pattern="[0-9\s]{0,9}"
        />
      </BoxFill>
    </BoxFill>
  )
}
