import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { AppState } from 'store/rootReducer'
import AuthActions from 'store/auth/actions'
import { IUser } from 'types/models'

import Avatar from 'components/Avatar'
import { ExpandArrow } from 'components/Icons'

import { Box, BoxFill, Text, NavLi } from 'styles'
import { UserAvatarContainer, ProfileContainer, StatusWrapper, BoxStatus, Logo, AvatarWrap, SearchIcon } from './styled'

import searchIcon from 'assets/icons/searchIcon.svg'

/* NOT IN MVP */
// const IconContainer = styled(BoxCenterContent).attrs({
//   width: '36px',
//   height: '36px',
//   br: '18px',
//   iconColor: true,
// })``

// const CustomImage = styled(Image).attrs({
//   width: '14px',
//   height: '14px',
//   pointer: true,
// })``

type Props = {
  user: IUser
  logout: typeof AuthActions.logout
}

const DashboardMenu: React.FC<Props & RouteComponentProps> = ({ user, history, logout }) => {
  const onLogout = () => {
    localStorage.removeItem('persist:root')
    logout()
  }
  const editProfileRoute = () => history.push('/edit-profile')
  return (
    <BoxFill alignCenter spaceBetween mainBC padding="25px 0">
      <Box pl="40px">
        <Logo onClick={() => history.push('/dashboard')} src={require('assets/images/Logo-RL_white-2.png')} />
      </Box>
      <Box spaceBetween>
        <NavLi to="/dashboard">Dashboard</NavLi>
        <NavLi to="/live-jobs">Employers</NavLi>
        <NavLi to="/live-jobs-new">Live Jobs</NavLi>
        <NavLi to="/candidates">Candidates</NavLi>
        <NavLi to="/pipeline">Pipeline</NavLi>
        <NavLi to="/contacts">Contacts</NavLi>
        {/* <NavLi to="/support">Support</NavLi> */}
      </Box>
      <SearchIcon
        to={{
          pathname: '/search',
          state: { fromPage: history.location },
        }}
      >
        <img src={searchIcon} alt="search" />
      </SearchIcon>

      <ProfileContainer>
        {/* NOT IN MVP */}
        {/* <Box width="90px" spaceBetween>
          <IconContainer>
            <CustomImage src={require('assets/icons/search.svg')} />
          </IconContainer>
          <IconContainer>
            <CustomImage src={require('assets/icons/notification.svg')} />
          </IconContainer>
        </Box> */}
        <AvatarWrap>
          <UserAvatarContainer className="user-avatar-container">
            <Avatar item={user} width="36px" />
          </UserAvatarContainer>
          <ExpandArrow className="arrow-box" fill="#ffffff" />
        </AvatarWrap>
        <StatusWrapper className="statusBox">
          <BoxStatus>
            <Text onClick={editProfileRoute} black pointer>
              Edit profile
            </Text>
          </BoxStatus>
          <BoxStatus onClick={onLogout}>
            <Text red pointer>
              Log out
            </Text>
          </BoxStatus>
        </StatusWrapper>
      </ProfileContainer>
    </BoxFill>
  )
}

const mapStateToProps = (state: AppState) => ({
  user: state.auth.get('user'),
})

export default connect(
  mapStateToProps,
  { logout: AuthActions.logout }
)(withRouter(DashboardMenu))
