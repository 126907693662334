import { ActionsUnion, IQueryParams, createAction } from 'types/helps'
import { INotValidFieldsErr, IError } from 'types/errors'
import { IDataCandidates, IDataCandidateWithPagedPotentialJobPositions } from 'types/responseData'
import { IWorkPlace, ICandidateWithLastWorkPlace, ICandidate, IMatchedJobPositionWithEmployer, IMatched, IMatchStatuses } from 'types/models'

export const FETCH_ALL_CANDIDATES_STARTED = 'FETCH_ALL_CANDIDATES_STARTED'
export const FETCH_ALL_CANDIDATES_SUCCESS = 'FETCH_ALL_CANDIDATES_SUCCESS'
export const FETCH_ALL_CANDIDATES_FAIL = 'FETCH_ALL_CANDIDATES_FAIL'

export const FETCH_CANDIDATE_STARTED = 'FETCH_CANDIDATE_STARTED'
export const FETCH_CANDIDATE_SUCCESS = 'FETCH_CANDIDATE_SUCCESS'
export const FETCH_CANDIDATE_FAIL = 'FETCH_CANDIDATE_FAIL'

export const CREATE_CANDIDATE_STARTED = 'CREATE_CANDIDATE_STARTED'
export const CREATE_CANDIDATE_SUCCESS = 'CREATE_CANDIDATE_SUCCESS'
export const CREATE_CANDIDATE_FAIL = 'CREATE_CANDIDATE_FAIL'

export const MATCH_CANDIDATE = 'MATCH_CANDIDATE'
export const MATCH_CANDIDATE_SUCCESS = 'MATCH_CANDIDATE_SUCCESS'
export const MATCH_CANDIDATE_FAIL = 'MATCH_CANDIDATE_FAIL'

export const UPDATE_CANDIDATE_STARTED = 'UPDATE_CANDIDATE_STARTED'
export const UPDATE_CANDIDATE_SUCCESS = 'UPDATE_CANDIDATE_SUCCESS'
export const UPDATE_CANDIDATE_FAIL = 'UPDATE_CANDIDATE_FAIL'

export const DELETE_CANDIDATE = 'DELETE_CANDIDATE'
export const DELETE_CANDIDATE_SUCCESS = 'DELETE_CANDIDATE_SUCCESS'
export const DELETE_CANDIDATE_FAIL = 'DELETE_CANDIDATE_FAIL'

export const UPDATE_WORK_PLACE_STARTED = 'UPDATE_WORK_PLACE_STARTED'
export const UPDATE_WORK_PLACE_SUCCESS = 'UPDATE_WORK_PLACE_SUCCESS'
export const UPDATE_WORK_PLACE_FAIL = 'UPDATE_WORK_PLACE_FAIL'

export const CREATE_WORK_PLACE_STARTED = 'CREATE_WORK_PLACE_STARTED'
export const CREATE_WORK_PLACE_SUCCESS = 'CREATE_WORK_PLACE_SUCCESS'
export const CREATE_WORK_PLACE_FAIL = 'CREATE_WORK_PLACE_FAIL'

export const DELETE_WORK_PLACE_STARTED = 'DELETE_WORK_PLACE_STARTED'
export const DELETE_WORK_PLACE_SUCCESS = 'DELETE_WORK_PLACE_SUCCESS'
export const DELETE_WORK_PLACE_FAIL = 'DELETE_WORK_PLACE_FAIL'

export const ON_SET_STATUS_CANDIDATE = 'ON_SET_STATUS_CANDIDATE'
export const ON_SET_STATUS_CANDIDATE_SUCCESS = 'ON_SET_STATUS_CANDIDATE_SUCCESS'
export const ON_SET_STATUS_CANDIDATE_FAIL = 'ON_SET_STATUS_CANDIDATE_FAIL'

export const UPLOAD_LICENSE_SUCCESS = 'UPLOAD_LICENSE_SUCCESS'

export const UPDATE_CV_SUCCESS = 'UPDATE_CV_SUCCESS'

export const DELETE_LICENSE = 'DELETE_LICENSE'
export const DELETE_LICENSE_SUCCESS = 'DELETE_LICENSE_SUCCESS'
export const DELETE_LICENSE_FAIL = 'DELETE_LICENSE_FAIL'

export const DELETE_CV = 'DELETE_CV'
export const DELETE_CV_SUCCESS = 'DELETE_CV_SUCCESS'
export const DELETE_CV_FAIL = 'DELETE_CV_FAIL'

export const RESET_STORE = 'RESET_STORE'

export const SET_CANDIDATE_DATA = 'SET_CANDIDATE_DATA'

export const CANDIDATE__CHANGE_NOTES_COUNT = 'CANDIDATE__CHANGE_NOTES_COUNT'

export const CLEAR_NOTIFICATION_CANDIDATE = 'CLEAR_NOTIFICATION_CANDIDATE'

// SET PUBLIC DATA
export const CREATE_PUBLIC_CANDIDATE = 'CREATE_PUBLIC_CANDIDATE'
export const CREATE_PUBLIC_CANDIDATE_SUCCESS = 'CREATE_PUBLIC_CANDIDATE_SUCCESS'
export const CREATE_PUBLIC_CANDIDATE_FAIL = 'CREATE_PUBLIC_CANDIDATE_FAIL'

export const CREATE_PUBLIC_CANDIDATEWITHOUT_ID = 'CREATE_PUBLIC_CANDIDATEWITHOUT_ID'
export const CREATE_PUBLIC_CANDIDATEWITHOUT_ID_SUCCESS = 'CREATE_PUBLIC_CANDIDATEWITHOUT_ID_SUCCESS'
export const CREATE_PUBLIC_CANDIDATEWITHOUT_ID_FAIL = 'CREATE_PUBLIC_CANDIDATEWITHOUT_ID_FAIL'

export const UPDATE_PUBLIC_CV_SUCCESS = 'UPDATE_PUBLIC_CV_SUCCESS'
export const UPLOAD_PUBLIC_LICENSE_SUCCESS = 'UPLOAD_PUBLIC_LICENSE_SUCCESS'

export type CandidatesTypes = ActionsUnion<typeof CandidatesActions>

const CandidatesActions = {
  fetchCandidatesStarted: (query: IQueryParams) => createAction(FETCH_ALL_CANDIDATES_STARTED, query),
  fetchCandidatesSuccess: (data: IDataCandidates) => createAction(FETCH_ALL_CANDIDATES_SUCCESS, data),
  fetchCandidatesFail: (error: IError) => createAction(FETCH_ALL_CANDIDATES_FAIL, error),

  fetchCandidateStarted: (employerId: number, page?: number, perPage?: number) =>
    createAction(FETCH_CANDIDATE_STARTED, { employerId, page, perPage }),
  fetchCandidateSuccess: (data: IDataCandidateWithPagedPotentialJobPositions) => createAction(FETCH_CANDIDATE_SUCCESS, data),
  fetchCandidateFail: (error: IError) => createAction(FETCH_CANDIDATE_FAIL, error),

  match: (JobPositionId: number, CandidateId: number, status: IMatchStatuses = 1) =>
    createAction(MATCH_CANDIDATE, { JobPositionId, CandidateId, status }),
  matchSuccess: (data: { JobPosition: IMatchedJobPositionWithEmployer; Candidate: ICandidate; Matched: IMatched }) =>
    createAction(MATCH_CANDIDATE_SUCCESS, data),
  matchFail: (error: INotValidFieldsErr) => createAction(MATCH_CANDIDATE_FAIL, error),

  createCandidateStarted: (candidate: ICandidateWithLastWorkPlace) => createAction(CREATE_CANDIDATE_STARTED, candidate),
  createCandidateSuccess: (candidate: ICandidate) => createAction(CREATE_CANDIDATE_SUCCESS, candidate),
  createCandidateFail: (error: INotValidFieldsErr) => createAction(CREATE_CANDIDATE_FAIL, error),

  updateCandidateStarted: (id: number, candidate: Partial<ICandidate>) => createAction(UPDATE_CANDIDATE_STARTED, { id, candidate }),
  updateCandidateSuccess: (candidate: ICandidate) => createAction(UPDATE_CANDIDATE_SUCCESS, candidate),
  updateCandidateFail: (error: INotValidFieldsErr) => createAction(UPDATE_CANDIDATE_FAIL, error),

  deleteCandidate: (id: number) => createAction(DELETE_CANDIDATE, id),
  deleteCandidateSucess: (id: number) => createAction(DELETE_CANDIDATE_SUCCESS, id),
  deleteCandidateFail: (error: any) => createAction(DELETE_CANDIDATE_FAIL, error),

  createWorkPlace: (id: number, workPlace: Partial<IWorkPlace>) => createAction(CREATE_WORK_PLACE_STARTED, { id, workPlace }),
  createWorkPlaceSuccess: (workPlace: any) => createAction(CREATE_WORK_PLACE_SUCCESS, workPlace),
  createWorkPlaceFail: (error: INotValidFieldsErr) => createAction(CREATE_WORK_PLACE_FAIL, error),

  updateWorkPlace: (id: number, workPlace: Partial<IWorkPlace>) => createAction(UPDATE_WORK_PLACE_STARTED, { id, workPlace }),
  updateWorkPlaceSuccess: (id: number, workPlace: IWorkPlace) => createAction(UPDATE_WORK_PLACE_SUCCESS, { id, workPlace }),
  updateWorkPlaceFail: (error: INotValidFieldsErr) => createAction(UPDATE_WORK_PLACE_FAIL, error),

  deleteWorkPlace: (id: number) => createAction(DELETE_WORK_PLACE_STARTED, id),
  deleteWorkPlaceSuccess: (id: number) => createAction(DELETE_WORK_PLACE_SUCCESS, id),
  deleteWorkPlaceFail: (error: any) => createAction(DELETE_WORK_PLACE_FAIL, error),

  setStatusCandidate: (id: number, value: boolean) => createAction(ON_SET_STATUS_CANDIDATE, { id, value }),
  setStatusCandidateSuccess: (candidate: ICandidate) => createAction(ON_SET_STATUS_CANDIDATE_SUCCESS, candidate),
  setStatusCandidateFail: () => createAction(ON_SET_STATUS_CANDIDATE_FAIL),

  uploadLicenseSuccess: (candidate: ICandidate) => createAction(UPLOAD_LICENSE_SUCCESS, candidate),
  uploadPublicLicenseSuccess: (candidate: ICandidate) => createAction(UPLOAD_PUBLIC_LICENSE_SUCCESS, candidate),

  updateCVSuccess: (candidate: ICandidate) => createAction(UPDATE_CV_SUCCESS, candidate),
  updatePublicCVSuccess: (candidate: ICandidate) => createAction(UPDATE_PUBLIC_CV_SUCCESS, candidate),

  deleteLicense: (id: number, candidateId: number) => createAction(DELETE_LICENSE, { id, candidateId }),
  deleteLicenseSuccess: () => createAction(DELETE_LICENSE_SUCCESS),
  deleteLicenseFail: (error: any) => createAction(DELETE_LICENSE_FAIL, error),

  deleteCV: (id: number, candidateId: number) => createAction(DELETE_CV, { id, candidateId }),
  deleteCVSuccess: () => createAction(DELETE_CV_SUCCESS),
  deleteCVFail: (error: any) => createAction(DELETE_CV_FAIL, error),

  resetStore: () => createAction(RESET_STORE),

  setCandidateData: (name: string, value: string) => createAction(SET_CANDIDATE_DATA, { name, value }),

  changeNotesCount: (id: number, increase: number) => createAction(CANDIDATE__CHANGE_NOTES_COUNT, { id, increase }),
  clearNotificationCandidate: () => createAction(CLEAR_NOTIFICATION_CANDIDATE),

  createPublicCandidate: (candidate: ICandidateWithLastWorkPlace, recruiterId: string) =>
    createAction(CREATE_PUBLIC_CANDIDATE, { candidate, recruiterId }),
  createPublicCandidateSuccess: (candidate: ICandidate) => createAction(CREATE_PUBLIC_CANDIDATE_SUCCESS, candidate),
  createPublicCandidateFail: (error: INotValidFieldsErr) => createAction(CREATE_PUBLIC_CANDIDATE_FAIL, error),

  createPublicCandidateWithoutId: (candidate: ICandidateWithLastWorkPlace) => createAction(CREATE_PUBLIC_CANDIDATEWITHOUT_ID, candidate),
  createPublicCandidateWithoutIdSuccess: (candidate: ICandidate) => createAction(CREATE_PUBLIC_CANDIDATEWITHOUT_ID_SUCCESS, candidate),
  createPublicCandidateWithoutIdFail: (error: INotValidFieldsErr) => createAction(CREATE_PUBLIC_CANDIDATEWITHOUT_ID_FAIL, error),
}

export default CandidatesActions
