import React, { useState } from 'react'
import moment from 'moment'

import WorkPlaceForm from './workPlaceForm'

import { Box, BoxFill, BoxCol, BoxFullFill, Text, Span, Image } from 'styles'

type Props = {
  work: any
  candidateId: number
}

const WorkPlace: React.FC<Props> = ({ work, candidateId }) => {
  const [isEditMode, setIsEditModa] = useState(false)
  const toggleIsEditMode = () => setIsEditModa(!isEditMode)
  if (isEditMode) {
    return <WorkPlaceForm work={work} candidateId={candidateId} isEditMode={isEditMode} onCancel={toggleIsEditMode} />
  }

  const { companyName, Position, City, fromDate, toDate, current } = work
  return (
    <BoxFill mb="10px" defaultBorder smooth padding="20px">
      {companyName || Position || City || fromDate ? (
        <BoxCol>
          <BoxCol mb="14px">
            <Text black>{companyName && companyName}</Text>
            <Text s>{Position && Position.name}</Text>
          </BoxCol>
          <BoxFill alignCenter>
            {City ? (
              <BoxFill borderRight="1px solid #E3E8F1" pr="8px">
                <Text s black whiteSpace lh="16px">
                  {City.name.indexOf(City.State.abbr) > 0 ? City.name : `${City.name} ${City.State.abbr}`}
                </Text>
              </BoxFill>
            ) : null}
            <BoxFill ml="8px">
              <Text s black whiteSpace>
                {fromDate ? `${moment(fromDate).format('M/YYYY')} - ${current ? 'now' : toDate && moment(toDate).format('M/YYYY')}` : null}{' '}
                {fromDate ? moment(fromDate).from((toDate && toDate) || new Date(), true) : null}
              </Text>
            </BoxFill>
          </BoxFill>
        </BoxCol>
      ) : null}
      <BoxFullFill allPointer justifyRight alignSelf="center">
        <Box alignCenter>
          <Box mr="6px">
            <Span accentColor onClick={toggleIsEditMode}>
              Edit
            </Span>
          </Box>
          <Image src={require('assets/icons/edit.svg')} />
        </Box>
      </BoxFullFill>
    </BoxFill>
  )
}

export default WorkPlace
