import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { ValidationError } from 'yup'
import set from 'lodash/set'

import { AppState } from 'store/rootReducer'
import { getInInner } from 'store/currentInnerPage'
import EmployersActions from 'store/employers/actions'
import { IEmployerContact, IJobPosition } from 'types/models'
import { InputEvent } from 'types/helps'

import EditFrame from 'components/EditFrame'
import Loader from 'components/Loader'
import * as forms from 'components/Forms/JobPositionFormSteps'

import { editJobPositionSchemaFirstStep, addJobPositionSchemaSecondStep, addJobPositionSchemaThirdStep, formatErrors } from 'services/validation'
import { filterByKeys } from 'utils'

const CONTACT_REQUIRED_FIELDS = ['name', 'position', 'email', 'phone']
const CANDIDATE_STEPS_EDIT = ['Basic information', 'Hiring manager info', 'Job description']
const SETTING_TEXTS = {
  title: 'job position',
  deleteBtnText: 'Delete job position',
  viewItemBtnText: 'VIEW JOB POSITION',
}

type Params = {
  jobPositionID: number
}

type Props = {
  isFetching: boolean
  match: { params: Params }
  fetchJobPosition: typeof EmployersActions.fetchJobWithCandidates
  jobPosition: Partial<IJobPosition>
  updateJobPosition: (id: number, job: Partial<IJobPosition>) => void
  deleteJobPosition: (id: number) => void
  updateContact: (contactId: number, contact: Partial<IEmployerContact>) => void
  clearNotification: () => void
  isUpdateData: boolean
}

type State = {
  currentStep: number
  jobPosition?: Partial<IJobPosition>
  errors: Partial<IJobPosition>
}

class EditJobPosition extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    currentStep: 0,
    jobPosition: undefined,
    errors: {},
  }

  componentWillReceiveProps(nextProps: Props & RouteComponentProps) {
    this.setState({ jobPosition: nextProps.jobPosition })
  }

  componentWillMount() {
    this.props.fetchJobPosition(this.props.match.params.jobPositionID)
  }

  setStep = (step: number) => {
    this.props.clearNotification()
    this.setState({ currentStep: step })
  }

  onUpdate = () => {
    const validatorSteps: any = {
      0: { validator: editJobPositionSchemaFirstStep, data: this.state.jobPosition },
      1: { validator: addJobPositionSchemaSecondStep, data: this.state.jobPosition!.EmployerContact },
      2: { validator: addJobPositionSchemaThirdStep, data: this.state.jobPosition },
    }
    const validator = validatorSteps[this.state.currentStep].validator
    validator
      .validate(validatorSteps[this.state.currentStep].data, { abortEarly: false })
      .then(() => {
        if (this.state.currentStep === 1) {
          const EmployerContact = this.state.jobPosition!.EmployerContact
          if (EmployerContact) {
            this.props.updateContact(EmployerContact.id!, filterByKeys(EmployerContact, CONTACT_REQUIRED_FIELDS))
          }
        } else {
          this.props.updateJobPosition(this.props.match.params.jobPositionID, this.state.jobPosition!)
        }
        this.setState({ errors: {} })
      })
      .catch((err: ValidationError) => this.setState({ ...this.state, errors: formatErrors(err) }))
  }

  onDelete = () => {
    this.props.deleteJobPosition(this.props.match.params.jobPositionID)
    this.props.history.goBack()
  }

  onChangeField = (e: InputEvent, type?: string) => {
    if (type) return this.setState({ jobPosition: { ...this.state.jobPosition, [type]: e } })
    const { name, value } = e.currentTarget
    const jobPosition = { ...this.state.jobPosition }
    set(jobPosition, name, value)
    this.setState({ jobPosition })
  }

  render() {
    const { isFetching, history, isUpdateData } = this.props
    const { jobPosition, currentStep, errors } = this.state
    const viewEditingItem = () => history.push(`/live-jobs/employer/${jobPosition!.Employer!.id}?job=${this.props.match.params.jobPositionID}`)
    if (isFetching || !jobPosition) return <Loader />
    return (
      <EditFrame
        texts={{
          ...SETTING_TEXTS,
          subTitle: jobPosition.Position!.name,
        }}
        steps={CANDIDATE_STEPS_EDIT}
        forms={forms}
        errors={errors}
        editItemData={jobPosition}
        currentStep={currentStep}
        setStep={this.setStep}
        onUpdate={this.onUpdate}
        onDelete={this.onDelete}
        onChangeField={this.onChangeField}
        viewEditingItem={viewEditingItem}
        isUpdateData={isUpdateData}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  isFetching: state.currentInnerPage.getIn(getInInner(['isFetching'])),
  jobPosition: state.currentInnerPage.getIn(getInInner(['item'])),
  isUpdateData: state.currentInnerPage.getIn(getInInner(['isUpdateData'])),
})

export default connect(
  mapStateToProps,
  {
    updateContact: EmployersActions.updateEmployerContact,
    updateJobPosition: EmployersActions.updateJob,
    deleteJobPosition: EmployersActions.deleteJob,
    fetchJobPosition: EmployersActions.fetchJobWithCandidates,
    clearNotification: EmployersActions.clearNotification,
  }
)(EditJobPosition)
