import styled from 'styled-components'

import { ILabel } from 'styles/interfaces'
import { BoxFill, Text } from 'styles'

interface ISignatureImg {
  show: boolean
}

export const Label = styled.label<ILabel>`
  color: ${props => props.theme.main.inputLabelTextColor};
  font-size: 16px;
  margin-bottom: 5px;
  cursor: ${props => (props.pointer ? 'pointer' : 'text')};
`

export const SignatureImg = styled.img<ISignatureImg>`
  margin: 0 0 10px 0;
  display: ${props => (props.show ? 'block' : 'none')};
  height: ${props => (props.show ? '200px' : '0')};
`

export const UplaodSignatureInput = styled.input.attrs({ type: 'file' })`
  display: block !important;
  width: 131px;
  height: 22px;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
  text-indent: -9999px;
`

export const UploadText = styled.div`
  color: #3cb593;
  cursor: pointer;
  margin: -22px 0 3px 0;
  width: 131px;
  height: 22px;
  font-size: 14px;
  line-height: 18px;
`

export const BottomBlock = styled(BoxFill).attrs({})`
  padding: 20px 40px 20px 0;
  justify-content: flex-end;
`

export const Error = styled(Text)``
