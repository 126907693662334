import React from 'react'

import Button from 'components/Button'

import theme from 'styles/theme'
import { Top, Bottom, TopText, BottomText } from './styles'

const Complete: React.FC = () => (
  <div>
    <Top>
      <TopText m color={theme.main.colors.grey3}>
        Complete profile <br /> to withdraw money
      </TopText>
      <Button title="COMPLETE PROFILE" onClick={() => console.log('Complete work')} width="157px" height="52px" />
    </Top>
    <Bottom>
      <BottomText s grey>
        We need some additional details to unlock "Withdraw money" function for your profile
      </BottomText>
    </Bottom>
  </div>
)

export default Complete
