import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { AppState } from 'store/rootReducer'
import MessengerActions from 'store/messenger/actions'
import { IMessengerState } from 'types/reducerStates'
import { IUser } from 'types/models'

import PreviewToggler from './components/PreviewToggler'
import PreviewContent from './components/PreviewContent'
import DialogToggler from './components/DialogToggler'
import DialogContent from './components/DialogContent'
import DialogInputBox from './DialogInputBox'
import MessengerModal from './MessengerModal'
import { AutosignatureModal } from './modals/AutosignatureModal'

import {
  Preview,
  PreviewContainer,
  Dialog,
  DialogContainer,
  TransparentBackground,
  IsNotModal,
  ModalWrap,
  ModalTitle,
  ModalSubTitle,
  AddEmail,
} from './components/styles'

import emailNotFound from 'assets/images/email-not-found.png'

const messengerDOM: HTMLElement | null = document.getElementById('messenger')
type Props = IMessengerState & {
  isLoggedIn: boolean
  isFetching: boolean
  onTogglePreview: typeof MessengerActions.onTogglePreview
  onCloseDialog: typeof MessengerActions.onCloseDialog
  setUserAsActive: typeof MessengerActions.setUserAsActive
  getChatList: typeof MessengerActions.getChatList
  autosignature: boolean
  user: IUser
  error: string
  clearError: typeof MessengerActions.clearError
  message: string
  currentUser: boolean
  isFetchingChat: boolean
  getSignature: typeof MessengerActions.getSignature
}

type State = {
  showAutosignatureModal: boolean
}

class Messenger extends React.Component<Props & RouteComponentProps> {
  state: State = {
    showAutosignatureModal: true,
  }

  el = document.createElement('div')

  componentDidMount() {
    const { message, getChatList, getSignature } = this.props
    messengerDOM!.appendChild(this.el)
    getChatList()
    getSignature()
    setInterval(() => {
      if (!message) getChatList()
    }, 60000)
  }

  componentDidUpdate(prevState: any, prevProps: any) {
    const { showAutosignatureModal } = this.state
    const showAutosignature = localStorage.getItem('showAutosignature')
    if (prevProps.showAutosignatureModal === showAutosignatureModal) {
      if (!showAutosignature && showAutosignatureModal) {
        this.setState({ showAutosignatureModal: false })
      } else if (showAutosignature && !showAutosignatureModal) {
        this.setState({ showAutosignatureModal: true })
      }
    }
  }

  componentWillUnmount() {
    messengerDOM!.removeChild(this.el)
  }

  handleCloseAutosignatureModal() {
    this.setState({ showAutosignatureModal: true })
    localStorage.setItem('showAutosignature', 'true')
  }

  addSignature() {
    const { history, onTogglePreview } = this.props
    onTogglePreview()
    const params = {
      currentState: 4,
    }
    history.push(`/edit-profile`, params)
    localStorage.setItem('showAutosignature', 'true')
  }

  closeModal() {
    this.props.clearError()
  }

  addEmail(id: number) {
    const { history, clearError } = this.props
    history.push(`/candidates/candidate/${id}/edit`)
    clearError()
  }

  render() {
    const {
      isLoggedIn,
      isFetching,
      isOpenPreview,
      showNotificationDot,
      previewList,
      isOpenDialog,
      onTogglePreview,
      setUserAsActive,
      onCloseDialog,
      dialogUser,
      messages,
      autosignature,
      error,
      currentUser,
      isFetchingChat,
    } = this.props
    const { showAutosignatureModal } = this.state
    if (isFetching || !isLoggedIn) return null

    const noEmailSet = error === 'Candidate have not email address.'

    const showAutosignature = localStorage.getItem('showAutosignature')
    const typeChatUser = dialogUser.get('chatUserType') || dialogUser.get('type')

    return ReactDOM.createPortal(
      <>
        {isOpenPreview || isOpenDialog ? <TransparentBackground onClick={onTogglePreview} /> : null}

        <Preview>
          <PreviewContainer isOpen={isOpenPreview && showAutosignatureModal}>
            <PreviewToggler isOpen={isOpenPreview && showAutosignatureModal} onToggle={onTogglePreview} hasNotification={showNotificationDot} />
            <PreviewContent list={previewList} onToggleDialog={setUserAsActive} isFetchingChat={isFetchingChat} currentUser={currentUser} />
          </PreviewContainer>
        </Preview>

        <Dialog>
          <DialogContainer isOpen={isOpenDialog}>
            <DialogToggler onToggle={onCloseDialog} userData={dialogUser} />
            <DialogContent list={messages} user={this.props.user} />
            <DialogInputBox chatUserType={typeChatUser || ''} />
          </DialogContainer>
        </Dialog>

        <MessengerModal />

        <AutosignatureModal
          autosignature={autosignature}
          handleCloseAutosignatureModal={() => this.handleCloseAutosignatureModal()}
          addSignature={() => this.addSignature()}
          showAutosignature={showAutosignature}
        />

        <IsNotModal isOpen={noEmailSet} zIndex="1000" hideCloseIcon onRequestClose={() => this.closeModal()}>
          <ModalWrap>
            <img src={emailNotFound} alt="email not found" />
            <ModalTitle>No email found</ModalTitle>
            <ModalSubTitle>
              Would you like to add an email <br />
              to this candidate?
            </ModalSubTitle>
            <AddEmail title="ADD EMAIL" width="140px" height="52px" onClick={() => this.addEmail(dialogUser.get('id'))} />
          </ModalWrap>
        </IsNotModal>
      </>,
      this.el
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.auth.get('isLoggedIn'),
  isFetching: state.auth.get('isFetching'),
  isOpenPreview: state.messenger.get('isOpenPreview'),
  isOpenDialog: state.messenger.get('isOpenDialog'),
  showNotificationDot: state.messenger.get('showNotificationDot'),
  previewList: state.messenger.get('previewList'),
  dialogUser: state.messenger.get('dialogUser'),
  messages: state.messenger.get('messages'),
  autosignature: state.messenger.get('autosignature'),
  user: state.auth.get('user'),
  error: state.messenger.get('error'),
  message: state.messenger.get('message'),
  currentUser: state.messenger.get('currentUser'),
  isFetchingChat: state.messenger.get('isFetching'),
})

export default connect(
  mapStateToProps,
  {
    onTogglePreview: MessengerActions.onTogglePreview,
    onCloseDialog: MessengerActions.onCloseDialog,
    setUserAsActive: MessengerActions.setUserAsActive,
    getChatList: MessengerActions.getChatList,
    clearError: MessengerActions.clearError,
    getSignature: MessengerActions.getSignature,
  }
)(Messenger)
