import React from 'react'
import styled from 'styled-components'

import { Box, Span, Image } from 'styles'

export const EditIconContainer = styled(Box).attrs({
  pointer: true,
  alignCenter: true,
})`
  &:hover {
    opacity: 0.8;
  }
`

type Props = {
  onClick: () => void
}

const EditIcon: React.FC<Props> = ({ onClick }) => {
  return (
    <EditIconContainer onClick={onClick}>
      <Box pointer mr="6px">
        <Span>Edit</Span>
      </Box>
      <Image pointer src={require('assets/icons/edit.svg')} />
    </EditIconContainer>
  )
}

export default EditIcon
