import { combineReducers } from 'redux'

import auth from './auth'
import lists from './lists'
import employers from './employers'
import candidates from './candidates'
import currentInnerPage from './currentInnerPage'
import pipeline from './pipeline'
import notes from './notes'
import dashboard from './dashboard'
import messenger from './messenger'
import search from './search'
import task from './task'

const rootReducer = combineReducers({
  auth,
  lists,
  employers,
  candidates,
  currentInnerPage,
  pipeline,
  notes,
  dashboard,
  messenger,
  search,
  task,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
