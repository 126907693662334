import styled from 'styled-components'

import { Text, Bold } from 'styles'

import trackerBG from 'assets/images/performance-tracker.png'

export const Wrap = styled.div`
  background: url(${trackerBG}) no-repeat center 22px;
  padding: 135px 20px 0 20px;
  border-top: 1px solid #e3e8f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const Title = styled(Bold).attrs({})`
  color: #292c38;
`
export const Subtitle = styled(Text).attrs({})`
  margin: 0 0 20px 0;
`
