import React from 'react'
import styled from 'styled-components'

import { BoxFill, Box } from 'styles'

interface IProps {
  type?: string
}

const SectionNameBox = styled(Box).attrs<{ isCurrent?: boolean }>(props => ({
  br: '2px',
  padding: '25px 10px 10px 10px',
  bb: props.isCurrent ? '3px solid #2697FF' : null,
}))<{ isCurrent?: boolean }>``

// const SectionName = styled(Text).attrs({
//   pointer: true,
// })<IProps & {isActive: boolean}>`
//   color: ${props => (props.isActive ? '#2697FF' : props.type === 'task' ? '#9CAAB8' :'#292C38')};
// `

const SectionName = styled.span<IProps & { isActive: boolean }>`
  cursor: pointer;
  color: ${props => (props.isActive ? '#2697FF' : props.type === 'task' ? '#9CAAB8' : '#292C38')};
`

const Wrap = styled(BoxFill).attrs({})<IProps>`
  padding: ${props => (props.type === 'task' ? '0' : '0 0 0 25px')};
`

type Props = {
  currentSection: number
  sections: string[]
  setSection: (currentSection: number) => void
  type?: string
  amount?: any
}

const Tabs: React.FC<Props> = ({ currentSection, sections, setSection, type, amount }) => {
  const renderSection = (section: string, amount: any) => {
    switch (section) {
      case 'High priority':
        return `${section} (${amount.high})`
      case 'Medium priority':
        return `${section} (${amount.medium})`
      case 'Any time':
        return `${section} (${amount.any})`
      case 'Done':
        return `${section} (${amount.done})`
      default:
        return section
    }
  }

  return (
    <Wrap type={type} bb="1px solid #E3E8F1" mb="20px">
      {sections.map((section: string, index) => (
        <SectionNameBox key={index} isCurrent={currentSection === index}>
          <SectionName type={type} isActive={currentSection === index} onClick={() => setSection(index)}>
            {renderSection(section, amount)}
          </SectionName>
        </SectionNameBox>
      ))}
    </Wrap>
  )
}

export default Tabs
