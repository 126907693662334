import React from 'react'
import _ from 'lodash'

import { Box, Text, Span } from 'styles'
import { INotValidFieldsErr } from 'types/errors'

/**
 *  func for render error response server while try to send some forms
 */

type PropsNotValidFieldsErr = {
  errors: INotValidFieldsErr | undefined
}

export const RenderNotValidFields: React.FC<PropsNotValidFieldsErr> = ({ errors }) => {
  if (_.isEmpty(errors)) return null
  return (
    <Box column>
      {errors!.notValidFields ? (
        errors!.notValidFields!.map(error => (
          <Box key={error.fieldName}>
            <Text s red>
              {error.fieldName}:{error.errorMessage}
            </Text>
          </Box>
        ))
      ) : (
        <Text red s>
          {errors!.message}
        </Text>
      )}
    </Box>
  )
}

type EmailProps = {
  email: string
  label?: string
}

export const Email: React.FC<EmailProps> = ({ email, label }) => <Span onClick={() => window.open(`mailto:${email}`)}>{label ? label : email}</Span>
