import styled from 'styled-components'

interface IProps {
  isOpen: boolean
}

export const Wrap = styled.div<IProps>`
  width: 345px;
  max-height: 435px;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  border-radius: 6px;
  position: absolute;
  background: #fff;
  padding: 11px;
  z-index: 11;
  flex-direction: column;
  box-shadow: 0px 3px 17px rgba(150, 170, 180, 0.3);
`

export const Top = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: #9caab8;
  border-bottom: 1px solid #e3e8f1;
  padding: 0 0 10px 0;
  margin: 0 0 13px 0;
  position: relative;
`

export const Close = styled.img`
  width: 10px;
  height: 10px;
  margin: 0 0 0 auto;
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
`

export const Docs = styled.div`
  overflow-y: scroll;
`

export const Doc = styled.div`
  border: 1px solid #ededed;
  border-radius: 4px;
  margin: 0 0 10px 0;
  padding: 14px 20px;
`

export const DocTop = styled.div`
  display: flex;
  color: #292c38;
  text-align: left;
  margin: 0 0 6px 0;
`

export const DocBottom = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DayAndMonth = styled.div`
  background: #ebedf0;
  border-radius: 6px;
  font-size: 12px;
  text-align: center;
  color: rgba(41, 44, 56, 0.6);
  padding: 3px 14px;
`
