import Api from './index'
import { INote } from 'types/models'

class NotesApi {
  /**
   * Gets candidate notes by candidate id
   */
  static getCandidateNotes(id: number) {
    return Api.get(`/user/candidates/${id}/notes/`)
  }
  /**
   * Gets employer notes by employer id
   */
  static getEmployerNotes(id: number) {
    return Api.get(`/user/employers/${id}/notes/`)
  }

  /**
   * Gets employer-contact notes by employer id
   */
  static getEmployerContactNotes(id: number) {
    return Api.get(`/user/employer-contacts/${id}/notes/`)
  }

  /**
   * Gets job position notes by job position id
   */
  static getJobPositionNotes(id: number) {
    return Api.get(`/user/job-positions/${id}/notes/`)
  }

  /**
   * Add new candidate note
   */
  static postCandidateNotes(id: number, note: Partial<INote>) {
    return Api.post(`/user/candidates/${id}/notes/`, note)
  }
  /**
   * Add new employer note
   */
  static postEmployerNotes(id: number, note: Partial<INote>) {
    return Api.post(`/user/employers/${id}/notes/`, note)
  }
  /**
   * Add new employer-contact note
   */
  static postEmployerContactNotes(id: number, note: Partial<INote>) {
    return Api.post(`/user/employer-contacts/${id}/notes/`, note)
  }
  /**
   * Add new job position note
   */
  static postJobPositionNotes(id: number, note: Partial<INote>) {
    return Api.post(`/user/job-positions/${id}/notes/`, note)
  }

  /**
   * Edit candidate note by note id
   */
  static editCandidateNotes(id: number, note: Partial<INote>) {
    return Api.patch(`/user/candidates/notes/${id}/`, note)
  }
  /**
   * Edit employer note by note id
   */
  static editEmployerNotes(id: number, note: Partial<INote>) {
    return Api.patch(`/user/employers/notes/${id}/`, note)
  }
  /**
   * Edit employer-contact note by note id
   */
  static editEmployerContactNotes(id: number, note: Partial<INote>) {
    return Api.patch(`/user/employer-contacts/notes/${id}/`, note)
  }
  /**
   * Edit job position note by note id
   */
  static editJobPositionNotes(id: number, note: Partial<INote>) {
    return Api.patch(`/user/job-positions/notes/${id}/`, note)
  }

  /**
   * Delete candidate note by note id
   */
  static deleteCandidateNotes(id: number) {
    return Api.delete(`/user/candidates/notes/${id}/`)
  }
  /**
   * Delete employer note by note id
   */
  static deleteEmployerNotes(id: number) {
    return Api.delete(`/user/employers/notes/${id}/`)
  }
  /**
   * Delete employer-contact note by note id
   */
  static deleteEmployerContactNotes(id: number) {
    return Api.delete(`/user/employer-contacts/notes/${id}/`)
  }
  /**
   * Delete job position note by note id
   */
  static deleteJobPositionNotes(id: number) {
    return Api.delete(`/user/job-positions/notes/${id}/`)
  }
}

export default NotesApi
