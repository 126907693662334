import { fromJS } from 'immutable'

import {
  AuthTypes,
  COMPLETE_REGISTRATION_SUCCESS,
  CREATE_USER_FAIL,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_LOGIN,
  REFRESH_LOGIN_FAIL,
  REFRESH_LOGIN_SUCCESS,
  RESET_PASSWORD_FAIL,
  SET_NEW_PASSWORD_FAIL,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPLOAD_PHOTO_SUCCESS,
  CLEAR_NOTIFICATION,
} from './actions'

import { IAuthState } from 'types/reducerStates'

const initialState: IAuthState = fromJS({
  successRegistered: false,
  isFetching: false,
  isLoggedIn: false,
  isUpdateData: false,
  user: null,
  token: null,
  error: null,
})

export default (state = initialState, action: AuthTypes): IAuthState => {
  switch (action.type) {
    case REFRESH_LOGIN:
      return state.set('isFetching', true)
    case LOGIN_SUCCESS:
      return state
        .set('isLoggedIn', true)
        .set('isFetching', false)
        .set('user', action.payload.user)
        .set('token', action.payload.token)
        .set('error', null)
    case REFRESH_LOGIN_SUCCESS:
      return state
        .set('isLoggedIn', true)
        .set('isFetching', false)
        .set('token', action.payload.token)
        .set('user', action.payload.user)
        .set('error', null)
    case UPDATE_USER_SUCCESS:
      return state.set('user', action.payload).set('isUpdateData', true)
    case UPDATE_USER_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case UPLOAD_PHOTO_SUCCESS:
      return state.set('user', action.payload)
    case LOGIN_FAIL:
    case CREATE_USER_FAIL:
    case SET_NEW_PASSWORD_FAIL:
    case REFRESH_LOGIN_FAIL:
    case RESET_PASSWORD_FAIL:
      return state.set('error', action.payload).set('isFetching', false)
    case COMPLETE_REGISTRATION_SUCCESS:
      return state.set('successRegistered', true)
    case LOGOUT:
      return initialState
    case CLEAR_NOTIFICATION:
      return state.set('isUpdateData', false)
    default:
      return state
  }
}
