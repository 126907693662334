const TEXT_OF_STEP = {
  firstStep: {
    title: 'Enter main company info',
    subTitle: 'Before we get you started we need some important information from you ',
    faq: {
      title: 'Do I need to fill in all the fields?',
      text: `Optional fields are not mandatory but very useful to have handy`,
    },
  },
  secondStep: {
    title: 'Enter your client contact details',
    subTitle: 'Please give us a little bit more information about your client',
    faq: {
      title: 'Do I need to fill in all the fields?',
      text: `Optional fields are not mandatory but very useful to have handy`,
    },
  },
  thirdStep: {
    title: 'Upload company logo (optional)',
    subTitle: `Company logo isn't mandatory to upload but will make your listings looking much nicer if you do`,
    faq: {
      title: 'Can I crop my logo?',
      text: `Unfortunately you will need to upload the correct and final image.`,
    },
  },
}

export default function(currentStep: number) {
  switch (currentStep) {
    case 1:
      return TEXT_OF_STEP.firstStep
    case 2:
      return TEXT_OF_STEP.secondStep
    case 3:
      return TEXT_OF_STEP.thirdStep
    default:
      return null
  }
}
