import React from 'react'

import CandidatesActions from 'store/candidates/actions'
import { ISelectListItem } from 'types/forms'
import { ICandidateWithLastWorkPlace } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import InputSlider from 'components/Input/inputSlider'
import InputSelect from 'components/Input/InputSelect'
import CityStateSelect from 'components/Input/cityStateSelect'
import InputCheckbox from 'components/Input/inputCheckbox'
import InputMain from 'components/Input/inputMain'
import { LinkedIn } from 'components/Icons'

import theme from 'styles/theme'
import { BoxFill, Textarea, Image, Text, Box, H3 } from 'styles'

// const ICON_CALENDAR = <Image src={require('assets/icons/calendar.svg')} />
const ICON_DOLLAR = <Image src={require('assets/icons/$.svg')} />

type Props = {
  form: Partial<ICandidateWithLastWorkPlace>
  errors: Partial<ICandidateWithLastWorkPlace>
  onChangeField: (e: InputEvent & ISelectListItem & any, type?: string) => void
  handleInputChange: (e: InputEvent) => void
  setCandidateData: typeof CandidatesActions.setCandidateData
  day: {
    value: string
    error: string
    get: (value: string) => string
  }
  month: {
    value: string
    error: string
    get: (value: string) => string
  }
  year: {
    value: string
    error: string
    get: (value: string) => string
  }
}

const Second: React.FC<Props> = ({ form, errors, onChangeField, handleInputChange, day, month, year, setCandidateData }) => {
  const onChangeCity = (e: InputEvent & ISelectListItem) => onChangeField(e, 'wpCity')
  const onChangePostion = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Positions')
  const onChangeProjectTypes = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Sectors')
  const onChangePrefPosition = (e: InputEvent & ISelectListItem) => onChangeField(e, 'wpPosition')
  const onChangeExperience = (e: InputEvent & ISelectListItem) => onChangeField(e, 'experience')
  const onChangeItPackages = (e: InputEvent & ISelectListItem) => onChangeField(e, 'ItPackages')
  const onChangeProjectValues = (e: InputEvent & ISelectListItem) => onChangeField(e, 'ProjectValues')

  let d = new Date(Date.now())
  let dd: number | string = d.getDate()
  let mm: number | string = d.getMonth() + 1
  let y: number | string = d.getFullYear()

  if (!day.get('value') && !month.get('value') && !year.get('value')) {
    if (dd < 10) dd = `0${dd}`
    if (mm < 10) mm = `0${mm}`
    setCandidateData('day', dd.toString())
    setCandidateData('month', mm.toString())
    setCandidateData('year', y.toString())
  }

  const selectWeek = new Date(+year.get('value'), +month.get('value') - 1, +day.get('value'))
  const selectWeekForValid = new Date(+year.get('value'), +month.get('value') - 1, +day.get('value') + 1)

  let errorMessage = ''

  if (selectWeekForValid < new Date(Date.now())) {
    errorMessage = 'Date cannot be past'
  }

  const validationBirthDay = (day: number, month: number) => {
    const thirty = [4, 6, 9, 11].indexOf(month) !== -1 && day > 30
    if (thirty || (month === 2 && day > 29)) return true
    return thirty
  }

  if (validationBirthDay(+day.get('value'), +month.get('value'))) {
    errorMessage = 'This month has fewer days'
  }

  let res = Date.parse(selectWeek.toString()) - Date.now()
  res = Math.floor(res / 1000 / 60 / 60 / 24) + 1

  const currentWeek = [{ id: '1', name: 'Immediate' }]

  if (res === 0) currentWeek[0] = { id: '1', name: 'Immediate' }
  if (res > 0 && res <= 7) currentWeek[0] = { id: '2', name: '1 week' }
  if (res > 7 && res <= 14) currentWeek[0] = { id: '3', name: '2 week' }
  if (res > 14 && res <= 21) currentWeek[0] = { id: '4', name: '3 week' }
  if (res > 21 && res <= 28) currentWeek[0] = { id: '5', name: '4 week' }
  if (res > 28) currentWeek[0] = { id: '6', name: 'longer' }

  const onChangeDate = (e: InputEvent & ISelectListItem) => {
    const d = new Date(Date.now())
    if (e.value) d.setDate(d.getDate() + (e && Number(e.value)))

    dd = d.getDate()
    if (dd < 10) dd = `0${dd}`
    mm = d.getMonth() + 1
    if (mm < 10) mm = `0${mm}`
    y = d.getFullYear()

    setCandidateData('day', dd.toString())
    setCandidateData('month', mm.toString())
    setCandidateData('year', y.toString())

    return d
  }

  const normalizeDate = () => {
    let actualDay: number | string = day.get('value')
    if (parseInt(actualDay, 10) < 10) actualDay = `0${parseInt(actualDay, 10)}`
    let actualMonth: number | string = month.get('value')
    if (parseInt(actualMonth, 10) < 10) actualMonth = `0${parseInt(actualMonth, 10)}`
    setCandidateData('day', actualDay)
    setCandidateData('month', actualMonth)
  }

  const de = day.get('error')
  const me = month.get('error')
  const ye = year.get('error')

  return (
    <BoxFill column>
      <BoxFill mb="20px">
        <InputSelect value={form.Positions} isMulti onChange={onChangePostion} placeholder="Any" label="Job Titles they hire" optionsType="jobs" />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.Sectors}
          isMulti
          onChange={onChangeProjectTypes}
          placeholder="Any"
          label="Preferable project types"
          optionsType="projectTypes"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.ItPackages}
          isMulti
          onChange={onChangeItPackages}
          placeholder="Choose"
          label="Qualifications / IT Packages (optional)"
          optionsType="itPackages"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.ProjectValues}
          isMulti
          onChange={onChangeProjectValues}
          placeholder="Choose"
          label="Project values (optional)"
          optionsType="projectValues"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSlider value={form.experience} onChange={onChangeExperience} />
      </BoxFill>
      <BoxFill mb="20px">
        <Input
          value={form.minSalary}
          error={errors.minSalary}
          onChange={onChangeField}
          leftElement={ICON_DOLLAR}
          pl="30px"
          name="minSalary"
          label="Min. annual salary"
          type="text"
          pattern="[0-9\s]{0,9}"
        />
      </BoxFill>

      {/* <BoxFill column mb="20px">
        <Input
          value={form.availability}
          error={errors.availability}
          onChange={onChangeField}
          rightElement={ICON_CALENDAR}
          name="availability"
          label="Availability"
          type="date"
        />
        <Text left s>
          Please select a date when the candidate can start working. This will simplify the job search.
        </Text>
      </BoxFill> */}

      <Box spaceBetween fill>
        <Box width="48%" column>
          <InputSelect value={currentWeek} onChange={onChangeDate} label="Availability" optionsType="weeks" />
        </Box>
        <Box width="48%" mt="32px">
          <Box spaceBetween>
            <InputMain
              name="day"
              type="text"
              placeholder="DD"
              width="30%"
              pattern="[0-9]{0,2}"
              onBlur={normalizeDate}
              onChange={handleInputChange}
              value={day.get('value')}
            />
            <InputMain
              name="month"
              type="text"
              placeholder="MM"
              width="30%"
              pattern="[0-9]{0,2}"
              onBlur={normalizeDate}
              onChange={handleInputChange}
              value={month.get('value')}
            />
            <InputMain
              name="year"
              type="text"
              placeholder="Year"
              width="30%"
              pattern="[0-9]{0,4}"
              onChange={handleInputChange}
              value={year.get('value')}
            />
          </Box>
        </Box>
      </Box>

      <Box display="block">
        <Text s left color={theme.main.red}>
          {de}
        </Text>
        <Text s left color={theme.main.red}>
          {ye}
        </Text>
        <Text s left color={theme.main.red}>
          {me}
        </Text>
        <Text s left color={theme.main.red}>
          {errorMessage}
        </Text>
      </Box>

      <Box mb="20px">
        <Text left s>
          Please select a date when the candidate can start working. This will simplify the job search.
        </Text>
      </Box>

      <BoxFill mb="20px">
        <Input
          value={form.linkedIn}
          error={errors.linkedIn}
          onChange={onChangeField}
          name="linkedIn"
          leftElement={<LinkedIn isActive={form.linkedIn} />}
          pl="55px"
          placeholder="Enter the URL"
          label="LinkedIn profile (optional)"
        />
      </BoxFill>
      <BoxFill mb="30px" column>
        <Text black>Candidate description (optional)</Text>
        <BoxFill height="115px" mb="15px">
          <Textarea value={form.addInfo} onChange={onChangeField} name="addInfo" maxLength={2000} />
        </BoxFill>
        <BoxFill height="15px" />
        <Text s>2000 characters maximum</Text>
      </BoxFill>
      <Box column pb="20px" bb="1px solid #E3E8F1" mb="20px">
        <H3 fw="bold" black>
          Work experience
        </H3>
        <BoxFill maxWidth="70%">
          <Text left>
            Enter information about the last place of work. You’ll be able to add more workplaces in the future via user profile editing
          </Text>
        </BoxFill>
      </Box>
      <BoxFill column>
        <Box mb="20px">
          <H3 fw="bold" black>
            Last place of work
          </H3>
        </Box>
        <BoxFill>
          <BoxFill mb="20px" mr="14px">
            <Input
              value={form.wpCompanyName}
              error={errors.wpCompanyName}
              onChange={onChangeField}
              name="wpCompanyName"
              label="Company name (optional)"
            />
          </BoxFill>
          <BoxFill mb="20px">
            <Input
              value={form.wpCompanyWebsite}
              error={errors.wpCompanyWebsite}
              onChange={onChangeField}
              name="wpCompanyWebsite"
              label="Company webiste (optional)"
            />
          </BoxFill>
        </BoxFill>
        <BoxFill mb="20px">
          <InputSelect
            value={form.wpPosition}
            error={errors.wpPosition}
            onChange={onChangePrefPosition}
            placeholder="Choose position title"
            name="prefPosition"
            label="Job position (optional)"
            optionsType="jobs"
          />
        </BoxFill>
        <BoxFill>
          <CityStateSelect error={errors.wpCity} value={form.wpCity!} onChange={onChangeCity} />
        </BoxFill>
        <BoxFill mb="20px">
          <InputCheckbox value={form.wpCurrent} onChange={onChangeField} title="That is a current workplace" name="wpCurrent" />
        </BoxFill>
        <BoxFill spaceBetween>
          <Box width="48%">
            <Input value={form.wpFromDate} error={errors.wpFromDate} onChange={onChangeField} name="wpFromDate" label="From (optional)" type="date" />
          </Box>
          {form.wpCurrent || (
            <Box width="48%">
              <Input value={form.wpToDate} error={errors.wpToDate} onChange={onChangeField} name="wpToDate" label="To (optional)" type="date" />
            </Box>
          )}
        </BoxFill>
      </BoxFill>
    </BoxFill>
  )
}

export default Second
