import React from 'react'

import Button from 'components/Button'
import { Wrap, Title, Subtitle } from './styles'

type Props = {
  onOpenModal: (value: boolean) => void
}

const Goals: React.FC<Props> = ({ onOpenModal }) => (
  <Wrap>
    <Title l>
      Track your financial goals with <br />
      performance tracker
    </Title>
    <Subtitle m>Set your goals and increase productivity</Subtitle>
    <Button title="ADD GOALS" width="110px" height="52px" type="secondary" onClick={() => onOpenModal(true)} />
  </Wrap>
)

export default Goals
