import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'

import { AppState } from 'store/rootReducer'
import { IMatchedJobPosition } from 'types/models'

import { PaginationArrow } from 'components/Icons'
import ModalComponents from 'components/Modal'
import { ChangeStatus, CloseChanges, CloseDeal } from 'containers/Pipeline/components/Board/styled'
import { SmallLoader } from 'components/Loader'

import { getCandidateStatus } from 'utils'
import { BoxFill, Box, BoxFullFill, Text, Span, BoxCenterContent } from 'styles'

const Wrapper = styled(BoxFullFill).attrs(props => ({
  column: true,
  smooth: true,
  spaceBetween: true,
  border: `1px solid ${props.theme.main.borderInputColor}`,
}))``

type Props = {
  job: IMatchedJobPosition
  closeJobDeal: () => void
}

const MatchedJobCard: React.FC<Props & RouteComponentProps> = ({ job, history, closeJobDeal }) => {
  const [isOpenConfirmation, toggleOpenConfirmation] = useState<boolean>(false)
  const toPipelineRoute = () => history.push('/pipeline')
  const isFetchingPipeline = useSelector((state: AppState) => state.pipeline.get('isFetching'))
  const isFetchingCurrentInnerPage = useSelector((state: AppState) => state.currentInnerPage.get('isFetching'))
  const isFetching = isFetchingPipeline || isFetchingCurrentInnerPage

  return (
    <BoxFullFill shadow2 whiteBC smooth>
      <Wrapper>
        <BoxFill padding="15px" column>
          <BoxFill spaceBetween>
            <Box maxWidth="70%">
              <Text s fw="500" lh="14px" left black>
                {job.Position.name}
              </Text>
            </Box>
            <Text xs>{moment(job.Matched.updatedAt).fromNow()}</Text>
          </BoxFill>
          <BoxFill>
            <Text xs>{job.Employer.companyName && job.Employer.companyName}</Text>
          </BoxFill>
          <BoxFill>
            <Text xs secondColor>
              {getCandidateStatus(job.Matched.status)}
            </Text>
          </BoxFill>
        </BoxFill>
        <BoxFill spaceBetween>
          <BoxCenterContent onClick={toPipelineRoute} fill padding="12px 0" bt="1px solid #E3E8F1">
            <Box mr="6px">
              <Span>Show pipeline</Span>
            </Box>
            <PaginationArrow rotate="rotate(180deg)" fill="#2697FF" />
          </BoxCenterContent>
          {+job.Matched.status === -1 ? null : (
            <BoxCenterContent onClick={() => toggleOpenConfirmation(true)} fill padding="12px 0" bt="1px solid #E3E8F1">
              <Box mr="6px">
                <Text red pointer>
                  Unmatch
                </Text>
              </Box>
            </BoxCenterContent>
          )}
        </BoxFill>
      </Wrapper>
      {isOpenConfirmation ? (
        <ModalComponents isOpen={isOpenConfirmation} onRequestClose={() => toggleOpenConfirmation(false)}>
          <BoxFill column>
            <div>Are you sure that you want to unmatch?</div>
            <Box alignSelf="center">
              {isFetching ? (
                <SmallLoader width="40px" />
              ) : (
                <Box alignSelf="flex-end">
                  <ChangeStatus onClick={() => toggleOpenConfirmation(false)}>
                    <CloseChanges>No</CloseChanges>
                  </ChangeStatus>
                  <ChangeStatus onClick={closeJobDeal}>
                    <CloseDeal>Yes</CloseDeal>
                  </ChangeStatus>
                </Box>
              )}
            </Box>
          </BoxFill>
        </ModalComponents>
      ) : null}
    </BoxFullFill>
  )
}

export default withRouter(MatchedJobCard)
