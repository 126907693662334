import React from 'react'
import get from 'lodash/get'

import { uploadPhotoOfCandidate } from 'api/candidates'

import ImageUploadBlock from 'components/UploadInput/imageUploadNew'

import { BoxFill, H2 } from 'styles'

type Props = {
  form: any
  title: string
}

const Five: React.FC<Props> = ({ form, title }) => {
  const link = get(form, 'photoFile.link', '')
  return (
    <BoxFill column bbDefault padding="30px 40px 0">
      <BoxFill mb="20px" spaceBetween>
        <H2 black fw="bold">
          Edit {title.toLocaleLowerCase()}
        </H2>
      </BoxFill>
      <ImageUploadBlock id={form.id} photo={link} paramsForUploading={uploadPhotoOfCandidate} />
    </BoxFill>
  )
}

export default Five
