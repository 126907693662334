import {
  TaskTypes,
  GET_TASK_LIST,
  GET_TASK_LIST_SUCCESS,
  GET_TASK_LIST_FAIL,
  GET_TASK_LIST_BY_FILTER_SUCCESS,
  CHANGE_INPUT,
  CLEAR_INPUT,
  CHANGE_SELECT,
  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAIL,
  RESTORE_TASK,
  RESTORE_TASK_SUCCESS,
  RESTORE_TASK_FAIL,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
  CLOSE_TASK,
  CLOSE_TASK_SUCCESS,
  CLOSE_TASK_FAIL,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAIL,
} from './action'

import { TaskState } from './types'

const initialState: TaskState = {
  title: '',
  description: '',
  priority: 'Any time',
  taskDate: '',
  taskTime: '',
  isLoading: false,
  isLoadingAllTask: false,
  highPrioriy: {
    count: 0,
    rows: [],
  },
  mediumPrioriy: {
    count: 0,
    rows: [],
  },
  anyTime: {
    count: 0,
    rows: [],
  },
  done: {
    count: 0,
    rows: [],
  },
  error: false,
}

export default (state = initialState, action: TaskTypes): TaskState => {
  switch (action.type) {
    case CHANGE_INPUT:
      const { name, value } = action.payload
      return {
        ...state,
        [name]: value,
        error: '',
      }
    case CLEAR_INPUT:
      return {
        ...state,
        title: '',
        description: '',
        taskDate: '',
        taskTime: '',
        error: '',
      }
    case CHANGE_SELECT:
      return {
        ...state,
        priority: action.payload.value,
      }
    case GET_TASK_LIST:
      return {
        ...state,
        isLoadingAllTask: true,
        error: false,
      }
    case GET_TASK_LIST_SUCCESS:
      const { highPrioriy, mediumPrioriy, anyTime, done } = action.payload.data
      return {
        ...state,
        isLoadingAllTask: false,
        highPrioriy: {
          count: highPrioriy.count,
          rows: highPrioriy.rows,
        },
        mediumPrioriy: {
          count: mediumPrioriy.count,
          rows: mediumPrioriy.rows,
        },
        anyTime: {
          count: anyTime.count,
          rows: anyTime.rows,
        },
        done: {
          count: done.count,
          rows: done.rows,
        },
      }
    case GET_TASK_LIST_FAIL:
      return {
        ...state,
        isLoadingAllTask: false,
        error: action.payload,
      }
    case GET_TASK_LIST_BY_FILTER_SUCCESS:
      const { priority, data } = action.payload

      const typePriority: any = {
        high: 'highPrioriy',
        medium: 'mediumPrioriy',
        any: 'anyTime',
        done: 'done',
      }

      const currentPriority = `${typePriority[priority]}`

      return {
        ...state,
        [currentPriority]: {
          count: data.count,
          rows: data.rows,
        },
      }
    case CREATE_TASK:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        title: '',
        description: '',
        priority: 'Any time',
        taskDate: '',
        taskTime: '',
        isLoading: false,
      }
    case CREATE_TASK_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case RESTORE_TASK:
      return {
        ...state,
        isLoading: true,
      }
    case RESTORE_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case RESTORE_TASK_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_TASK:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_TASK_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case CLOSE_TASK:
      return {
        ...state,
        isLoading: true,
      }
    case CLOSE_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CLOSE_TASK_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_TASK:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        priority: 'Any time',
      }
    case UPDATE_TASK_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
