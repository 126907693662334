import React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PDFDownloadLink, StyleSheet } from '@react-pdf/renderer'
// import moment from 'moment'

import PipelineActions from 'store/pipeline/actions'
import { AppState } from 'store/rootReducer'
import { IInvoiceCreating, IUser } from 'types/models'

import Input from 'components/Input'
import ModalComponent from 'components/Modal'
import validate from 'containers/Pipeline/components/Board/helpers'
import Invoice from './pdfToPrint'

import { Image } from 'styles'
import {
  TopWrapper,
  TitleModal,
  CloseModal,
  CloseModalIcon,
  Subtitle,
  InputBlock,
  TextError,
  IBHeader,
  IBDescription,
  DownloadInvoiceBlock,
} from './styled'

const styles = StyleSheet.create({
  downloadInvoice: {
    padding: '20px 0',
    width: '545px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
  },
})

const ICON_DOLLAR = <Image src={require('assets/icons/$.svg')} />
const ICON_PERCENT = <Image src={require('assets/icons/percent.svg')} />

type Props = {
  // Parent component props
  isOpen: boolean
  closeModal: () => void
  task: null | {
    id: string
    JobPosition: any
    Candidate: any
    JobPositionId: number
    CandidateId: number
    column: string
    interviewDate: string
    photoFile: any
    itemId: number
    createdInvoice?: boolean
  }
  // Redux props
  userData: IUser
  sendBlobfile: typeof PipelineActions.sendBlobfile
  changePipelineStatus: typeof PipelineActions.changePipelineStatus
}

class CreateInvoiceModal extends React.Component<Props & RouteComponentProps> {
  state = {
    startDate: {
      value: '',
      // valid: false,
      valid: true,
      validationRules: {
        notEmpty: true,
        isPastDate: false,
      },
      touched: false,
      isPastDate: false,
    },
    salary: {
      value: '',
      valid: false,
      validationRules: {
        notEmpty: true,
      },
      touched: false,
    },
    percent: {
      value: '',
      valid: false,
      validationRules: {
        notEmpty: true,
      },
      touched: false,
    },
  }

  blobFile?: Blob | null

  onChangeModalField = (e: any) => {
    const { value, name } = e.target
    this.setState((prevState: any) => {
      return {
        [name]: {
          ...prevState[name],
          value,
          valid: name === 'startDate' ? true : validate(value, prevState[name].validationRules),
          touched: true,
        },
      }
    })
  }

  canDownloadInvoice = () => {
    const {
      userData: { firstName, lastName, abn, tfn, bsb, account },
    } = this.props
    return firstName && lastName && abn && tfn && bsb && account && this.checkModalData()
  }

  checkModalData = () => {
    const { startDate, salary, percent } = this.state
    return !startDate.isPastDate && startDate.value.length > 0 && salary.value.length > 0 && percent.value.length > 0
  }

  checkFields = () => {
    // const startDateValid = validate(this.state.startDate.value, this.state.startDate.validationRules)
    const salaryValid = validate(this.state.salary.value, this.state.salary.validationRules)
    const percentValid = validate(this.state.percent.value, this.state.percent.validationRules)
    this.setState({
      startDate: {
        ...this.state.startDate,
        // valid: startDateValid,
        valid: true,
        touched: true,
      },
      salary: {
        ...this.state.salary,
        valid: salaryValid,
        touched: true,
      },
      percent: {
        ...this.state.percent,
        valid: percentValid,
        touched: true,
      },
    })
    // return startDateValid && salaryValid && percentValid
    return salaryValid && percentValid && this.state.startDate.value
  }

  sendBlob = () => {
    const { sendBlobfile, task } = this.props
    if (this.blobFile && task) {
      const { startDate, salary, percent } = this.state
      const data: IInvoiceCreating = {
        startDate: startDate.value,
        annualSalary: salary.value,
        commissionPercent: percent.value,
      }
      sendBlobfile(task.itemId, data, this.blobFile)
    }
  }

  changeStatus = (statusId: string) => {
    const { changePipelineStatus, task } = this.props
    if (task) {
      changePipelineStatus(task.JobPositionId, task.CandidateId, statusId, task.interviewDate)
    }
  }

  // checkUserData = () => {
  //   const {
  //     userData: { firstName, lastName, abn, tfn, bsb, account },
  //     history,
  //   } = this.props
  //   if (!firstName || !lastName || !abn || !tfn || !bsb || !account) {
  //     history.push('/edit-profile')
  //     return true
  //   }
  //   return false
  // }

  checkDate = () => {
    const { startDate } = this.state

    this.setState({
      startDate: {
        ...startDate,
        valid: true,
        isPastDate: false,
      },
    })

    // if (startDate.value && !moment(startDate.value).isAfter()) {
    //   this.setState({
    //     startDate: {
    //       ...startDate,
    //       valid: false,
    //       isPastDate: true,
    //     },
    //   })
    // } else {
    //   this.setState({
    //     startDate: {
    //       ...startDate,
    //       isPastDate: false,
    //     },
    //   })
    // }
  }

  downloadHandler = (e: any) => {
    const isValid = this.checkFields()
    if (isValid) {
      this.props.closeModal()
      this.changeStatus('7')
      this.sendBlob()
    } else {
      e.preventDefault()
    }
  }

  render() {
    const { startDate, salary, percent } = this.state
    const { task, userData, isOpen, closeModal } = this.props

    const errorText = <TextError>This field is required</TextError>
    const pastDataErrorText = <TextError>Cannot select past date</TextError>
    const addDateValue = <TextError>Please choose date</TextError>

    return (
      userData &&
      task && (
        <ModalComponent isOpen={isOpen}>
          <TopWrapper>
            <TitleModal>Create invoice</TitleModal>
            <CloseModal onClick={closeModal}>
              <CloseModalIcon src={require('assets/icons/close.svg')} />
            </CloseModal>
          </TopWrapper>
          <Subtitle>Please fill in all fields to complete Create invoice</Subtitle>
          <InputBlock>
            <IBHeader>Start date</IBHeader>
            <Input
              onBlur={this.checkDate}
              name="startDate"
              type="date"
              value={startDate.value}
              onChange={this.onChangeModalField}
              error={!startDate.valid && startDate.touched}
              placeholder="Choose date YYYY-MM-DD"
            />
            <IBDescription>Please select a date agreed with the candidate by an employer</IBDescription>
            {!startDate.valid && startDate.touched && (startDate.isPastDate ? pastDataErrorText : errorText)}
            {!startDate.value && startDate.touched && addDateValue}
          </InputBlock>
          <InputBlock>
            <IBHeader>Annual salary</IBHeader>
            <Input
              name="salary"
              value={salary.value}
              onChange={this.onChangeModalField}
              error={!salary.valid && salary.touched}
              type="number"
              leftElement={ICON_DOLLAR}
              pl="30px"
            />
            <IBDescription>FIll in the candidate annual salary on that position</IBDescription>
            {!salary.valid && salary.touched && errorText}
          </InputBlock>
          <InputBlock>
            <IBHeader>Your commission percent</IBHeader>
            <Input
              name="percent"
              value={percent.value}
              onChange={this.onChangeModalField}
              error={!percent.valid && percent.touched}
              type="number"
              pl="35px"
              leftElement={ICON_PERCENT}
            />
            {!percent.valid && percent.touched && errorText}
          </InputBlock>
          <DownloadInvoiceBlock onClick={this.downloadHandler}>
            <PDFDownloadLink
              style={styles.downloadInvoice}
              document={<Invoice userData={userData} candidate={task} startDate={startDate.value} salary={salary.value} percent={percent.value} />}
              fileName={`Invoice_${task ? task.id : ''}.pdf`}
            >
              {({ loading, blob }) => {
                this.blobFile = blob
                return loading ? 'LOADING DOCUMENT...' : 'SEND AND DOWNLOAD INVOICE'
              }}
            </PDFDownloadLink>
          </DownloadInvoiceBlock>
        </ModalComponent>
      )
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  userData: state.auth.get('user'),
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      sendBlobfile: PipelineActions.sendBlobfile,
      changePipelineStatus: PipelineActions.changePipelineStatus,
    }
  )(CreateInvoiceModal)
)
