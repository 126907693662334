import styled from 'styled-components'

import { Box, BoxCol, Bold, Text } from 'styles'

export const WrapMain = styled(BoxCol).attrs({
  maxWidth: '30vw',
  minWidth: '407px',
  padding: '20px 0',
  alignCenter: true,
  relative: true,
  smooth: true,
  whiteBC: true,
  flex: 1,
  mr: '30px',
})``

export const WrapAvatar = styled(Box).attrs({
  relative: true,
  shadow2: true,
})`
  cursor: pointer;
  width: 120px;
  height: 120px;
  margin: 0 0 10px 0;
`

export const Linked = styled(Box).attrs({
  absolute: true,
  bottom: '-9px',
  right: 0,
})``

export const Header = styled(Bold).attrs({})`
  margin: 0 0 10px 0;
  word-break: break-all;
  padding: 0 20px;
`

export const WrapCenter = styled(Box).attrs({
  width: '100%',
  maxWidth: '90%',
  border: '1px solid #E3E8F1',
  smooth: true,
  padding: '20px',
  margin: '20px',
  display: 'block',
})``

export const Edit = styled.img`
  margin: 0 0 -2px 6px;
`

export const InputBlock = styled.div`
  width: 545px;
  margin: 0 18px 20px 18px;
`

export const Bottom = styled.div`
  border-top: 1px solid #e3e8f1;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0 0 0;
`
export const Title = styled(Bold).attrs({})``

export const Subtitle = styled(Text).attrs({})`
  margin: 0 0 30px 0;
`

export const LinkWrap = styled(BoxCol).attrs({})`
  width: 100%;
  padding: 0 20px;
`

export const CopyLink = styled.div`
  width: 100%;
  border: 1px solid #e3e8f1;
  box-shadow: inset 0px 1px 5px rgba(188, 196, 208, 0.2);
  border-radius: 6px;
  padding: 5px 10px;
  margin: 2px 0 0 0;
  position: relative;
`

export const LinkBlock = styled.div`
  width: 100%;
  margin: 0 0 8px 0;
`

export const Copied = styled.span`
  position: absolute;
  right: 0px;
  top: -30px;
  color: #3cb593;
`

export const CopyWrap = styled(Box).attrs({})`
  align-items: center;
  justify-content: space-between;
  span {
    color: #3cb593;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
`
