import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { AppState } from 'store/rootReducer'
import CandidatesActions from 'store/candidates/actions'
import { ICandidatesState } from 'types/reducerStates'

import NotesModal from 'containers/Notes'
import Header from 'components/ForCandidates&Employers/header'
import Body from 'components/ForCandidates&Employers/body'
import Pagination from 'components/Pagination'
import CandidateCard from 'components/ForCandidates&Employers/candidateCard'
import Loader from 'components/Loader'

// import { sortByFirstAndLastName } from 'utils'
import { sortByCreatedAt } from 'utils'

import { Image, BoxFullFill } from 'styles'

/* hide in MVP version */
// type Sort =
//   | 'Recent activity'
//   | 'By name (A-Z)'
//   | 'By name (Z-A)'
//   | 'By job positions (ascending)'
//   | 'By job positions (descending)'

type Props = {
  candidates: ICandidatesState
  fetchCandidates: typeof CandidatesActions.fetchCandidatesStarted
  setStatusCandidate: typeof CandidatesActions.setStatusCandidate
}

const Candidates: React.FC<Props & RouteComponentProps> = ({ history, location, candidates, fetchCandidates, setStatusCandidate }) => {
  /* hide in MVP version */
  // const [currentSort, setCurrentSort] = useState<Sort>('Recent activity')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const page = Number(params.get('page')) || 1
    fetchCandidates({ page })
  }, [])

  const renderCandidates = () =>
    candidates
      .get('items')
      .valueSeq()
      // .sort(sortByFirstAndLastName)
      .sort(sortByCreatedAt)
      .map((data: any) => <CandidateCard key={data.id} candidate={data} setStatusCandidate={setStatusCandidate} />)

  /* hide in MVP version */
  // const changeSort = (sort: any) => setCurrentSort(sort)

  const addCandidateRoute = () => history.push(`${location.pathname}/add`)
  return (
    <BoxFullFill column>
      <Header
        /* hide in MVP version */
        // currentSort={currentSort}
        // changeSort={changeSort}
        title="Candidates"
        addItemRoute={addCandidateRoute}
        isListEmpty={candidates.get('items').isEmpty()}
        numItems={candidates.get('candidatesQty')}
      />
      {candidates.get('isFetching') ? (
        <Loader withoutBg height="700px" />
      ) : (
        <>
          <Body
            items={candidates}
            renderItems={renderCandidates}
            addItemRoute={addCandidateRoute}
            title="Here will be your list of candidates"
            subTitle="Add your first candidates to start matching them with the related job positions"
            buttonText="ADD CANDIDATE"
            image={<Image width="453px" height="106px" src={require('assets/images/no-list-of-candidates.png')} />}
          />
          {candidates.get('pagesQty') <= 1 || (
            <Pagination currentPage={candidates.getIn(['query', 'page'])} fetchPage={fetchCandidates} pagesQty={candidates.get('pagesQty')} />
          )}
        </>
      )}
      <NotesModal />
    </BoxFullFill>
  )
}

const mapStateToProps = (state: AppState) => ({
  candidates: state.candidates,
  currentPage: state.candidates.getIn(['query', 'page']),
  pagesQty: state.candidates.get('pagesQty'),
})

export default connect(
  mapStateToProps,
  {
    fetchCandidates: CandidatesActions.fetchCandidatesStarted,
    setStatusCandidate: CandidatesActions.setStatusCandidate,
  }
)(Candidates)
