import React from 'react'
import { Map } from 'immutable'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'
import CandidateActions from 'store/candidates/actions'
import { ICandidate } from 'types/models'

import Loader from 'components/Loader'
import DocsUploadInput from 'components/UploadInput/docsUploadInput'

type Props = {
  form: ICandidate
  updateCVSuccess: (cv: any) => void
  onDeleteCV: (id: number, candidateId: number) => void
  isFetching: boolean
}

const Third: React.FC<Props> = ({ form, updateCVSuccess, onDeleteCV, isFetching }) => {
  if (!form) return null
  const arrayToObject = (array: any, keyField: string) =>
    array.reduce((obj: any, item: any) => {
      obj[item[keyField]] = item
      return obj
    }, {})
  return isFetching ? (
    <Loader withoutBg height="500px" />
  ) : (
    <DocsUploadInput
      title="CV"
      id={form.id}
      uploadedFiles={form.cvFile.length ? Map(arrayToObject(form.cvFile, 'id')) : Map()}
      onUploadSuccess={updateCVSuccess}
      onDeleteCV={onDeleteCV}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  isFetching: state.candidates.get('isFetching'),
})

export default connect(
  mapStateToProps,
  {
    updateCVSuccess: CandidateActions.updateCVSuccess,
    onDeleteCV: CandidateActions.deleteCV,
  }
)(Third)
