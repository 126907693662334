import React from 'react'

import MessengerActions from 'store/messenger/actions'

import { Close, Cyrcle, Header } from './styles'

import close from 'assets/icons/close.svg'

type Props = {
  onToggle: typeof MessengerActions.onTogglePreview
  hasNotification: boolean
  isOpen: boolean
}

const PreviewToggler: React.FC<Props> = ({ onToggle, hasNotification, isOpen }) => (
  <Header onClick={onToggle}>
    <div>
      Messages
      {hasNotification && <Cyrcle />}
    </div>
    {isOpen && <Close src={close} />}
  </Header>
)

export default PreviewToggler
