import { ActionsUnion, createAction } from 'types/helps'
import { IError } from 'types/errors'

export const GET_SIGNATURE = 'GET_SIGNATURE'
export const GET_SIGNATURE_SUCCESS = 'GET_SIGNATURE_SUCCESS'
export const GET_SIGNATURE_FAIL = 'GET_SIGNATURE_FAIL'

export const SET_SIGNATURE = 'SET_SIGNATURE'
export const SET_SIGNATURE_SUCCESS = 'SET_SIGNATURE_SUCCESS'
export const SET_SIGNATURE_FAIL = 'SET_SIGNATURE_FAIL'

export const CLEAR_SIGNATURE_ERROR = 'CLEAR_SIGNATURE_ERROR'

export const GET_CHAT_LIST = 'GET_CHAT_LIST'
export const GET_CHAT_LIST_SUCCESS = 'GET_CHAT_LIST_SUCCESS'
export const GET_CHAT_LIST_FAIL = 'GET_CHAT_LIST_FAIL'

export const SEND_INTERVIEW = 'SEND_INTERVIEW'
export const SEND_INTERVIEW_SUCCESS = 'SEND_INTERVIEW_SUCCESS'
export const SEND_INTERVIEW_FAIL = 'SEND_INTERVIEW_FAIL'

export const SEND_MESSAGE = 'SEND_MESSAGE'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL'

export const CHANGE_INPUT_VALUE = 'CHANGE_VALUE'
export const CLEAR_INPUT_VALUE = 'CLEAR_INPUT_VALUE'
export const CLEAR_ERROR = 'CLEAR_ERROR'

export const ADD_NEW_CANDIDATE_OR_EMPLOYER = 'ADD_NEW_CANDIDATE_OR_EMPLOYER'

export const MESSENGER__TOGGLE_PREVIEW = 'MESSENGER__TOGGLE_PREVIEW'
export const MESSENGER__CLOSE_DIALOG = 'MESSENGER__CLOSE_DIALOG'
export const MESSENGER__ON_CHANGE_MESSENGER_MODAL = 'MESSENGER__ON_CHANGE_MESSENGER_MODAL'

export const MESSENGER__GET_PREVIEW = 'MESSENGER__GET_PREVIEW'
export const MESSENGER__GET_PREVIEW__SUCCESS = 'MESSENGER__GET_PREVIEW__SUCCESS'

export const MESSENGER__SET_ACTIVE_USER = 'MESSENGER__SET_ACTIVE_USER'
export const MESSENGER__SET_ACTIVE_USER__SUCCESS = 'MESSENGER__SET_ACTIVE_USER__SUCCESS'

const MessengerActions = {
  getSignature: () => createAction(GET_SIGNATURE),
  getSignatureSuccess: (data: any) => createAction(GET_SIGNATURE_SUCCESS, data),
  getSignatureFail: (error: IError) => createAction(GET_SIGNATURE_FAIL, error),

  setSignature: () => createAction(SET_SIGNATURE),
  setSignatureSuccess: (data: any) => createAction(SET_SIGNATURE_SUCCESS, data),
  setSignatureFail: (error: IError) => createAction(SET_SIGNATURE_FAIL, error),

  getChatList: () => createAction(GET_CHAT_LIST),
  getChatListSuccess: (data: any) => createAction(GET_CHAT_LIST_SUCCESS, { data }),
  getChatListFail: (error: IError) => createAction(GET_CHAT_LIST_FAIL, { error }),

  sendMessage: () => createAction(SEND_MESSAGE),
  sendMessageSuccess: () => createAction(SEND_MESSAGE_SUCCESS),
  sendMessageFail: (error: IError) => createAction(SEND_MESSAGE_FAIL, { error }),

  sendInterview: (employerId: number, jobPositionId: number) => createAction(SEND_INTERVIEW, { employerId, jobPositionId }),
  sendInterviewSuccess: () => createAction(SEND_INTERVIEW_SUCCESS),
  sendInterviewFail: (error: IError) => createAction(SEND_INTERVIEW_FAIL, { error }),

  changeInputValue: (name: string, value: any) => createAction(CHANGE_INPUT_VALUE, { name, value }),
  clearInputValue: () => createAction(CLEAR_INPUT_VALUE),
  clearError: () => createAction(CLEAR_ERROR),
  clearSignatureError: () => createAction(CLEAR_SIGNATURE_ERROR),

  addNewCandidateOrEmployer: (type: string, id: number | null | undefined, name: string, position: string, avatar?: string) =>
    createAction(ADD_NEW_CANDIDATE_OR_EMPLOYER, { type, id, name, position, avatar }),

  onTogglePreview: () => createAction(MESSENGER__TOGGLE_PREVIEW),
  onCloseDialog: () => createAction(MESSENGER__CLOSE_DIALOG),
  setModalType: (modalType: string) => createAction(MESSENGER__ON_CHANGE_MESSENGER_MODAL, modalType),

  setUserAsActive: (user: any) => createAction(MESSENGER__SET_ACTIVE_USER, user),
  setUserAsActiveSuccess: (messages: any) => createAction(MESSENGER__SET_ACTIVE_USER__SUCCESS, messages),

  getPreviewSuccess: (previewList: any) => createAction(MESSENGER__GET_PREVIEW__SUCCESS, previewList),
}

export type MessengerTypes = ActionsUnion<typeof MessengerActions>
export default MessengerActions
