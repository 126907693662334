import React from 'react'

import { IUser } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'

import { BoxFill } from 'styles'

type Props = {
  form: IUser
  errors: IUser
  onChangeField: (e: InputEvent) => void
}

const Step2: React.FC<Props> = ({ form, onChangeField, errors }) => (
  <BoxFill column>
    <BoxFill mb="20px">
      <Input value={form.abn} error={errors.abn} name="abn" onChange={onChangeField} label="Sole trade ABN" />
    </BoxFill>
    <BoxFill mb="20px">
      <Input value={form.tfn} error={errors.tfn} name="tfn" onChange={onChangeField} label="TFN" />
    </BoxFill>
    <BoxFill mb="20px">
      <Input value={form.bsb} error={errors.bsb} name="bsb" onChange={onChangeField} label="BSB number" />
    </BoxFill>
    <BoxFill>
      <Input value={form.account} error={errors.account} name="account" onChange={onChangeField} label="Account number" />
    </BoxFill>
  </BoxFill>
)

export default Step2
