import React from 'react'

import { ISelectListItem } from 'types/forms'
import { IJobPosition } from 'types/models'
import { InputEvent } from 'types/helps'

import { BoxFullFill, Textarea, Text } from 'styles'

type Props = {
  form: IJobPosition
  onChangeField: (e: InputEvent & ISelectListItem) => void
  description: string | undefined
}

const Third: React.FC<Props> = ({ form, onChangeField }) => {
  return (
    <BoxFullFill column height="45vh !important">
      <Text black>Description</Text>
      <Textarea value={form.description} name="description" maxLength={5000} onChange={onChangeField} />
      <Text s>5000 characters maximum</Text>
    </BoxFullFill>
  )
}

export default Third
