import React from 'react'
import { BrowserRouter as Router, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
/* for IE polyfills */
import 'core-js/es6/promise'
import 'core-js/es6/function'
import 'core-js/es6/array'
import 'core-js/fn/object/assign'
import redux from 'store/store'
import SignIn from 'containers/SignIn'
import LastStep from 'containers/LastStepSignUp'
import SuccessRequest from 'containers/SignIn/SuccessRequest'
import SuccessResetPassword from 'containers/SignIn/SuccessResetPassword'
import ForgotPassword from 'containers/SignIn/ForgotPassword'
import ResetPassword from 'containers/SignIn/ResetPassword'
import Dashboard from 'containers/Dashboard'
import LiveJobs from 'containers/LiveJobs'
import Candidates from 'containers/Candidates'
import AddCandidate from 'containers/Candidates/AddCandidate'
import AddCandidateByLink from 'containers/Candidates/AddCandidateByLink'
import AddEmployer from 'containers/LiveJobs/AddEmployer'
import EditProfile from 'containers/EditProfile'
import Employer from 'containers/LiveJobs/Employer'
import Candidate from 'containers/Candidates/Candidate'
import AddJobPosition from 'containers/LiveJobs/AddJobPosition'
import AddJobPositionByLink from 'containers/LiveJobs/AddJobPositionByLink'
import Pipeline from 'containers/Pipeline'
import EditCandidate from 'containers/Candidates/Candidate/Edit'
import EditJobPosition from 'containers/LiveJobs/JobPositionInner/Edit'
import EditEmployer from 'containers/LiveJobs/Employer/Edit'
import AdminPanel from 'containers/AdminPanel'
import NoPage from 'containers/404'
import Messenger from 'containers/Messenger'
import Search from 'containers/Search'
import LiveJobsNew from 'containers/LiveJobsNew'
import Contacts from 'containers/Contacts'
import ProtectedRoute from 'components/ProtectedRoute'
import theme from 'styles/theme'
import GlobalStyles from 'styles/global'

function CONTRACTS() {
  return <h3>CONTRACTS</h3>
}

function SUPPORT() {
  return <h3>SUPPORT</h3>
}

class App extends React.Component<RouteComponentProps> {
  render() {
    const messengerPaths = [
      '/dashboard',
      '/pipeline',
      '/edit-profile',
      '/contracts',
      '/support',
      '/live-jobs',
      '/live-jobs/add',
      '/live-jobs/employer/:id',
      '/live-jobs/employer/:id/add-job',
      '/live-jobs-new',
      '/candidates',
      '/candidates/candidate/:id',
      '/candidates/add',
      '/candidates/candidate/:id/edit',
      '/live-jobs/employer/:id/edit',
      '/live-jobs/employer/:id/:jobPositionID/edit',
      '/search',
      '/contacts',
    ]
    return (
      <Provider store={redux.store}>
        <PersistGate loading={null} persistor={redux.persistor}>
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <GlobalStyles />
              <Router>
                <Switch>
                  <Route path="/" exact component={SignIn} />
                  <Route path="/complete-registration" component={LastStep} />
                  <Route path="/success-request" component={SuccessRequest} />
                  <Route path="/success-reset-password" component={SuccessResetPassword} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/reset-password" component={ResetPassword} />
                  <Route path="/admin" component={AdminPanel} />
                  <Route path="/add-job-position-by-link" component={AddJobPositionByLink} />
                  <Route path="/add-candidate-by-link" header={null} component={AddCandidateByLink} />
                  <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                  <ProtectedRoute exact path="/pipeline" component={Pipeline} />
                  <ProtectedRoute exact path="/edit-profile" component={EditProfile} />
                  <ProtectedRoute path="/contracts" component={CONTRACTS} />
                  <ProtectedRoute path="/support" component={SUPPORT} />
                  <ProtectedRoute exact path="/live-jobs-new" component={LiveJobsNew} />
                  <ProtectedRoute exact path="/live-jobs" component={LiveJobs} />
                  <ProtectedRoute exact path="/live-jobs/add" header={null} component={AddEmployer} />
                  <ProtectedRoute exact path="/live-jobs/employer/:id" component={Employer} />
                  <ProtectedRoute exact path="/live-jobs/employer/:id/add-job" header={null} component={AddJobPosition} />
                  <ProtectedRoute exact path="/live-jobs/add-job-position" header={null} component={AddJobPosition} />
                  <ProtectedRoute exact path="/candidates" component={Candidates} />
                  <ProtectedRoute exact path="/candidates/candidate/:id" component={Candidate} />
                  <ProtectedRoute exact path="/candidates/add" header={null} component={AddCandidate} />
                  <ProtectedRoute exact path="/candidates/candidate/:id/edit" component={EditCandidate} />
                  <ProtectedRoute exact path="/live-jobs/employer/:id/edit" component={EditEmployer} />
                  <ProtectedRoute exact path="/live-jobs/employer/:id/:jobPositionID/edit" component={EditJobPosition} />
                  <ProtectedRoute exact path="/search" component={Search} />
                  <ProtectedRoute exact path="/contacts" component={Contacts} />
                  <Route component={NoPage} />
                </Switch>
                <Route exact path={messengerPaths} component={Messenger} />
              </Router>
            </React.Fragment>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
