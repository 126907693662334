import React from 'react'

import PrimaryButton from 'components/Button'

import { Span, Text, H1, Box } from 'styles'
import { Container } from '../styles'

type Props = {
  logInScroll: () => void
  nextStep: () => void
}

const StartScreen: React.FunctionComponent<Props> = ({ logInScroll, nextStep }) => (
  <Container>
    <H1 fw="bold" black>
      Start now!
    </H1>
    <Box margin="10px 0 40px 0">
      <Text>It's free to join, no strings attached. But we promise you won't leave. </Text>
    </Box>
    <Box fill height="60px" mb="30px">
      <PrimaryButton onClick={nextStep} title="JOIN FOR FREE" />
    </Box>
    <Box>
      <Text>
        Already a member?
        <Span onClick={logInScroll}> Log In</Span>
      </Text>
    </Box>
  </Container>
)

export default StartScreen
