import { call, put, takeLatest, delay } from 'redux-saga/effects'
import moment from 'moment'

import CandidatesActions, {
  CREATE_CANDIDATE_STARTED,
  UPDATE_CANDIDATE_STARTED,
  DELETE_CANDIDATE,
  FETCH_ALL_CANDIDATES_STARTED,
  FETCH_CANDIDATE_STARTED,
  UPDATE_WORK_PLACE_STARTED,
  CREATE_WORK_PLACE_STARTED,
  DELETE_WORK_PLACE_STARTED,
  ON_SET_STATUS_CANDIDATE,
  MATCH_CANDIDATE,
  DELETE_LICENSE,
  DELETE_CV,
  CREATE_PUBLIC_CANDIDATE,
  CREATE_PUBLIC_CANDIDATEWITHOUT_ID,
} from './actions'

import SearchActions from 'store/search/action'

import CandidatesApi from 'api/candidates'
import { IMatchedSuccess } from 'types/responseData'

import { smoothScroll } from 'utils'

function* fetchCandidatesFlow(action: ReturnType<typeof CandidatesActions.fetchCandidatesStarted>) {
  try {
    const { data } = yield call(CandidatesApi.getCandidates, action.payload)
    yield put(CandidatesActions.fetchCandidatesSuccess(data))
  } catch (err) {
    yield put(CandidatesActions.fetchCandidatesFail(err))
  }
}

function* fetchCandidateFlow(action: ReturnType<typeof CandidatesActions.fetchCandidateStarted>) {
  try {
    const { employerId, page, perPage } = action.payload
    const { data } = yield call(CandidatesApi.getCandidate, employerId, page, perPage)
    yield put(CandidatesActions.fetchCandidateSuccess(data))
  } catch (err) {
    yield put(CandidatesActions.fetchCandidateFail(err))
  }
}

function* createCandidateFlow(action: ReturnType<typeof CandidatesActions.createCandidateStarted>) {
  try {
    const { City, wpCity, minSalary, experience, Positions, Sectors, wpPosition, RelocateCity, wpCurrent, ItPackages, ProjectValues } = action.payload
    const form = Object.assign({}, action.payload, {
      wpCurrent: (wpCurrent && wpCurrent) || undefined,
      CityId: City && City.id,
      wpCityId: wpCity && wpCity.id,
      minSalary: Number(minSalary),
      experience: Number(experience),
      PositionIds: Positions.map(position => position.id),
      SectorIds: Sectors.map(sector => sector.id),
      wpPositionId: wpPosition && wpPosition.id,
      RelocateCityIds: RelocateCity!.map((item: any) => item.id),
      ItPackageIds: ItPackages ? ItPackages.map((item: any) => item.id) : [],
      ProjectValueIds: ProjectValues ? ProjectValues.map((item: any) => item.id) : [],
    })
    const { data } = yield call(CandidatesApi.createCandidate, form)
    yield put(CandidatesActions.createCandidateSuccess(data))
    yield localStorage.setItem('draftCandidateId', data.id)
  } catch (err) {
    yield put(CandidatesActions.createCandidateFail(err))
  }
}

function* matchFlow(action: ReturnType<typeof CandidatesActions.match>) {
  try {
    const { JobPositionId, CandidateId, status } = action.payload
    const { data }: IMatchedSuccess = yield call(CandidatesApi.match, Number(JobPositionId), CandidateId, status)
    const Matched = {
      JobPositionId,
      CandidateId,
      status,
      createdAt: data.matched.createdAt,
      updatedAt: data.matched.updatedAt,
    }
    const JobPosition = { ...data.matched.JobPosition, Matched }
    const Candidate = { ...data.matched.Candidate, Matched }
    const matchedItem = { JobPosition, Candidate, Matched }
    yield put(CandidatesActions.matchSuccess(matchedItem))
  } catch (err) {
    yield put(CandidatesActions.matchFail(err))
  }
}

function* updateCandidateFlow(action: ReturnType<typeof CandidatesActions.updateCandidateStarted>) {
  try {
    const {
      City,
      minSalary,
      experience,
      birthday,
      availability,
      Positions,
      Sectors,
      hired,
      RelocateCity,
      phone,
      email,
      addInfo,
      ItPackages,
      ProjectValues,
    } = action.payload.candidate

    const form = Object.assign({}, action.payload.candidate, {
      CityId: City!.id,
      StateId: City!.State.id,
      minSalary: Number(minSalary),
      experience: Number(experience),
      birthday: birthday ? moment(birthday).format('YYYY-MM-DD') : null,
      availability: moment(availability).format('YYYY-MM-DD'),
      PositionIds: Positions!.map((item: any) => item.id),
      SectorIds: Sectors!.map((item: any) => item.id),
      hired: Boolean(hired),
      phone: phone || '',
      email: email || '',
      addInfo: addInfo || '',
      RelocateCityIds: RelocateCity!.map((item: any) => item.id),
      ItPackageIds: ItPackages ? ItPackages!.map((item: any) => item.id) : [],
      ProjectValueIds: ProjectValues ? ProjectValues!.map((item: any) => item.id) : [],
    })

    delete form.ProjectValues
    delete form.ItPackages

    const { data } = yield call(CandidatesApi.updateCandidate, action.payload.id, form)
    yield put(CandidatesActions.updateCandidateSuccess(data))
    smoothScroll()
    yield delay(3000)
    yield put(CandidatesActions.clearNotificationCandidate())
  } catch (err) {
    yield put(CandidatesActions.updateCandidateFail(err))
  }
}

function* deleteCandidateFLow(action: ReturnType<typeof CandidatesActions.deleteCandidate>) {
  try {
    yield call(CandidatesApi.deleteCandidate, action.payload)
    yield put(CandidatesActions.deleteCandidateSucess(action.payload))
  } catch (err) {
    yield put(CandidatesActions.deleteCandidateFail(err))
  }
}

function* setStatusCandidate(action: ReturnType<typeof CandidatesActions.setStatusCandidate>) {
  try {
    const { data } = yield call(CandidatesApi.setStatusCandidate, action.payload.id, action.payload.value)
    yield put(CandidatesActions.setStatusCandidateSuccess(data))
    yield put(SearchActions.searchCandidates())
  } catch (err) {
    yield put(CandidatesActions.setStatusCandidateFail())
  }
}

function* updateWorkPlaceFlow(action: ReturnType<typeof CandidatesActions.updateWorkPlace>) {
  try {
    const { City, Position, fromDate, toDate, current, companyWebsite } = action.payload.workPlace
    const workPlaceForm = Object.assign({}, action.payload.workPlace, {
      CityId: City!.id,
      current: Boolean(current),
      PositionId: Position!.id,
      companyWebsite: companyWebsite || '',
      toDate: moment(toDate).format('YYYY-MM-DD'),
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
    })
    if (Boolean(current)) delete workPlaceForm.toDate
    const { data } = yield call(CandidatesApi.updateWorkPlace, action.payload.id, workPlaceForm)
    yield put(CandidatesActions.updateWorkPlaceSuccess(data.id, data))
    smoothScroll()
  } catch (err) {
    yield put(CandidatesActions.updateWorkPlaceFail(err))
  }
}

function* createWorkPlaceFlow(action: ReturnType<typeof CandidatesActions.createWorkPlace>) {
  try {
    const { City, Position, fromDate, toDate, current } = action.payload.workPlace
    const workPlaceForm = Object.assign({}, action.payload.workPlace, {
      CityId: City!.id,
      PositionId: Position!.id,
      fromDate: moment(fromDate).format('YYYY-MM-DD'),
      toDate: moment(toDate).format('YYYY-MM-DD'),
    })
    if (current) delete workPlaceForm.toDate
    const { data } = yield call(CandidatesApi.createWorkPlace, action.payload.id, workPlaceForm)
    yield put(CandidatesActions.createWorkPlaceSuccess(data))
  } catch (err) {
    yield put(CandidatesActions.createWorkPlaceFail(err))
  }
}

function* deleteWorkPlaceFlow(action: ReturnType<typeof CandidatesActions.deleteWorkPlace>) {
  try {
    yield call(CandidatesApi.deleteWorkPlace, action.payload)
    yield put(CandidatesActions.deleteWorkPlaceSuccess(action.payload))
  } catch (err) {
    yield put(CandidatesActions.deleteWorkPlaceFail(err))
  }
}

function* deleteLicenseFlow(action: ReturnType<typeof CandidatesActions.deleteLicense>) {
  try {
    yield call(CandidatesApi.deleteLicense, action.payload.id)
    yield put(CandidatesActions.deleteLicenseSuccess())
    yield put(CandidatesActions.fetchCandidateStarted(action.payload.candidateId))
  } catch (err) {
    yield put(CandidatesActions.deleteLicenseFail(err))
  }
}

function* deleteCVFlow(action: ReturnType<typeof CandidatesActions.deleteCV>) {
  try {
    yield call(CandidatesApi.deleteCV, action.payload.id)
    yield put(CandidatesActions.deleteCVSuccess())
    yield put(CandidatesActions.fetchCandidateStarted(action.payload.candidateId))
  } catch (err) {
    yield put(CandidatesActions.deleteCVFail(err))
  }
}

function* createPublicCandidateFlow(action: ReturnType<typeof CandidatesActions.createPublicCandidate>) {
  try {
    const {
      City,
      wpCity,
      minSalary,
      experience,
      Positions,
      Sectors,
      wpPosition,
      RelocateCity,
      wpCurrent,
      ItPackages,
      ProjectValues,
    } = action.payload.candidate
    const form = Object.assign({}, action.payload.candidate, {
      wpCurrent: (wpCurrent && wpCurrent) || undefined,
      CityId: City && City.id,
      wpCityId: wpCity && wpCity.id,
      minSalary: Number(minSalary),
      experience: Number(experience),
      PositionIds: Positions.map(position => position.id),
      SectorIds: Sectors.map(sector => sector.id),
      wpPositionId: wpPosition && wpPosition.id,
      RelocateCityIds: RelocateCity!.map((item: any) => item.id),
      ItPackageIds: ItPackages ? ItPackages.map((item: any) => item.id) : [],
      ProjectValueIds: ProjectValues ? ProjectValues.map((item: any) => item.id) : [],
    })
    const { data } = yield call(CandidatesApi.createPublicCandidate, form, +action.payload.recruiterId)
    yield put(CandidatesActions.createPublicCandidateSuccess(data))
    yield localStorage.setItem('draftCandidateId', data.id)
  } catch (err) {
    yield put(CandidatesActions.createPublicCandidateFail(err))
  }
}

function* createPublicCandidateWithoutIdFlow(action: ReturnType<typeof CandidatesActions.createPublicCandidateWithoutId>) {
  try {
    const { City, wpCity, minSalary, experience, Positions, Sectors, wpPosition, RelocateCity, wpCurrent, ItPackages, ProjectValues } = action.payload
    const form = Object.assign({}, action.payload, {
      wpCurrent: (wpCurrent && wpCurrent) || undefined,
      CityId: City && City.id,
      wpCityId: wpCity && wpCity.id,
      minSalary: Number(minSalary),
      experience: Number(experience),
      PositionIds: Positions.map(position => position.id),
      SectorIds: Sectors.map(sector => sector.id),
      wpPositionId: wpPosition && wpPosition.id,
      RelocateCityIds: RelocateCity!.map((item: any) => item.id),
      ItPackageIds: ItPackages ? ItPackages.map((item: any) => item.id) : [],
      ProjectValueIds: ProjectValues ? ProjectValues.map((item: any) => item.id) : [],
    })
    const { data } = yield call(CandidatesApi.createPublicCandidateWithoutId, form)
    yield put(CandidatesActions.createPublicCandidateWithoutIdSuccess(data))
    yield localStorage.setItem('draftCandidateId', data.id)
  } catch (err) {
    yield put(CandidatesActions.createPublicCandidateWithoutIdFail(err))
  }
}

export default function* watchCandidates() {
  yield takeLatest(FETCH_ALL_CANDIDATES_STARTED, fetchCandidatesFlow)
  yield takeLatest(FETCH_CANDIDATE_STARTED, fetchCandidateFlow)
  yield takeLatest(CREATE_CANDIDATE_STARTED, createCandidateFlow)
  yield takeLatest(MATCH_CANDIDATE, matchFlow)
  yield takeLatest(UPDATE_CANDIDATE_STARTED, updateCandidateFlow)
  yield takeLatest(DELETE_CANDIDATE, deleteCandidateFLow)
  yield takeLatest(CREATE_WORK_PLACE_STARTED, createWorkPlaceFlow)
  yield takeLatest(UPDATE_WORK_PLACE_STARTED, updateWorkPlaceFlow)
  yield takeLatest(DELETE_WORK_PLACE_STARTED, deleteWorkPlaceFlow)
  yield takeLatest(ON_SET_STATUS_CANDIDATE, setStatusCandidate)
  yield takeLatest(DELETE_LICENSE, deleteLicenseFlow)
  yield takeLatest(DELETE_CV, deleteCVFlow)
  yield takeLatest(CREATE_PUBLIC_CANDIDATE, createPublicCandidateFlow)
  yield takeLatest(CREATE_PUBLIC_CANDIDATEWITHOUT_ID, createPublicCandidateWithoutIdFlow)
}
