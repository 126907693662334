import React, { useState } from 'react'
import { ValidationError } from 'yup'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'

import { AppState } from 'store/rootReducer'
import { IGoals, IPerformanceTracker, IUser } from 'types/models'
import { InputEvent } from 'types/helps'

import { LinkedIn } from 'components/Icons'
import Avatar from 'components/Avatar'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Input from 'components/Input'
import Withdraw from './Witdraw'
import Complete from './Complete'
import Goals from './Goals'
import PerformanceTracker from './PerformanceTracker'
import { GoalsModal } from './PerformanceTracker/styles'

import { correctGoalsScheme, formatErrors } from 'services/validation'
import { getValidUrl } from 'utils'
import theme from 'styles/theme'
import { Box, Text, Image } from 'styles'
import {
  WrapMain,
  WrapAvatar,
  Linked,
  Header,
  WrapCenter,
  Edit,
  Bottom,
  InputBlock,
  Subtitle,
  Title,
  LinkWrap,
  CopyLink,
  LinkBlock,
  CopyWrap,
  Copied,
} from './styles'

import edit from 'assets/icons/edit.svg'

const EditText = styled(Text).attrs({})`
  &:hover {
    opacity: 0.8;
  }
`

const ICON_DOLLAR = <Image src={require('assets/icons/$.svg')} />

type Props = {
  user: IUser
  updateGoals: (user: Partial<IGoals>, type: string) => void
  performanceTracker: IPerformanceTracker
}

type ModalType = {
  isOpen: boolean
  data: Partial<IGoals>
  errors: Partial<IGoals>
}

const Sidebar: React.FC<Props & RouteComponentProps> = ({ history, user, updateGoals, performanceTracker }) => {
  const [modal, _setModal] = useState<ModalType>({ isOpen: false, data: {}, errors: {} })
  const setModal = (modalNew: Partial<ModalType>) => _setModal({ ...modal, ...modalNew })
  const setIsOpen = (isOpen: boolean) => setModal({ isOpen })
  const userId = useSelector((state: AppState) => state.auth.get('user').id)
  const [copy1, setCopy1] = useState(false)
  const [copy2, setCopy2] = useState(false)

  const handleChangeModal = (e: InputEvent) => {
    const {
      name,
      value,
      validity: { valid },
    } = e.target
    if (valid) {
      setModal({ data: { ...modal.data, [name]: value } })
    }
  }

  const saveGoals = () =>
    correctGoalsScheme
      .validate(modal.data, { abortEarly: false })
      .then((goals: Partial<IGoals>) => {
        updateGoals(goals, 'goals')
        setModal({ isOpen: false, errors: {}, data: {} })
      })
      .catch((err: ValidationError) => setModal({ errors: formatErrors(err) }))

  const editProfileRoute = () => history.push('/edit-profile')
  const editProfilePhotoRoute = () => history.push('/edit-profile', { step: 3 })
  const hasGoals = user.annualGoal || user.monthlyGoal || user.quarterGoal || user.weeklyGoal

  const setCopyHandle = (type: number) => {
    if (type === 1) {
      setCopy1(true)
      setTimeout(() => {
        setCopy1(false)
      }, 1000)
    } else {
      setCopy2(true)
      setTimeout(() => {
        setCopy2(false)
      }, 1000)
    }
  }
  return (
    <WrapMain>
      <Box absolute top="23px" right="20px">
        <EditText accentColor pointer onClick={editProfileRoute}>
          Edit
          <Edit src={edit} />
        </EditText>
      </Box>
      <WrapAvatar br="50%">
        <Avatar width="120px" onClick={editProfilePhotoRoute} item={user} textSize="50px" />
        {user.linkedIn ? (
          <a href={getValidUrl(user.linkedIn)} target="_blank" rel="noopener noreferrer">
            <Linked>
              <LinkedIn isActive />
            </Linked>
          </a>
        ) : null}
      </WrapAvatar>
      <Header xl color={theme.main.colors.grey3}>
        {user.firstName} <br /> {user.lastName}
      </Header>
      <LinkWrap>
        <LinkBlock>
          <Text left s black>
            Public link for adding job positions
          </Text>
          <CopyLink>
            <CopyWrap>
              <Text xs left>{`https://app.recruiterslife.com/add-job-position-by-link/${userId}`}</Text>
              <CopyToClipboard text={`https://app.recruiterslife.com/add-job-position-by-link/${userId}`} onCopy={() => setCopyHandle(2)}>
                <span>Copy</span>
              </CopyToClipboard>
            </CopyWrap>
            {copy2 ? <Copied>Copied</Copied> : null}
          </CopyLink>
        </LinkBlock>
        <LinkBlock>
          <Text left s black>
            Public link for adding candidates
          </Text>
          <CopyLink>
            <CopyWrap>
              <Text xs left>{`https://app.recruiterslife.com/add-candidate-by-link/${userId}`}</Text>
              <CopyToClipboard text={`https://app.recruiterslife.com/add-candidate-by-link/${userId}`} onCopy={() => setCopyHandle(1)}>
                <span>Copy</span>
              </CopyToClipboard>
            </CopyWrap>
            {copy1 ? <Copied>Copied</Copied> : null}
          </CopyLink>
        </LinkBlock>
      </LinkWrap>
      <Text s>
        Share these links to your clients so they can add jobs <br /> and candidates directly to your RL account
      </Text>
      <WrapCenter>{true ? <Withdraw total={performanceTracker.total} /> : <Complete />}</WrapCenter>
      {hasGoals ? (
        <PerformanceTracker
          setModal={setModal}
          annualGoal={user.annualGoal}
          monthlyGoal={user.monthlyGoal}
          quarterGoal={user.quarterGoal}
          weeklyGoal={user.weeklyGoal}
          performanceTracker={performanceTracker}
        />
      ) : (
        <Goals onOpenModal={setIsOpen} />
      )}

      <Modal isOpen={modal.isOpen} onRequestClose={() => setIsOpen(false)} hideCloseIcon>
        <Title xl3 color={theme.main.colors.grey3}>
          {hasGoals ? 'Edit your goals' : 'Set your goals'}
        </Title>
        <Subtitle m>Choose your money tracker goals!</Subtitle>
        <GoalsModal>
          <InputBlock>
            <Input
              label="Weekly goal"
              type="text"
              leftElement={ICON_DOLLAR}
              pl="30px"
              onChange={handleChangeModal}
              name="weeklyGoal"
              error={modal.errors.weeklyGoal}
              value={modal.data.weeklyGoal || ''}
              pattern="[0-9\s]{0,9}"
            />
          </InputBlock>
          <InputBlock>
            <Input
              label="Monthly goal"
              type="text"
              leftElement={ICON_DOLLAR}
              pl="30px"
              onChange={handleChangeModal}
              name="monthlyGoal"
              error={modal.errors.monthlyGoal}
              value={modal.data.monthlyGoal || ''}
              pattern="[0-9\s]{0,9}"
            />
          </InputBlock>
          <InputBlock>
            <Input
              label="Quarter goal"
              type="text"
              leftElement={ICON_DOLLAR}
              pl="30px"
              onChange={handleChangeModal}
              name="quarterGoal"
              error={modal.errors.quarterGoal}
              value={modal.data.quarterGoal || ''}
              pattern="[0-9\s]{0,9}"
            />
          </InputBlock>
          <InputBlock>
            <Input
              label="Annual goal"
              type="text"
              leftElement={ICON_DOLLAR}
              pl="30px"
              onChange={handleChangeModal}
              name="annualGoal"
              error={modal.errors.annualGoal}
              value={modal.data.annualGoal || ''}
              pattern="[0-9\s]{0,9}"
            />
          </InputBlock>
        </GoalsModal>
        <Bottom>
          <Button title="SAVE CHANGES" onClick={saveGoals} width="172px" height="62px" />
        </Bottom>
      </Modal>
    </WrapMain>
  )
}

export default withRouter(Sidebar)
