import React from 'react'

import { IUserForm } from 'types/forms'
import { InputEvent } from 'types/helps'
import { INotValidFieldsErr } from 'types/errors'

import InputBox from 'components/Input'
import InputSlider from 'components/Input/inputSlider'
import PrimaryButton from 'components/Button'
import { RenderNotValidFields } from 'components/Others'
import { LinkedIn } from 'components/Icons'

import { Box, Span, Text, H2, BoxFill } from 'styles'
import { Container, Header } from '../styles'

type Props = {
  backStep: () => void
  onChangeField: (e: InputEvent, type?: string) => void
  finishSignUp: () => void
  form: Partial<IUserForm>
  errors: Partial<IUserForm>
  errorCreateUser: INotValidFieldsErr
}

const Step2: React.FC<Props> = ({ backStep, form, onChangeField, finishSignUp, errors, errorCreateUser }) => {
  const onChangeExperience = (e: any) => onChangeField(e, 'experience')
  return (
    <Container>
      <Header>
        <Span secondColor>Step 2</Span>
        <H2 fw="bold" black>
          Work experience
        </H2>
      </Header>
      <Box margin="5px 0 40px 0">
        <Text>How many years of expereince do you have? Please add details below.</Text>
      </Box>
      <BoxFill mb="30px">
        <InputSlider value={form.experience} onChange={onChangeExperience} />
      </BoxFill>
      <InputBox
        leftElement={<LinkedIn isActive={form.linkedIn} />}
        pl="55px"
        placeholder="Enter the URL"
        label="LinkedIn profile"
        name="linkedIn"
        error={errors.linkedIn}
        value={form.linkedIn}
        onChange={onChangeField}
      />
      <BoxFill spaceBetween alignCenter mt="40px">
        <Span secondColor onClick={backStep}>
          Back
        </Span>
        <Box height="60px" width="40%">
          <PrimaryButton onClick={finishSignUp} title="FINISH" />
        </Box>
      </BoxFill>
      <RenderNotValidFields errors={errorCreateUser} />
    </Container>
  )
}

export default Step2
