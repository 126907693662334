import Api from './index'

import { IPerformanceTracker } from 'types/models'
import { IInvoicesSuccess } from 'types/responseData'
import { IQueryParams } from 'types/helps'

class Dashboard {
  /**
   * Gets performance tracker
   */
  static getPerformanceTracker() {
    return Api.get<IPerformanceTracker>('/user/dashboard/performance-tracker/')
  }

  /**
   * Gets dashboard invoices
   */
  static getInvoices(query: IQueryParams) {
    return Api.get<IInvoicesSuccess>(`/user/dashboard/invoices/`, {
      params: query,
    })
  }
}

export default Dashboard
