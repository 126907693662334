import React, { FC, useState, useCallback } from 'react'
import { ProjectTypeListItem } from 'hooks/useProjectTypesCRUD'
import edit from 'assets/icons/edit.svg'
import styled from 'styled-components'
import { Text } from 'styles'
import { EditProjectModal } from './EditProjectModal'

interface Props {
  project: ProjectTypeListItem
  refreshList: () => void
}

export const ProjectTypeCard: FC<Props> = ({ project, refreshList }) => {
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false)

  const closeEditModal = useCallback(() => {
    setEditModalOpen(false)
  }, [])

  const openEditModal = useCallback(() => {
    setEditModalOpen(true)
  }, [])

  return (
    <div style={{ marginBottom: '20px', width: '48%' }}>
      <Wrapper>
        <div style={{ paddingRight: 5 }}>
          <Name>{project.name}</Name>
          <JobsCount>{project.jobsCount === 1 ? `${project.jobsCount} job position` : `${project.jobsCount} job positions`}</JobsCount>
        </div>
        <Edit accentColor pointer onClick={openEditModal}>
          Edit
          <EditIcon src={edit} />
        </Edit>
      </Wrapper>
      <EditProjectModal isOpen={isEditModalOpen} onClose={closeEditModal} project={project} refreshList={refreshList} />
    </div>
  )
}

const Name = styled.p`
  font-size: 17px;
  font-weight: bold;
  line-height: 18px;
  word-break: break-all;
  white-space: wrap;
  padding-right: 20px;
`

const JobsCount = styled.div`
  font-size: 14px;
  line-height: 19px;
  margin-top: 8px;
  color: #292c38;
`

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  padding: 20px 24px;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(231, 236, 245, 0.5);
  border: 1px solid #e3e8f1;
`

const Edit = styled(Text).attrs({})`
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
`

const EditIcon = styled.img`
  margin-left: 6px;
`
