import React from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { RouteComponentProps } from 'react-router-dom'

import { AppState } from 'store/rootReducer'
import NotesActions from 'store/notes/actions'
import EmployersActions from 'store/employers/actions'
import { IEmployer, IJobPosition } from 'types/models'
import { INotValidFieldsErr } from 'types/errors'

import NotesModal from 'containers/Notes'
import Button from 'components/Button'
import Avatar from 'components/Avatar'
import Modal from 'components/Modal'
import JobPositionCard from 'components/ForCandidates&Employers/jobPositionCard'
import EmployerContactCard from 'components/ForCandidates&Employers/employerContactCard'
import Loader from 'components/Loader'
import EditIcon from 'components/EditIcon'
import JobPositionInner from '../JobPositionInner'
import { RenderNotValidFields } from 'components/Others'

import { Box, BoxFill, BoxCol, Text, Bold, Image, H1, H2, Span } from 'styles'
import {
  FormHeader,
  FormRow,
  BG,
  Wrapper,
  EmployerInfoBox,
  CompanyInfo,
  InfoBox,
  TextNoWrap,
  TextEllipsisContainer,
  SpanEllipsis,
  AddNotes,
} from './styles'

type Params = {
  id: number
}

type Props = {
  isFetching: boolean
  employer: IEmployer
  jobPositions: Map<number, IJobPosition>
  jobPositionsQty: number | null
  pagesQty: number | null
  query: { page: number; perPage: number } | null
  fetchEmployer: typeof EmployersActions.fetchEmployer
  match: { params: Params }
  resetStore: typeof EmployersActions.resetStore
  onToggleModal: typeof NotesActions.onToggleModal
  employers: IEmployer[]
  error: INotValidFieldsErr
}

type State = {
  currentPositionInnerId: false | number | string
}

class Employer extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    currentPositionInnerId: false,
  }

  componentDidMount() {
    this.props.fetchEmployer(this.props.match.params.id)
    const jobId = this.props.location.search.split('=')[1]
    if (jobId) this.setState({ currentPositionInnerId: jobId })
  }

  componentWillUnmount() {
    this.props.resetStore()
  }

  loadMore = () => this.props.fetchEmployer(this.props.match.params.id, ++this.props.query!.page)

  Info = () => {
    const { employer, history, onToggleModal } = this.props
    const onOpenWebSite = () => window.open(employer.website)
    const editEmployerRoute = () => history.push(`${window.location.pathname}/edit`)
    if (!employer) return null
    const { City } = employer
    const city = City && City.name.indexOf(City.State.abbr) > 0 ? City.name : `${City.name} ${City.State.abbr}`
    return (
      <EmployerInfoBox>
        <Box absolute top="23px" right="20px">
          <EditIcon onClick={editEmployerRoute} />
        </Box>
        <Box shadow2 br="50%">
          <Avatar contain item={employer} />
        </Box>
        <BoxCol fill overflow="hidden" alignCenter mt="16px">
          <Bold black xl>
            {employer.companyName}
          </Bold>
          {employer.City ? <Text>{city}</Text> : null}
        </BoxCol>
        <CompanyInfo fill>
          <FormHeader title="Company info" />
          <FormRow title="HQ address" value={employer.address} />
          <FormRow title="ABN" value={employer.abn} />
          <InfoBox>
            <TextNoWrap>Website link</TextNoWrap>
            <TextEllipsisContainer>
              <SpanEllipsis onClick={onOpenWebSite}>{employer.website}</SpanEllipsis>
            </TextEllipsisContainer>
          </InfoBox>
          <FormRow title="Office phone" value={employer.phone} />
        </CompanyInfo>
        <BoxCol fill mt="20px">
          <FormHeader title="Notes" />
          <Box fill defaultBorder smooth height="52px" alignCenter justifyCenter>
            <AddNotes
              secondColor
              xs
              fw="bold"
              pointer
              onClick={() => {
                onToggleModal(true, employer.id, 'employer')
              }}
            >
              {employer.notesCount === 1 ? `${employer.notesCount} note` : employer.notesCount ? `${employer.notesCount} notes` : 'Add notes'}
            </AddNotes>
          </Box>
        </BoxCol>
        <BoxCol fill mt="20px">
          <FormHeader title="Client contact details" />
          {employer.EmployerContacts &&
            employer.EmployerContacts.map((employerContact, key) => <EmployerContactCard key={key} contact={employerContact} />)}
        </BoxCol>
      </EmployerInfoBox>
    )
  }

  noPositions = (addJobPositionRoute: () => void) => (
    <BoxCol fill smooth>
      <BoxFill column smooth whiteBC alignCenter padding="50px 0" mt="20px">
        <Image width="210px" height="70px" src={require('assets/images/no-job-positions-.png')} />
        <Box mt="20px" mb="2px">
          <H2 black fw="bold">
            You have no job positions <br />
            for this employer
          </H2>
        </Box>
        <Text>
          To start working with this employer you should
          <br />{' '}
          <Span secondColor onClick={addJobPositionRoute}>
            add a job position
          </Span>{' '}
          to his profile
        </Text>
      </BoxFill>
    </BoxCol>
  )

  setJobPositionInnerId = (id: number) => this.setState({ currentPositionInnerId: id })

  hideJobPositionInnerModal = () => {
    this.setState({ currentPositionInnerId: false })
    this.props.history.replace(this.props.history.location.pathname)
    this.props.fetchEmployer(this.props.match.params.id)
  }

  render() {
    const { currentPositionInnerId } = this.state
    const { history, location, isFetching, jobPositions, pagesQty, query, jobPositionsQty, onToggleModal, error } = this.props

    if (isFetching) return <Loader />

    const addJobPositionRoute = () => history.push(`${location.pathname}/add-job`, { id: this.props.match.params.id })
    const addCandidateRoute = () => history.push('/candidates/add')
    const editJobPositionRoute = () => history.push(`${location.pathname}/${currentPositionInnerId}/edit`)

    let moreItemsCount
    if (jobPositionsQty && pagesQty === query!.page + 1) {
      const actualCount = jobPositionsQty - jobPositions!.size
      if (actualCount < 8) moreItemsCount = actualCount
    }

    return (
      <BoxFill>
        <BG />
        <Wrapper pb="30px">
          {this.Info()}
          <BoxFill column flex={2.3}>
            <BoxFill spaceBetween>
              <H1 fw="bold">Job positions</H1>
              <Box width="160px" height="60px">
                <Button title="ADD JOB POSITION" onClick={addJobPositionRoute} />
              </Box>
            </BoxFill>
            <RenderNotValidFields errors={error} />
            {jobPositions.isEmpty()
              ? this.noPositions(addJobPositionRoute)
              : jobPositions
                  .valueSeq()
                  .map((el, i) => (
                    <JobPositionCard
                      key={el.id}
                      jobPosition={el}
                      setCurrentJobPositionId={this.setJobPositionInnerId}
                      toggleNoteModal={onToggleModal}
                      numberOfCandidates={el.Candidates && +el.Candidates.length}
                    />
                  ))}
            {query && pagesQty !== query!.page && !jobPositions.isEmpty() && moreItemsCount && (
              <BoxFill>
                <Button type="load" onClick={this.loadMore} title={`Show ${moreItemsCount} more positions`} />
              </BoxFill>
            )}
          </BoxFill>
          <Modal isOpen={currentPositionInnerId} onRequestClose={this.hideJobPositionInnerModal} withScroll hideCloseIcon>
            <JobPositionInner
              id={currentPositionInnerId}
              addCandidateRoute={addCandidateRoute}
              editJobPositionRoute={editJobPositionRoute}
              onClose={this.hideJobPositionInnerModal}
            />
          </Modal>
        </Wrapper>
        <NotesModal />
      </BoxFill>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  isFetching: state.currentInnerPage.get('isFetching'),
  employer: state.currentInnerPage.get('item'),
  jobPositions: state.currentInnerPage.get('positions'),
  jobPositionsQty: state.currentInnerPage.get('positionsQty'),
  pagesQty: state.currentInnerPage.get('pagesQty'),
  query: state.currentInnerPage.get('query'),
  employers: state.employers.get('items'),
  error: state.employers.get('error'),
})

export default connect(
  mapStateToProps,
  {
    fetchEmployer: EmployersActions.fetchEmployer,
    resetStore: EmployersActions.resetStore,
    onToggleModal: NotesActions.onToggleModal,
  }
)(Employer)
