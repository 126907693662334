import Swal, { SweetAlertOptions } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const SwalReact = withReactContent(Swal)

const DEFAULT_SETTINGS = {
  showCancelButton: true,
  allowOutsideClick: true,
  allowEscapeKey: false,
  reverseButtons: false,
  focusConfirm: false,
  focusCancel: false,
  confirmButtonColor: '#9CAAB8',
  cancelButtonColor: '#3CB593',
  animation: false,
  padding: '40px',
  customClass: {
    title: 'swal-title',
    content: 'swal-content',
  },
}

const Alert = (settings: SweetAlertOptions) => SwalReact.fire(Object.assign(DEFAULT_SETTINGS, settings))

export default Alert
