import { call, put, takeLatest } from 'redux-saga/effects'
import DashboardActions, { DASHBOARD__GET_PERFORMANCE_TRACKER, DASHBOARD__GET_INVOICES } from './actions'
import DashboardApi from '../../api/dashboard'

function* getPerformanceTrackerFlow() {
  try {
    const { data } = yield call(DashboardApi.getPerformanceTracker)
    yield put(DashboardActions.getPerformanceTrackerSuccess(data))
  } catch (err) {}
}

function* getInvoicesFlow(action: ReturnType<typeof DashboardActions.getInvoices>) {
  try {
    const { data } = yield call(DashboardApi.getInvoices, action.payload)
    yield put(DashboardActions.getInvoicesSuccess(data))
  } catch (err) {}
}

export default function* watchDashboard() {
  yield takeLatest(DASHBOARD__GET_PERFORMANCE_TRACKER, getPerformanceTrackerFlow)
  yield takeLatest(DASHBOARD__GET_INVOICES, getInvoicesFlow)
}
