import styled from 'styled-components'
import Button from 'components/Button'

export const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 15px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  box-shadow: 0px 2px 6px rgba(231, 236, 245, 0.5);
  border-radius: 6px;
  background: #fff;
`

export const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`

export const Company = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #9caab8;
  border-right: 1px solid #e3e8f1;
  height: 18px;
  padding: 0 6px 0 0;
  margin: 0 6px 0 0;
`

export const Avatar = styled.img`
  width: 42px;
  height: 42px;
  margin: 0 6px 0 0;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
`

export const Create = styled(Button).attrs({
  width: '100%',
  height: '40px',
})``

export const TopWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const TitleModal = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #292c38;
  padding: 20px 0 10px 0;
`

export const CloseModal = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloseModalIcon = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
`

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #9caab8;
  width: 350px;
  margin: 0 auto 30px auto;
`

export const InputBlock = styled.div`
  width: 545px;
  margin: 0 0 20px 0;
`

export const IBHeader = styled.p`
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 5px;
  color: #292c38;
`

export const IBDescription = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #9caab8;
  margin: 5px 0 0 0;
`

export const DownloadInvoiceBlock = styled.div`
  width: 545px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 10px 0;
  background: #3cb593;
  color: #fff;
  font-family: Mukta;
  letter-spacing: 1px;
  font-size: 12px;
  cursor: pointer;
`

export const TextError = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: red;
  margin: 5px 0 0 0;
`
