import React from 'react'

import Button from 'components/Button'

import { Box, Text, Span, H2 } from 'styles'

import { Container, Footer, Header, Wrap, WrapButton, WrapBack } from './styles'

type Props = {
  children: import('react').ReactNode
  currentStep: number
  maxStep: number
  title: string
  text: string
  btnText: string
  isShowBackStep?: boolean
  isShowFooter?: boolean
  // nextStep: () => void
  nextStep: any
  back: () => void
  isFetching?: boolean
}

const Form: React.FunctionComponent<Props> = ({
  children,
  currentStep,
  maxStep,
  title,
  text,
  btnText,
  nextStep,
  back,
  isShowBackStep,
  isShowFooter,
  isFetching,
}) => (
  <Container>
    <Header>
      <Box>
        <Text secondColor fw="bold">
          Step {currentStep}&nbsp;of&nbsp;{maxStep}
        </Text>
      </Box>
      <Box mt="14px" mb="5px">
        <H2 black left fw="bold">
          {title}
        </H2>
      </Box>
      <Box width="70%">
        <Text left>{text}</Text>
      </Box>
    </Header>
    <Wrap column>{children}</Wrap>
    {isShowFooter ? (
      <Footer>
        <WrapBack pl="40px">
          {currentStep > 1 && isShowBackStep ? (
            <Span onClick={back} secondColor>
              Back
            </Span>
          ) : null}
        </WrapBack>
        {btnText ? (
          <WrapButton pr="40px" height="60px">
            <Button padding="22px 20px" onClick={nextStep} title={btnText} disabled={isFetching} />
          </WrapButton>
        ) : null}
      </Footer>
    ) : null}
  </Container>
)

Form.defaultProps = {
  isShowBackStep: true,
  isShowFooter: true,
}

export default Form
