import React from 'react'
import get from 'lodash/get'

import { paramsForUploadPhotoUser } from 'api/auth'

import ImageUploadBlock from 'components/UploadInput/imageUploadNew'

import { BoxFill, H2 } from 'styles'

type Props = {
  form: any
  title: string
  onUpdate: any
}

const Four: React.FC<Props> = ({ form, title, onUpdate }) => {
  const link = get(form, 'photoFile.link', '')
  return (
    <BoxFill column bbDefault padding="30px 40px 0">
      <BoxFill mb="20px" spaceBetween>
        <H2 black fw="bold">
          Edit {title}
        </H2>
      </BoxFill>
      <ImageUploadBlock id={form.id} photo={link} paramsForUploading={paramsForUploadPhotoUser} onUpdate={onUpdate} />
    </BoxFill>
  )
}

export default Four
