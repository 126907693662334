import styled from 'styled-components'

import { Box } from 'styles'

export const Container = styled(Box).attrs({
  column: true,
  alignCenter: true,
  justifyCenter: true,
  padding: '0 5vw',
  height: '100vh',
})`
  overflow-x: auto;
`

export const Header = styled(Box).attrs({
  alignCenter: true,
  column: true,
  width: '70%',
})``
