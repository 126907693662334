import React from 'react'
import styled from 'styled-components'
import { IMeta } from 'react-dropzone-uploader'

import { IFile } from 'types/models'

import { bytesToSize, checkStatuses, getIconOfFileByType } from 'utils'

import { BoxFill, Box, Text, Image } from 'styles'

const Container = styled(BoxFill)`
  border: ${props => '1px solid' + props.theme.main.borderInputColor};
  align-items: center;
  padding: 15px;
  border-radius: 6px;
  position: relative;
`

const ProgressLine = styled(Box)`
  position: absolute;
  border-radius: 6px;
  height: 4px;
  bottom: 0.5px;
`

type Props = {
  meta?: IMeta
  file?: IFile
  remove?: () => void
  onClick?: () => void
}

const DocItem: React.FC<Props> = ({ meta, file, remove, onClick }) => {
  const isLoadingDone = meta ? checkStatuses(meta, ['done']) : true
  const isUploading = meta ? checkStatuses(meta, ['uploading']) : false
  const textOfLoadingStatus = () => {
    if (isLoadingDone && remove) remove()
    return meta ? !isUploading || `Uploading. ${bytesToSize((meta.size * meta.percent) / 100)} of ${bytesToSize(meta.size)}` : bytesToSize(file!.size)
  }
  return (
    <BoxFill relative mb="10px">
      <Container>
        <Box mr="10px">
          <Image width="24px" height="30px" src={getIconOfFileByType(meta ? meta.type : file!.mimeType)} />
        </Box>
        <Box column>
          <Text black fw="500">
            {meta ? meta.name : file!.name}
          </Text>
          <Text s lh="14px">
            {textOfLoadingStatus()}
          </Text>
        </Box>
        {remove ? (
          <Box justifyRight flex={1}>
            <Image
              onClick={() => remove()}
              pointer
              width="14px"
              height="14px"
              src={require(`assets/icons/${isLoadingDone ? 'trash' : 'close'}.svg`)}
            />
          </Box>
        ) : (
          <Box justifyRight flex={1} ml="10px">
            <Image onClick={() => onClick!()} pointer width="36px" height="36px" src={require(`assets/icons/download.svg`)} />
          </Box>
        )}
      </Container>
      <ProgressLine fill bc={`${isLoadingDone || '#D8F0E9'}`} />
      {meta && <ProgressLine width={`${meta.percent}%`} bc={`${isLoadingDone || '#3CB593'}`} />}
    </BoxFill>
  )
}

export default DocItem
