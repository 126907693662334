import React from 'react'

import MessengerActions from 'store/messenger/actions'

import { Text } from 'styles'
import { Header, CompanionName, Avatar, HeaderCompanionWrapper, Minus, AvatarBG } from './styles'

import minus from 'assets/icons/minus.svg'

type Props = {
  onToggle: typeof MessengerActions.onCloseDialog
  userData: any
}

const DialogToggler: React.FC<Props> = ({ userData, onToggle }) => (
  <Header
    onClick={() => {
      onToggle()
    }}
  >
    <HeaderCompanionWrapper>
      {userData.size && (
        <>
          {userData.get('chatUserType') === 'candidate' &&
            (userData.get('Candidate').get('photoFile') ? (
              <Avatar
                mini
                src={userData
                  .get('Candidate')
                  .get('photoFile')
                  .get('link')}
              />
            ) : (
              <AvatarBG mini>
                {userData
                  .get('Candidate')
                  .get('firstName')
                  .match(/\b(\w)/g)}
                {userData
                  .get('Candidate')
                  .get('lastName')
                  .match(/\b(\w)/g)}
              </AvatarBG>
            ))}

          {userData.get('chatUserType') === 'employerContact' && (
            <AvatarBG mini>
              {userData
                .get('EmployerContact')
                .get('name')
                .match(/\b(\w)/g)}
            </AvatarBG>
          )}

          {userData.get('type') === 'candidate' &&
            (userData.get('avatar') ? (
              <Avatar mini src={userData.get('avatar')} />
            ) : (
              <AvatarBG mini>{userData.get('name').match(/\b(\w)/g)}</AvatarBG>
            ))}

          {userData.get('type') === 'employerContact' && <AvatarBG mini>{userData.get('name').match(/\b(\w)/g)}</AvatarBG>}
        </>
      )}

      <CompanionName>
        {userData.size &&
          (userData.get('EmployerContact') && (
            <>
              {userData.get('EmployerContact').get('name') ||
                `${userData.get('Candidate').get('firstName')} ${userData.get('Candidate').get('lastName')}`}
              {userData.get('EmployerContact').get('position') && (
                <Text textOverflow whiteSpace overflow="hidden" fz="17px" pointer>
                  {`・ ${userData.get('EmployerContact').get('position')}`}
                </Text>
              )}
            </>
          ))}

        {userData.get('type') === 'employerContact' && userData.get('name')}
        {userData.get('type') === 'employerContact' && userData.get('position') && (
          <Text textOverflow whiteSpace overflow="hidden" fz="17px" pointer>
            {`・ ${userData.get('position')}`}
          </Text>
        )}
      </CompanionName>

      <CompanionName>
        {userData.size &&
          (userData.get('Candidate') &&
            (userData.get('Candidate').get('name') || `${userData.get('Candidate').get('firstName')} ${userData.get('Candidate').get('lastName')}`))}
        {userData.get('type') === 'candidate' && userData.get('name')}
      </CompanionName>
    </HeaderCompanionWrapper>
    <Minus src={minus} />
  </Header>
)

export default DialogToggler
