import styled from 'styled-components'

import { BoxCol, Text } from 'styles'

export const Nothing = styled(BoxCol)`
  align-items: center;
  margin: 100px 0 0 0;
`

export const NothingTitle = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #292c38;
  margin: 0 0 10px 0;
`

export const NothingDesc = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  color: #9caab8;
`
