import styled from 'styled-components'

import { Box, BoxCol, BoxFill, Text, BoxFullFill } from 'styles'

export const Wrap = styled(BoxCol)`
  background: #fff;
  min-height: 249px;
  justify-content: space-between;
`

export const Top = styled(BoxFill)`
  align-items: center;
  padding: 20px 20px 23px 20px;
  position: relative;
`

export const Circle = styled(Box).attrs({
  width: '6px',
  height: '6px',
  br: '3px',
  mainBC: true,
  margin: '0 1px',
  pointer: true,
})``

export const OptionContainer = styled(Box).attrs({
  shadow2: true,
  absolute: true,
  zIndex: '10',
  br: '6px',
  right: '0',
  mt: '10px',
  whiteBC: true,
  width: '170px !important',
  column: true,
})``

export const OptionHeader = styled(BoxFill).attrs(props => ({
  alignCenter: true,
  spaceBetween: true,
  padding: '10px',
  bb: `1px solid ${props.theme.main.borderInputColor}`,
}))``

export const Option = styled(BoxFill)`
  &:hover .statusBox {
    display: block;
  }
  &:hover {
    background-color: ${props => props.theme.main.homeBackground};
  }
`

export const OptionText = styled(Text).attrs({
  pointer: true,
})``

export const TopCard = styled(BoxCol).attrs({})`
  word-break: break-all;
`

export const Name = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 0 10px;
  padding: 0 60px 0 0;
  a {
    color: ${props => props.theme.main.inputLabelTextColor};
  }
  &:hover {
    opacity: 0.8;
  }
`

export const Position = styled.p`
  margin: 0 0 0 10px;
  color: ${props => props.theme.main.text};
  display: flex;
  align-items: center;
  max-width: 150px;
`

export const Email = styled(BoxCol)`
  width: 100%;
  min-height: 56px;
  padding: 0 20px 16px 20px;
  font-size: 15px;
  line-height: 20px;
  word-break: break-all;
  p {
    font-weight: 400;
  }
  a {
    color: ${props => props.theme.main.accentColor};
    font-size: 17px;
    line-height: 20px;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`

export const ContainerCard = styled(BoxFullFill).attrs({
  height: 'auto !important',
})`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
  width: 100%;
  margin-top: 30px;
  height: auto !important;
`

export const CompanyNameBlock = styled(Box).attrs({})`
  align-items: center;
`

export const CompanyName = styled.span`
  color: ${props => props.theme.main.accentColor};
  margin: 0 0 0 10px;
  display: inline-block;
  text-align: center;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`

export const Separate = styled.span`
  margin: 0 10px -5px 0px;
  width: 1px;
  height: 18px;
  background: ${props => props.theme.main.colors.grey};
  display: inline-block;
`

export const BottomCard = styled(BoxFill).attrs({})`
  height: 50px;
  border-top: ${props => `1px solid ${props.theme.main.borderInputColor}`};
  align-items: center;
  justify-content: center;
`

export const Sep = styled.span`
  width: 1px;
  height: 30px;
  background: ${props => props.theme.main.colors.grey2};
  margin: 0 48px 0 40px;
`

export const Notes = styled.span`
  color: ${props => props.theme.main.colors.primary};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`
export const Message = styled.span`
  color: ${props => props.theme.main.accentColor};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`

export const PhonesWrap = styled(BoxFill).attrs({})`
  justify-content: space-between;
`
