import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { ValidationError } from 'yup'
import get from 'lodash/get'

// import { uploadPhotoOfCandidate } from 'api/candidates'
import { AppState } from 'store/rootReducer'
import CandidatesActions from 'store/candidates/actions'
import { InputEvent } from 'types/helps'
import { ISelectListItem } from 'types/forms'
import { ICandidate } from 'types/models'

import Loader from 'components/Loader'
import EditFrame from 'components/EditFrame'
import * as forms from 'components/Forms/CandidateFormSteps'
import Five from 'components/Forms/CandidateFormSteps/5'

import { addCandidateSchemaFirstStep, addCandidateSecondStepWithoutWorkPlace, formatErrors } from 'services/validation'

const CANDIDATE_STEPS_EDIT = ['Personal information', 'Professional details', 'Candidate CV', 'Candidate licenses', 'Candidate photo']
const SETTING_TEXTS = {
  title: 'candidate',
  deleteBtnText: 'Delete candidate',
  viewItemBtnText: 'VIEW CANDIDATE',
}

type Params = {
  id: number
}

type Props = {
  isFetching: boolean
  match: { params: Params }
  fetchCandidate: typeof CandidatesActions.fetchCandidateStarted
  candidate: Partial<ICandidate>
  updateCandidate: (id: number, candidate: Partial<ICandidate>) => void
  deleteCandidate: (id: number) => void
  isUpdateData: boolean
  clearNotification: () => void
}

type State = {
  candidate: Partial<ICandidate>
  errors: Partial<ICandidate>
  currentStep: number
}

class Edit extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    currentStep: 0,
    candidate: {},
    errors: {},
  }
  newPhoto: any = undefined

  componentWillReceiveProps(nextProps: Props & RouteComponentProps) {
    this.setState({ candidate: nextProps.candidate })
  }

  componentWillMount() {
    const currentStep = get(this.props, 'history.location.state.step') || 0
    this.setState({ currentStep })
    this.props.fetchCandidate(this.props.match.params.id)
  }

  getComponent = () => {
    const { candidate, currentStep } = this.state
    switch (currentStep) {
      case 4:
        return <Five title={CANDIDATE_STEPS_EDIT[currentStep]} form={candidate} />
    }
    return undefined
  }

  setStep = (step: number) => {
    this.props.clearNotification()
    this.setState({ currentStep: step })
  }

  onUpdate = () => {
    const { candidate, currentStep } = this.state
    const validatorSteps: any = {
      0: addCandidateSchemaFirstStep,
      1: addCandidateSecondStepWithoutWorkPlace,
    }
    if (currentStep < 2) {
      if (!candidate.birthday) delete candidate.birthday
      const validator = validatorSteps[currentStep]
      validator
        .validate(candidate, { abortEarly: false })
        .then(() => {
          this.props.updateCandidate(this.props.match.params.id, candidate)
          this.setState({ errors: {} })
        })
        .catch((err: ValidationError) => this.setState({ ...this.state, errors: formatErrors(err) }))
    }
    // this.newPhoto && uploadPhotoOfCandidate(this.newPhoto, this.props.match.params.id)
  }

  onDelete = () => {
    this.props.deleteCandidate(this.props.match.params.id)
    this.props.history.push('/candidates')
  }

  onChangeField = (e: InputEvent & ISelectListItem & number, type?: string) => {
    const { candidate } = this.state
    if (type) return this.setState({ candidate: { ...candidate, [type]: e } })
    const { name, value } = e.currentTarget
    if (e.currentTarget.type === 'checkbox') {
      return this.setState({ candidate: { ...candidate, [name]: !candidate![name as keyof boolean] } })
    }
    this.setState({ candidate: { ...candidate, [name]: value } })
  }

  setNewPhoto = (newPhoto: any) => (this.newPhoto = newPhoto)

  render() {
    const { isFetching, history, isUpdateData } = this.props
    const { candidate, currentStep, errors } = this.state
    const viewEditingItem = () => history.push(`/candidates/candidate/${this.props.match.params.id}`)
    if (isFetching || !candidate) return <Loader />
    const customComponent = this.getComponent()
    return (
      <EditFrame
        texts={{
          ...SETTING_TEXTS,
          subTitle: `${candidate.firstName} ${candidate.lastName}`,
        }}
        steps={CANDIDATE_STEPS_EDIT}
        forms={forms}
        errors={errors}
        editItemData={candidate}
        currentStep={currentStep}
        setStep={this.setStep}
        onUpdate={this.onUpdate}
        onDelete={this.onDelete}
        onChangeField={this.onChangeField}
        viewEditingItem={viewEditingItem}
        setNewPhoto={this.setNewPhoto}
        isShowSaveButton={currentStep < 2}
        customComponent={customComponent}
        isUpdateData={isUpdateData}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  isUpdateData: state.currentInnerPage.get('isUpdateData'),
  isFetching: state.currentInnerPage.get('isFetching'),
  candidate: state.currentInnerPage.get('item'),
})

export default connect(
  mapStateToProps,
  {
    fetchCandidate: CandidatesActions.fetchCandidateStarted,
    updateCandidate: CandidatesActions.updateCandidateStarted,
    deleteCandidate: CandidatesActions.deleteCandidate,
    clearNotification: CandidatesActions.clearNotificationCandidate,
  }
)(Edit)
