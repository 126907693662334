import React from 'react'
import { Box, Image, Text, H1 } from 'styles'

const MARGIN_TOP_IMAGE = '0px'
const MARGIN_BOTTOM_TITLE = '0px'
const WIDTH_IMAGE = '743px'
const HEIGHT_IMAGE = '402px'
const START_IMAGE = require('assets/images/startSlider.png')
const SIGNUP_SIMAGE = require('assets/images/signupSlider.png')
const LOGIN_IMAGE = require('assets/images/loginSlider.png')

const INIT_SLIDER_TEXT = `
Are you a recruiter who wants to join on the fun and revolutionise the way this industry work? 
Are you looking for independence and better quality of life? You have arrived in the right place. `
const STEP1_SLIDER_TEXT = `Eliminate stacks of resumes and email lists with an app you can use whenever, wherever. 
Engage with talents at the click of a button.`
const STEP2_SLIDER_TEXT = `Using our unique matching algorithm you will be able to match the ideal canidate t
o the perfect job position in a few taps while keeping all legecy data in one place.`
const LOGIN_SLIDER_TEXT = `Wellcome on board your new and exciting journey. 
Take your first step towards a simple way of recruiting.`

type Props = {
  title: string
  text: string
  image: any
}

const Slider: React.FunctionComponent<Props> = ({ title, text, image }) => (
  <Box column alignCenter width="65vw">
    <Image width={WIDTH_IMAGE} height={HEIGHT_IMAGE} src={image} />
    <Box mb={MARGIN_BOTTOM_TITLE} mt={MARGIN_TOP_IMAGE}>
      <H1>{title}</H1>
    </Box>
    <Box>
      <Text width="35vw">{text}</Text>
    </Box>
  </Box>
)

export const initSlider = <Slider title="Welcome to Recruiters Life" text={INIT_SLIDER_TEXT} image={START_IMAGE} />
export const step1Slider = <Slider title="Join the revolution!" text={STEP1_SLIDER_TEXT} image={SIGNUP_SIMAGE} />
export const step2Slider = <Slider title="Bill faster and bigger then before!" text={STEP2_SLIDER_TEXT} image={SIGNUP_SIMAGE} />
export const loginSlider = <Slider title="Good to see you!" text={LOGIN_SLIDER_TEXT} image={LOGIN_IMAGE} />
