import { all, fork } from 'redux-saga/effects'

import { getAllStatesData } from './lists/sagas'
import watchAuths from './auth/sagas'
import watchEmployers from './employers/sagas'
import watchCandidates from './candidates/sagas'
import watchPipeline from './pipeline/sagas'
import watchDashboard from './dashboard/sagas'
import watchNotes from './notes/sagas'
import watchMessenger from './messenger/sagas'
import watchSearch from './search/sagas'
import watchTask from './task/sagas'

const rootSagas = function* root() {
  yield all([
    fork(watchAuths),
    fork(getAllStatesData),
    fork(watchEmployers),
    fork(watchCandidates),
    fork(watchPipeline),
    fork(watchDashboard),
    fork(watchNotes),
    fork(watchMessenger),
    fork(watchSearch),
    fork(watchTask),
  ])
}

export default rootSagas
