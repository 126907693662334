import React, { useState } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'

import NotesActions from 'store/notes/actions'
import { InputEvent } from 'types/helps'
import { IEmployerWithRecentJobPosition } from 'types/models'

import Button from 'components/Button'
import Avatar from 'components/Avatar'

import { formatCurrency } from 'utils'
import { Box, BoxCenterContent, BoxFill, BoxFullFill, BoxCol, Text, Image, Span, SectorsTypeBox } from 'styles'

const NoJobContainer = styled(BoxFullFill).attrs(props => ({
  shadow1: true,
  br: '6px',
  margin: '20px',
  border: `1px solid ${props.theme.main.borderInputColor}`,
}))``

const JobContainer = styled(NoJobContainer).attrs({
  column: true,
  allPointer: true,
  padding: '15px',
  height: 'auto !important',
})``

const Circle = styled(Box).attrs({
  width: '6px',
  height: '6px',
  br: '3px',
  mainBC: true,
  margin: '0 1px',
  pointer: true,
})``

const OptionContainer = styled(Box).attrs({
  shadow2: true,
  absolute: true,
  zIndex: '10',
  br: '6px',
  right: '0',
  mt: '10px',
  whiteBC: true,
  width: '170px !important',
  column: true,
})``

const OptionHeader = styled(BoxFill).attrs(props => ({
  alignCenter: true,
  spaceBetween: true,
  padding: '10px',
  bb: `1px solid ${props.theme.main.borderInputColor}`,
}))``

const Option = styled(BoxFill)`
  &:hover .statusBox {
    display: block;
  }
  &:hover {
    background-color: #f5f6f8;
  }
`

const OptionText = styled(Text).attrs({
  pointer: true,
  black: true,
})``

const ItemBox = styled(Box).attrs(props => ({
  smooth: true,
  bc: props.theme.main.borderInputColor,
  padding: '5px 0px',
  margin: '-2px 0 0 0',
}))`
  p {
    color: ${props => props.theme.main.inputLabelTextColor};
    padding: 5px 10px;
  }
  :hover .popUpBox {
    display: block;
    margin-top: 6px;
    div {
      padding: 4px 5px;
      margin-top: 6px;
    }
  }
`
const ItemText = styled(Text).attrs(props => ({
  whiteSpace: true,
  textOverflow: true,
  overflow: 'hidden',
  fz: '14px',
  padding: '0 9px',
}))``

const PopUpBox = styled(BoxCol).attrs({
  whiteBC: true,
  absolute: true,
  shadow2: true,
  smooth: true,
  zIndex: '10',
  display: 'none',
  padding: '10px',
  top: '40px',
})``

type Props = {
  employer: IEmployerWithRecentJobPosition
  contain?: boolean
  toggleNoteModal: typeof NotesActions.onToggleModal
}

const EmployerCard: React.FC<Props & RouteComponentProps> = ({ employer, location, history, toggleNoteModal }) => {
  const [isShowOptions, setIsShowOptions] = useState<boolean>(false)
  const employerEditRoute = `${location.pathname}/employer/${employer.id}/edit`
  const employerRoute = `${location.pathname}/employer/${employer.id}`
  const addJobRoute = `${location.pathname}/employer/${employer.id}/add-job`
  const toEmployerRoute = () => history.push(employerRoute)
  const noJob = () => (
    <NoJobContainer justifyCenter>
      <BoxCenterContent pointer column padding="15px 0px">
        <Image width="170px" src={require('assets/images/no-job-positions-.png')} />
        <Text black fw="500">
          You have no job positions <br /> for this employer
        </Text>
      </BoxCenterContent>
    </NoJobContainer>
  )

  const toCandidateRoute = (e: InputEvent, index: number) => {
    e.stopPropagation()
    history.push(`/candidates/candidate/${index}`)
  }

  const toJobRoute = (e: InputEvent, jobId: number) => {
    e.stopPropagation()
    history.push(`${employerRoute}?job=${jobId}`)
  }

  const renderItems = (items: Array<{ name: string }>) => {
    const firstItem = items.length ? items[0].name : false
    const isShowNumberOfItems = items.length > 1
    const maxWidth = '115px'
    if (!items.length)
      return (
        <Box>
          <ItemBox>
            <ItemText>Any</ItemText>
          </ItemBox>
        </Box>
      )
    return (
      <Box>
        {!firstItem || (
          <ItemBox maxWidth={maxWidth}>
            <ItemText>{firstItem}</ItemText>
          </ItemBox>
        )}
        {!isShowNumberOfItems || (
          <ItemBox pointer justifyCenter relative ml="4px">
            <ItemText pointer>{items.length - 1}</ItemText>
            <PopUpBox className="popUpBox">
              {items.slice(1).map(item => (
                <ItemBox key={item.name}>
                  <ItemText>{item.name}</ItemText>
                </ItemBox>
              ))}
            </PopUpBox>
          </ItemBox>
        )}
      </Box>
    )
  }

  const jobItem = () => (
    <JobContainer margin="17px 10px 60px" onClick={(e: any) => toJobRoute(e, employer.RecentJobPosition.id)}>
      <BoxFill alignCenter spaceBetween>
        <Box column maxWidth="70%">
          <Text whiteSpace black fw="500" maxWidth="100%" overflow="hidden" textOverflow s>
            {employer.RecentJobPosition.Position.name}
          </Text>
          <Text whiteSpace xs>
            {employer.RecentJobPosition.City.name}
          </Text>
        </Box>
        <Box bc="#D8F0E9" br="6px" padding="4px 8px">
          <Text secondColor>${formatCurrency(employer.RecentJobPosition.salary)}</Text>
        </Box>
      </BoxFill>
      <BoxFill alignCenter spaceBetween mt="8px">
        <SectorsTypeBox width="100%">
          {employer.RecentJobPosition.Sectors && employer.RecentJobPosition.Sectors.length > 0 && (
            <>{renderItems(employer.RecentJobPosition.Sectors)}</>
          )}
        </SectorsTypeBox>
        <Box>
          {employer.RecentJobPosition.Candidates.slice(0, 3).map(candidate => (
            <Box key={candidate.id} ml="-8px">
              <Avatar item={candidate} width="24px" onClick={(e: InputEvent) => toCandidateRoute(e, candidate.id)} />
            </Box>
          ))}
          {employer.RecentJobPosition.Candidates.length - 3 > 0 && (
            <Box ml="-8px">{<Avatar chars={employer.RecentJobPosition.Candidates.length - 3} width="24px" />}</Box>
          )}
        </Box>
      </BoxFill>
    </JobContainer>
  )
  const toggleSetIsShowOptions = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsShowOptions(!isShowOptions)
  }
  return (
    <BoxFullFill column spaceBetween shadow1 allPointer relative br="6px" whiteBC>
      <BoxFill column onClick={toEmployerRoute}>
        <BoxFill spaceBetween margin="20px 20px 0 20px">
          <Box>
            <Avatar contain item={employer} width="49px" />
          </Box>
          <BoxFill column ml="10px">
            <Box overflow="hidden" maxWidth="60%">
              <Text whiteSpace black lh="20px" fw="500">
                {employer.companyName}
              </Text>
            </Box>
            {employer.City && (
              <Text whiteSpace lh="18px" s>
                {employer.City.name}
              </Text>
            )}
          </BoxFill>
        </BoxFill>
        <BoxFill justifyCenter padding="0 10px">
          {employer.RecentJobPosition ? jobItem() : noJob()}
        </BoxFill>
        <BoxFill padding="10px 25px" height="60px">
          {employer.jobPositionsQty > 1 ? (
            <Button type="load" title={`View more ${employer.jobPositionsQty - 1} positions`} onClick={() => {}} />
          ) : null}
        </BoxFill>
      </BoxFill>
      <BoxFill justifyCenter bt="1px solid #E3E8F1" padding="14px 0">
        <Link to={{ pathname: addJobRoute }}>
          <Span secondColor>Add job position</Span>
        </Link>
      </BoxFill>
      {/* TODO:! CREATE ONE COMPONENT REUSABLE (SAME component IN candidate card) */}
      <Box onClick={toggleSetIsShowOptions} padding="5px" column absolute top="25px" right="20px">
        <Box alignSelf="flex-end">
          <Circle />
          <Circle />
          <Circle />
        </Box>
        {!isShowOptions || (
          <OptionContainer>
            <OptionHeader>
              <Box />
              <Text s>Options</Text>
              <Image onClick={toggleSetIsShowOptions} pointer width="10px" src={require('assets/icons/close.svg')} />
            </OptionHeader>
            <BoxFill column>
              <Option>
                <Link to={{ pathname: addJobRoute }}>
                  <Box padding="10px">
                    <OptionText>Add job position</OptionText>
                  </Box>
                </Link>
              </Option>
              <Option>
                <Link to={{ pathname: employerEditRoute }}>
                  <Box padding="10px">
                    <OptionText>Edit employer</OptionText>
                  </Box>
                </Link>
              </Option>
              <Option
                onClick={() => {
                  toggleNoteModal(true, employer.id, 'employer')
                }}
              >
                <Box padding="10px" spaceBetween fill>
                  <OptionText>Notes</OptionText>
                  <OptionText>({employer.notesCount || 0})</OptionText>
                </Box>
              </Option>
            </BoxFill>
          </OptionContainer>
        )}
      </Box>
      {/* ------------------------------------------------------------------ */}
    </BoxFullFill>
  )
}

export default withRouter(EmployerCard)
