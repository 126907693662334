import React from 'react'
import isNumber from 'lodash/isNumber'

import { ISelectListItem } from 'types/forms'
import { InputEvent } from 'types/helps'

import AddItemIcon from 'components/AddItemIcon'
import Button from 'components/Button'
import Input from 'components/Input'

import { BoxFill, Text, H2, GreenBox, Box } from 'styles'

type Props = {
  isUpdated?: boolean | number
  title: string
  deleteBtnText?: string
  forms: any
  errors?: any
  onChangeField: (e: InputEvent & ISelectListItem, type?: string) => void
  addContact: () => void
  deleteEmployerContact: (key: number, id?: number) => void
  onUpdate: any
  isUpdateData: boolean
  errorFromServer: string | null
}

class Second extends React.Component<Props> {
  render() {
    const {
      title,
      deleteBtnText,
      forms,
      errors,
      onChangeField,
      deleteEmployerContact,
      onUpdate,
      isUpdated,
      addContact,
      isUpdateData,
      errorFromServer,
    } = this.props
    const showRemoveBtn = forms.EmployerContacts.length > 1
    const disabled = (data: any) => !data.wasChanged && data.id
    const inputType = (data: any) => (!data.wasChanged && data.id ? 'load' : undefined)
    const getErrors = (key: number) => (errors.index === key ? errors : {})
    return (
      <>
        <BoxFill padding="30px 40px 0">
          <Text red>{errorFromServer && errorFromServer}</Text>
        </BoxFill>
        {forms.EmployerContacts.map((contact: any, key: number) => (
          <BoxFill column bbDefault padding="30px 40px 0" key={key}>
            <BoxFill mb="20px" spaceBetween>
              <H2 black fw="bold">
                Edit {title.substr(title.indexOf(' ') + 1)} {key > 0 ? `#${key}` : ''}
              </H2>
              {showRemoveBtn ? (
                <Text pointer red onClick={() => deleteEmployerContact(key, contact.id)}>
                  {deleteBtnText}
                </Text>
              ) : null}
            </BoxFill>
            {isUpdateData && isNumber(isUpdated) && key === isUpdated && (
              <BoxFill mb="20px">
                <GreenBox justifyCenter fill>
                  <Text>Information successfully updated!</Text>
                </GreenBox>
              </BoxFill>
            )}
            <BoxFill column>
              <BoxFill mb="20px">
                <Box flex={1} column mr="10px">
                  <Input
                    value={contact.name}
                    error={getErrors(key).name}
                    onChange={onChangeField}
                    name={`EmployerContacts[${key}].name`}
                    label="Business contact name"
                  />
                </Box>
                <Box flex={1} column ml="10px">
                  <Input
                    value={contact.position}
                    error={getErrors(key).position}
                    onChange={onChangeField}
                    name={`EmployerContacts[${key}].position`}
                    label="Business contact position (optional)"
                  />
                </Box>
              </BoxFill>
              <BoxFill mb="20px">
                <Box flex={1} column mr="10px">
                  <Input
                    value={contact.email}
                    error={getErrors(key).email}
                    onChange={onChangeField}
                    name={`EmployerContacts[${key}].email`}
                    label="Business contact email"
                  />
                </Box>
                <Box flex={1} column ml="10px">
                  <Input
                    value={contact.phone}
                    error={getErrors(key).phone}
                    onChange={onChangeField}
                    name={`EmployerContacts[${key}].phone`}
                    label="Best phone number (optional)"
                  />
                </Box>
              </BoxFill>
            </BoxFill>
            <BoxFill justifyRight padding="0 40px 20px">
              <Box width="172px" height="60px">
                <Button
                  onClick={() => onUpdate({ ...contact, index: key })}
                  title={contact.id ? 'SAVE CHANGES' : 'ADD CONTACT'}
                  disabled={disabled(contact)}
                  type={inputType(contact)}
                />
              </Box>
            </BoxFill>
          </BoxFill>
        ))}
        <BoxFill column padding="40px 40px">
          <AddItemIcon onClick={addContact} label="Add another one contact" />
        </BoxFill>
      </>
    )
  }
}

export default Second
