import Api from './index'

export class Sector {
  static async saveNewProjectType(params: {
    name: string
  }): Promise<{
    name: string
    id: number
  }> {
    const { data } = await Api.post('/user/sectors/', params)
    return {
      name: data.name,
      id: data.id,
    }
  }

  static async getOptions(): Promise<[{ name: string; id: number }]> {
    const { data } = await Api.get('/user/sectors/')
    return data.map((item: any) => ({
      name: item.name,
      id: item.id,
    }))
  }

  static async getLinkFormOptions(id?: string): Promise<[{ name: string; id: number }]> {
    let response
    if (id && id.length > 0) {
      const { data } = await Api.get(`/lists/sectors/${id}/`)
      response = data
    } else {
      const { data } = await Api.get('/lists/sectors/')
      response = data
    }
    return response.map((item: any) => ({
      name: item.name,
      id: item.id,
    }))
  }

  static async getList(): Promise<{ name: string; id: number; jobsCount: number }[]> {
    const { data } = await Api.get('/user/sectors/custom')
    return data.map((item: any) => ({
      name: item.name,
      id: item.id,
      jobsCount: item.JobPositionsCount,
    }))
  }

  static async update(params: { id: number; name: string }): Promise<void> {
    await Api.patch(`/user/sectors/${params.id}/`, { name: params.name })
  }

  static async delete(params: { id: number }): Promise<void> {
    await Api.delete(`/user/sectors/${params.id}/`)
  }
}
