import React from 'react'
import moment from 'moment'

import { IPerformanceTracker } from 'types/models'

import Progress from './progress'

import { numberWithCommas } from 'utils'
import theme from 'styles/theme'
import { Text } from 'styles'
import { Wrap, Top, Performance, GoalsIcon, Goal, GoalTop, GoalLeft, GLText, Price } from './styles'
import goals from 'assets/icons/goals.svg'

type Props = {
  setModal: any
  annualGoal: number
  monthlyGoal: number
  quarterGoal: number
  weeklyGoal: number
  performanceTracker: IPerformanceTracker
}

const PerformanceTracker: React.FC<Props> = ({ setModal, annualGoal, monthlyGoal, quarterGoal, weeklyGoal, performanceTracker }) => {
  return (
    <Wrap>
      <Top>
        <Performance l color={theme.main.colors.grey3}>
          Performance tracker
        </Performance>
        <Text
          m
          pointer
          onClick={() =>
            setModal({
              isOpen: true,
              data: {
                annualGoal,
                monthlyGoal,
                quarterGoal,
                weeklyGoal,
              },
            })
          }
        >
          Edit goals <GoalsIcon src={goals} />
        </Text>
      </Top>
      <Goal>
        <GoalTop>
          <GoalLeft>
            <GLText m left color={theme.main.colors.grey3}>
              Weekly goal
            </GLText>
            <Text left s>
              ${numberWithCommas(performanceTracker.weekly)} earned in the last 7 days
            </Text>
          </GoalLeft>
          <Price>${numberWithCommas(weeklyGoal)}</Price>
        </GoalTop>
        <Progress percent={Math.ceil((performanceTracker.weekly * 100) / weeklyGoal)} />
      </Goal>
      <Goal>
        <GoalTop>
          <GoalLeft>
            <GLText m left color={theme.main.colors.grey3}>
              Monthly goal
            </GLText>
            <Text left s>
              ${numberWithCommas(performanceTracker.monthly)} earned in {moment().format('MMMM')}
            </Text>
          </GoalLeft>
          <Price>${numberWithCommas(monthlyGoal)}</Price>
        </GoalTop>
        <Progress percent={Math.ceil((performanceTracker.monthly * 100) / monthlyGoal)} />
      </Goal>
      <Goal>
        <GoalTop>
          <GoalLeft>
            <GLText m left color={theme.main.colors.grey3}>
              Quarter goal
            </GLText>
            <Text left s>
              ${numberWithCommas(performanceTracker.quarter)} earned in the last 3 months
            </Text>
          </GoalLeft>
          <Price>${numberWithCommas(quarterGoal)}</Price>
        </GoalTop>
        <Progress percent={Math.ceil((performanceTracker.quarter * 100) / quarterGoal)} />
      </Goal>
      <Goal>
        <GoalTop>
          <GoalLeft>
            <GLText m left color={theme.main.colors.grey3}>
              Annual goal
            </GLText>
            <Text left s>
              ${numberWithCommas(performanceTracker.annual)} earned in the last 12 months
            </Text>
          </GoalLeft>
          <Price>${numberWithCommas(annualGoal)}</Price>
        </GoalTop>
        <Progress percent={Math.ceil((performanceTracker.annual * 100) / annualGoal)} />
      </Goal>
    </Wrap>
  )
}

export default PerformanceTracker
