import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import TaskActions from 'store/task/action'
import { ITask } from 'types/models'

import Loader from 'components/Loader'
import Task from '../../Task'
import { NoTask } from '../../NoTask'

type Props = {
  mediumPrioriy: {
    count: number
    rows: ITask[]
  }
  isLoading: boolean
  deleteTask: any
  closeTask: any
}

export const MediumTasks = React.memo<Props>(({ mediumPrioriy, isLoading, deleteTask, closeTask }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(TaskActions.getTaskListByFilter('medium'))
  }, [dispatch])

  const renderMedium = () => (
    <>
      {mediumPrioriy.rows && mediumPrioriy.rows.length ? (
        mediumPrioriy.rows.map(el => {
          return (
            <Task
              id={el.id}
              key={el.id}
              title={el.title}
              type="medium"
              description={el.description}
              dueDate={el.dueDate}
              dueTime={el.dueTime}
              deleteTask={deleteTask}
              closeTask={closeTask}
              priority="medium priority"
            />
          )
        })
      ) : (
        <NoTask />
      )}
    </>
  )

  return <>{isLoading ? <Loader height="400px" /> : renderMedium()}</>
})
