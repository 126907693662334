import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { AppState } from 'store/rootReducer'
import TaskActions from 'store/task/action'
import DashboardActions from 'store/dashboard/actions'
import { IMatched } from 'types/models'

import CreateInvoiceModal from 'containers/CreateInvoiceModal'
import Tabs from 'components/Tabs'
import Modal from 'components/Modal'
import Input from 'components/Input'
import InputSelect from 'components/Input/InputSelect'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Empty from './Empty'
import MoneyTracker from './MoneyTracker'
import AllTasks from './Tasks/AllTasks'
import { HighTasks } from './Tasks/HighTasks'
import { MediumTasks } from './Tasks/MediumTasks'
import { AnyTasks } from './Tasks/AnyTasks'
import { DoneTasks } from './Tasks/DoneTasks'

import { smoothScroll } from 'utils'

import { Label, Textarea, Image, BoxFill, BoxCol } from 'styles'
import {
  WrapMain,
  WrapTabs,
  Tasks,
  AmountTasks,
  AddNewTask,
  ModalWrap,
  NewTask,
  NTSubtitle,
  TextareaWrap,
  InputBlock,
  CreateTask,
  CreateTaskWrap,
  NoTaskWrap,
  NoTaskTitle,
  NoTaskSubTitle,
  NewTaskLink,
  ErrorMessage,
  CharsWrap,
  Chars,
  ScrollToTop,
} from './styles'

const ICON_CALENDAR = <Image src={require('assets/icons/calendar.svg')} />
const ICON_CLOCK = <Image src={require('assets/icons/clock2.svg')} />
const IMG_NO_TASKS = <Image src={require('assets/images/no-tasks.png')} />

type Props = {
  moneyTracker: any
  getInvoices: typeof DashboardActions.getInvoices
}

export const Content: React.FC<Props> = ({ moneyTracker, getInvoices }) => {
  const [isOpen, toggleModal] = useState(false)
  const [matched, setMatched] = useState<any>(null)
  const [mainSection, setMainSection] = useState(0)
  const [secondSection, setSecondSection] = useState(0)
  const [isShowModal, setIsShowModal] = useState(false)
  const [titleChars, setTitleChars] = useState(150)
  const [descriptionChars, setDescriptionChars] = useState(1000)
  const [showScrollToTop, setScrollToTop] = useState(false)

  const dispatch = useDispatch()

  const { isLoading, isLoadingAllTask, highPrioriy, mediumPrioriy, anyTime, done, title, taskDate, priority, error } = useSelector(
    (state: AppState) => state.task
  )

  useEffect(() => {
    dispatch(TaskActions.getTaskList())
  }, [dispatch])

  const cloaseModal = () => {
    toggleModal(false)
  }

  const openModal = (modalData: IMatched) => {
    setMatched(modalData)
    toggleModal(true)
  }

  const closeTaskModal = useCallback(() => {
    dispatch(TaskActions.clearInput())
    setIsShowModal(false)
  }, [dispatch])

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const {
        name,
        value,
        validity: { valid },
      } = e.target

      if (valid) {
        if (name === 'title') {
          setTitleChars(titleChars - value.length)
        } else if (name === 'description') {
          setDescriptionChars(descriptionChars - value.length)
        }
        dispatch(TaskActions.changeInput(name, value))
      }
    },
    [dispatch]
  )

  const handleSelectChange = useCallback(
    (e: any) => {
      dispatch(TaskActions.changeSelect(e.value))
    },
    [dispatch]
  )

  const handleCreateTask = useCallback(
    (priority: string) => {
      dispatch(TaskActions.createTask(priority))
      setIsShowModal(false)
    },
    [dispatch]
  )

  const handleDeleteTask = useCallback(
    (id: number, priority: string) => {
      dispatch(TaskActions.deleteTask(id, priority))
    },
    [dispatch]
  )

  const handleCloseTask = useCallback(
    (id: number, priority: string) => {
      dispatch(TaskActions.closeTask(id, priority))
    },
    [dispatch]
  )

  const handleRestoreTask = useCallback(
    (id: number, priority: string) => {
      dispatch(TaskActions.restoreTask(id, priority))
    },
    [dispatch]
  )

  const allPriorityTasks = (): JSX.Element => (
    <AllTasks
      highPrioriy={highPrioriy}
      mediumPrioriy={mediumPrioriy}
      anyTime={anyTime}
      deleteTask={handleDeleteTask}
      closeTask={handleCloseTask}
      restoreTask={handleRestoreTask}
      isLoading={isLoadingAllTask}
    />
  )

  const highTasks = (): JSX.Element => (
    <HighTasks highPrioriy={highPrioriy} isLoading={isLoading} deleteTask={handleDeleteTask} closeTask={handleCloseTask} />
  )

  const mediumTasks = (): JSX.Element => (
    <MediumTasks mediumPrioriy={mediumPrioriy} isLoading={isLoading} deleteTask={handleDeleteTask} closeTask={handleCloseTask} />
  )
  const anyTasks = (): JSX.Element => <AnyTasks anyTime={anyTime} isLoading={isLoading} deleteTask={handleDeleteTask} closeTask={handleCloseTask} />
  const doneTasks = (): JSX.Element => <DoneTasks done={done} isLoading={isLoading} deleteTask={handleDeleteTask} restoreTask={handleRestoreTask} />

  const renderTasks: any = {
    0: allPriorityTasks,
    1: highTasks,
    2: mediumTasks,
    3: anyTasks,
    4: doneTasks,
  }

  const noTasks = () =>
    isLoadingAllTask ? (
      <Loader height="400px" />
    ) : (
      <NoTaskWrap>
        {IMG_NO_TASKS}
        <NoTaskTitle>Your tasks will be here</NoTaskTitle>
        <NoTaskSubTitle>
          Start by adding your <NewTaskLink onClick={() => setIsShowModal(true)}>new task</NewTaskLink>
        </NoTaskSubTitle>
      </NoTaskWrap>
    )

  const tasks = () => {
    const amount = {
      all: highPrioriy.count + mediumPrioriy.count + anyTime.count,
      high: highPrioriy.count,
      medium: mediumPrioriy.count,
      any: anyTime.count,
      done: done.count,
    }

    const task = highPrioriy.count || mediumPrioriy.count || anyTime.count || done.count

    window.addEventListener('scroll', () => {
      if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
        setScrollToTop(true)
      } else {
        setScrollToTop(false)
      }
    })

    return (
      <>
        <Tasks>
          <AmountTasks xl>{task ? `${amount.all} active tasks` : `${!isLoading && !isLoadingAllTask ? 'No tasks' : ''}`}</AmountTasks>
          <AddNewTask onClick={() => setIsShowModal(true)}>Add new task</AddNewTask>
        </Tasks>
        <WrapTabs>
          {task ? (
            <>
              <Tabs
                type="task"
                amount={amount}
                currentSection={secondSection}
                sections={['All tasks', 'High priority', 'Medium priority', 'Any time', 'Done']}
                setSection={setSecondSection}
              />
              <ErrorMessage>{error && error}</ErrorMessage>
              {renderTasks[secondSection]()}
              {showScrollToTop && <ScrollToTop src={require('assets/icons/scroll-to-top.svg')} onClick={() => smoothScroll()} />}
            </>
          ) : (
            noTasks()
          )}
        </WrapTabs>
        <Modal isOpen={isShowModal} hideCloseIcon onRequestClose={() => closeTaskModal()} withScroll>
          <ModalWrap>
            <NewTask black xl3>
              New task
            </NewTask>
            <NTSubtitle left>Add a task name and priority, also you can set the due date and add the task description if needed</NTSubtitle>
            <InputBlock>
              <Input
                name="title"
                label="Task title"
                placeholder="Task title"
                onChange={handleInputChange}
                chars={titleChars}
                pattern=".{0,150}"
                value={title}
              />
            </InputBlock>
            <InputBlock>
              <InputSelect
                label="Task priority"
                optionsType="priority"
                placeholder="Choose task priority"
                isSearchComponent
                onChange={handleSelectChange}
                defaultValue={priority}
              />
            </InputBlock>
            <CharsWrap>
              <Label>Task description</Label>
              <Chars>{descriptionChars} left</Chars>
            </CharsWrap>
            <TextareaWrap>
              <Textarea name="description" placeholder="Task description" onChange={handleInputChange} maxLength={1000} />
            </TextareaWrap>

            <InputBlock>
              <BoxCol mb="20px" mr="14px" width="100%">
                <Input
                  onChange={handleInputChange}
                  placeholder="Choose date"
                  name="taskDate"
                  type="date"
                  label="Due date and time"
                  rightElement={ICON_CALENDAR}
                />
              </BoxCol>
              <BoxFill mb="20px" mt="27px" width="100%">
                <Input
                  onChange={handleInputChange}
                  placeholder="HH : MM am"
                  name="taskTime"
                  type="time"
                  rightElement={ICON_CLOCK}
                  disabled={!taskDate}
                />
              </BoxFill>
            </InputBlock>

            <CreateTaskWrap justifyCenter>
              <CreateTask title="CREATE TASK" onClick={() => handleCreateTask(priority)} disabled={!title.trim()} />
            </CreateTaskWrap>
          </ModalWrap>
        </Modal>
      </>
    )
  }

  const tracker = () => (
    <>
      {moneyTracker.invoices.length ? <MoneyTracker invoices={moneyTracker.invoices} onOpenModal={openModal} /> : <Empty />}
      {moneyTracker.pagesQty > 1 ? (
        <Pagination currentPage={moneyTracker.query.page} fetchPage={getInvoices} pagesQty={moneyTracker.pagesQty} />
      ) : null}
      <CreateInvoiceModal isOpen={isOpen} closeModal={cloaseModal} task={matched} />
    </>
  )

  return (
    <WrapMain>
      <Tabs currentSection={mainSection} sections={['Tasks', 'Money tracker']} setSection={setMainSection} />
      {mainSection ? tracker() : tasks()}
    </WrapMain>
  )
}
