import React from 'react'
import moment from 'moment'

import NotesActions from 'store/notes/actions'
import { IJobPosition } from 'types/models'

import Avatar from 'components/Avatar'

import { formatCurrency } from 'utils'
import { Box, BoxFill, BoxCol, Text, Bold, SectorsTypeBox, GreenBox, Image } from 'styles'
import { Main, Wrapper, SearchCardWrap, CompanyName, CompanyNameDefault, ItemBox, ItemText, PopUpBox, UserIconWrap } from './styles'

type Props = {
  jobPosition: IJobPosition
  setCurrentJobPositionId: (id: number) => void
  setEmployerId?: any
  toggleNoteModal: typeof NotesActions.onToggleModal
  searchCard?: boolean
  numberOfCandidates?: number
}

const JobPositionCard: React.FC<Props> = ({
  jobPosition,
  setCurrentJobPositionId,
  toggleNoteModal,
  searchCard,
  setEmployerId,
  numberOfCandidates,
}) => {
  const setId = () => {
    setCurrentJobPositionId(jobPosition.id)
    setEmployerId && setEmployerId(jobPosition.EmployerId)
  }

  const renderItems = (items: Array<{ name: string }>) => {
    const firstItem = items.length ? items[0].name : false
    const secondItem = items.length >= 2 ? items[1].name : false
    const isShowNumberOfItems = items.length > 2
    const maxWidth = secondItem ? '200px' : '100%'
    if (!items.length)
      return (
        <Box>
          <ItemBox>
            <ItemText>Any</ItemText>
          </ItemBox>
        </Box>
      )
    return (
      <Box>
        {!firstItem || (
          <ItemBox maxWidth={maxWidth}>
            <ItemText>{firstItem}</ItemText>
          </ItemBox>
        )}
        {!secondItem || (
          <ItemBox maxWidth={maxWidth} ml="12px">
            <ItemText>{secondItem}</ItemText>
          </ItemBox>
        )}
        {!isShowNumberOfItems || (
          <ItemBox pointer justifyCenter relative ml="12px">
            <ItemText pointer>{items.length - 2}</ItemText>
            <PopUpBox className="popUpBox">
              {items.slice(2).map(item => (
                <ItemBox key={item.name}>
                  <ItemText>{item.name}</ItemText>
                </ItemBox>
              ))}
            </PopUpBox>
          </ItemBox>
        )}
      </Box>
    )
  }

  const { City } = jobPosition
  const city = City && City.name.indexOf(City.State.abbr) > 0 ? City.name : `${City.name} ${City.State.abbr}`

  return (
    <Main onClick={setId}>
      {searchCard ? (
        <SearchCardWrap>
          <Text lh="14px">
            <Avatar item={jobPosition.Employer} width="69px" />
          </Text>
          <CompanyName>{jobPosition.Employer.companyName}</CompanyName>
        </SearchCardWrap>
      ) : null}
      <Wrapper>
        <BoxFill spaceBetween alignCenter>
          <BoxCol>
            <Bold black l>
              <UserIconWrap alignCenter>
                {jobPosition.public && <Image src={require('assets/icons/user-icon.svg')} />}
                {jobPosition.Position.name}
              </UserIconWrap>
            </Bold>
            <Text lh="14px">
              {jobPosition.Employer && <CompanyNameDefault>{jobPosition.Employer.companyName}</CompanyNameDefault>}
              {city}
            </Text>
          </BoxCol>
          <Box>
            <SectorsTypeBox>{jobPosition.Sectors && jobPosition.Sectors.length > 0 && <>{renderItems(jobPosition.Sectors)}</>}</SectorsTypeBox>
            <GreenBox ml="10px">
              <Text s>${formatCurrency(jobPosition.salary)}</Text>
            </GreenBox>
          </Box>
        </BoxFill>
        <BoxFill margin="16px 0">
          <Text s black justify>
            {jobPosition.description}
          </Text>
        </BoxFill>
        <BoxFill spaceBetween alignCenter>
          <Box>
            <Box pr="6px" borderRight="1px solid #E3E8F1">
              <Text s lh="14px" fw="500" accentColor>
                {jobPosition.closed ? <Text red>Closed</Text> : 'Active'}
              </Text>
            </Box>
            <Box pl="6px" pr="6px" borderRight="1px solid #E3E8F1">
              <Text lh="14px" s>
                Last edit {moment(jobPosition.updatedAt).fromNow()}
              </Text>
            </Box>
          </Box>
          <Box>
            {jobPosition.Candidates.map((candidate, i) =>
              candidate.Matched.status > 0
                ? i < 4 && (
                    <>
                      <Box key={candidate.id} ml="-10px">
                        <Avatar item={candidate} width="38px" />
                      </Box>
                      {i === 3 && (
                        <Box key={candidate.id + i} ml="-10px">
                          {numberOfCandidates && numberOfCandidates - 4 > 0 && (
                            <Box ml="-8px">{<Avatar chars={numberOfCandidates - 4} width="38px" />}</Box>
                          )}
                        </Box>
                      )}
                    </>
                  )
                : null
            )}
          </Box>
        </BoxFill>
      </Wrapper>
    </Main>
  )
}

export default JobPositionCard
