import React from 'react'

import { Box, BoxFill, H3, Image } from 'styles'
import { BoxHeader, BoxHeaderWrap, Wrap, StepWrap, HeaderStepsText, Exit } from './styles'

const CheckBox = () => (
  <Box mr="10px">
    <Image src={require('assets/icons/successCheckbox.svg')} />
  </Box>
)

type Props = {
  currentStep: number
  steps: string[]
  title: string
  onExit?: () => void
}

const Head: React.FunctionComponent<Props> = ({ currentStep, steps, title, onExit }) => (
  <BoxFill mainBC pb="25vh">
    <Wrap padding="35px 40px" alignCenter spaceBetween>
      <Box alignCenter>
        <Image width="39px" height="35" src={require('assets/icons/x2logoMark.png')} />
        <Box ml="16px">
          <H3 whiteSpace>{title}</H3>
        </Box>
      </Box>
      <StepWrap>
        {steps.map((text, index) => (
          <BoxHeader key={text}>
            <BoxHeaderWrap>
              {currentStep <= ++index || <CheckBox />}
              <HeaderStepsText isActive={currentStep === index} isSuccess={currentStep < ++index}>
                {text}
              </HeaderStepsText>
            </BoxHeaderWrap>
          </BoxHeader>
        ))}
      </StepWrap>
      {onExit ? (
        <Box onClick={onExit}>
          <Exit white pointer>
            Exit
          </Exit>
        </Box>
      ) : (
        <Box />
      )}
    </Wrap>
  </BoxFill>
)

export default Head
