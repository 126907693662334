import React, { useState, useCallback } from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import SearchActions from 'store/search/action'
import EmployersActions from 'store/employers/actions'
import MessengerActions from 'store/messenger/actions'
import NotesActions from 'store/notes/actions'
import { IEmployer } from 'types/models'

import Avatar from 'components/Avatar'

import { Box, BoxCol, BoxFill, Text, Image } from 'styles'

import {
  Wrap,
  Top,
  Circle,
  OptionContainer,
  OptionHeader,
  Option,
  OptionText,
  TopCard,
  Name,
  Position,
  Email,
  CompanyNameBlock,
  CompanyName,
  Separate,
  BottomCard,
  Sep,
  Notes,
  Message,
  PhonesWrap,
} from './styles'

type Props = {
  id: number
  name: string
  position?: string
  phone?: string
  email: string
  employer: IEmployer
  notesCount: number
  publicData?: boolean
}

const ContactCard: React.FC<Props & RouteComponentProps> = ({ id, name, position, email, phone, employer, notesCount, publicData }) => {
  const [isShowOptions, setIsShowOptions] = useState<boolean>(false)

  const employerRoute = `/live-jobs/employer/${employer.id}`
  const employerEditRoute = `/live-jobs/employer/${employer.id}/edit`

  const toggleSetIsShowOptions = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsShowOptions(!isShowOptions)
  }

  const dispatch = useDispatch()

  const handleDeleteContact = useCallback(
    id => {
      dispatch(EmployersActions.deleteEmployerContact(id))
      setTimeout(() => {
        dispatch(SearchActions.searchContacts())
      }, 1000)
    },
    [dispatch]
  )

  const addNewEmployerContact = useCallback(
    (id, name, position) => {
      dispatch(MessengerActions.addNewCandidateOrEmployer('employerContact', id, name, position))
    },
    [dispatch]
  )

  const openNotes = useCallback(
    id => {
      dispatch(NotesActions.onToggleModal(true, id, 'employer-contact'))
    },
    [dispatch]
  )

  const renderText = (text: string, symbols: number) => (text.length > symbols ? `${text.substr(0, symbols)}...` : text)

  return (
    <Wrap smooth>
      <Top>
        <Link to={{ pathname: employerEditRoute }}>
          <Avatar item={employer} />
        </Link>
        <TopCard>
          <Name>
            {publicData && <Image style={{ margin: '0 6px -3px 0' }} src={require('assets/icons/user-icon.svg')} />}
            <Link to={{ pathname: employerEditRoute }}>{renderText(name, 25)}</Link>
          </Name>
          <CompanyNameBlock>
            {position && <Position>{renderText(position, 25)}</Position>}
            <Link to={{ pathname: employerRoute }}>
              <CompanyName>
                {position && <Separate />}
                {renderText(employer.companyName, 25)}
              </CompanyName>
            </Link>
          </CompanyNameBlock>
        </TopCard>
        <Box onClick={toggleSetIsShowOptions} padding="5px" column absolute top="25px" right="20px">
          <Box alignSelf="flex-end">
            <Circle />
            <Circle />
            <Circle />
          </Box>
          {!isShowOptions || (
            <OptionContainer>
              <OptionHeader>
                <Box />
                <Text s>Options</Text>
                <Image onClick={toggleSetIsShowOptions} pointer width="10px" src={require('assets/icons/close.svg')} />
              </OptionHeader>
              <BoxFill column>
                <Option>
                  <Link to={{ pathname: employerEditRoute }}>
                    <Box padding="10px">
                      <OptionText black>Edit contact</OptionText>
                    </Box>
                  </Link>
                </Option>
                <Option>
                  <Box padding="10px">
                    <OptionText color="red" onClick={() => handleDeleteContact(id)}>
                      Delete
                    </OptionText>
                  </Box>
                </Option>
              </BoxFill>
            </OptionContainer>
          )}
        </Box>
      </Top>
      <>
        <Email>
          <p>Email</p>
          <a href={`mailto:${email}`}>{renderText(email, 28)}</a>
        </Email>
        <Email>
          <PhonesWrap>
            {phone && (
              <BoxCol>
                <p>Phone number</p>
                <a href={`tel:${phone}`}>{renderText(phone, 14)}</a>
              </BoxCol>
            )}
            {employer.phone && (
              <BoxCol>
                <p>Office number</p>
                <a href={`tel:${employer.phone}`}>{renderText(employer.phone, 14)}</a>
              </BoxCol>
            )}
          </PhonesWrap>
        </Email>
        <BottomCard>
          <Notes onClick={() => openNotes(id)}>
            {notesCount === 1 ? `${notesCount} note` : notesCount && notesCount ? `${notesCount && notesCount} notes` : 'Add notes'}
          </Notes>
          <>
            <Sep />
          </>
          <Message onClick={() => addNewEmployerContact(id, name, position)}>Message</Message>
        </BottomCard>
      </>
    </Wrap>
  )
}

export default withRouter(ContactCard)
