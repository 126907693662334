import React from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { AppState } from 'store/rootReducer'
import CandidateActions from 'store/candidates/actions'
import { ICandidate, IFile } from 'types/models'

import DocsUploadInput from 'components/UploadInput/docsUploadInput'

type Props = {
  form: ICandidate
  licenseFiles: Map<number, IFile>
  successUploadLicense: (licenses: any) => void
  deleteLicense: (id: number, candidateId: number) => void
}

const Four: React.FC<Props> = ({ form, licenseFiles, successUploadLicense, deleteLicense }) => {
  return (
    <DocsUploadInput
      title="license"
      id={form.id}
      uploadedFiles={licenseFiles}
      multiple
      onUploadSuccess={successUploadLicense}
      onDeleteLicense={deleteLicense}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  licenseFiles: state.currentInnerPage.get('licenseFiles'),
})

export default connect(
  mapStateToProps,
  { deleteLicense: CandidateActions.deleteLicense, successUploadLicense: CandidateActions.uploadLicenseSuccess }
)(Four)
