import { fromJS, List } from 'immutable'

import {
  ListActionsTypes,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_JOB_POSITIONS,
  GET_JOB_POSITIONS_SUCCESS,
  GET_PROJECT_TYPES,
  GET_PROJECT_TYPES_SUCCESS,
  GET_PROJECT_TYPES_BY_ID,
  GET_PROJECT_TYPES_BY_ID_SUCCESS,
  GET_IT_PACKAGES,
  GET_IT_PACKAGES_SUCCESS,
  GET_PROJECT_VALUE,
  GET_PROJECT_VALUE_SUCCESS,
  GET_PUBLIC_EMPLOYERS_SUCCESS,
  GET_PUBLIC_EMPLOYERS_WITHOUT_ID_SUCCESS,
} from './actions'

import { IListState } from 'types/reducerStates'

import { formateDateToYYYYMMDD } from 'utils'

const initialState: IListState = fromJS({
  isFetching: false,
  states: List(),
  cities: List(),
  jobs: List(),
  projectTypes: List(),
  projectTypesById: List(),
  itPackages: List(),
  projectValues: List(),
  publicEmployers: List(),
  error: null,
  weeks: [
    {
      id: '1',
      name: 'Immediate',
      value: 0,
    },
    {
      id: '2',
      name: '1 week',
      value: 7,
    },
    {
      id: '3',
      name: '2 weeks',
      value: 14,
    },
    {
      id: '4',
      name: '3 weeks',
      value: 21,
    },
    {
      id: '5',
      name: '4 weeks',
      value: 28,
    },
    {
      id: '6',
      name: 'Longer',
      value: 31,
    },
  ],
  searchWeeks: [
    {
      id: '0',
      name: 'Any',
      value: '',
    },
    {
      id: '1',
      name: 'Immediate',
      value: formateDateToYYYYMMDD(0),
    },
    {
      id: '2',
      name: '1 week',
      value: formateDateToYYYYMMDD(7),
    },
    {
      id: '3',
      name: '2 weeks',
      value: formateDateToYYYYMMDD(14),
    },
    {
      id: '4',
      name: '3 weeks',
      value: formateDateToYYYYMMDD(21),
    },
    {
      id: '5',
      name: '4 weeks',
      value: formateDateToYYYYMMDD(28),
    },
    {
      id: '6',
      name: 'Longer',
      value: formateDateToYYYYMMDD(31),
    },
  ],
  status: [
    {
      id: '0',
      name: 'Any',
      value: '',
    },
    {
      id: '1',
      name: 'Active',
      value: 'Active',
    },
    {
      id: '2',
      name: 'Closed',
      value: 'Closed',
    },
  ],
  priority: [
    {
      id: 0,
      name: 'High priority',
      value: 'High priority',
    },
    {
      id: 1,
      name: 'Medium priority',
      value: 'Medium priority',
    },
    {
      id: 2,
      name: 'Any time',
      value: 'Any time',
    },
  ],
})

export default (state = initialState, action: ListActionsTypes): IListState => {
  switch (action.type) {
    case GET_STATES:
    case GET_CITIES:
    case GET_JOB_POSITIONS:
    case GET_PROJECT_TYPES:
    case GET_PROJECT_TYPES_BY_ID:
    case GET_PROJECT_VALUE:
    case GET_IT_PACKAGES:
      return state.set('isFetching', true)
    case GET_STATES_SUCCESS:
      return state.set('isFetching', false).set('states', List(action.payload))
    case GET_CITIES_SUCCESS:
      return state.set('isFetching', false).set('cities', List(action.payload))
    case GET_JOB_POSITIONS_SUCCESS:
      return state.set('isFetching', false).set('jobs', List(action.payload))
    case GET_PROJECT_TYPES_SUCCESS:
      return state.set('isFetching', false).set('projectTypes', List(action.payload))
    case GET_PROJECT_TYPES_BY_ID_SUCCESS:
      return state.set('isFetching', false).set('projectTypesById', List(action.payload))
    case GET_IT_PACKAGES_SUCCESS:
      return state.set('isFetching', false).set('itPackages', List(action.payload))
    case GET_PROJECT_VALUE_SUCCESS:
      return state.set('isFetching', false).set('projectValues', List(action.payload))
    case GET_PUBLIC_EMPLOYERS_SUCCESS:
      return state.set('isFetching', false).set('publicEmployers', List(action.payload))
    case GET_PUBLIC_EMPLOYERS_WITHOUT_ID_SUCCESS:
      return state.set('isFetching', false).set('publicEmployers', List(action.payload))
    default:
      return state
  }
}
