import styled from 'styled-components'

import { Text, Box, BoxCol, BoxFill, BoxFullFill } from 'styles'

/* jobPositionCard style */

export const Main = styled(BoxFullFill).attrs({
  smooth: true,
  allPointer: true,
  whiteBC: true,
  height: 'auto !important',
})`
  align-items: center;
  height: auto !important;

  & ~ & {
    margin-top: 20px;
  }
`

export const Wrapper = styled(BoxFill).attrs({
  width: '100%',
  column: true,
  padding: '20px 20px 30px',
})``

export const SearchCardWrap = styled(BoxCol)`
  width: 206px;
  height: 100%;
  border-right: 1px solid #efefef;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`

export const CompanyName = styled(BoxCol).attrs({
  mt: '16px !important',
})``

export const CompanyNameDefault = styled.span`
  max-width: 400px;
  color: #2697ff;
  border-right: 1px solid #e3e8f1;
  padding: 0 7px 0 0;
  height: 15px;
  margin: 0 7px 0 0;
  display: inline-block;
  word-break: break-all;
`

export const ItemBox = styled(Box).attrs(props => ({
  smooth: true,
  bc: props.theme.main.borderInputColor,
  padding: '5px 0px',
}))`
  p {
    color: ${props => props.theme.main.inputLabelTextColor};
    padding: 5px 10px;
  }
  :hover .popUpBox {
    display: block;
    margin-top: 6px;
    div {
      padding: 4px 10px;
      margin-top: 6px;
    }
  }
`
export const ItemText = styled(Text).attrs({
  whiteSpace: true,
  textOverflow: true,
  overflow: 'hidden',
  fz: '14px',
  padding: '0 8px',
})``

export const PopUpBox = styled(BoxCol).attrs({
  whiteBC: true,
  absolute: true,
  shadow2: true,
  smooth: true,
  zIndex: '10',
  display: 'none',
  padding: '10px',
  top: '40px',
})``

export const UserIconWrap = styled(Box).attrs({})`
  img {
    margin: 0 6px 0 0;
  }
`
