import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import { Map } from 'immutable'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { AppState } from 'store/rootReducer'
import EmployersActions from 'store/employers/actions'
import CandidatesActions from 'store/candidates/actions'
import PipelineActions from 'store/pipeline/actions'
import { getInInner } from 'store/currentInnerPage'
import { IJobPosition, ICandidate, IMatchedCandidate, IMatched } from 'types/models'

import { Email } from 'components/Others'
import Tabs from 'components/Tabs'
import Button from 'components/Button'
import StatusDropDown from 'components/StatusDropDown'
import Loader from 'components/Loader'
import EditIcon from 'components/EditIcon'
import MatchedCandidate from 'components/ForCandidates&Employers/matchedCandidate'
import PotentialCandidateCard from 'components/ForCandidates&Employers/potentialCandidateCard'

import { formatCurrency } from 'utils'

import {
  Box,
  BoxCol,
  BoxFill,
  BoxFullFill,
  BoxCenterContent,
  Text,
  Span,
  Textarea,
  Bold,
  H2,
  Image,
  SectorsTypeBox,
  GreenBox,
  Separator,
} from 'styles'

const MatchedJobsBox = styled(BoxFill)`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
`

const PotentialCandidateBox = styled(BoxFill)`
  flex-wrap: wrap;
  justify-content: space-around;
`

const CompanyNameDefault = styled.span`
  color: #2697ff;
  border-right: 1px solid #e3e8f1;
  padding: 0 7px 0 0;
  height: 15px;
  margin: 10px 7px 0 0;
  display: inline-block;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const ItemBox = styled(Box).attrs(props => ({
  smooth: true,
  bc: props.theme.main.borderInputColor,
  padding: '5px 0px',
}))`
  p {
    color: ${props => props.theme.main.inputLabelTextColor};
    padding: 5px 10px;
  }
  :hover .popUpBox {
    display: block;
    margin-top: 6px;
    div {
      padding: 4px 10px;
      margin-top: 6px;
    }
  }
`
const ItemText = styled(Text).attrs(props => ({
  whiteSpace: true,
  textOverflow: true,
  overflow: 'hidden',
  fz: '14px',
  padding: '0 8px',
}))``

const PopUpBox = styled(BoxCol).attrs({
  whiteBC: true,
  absolute: true,
  shadow2: true,
  smooth: true,
  zIndex: '10',
  display: 'none',
  padding: '10px',
  top: '40px',
})``

const StyledSectorsTypeBox = styled(Box).attrs({})`
  justify-content: flex-end;
  width: 100%;
`

const H2Styled = styled(H2).attrs({})`
  img {
    margin: 0 6px 0 0;
  }
`

type Props = {
  id: any
  isFetching: boolean
  jobPosition: IJobPosition
  potentialCandidates: Map<number, ICandidate>
  matchedCandidates: Map<number, IMatchedCandidate>
  potentialCandidatesQty: number
  pagesQty: number
  query: { page: number; perPage: number }
  matching: typeof CandidatesActions.match
  fetchJobWithCandidates: typeof EmployersActions.fetchJobWithCandidates
  setStatus: typeof EmployersActions.setStatusJobPosition
  addCandidateRoute: () => void
  editJobPositionRoute: () => void
  onClose: () => void
  changePipelineStatus: typeof PipelineActions.changePipelineStatus
}

const JobPositionInner: React.FC<Props & RouteComponentProps> = ({
  id,
  isFetching,
  jobPosition,
  matchedCandidates,
  potentialCandidates,
  potentialCandidatesQty,
  matching,
  query,
  pagesQty,
  fetchJobWithCandidates,
  addCandidateRoute,
  editJobPositionRoute,
  setStatus,
  onClose,
  changePipelineStatus,
  history,
}) => {
  const [currentSection, setCurrentSection] = useState(0)
  useEffect(() => {
    fetchJobWithCandidates(id)
  }, [])

  const matchCandidate = (CandidateId: number) => {
    return matching(id, CandidateId)
  }

  const fetchNextPage = () => {
    fetchJobWithCandidates(id, ++query.page)
  }

  const setStatusActive = () => setStatus(jobPosition.id, false)
  const setStatusClose = () => setStatus(jobPosition.id, true)

  const PositionIsClosed = () => (
    <BoxCenterContent column>
      <BoxCenterContent mt="50px" mb="20px">
        <Image width="60%" height="35%" src={require('assets/images/position-is-closed-.png')} />
      </BoxCenterContent>
      <BoxCenterContent column>
        <Box>
          <H2 black fw="bold">
            This position is closed right now
          </H2>
        </Box>
        <Box>
          <Text>Change position status to start matching the candidates from your list</Text>
        </Box>
      </BoxCenterContent>
    </BoxCenterContent>
  )

  const NoCandidates = () => {
    return (
      <BoxCenterContent column>
        <BoxCenterContent mt="50px" mb="20px">
          <Image width="50%" height="25%" src={require('assets/images/you-didn’t-add-candidates-yet-.png')} />
        </BoxCenterContent>
        <BoxCenterContent column>
          <Box>
            <H2 black fw="bold">
              You didn’t add candidates yet
            </H2>
          </Box>
          <Box>
            <Text>Add your first candidates to start matching them with the related job positions</Text>
          </Box>
          <Box width="140px" height="52px" mt="20px">
            <Button onClick={addCandidateRoute} title="ADD CANDIDATE" />
          </Box>
        </BoxCenterContent>
      </BoxCenterContent>
    )
  }

  const closeJobDeal = (matched: IMatched) => {
    changePipelineStatus(matched.JobPositionId, matched.CandidateId, '-1', matched.interviewDate || '', () => {
      fetchJobWithCandidates(id)
    })
  }

  const renderItems = (items: Array<{ name: string }>) => {
    const firstItem = items.length ? items[0].name : false
    const secondItem = items.length >= 2 ? items[1].name : false
    const isShowNumberOfItems = items.length > 2
    const maxWidth = secondItem ? '200px' : '100%'
    if (!items.length)
      return (
        <Box>
          <ItemBox>
            <ItemText>Any</ItemText>
          </ItemBox>
        </Box>
      )
    return (
      <StyledSectorsTypeBox>
        {!firstItem || (
          <ItemBox maxWidth={maxWidth}>
            <ItemText>{firstItem}</ItemText>
          </ItemBox>
        )}
        {!secondItem || (
          <ItemBox maxWidth={maxWidth} ml="12px">
            <ItemText>{secondItem}</ItemText>
          </ItemBox>
        )}
        {!isShowNumberOfItems || (
          <ItemBox pointer justifyCenter relative ml="12px">
            <ItemText pointer>{items.length - 2}</ItemText>
            <PopUpBox className="popUpBox">
              {items.slice(2).map(item => (
                <ItemBox key={item.name}>
                  <ItemText>{item.name}</ItemText>
                </ItemBox>
              ))}
            </PopUpBox>
          </ItemBox>
        )}
      </StyledSectorsTypeBox>
    )
  }

  if (isFetching)
    return (
      <Box overflow="visible" relative>
        <Loader fill={false} width="60vw" height="80vh" />
      </Box>
    )

  const { City } = jobPosition
  const city = City && City.name.indexOf(City.State.abbr) > 0 ? City.name : `${City.name} ${City.State.abbr}`

  const sortByDegree = (candidate: any) => Array.from(candidate).sort((a: any, b: any) => b[1].complianceDegree - a[1].complianceDegree)

  return (
    <BoxCol width="70vw" overflow="visible" relative>
      <BoxFullFill column overflow="scroll" mr="20px">
        <BoxFill spaceBetween>
          <Box>
            <Box pr="10px" borderRight="1px solid #E3E8F1">
              <StatusDropDown titles={['Active', 'Closed']} onActive={setStatusActive} onClose={setStatusClose} hired={jobPosition.closed} />
            </Box>
            <Box pl="6px">
              <Text>Last edit {moment(jobPosition.updatedAt).fromNow()}</Text>
            </Box>
          </Box>
          <Box>
            <EditIcon onClick={editJobPositionRoute} />
          </Box>
        </BoxFill>
        <BoxFill spaceBetween alignCenter mt="20px">
          <BoxCol>
            <H2Styled lh="24px" black fw="bold">
              {jobPosition.public && <Image src={require('assets/icons/user-icon.svg')} />}
              {jobPosition.Position.name}
            </H2Styled>
            <Text lh="14px">
              <CompanyNameDefault onClick={() => history.push(`live-jobs/employer/${jobPosition.EmployerId}`)}>
                {jobPosition.Employer.companyName}
              </CompanyNameDefault>
              {city}
            </Text>
          </BoxCol>
          <Box>
            <SectorsTypeBox width="100%">
              {jobPosition.Sectors && jobPosition.Sectors.length > 0 && <>{renderItems(jobPosition.Sectors)}</>}
            </SectorsTypeBox>
            <GreenBox ml="10px">
              <Text s>${formatCurrency(jobPosition.salary)}</Text>
            </GreenBox>
          </Box>
        </BoxFill>
        <Tabs currentSection={currentSection} sections={['Candidates', 'Job description']} setSection={setCurrentSection} />
        {currentSection ? (
          <BoxFullFill column>
            {jobPosition.EmployerContact ? (
              <React.Fragment>
                <Box margin="10px 0">
                  <Text xl black fw="bold">
                    Hiring manager info
                  </Text>
                </Box>
                <BoxFill pb="24px" bb="1px solid #E3E8F1">
                  <Box mr="15px">
                    <Text whiteSpace>
                      Name:{' '}
                      <Span black noPointer>
                        {jobPosition.EmployerContact.name}
                      </Span>
                    </Text>
                  </Box>
                  {jobPosition.EmployerContact.position && (
                    <Box mr="15px">
                      <Text whiteSpace>
                        Position:{' '}
                        <Span black noPointer>
                          {jobPosition.EmployerContact.position}
                        </Span>
                      </Text>
                    </Box>
                  )}
                  <Box mr="15px">
                    <Text whiteSpace>
                      Email: <Email email={jobPosition.EmployerContact.email} />
                    </Text>
                  </Box>
                  {jobPosition.EmployerContact.phone && (
                    <Box>
                      <Text whiteSpace>
                        Phone number:{' '}
                        <Span black noPointer>
                          {jobPosition.EmployerContact.phone}
                        </Span>
                      </Text>
                    </Box>
                  )}
                </BoxFill>
                {jobPosition.description && (
                  <BoxFullFill column>
                    <Box margin="10px 0">
                      <Text xl black fw="bold">
                        Job description
                      </Text>
                    </Box>
                    <BoxFullFill>
                      <Textarea disabled noBorder noShadow value={jobPosition.description} />
                    </BoxFullFill>
                  </BoxFullFill>
                )}
              </React.Fragment>
            ) : null}
          </BoxFullFill>
        ) : jobPosition.closed ? (
          PositionIsClosed()
        ) : potentialCandidates.size || matchedCandidates.size ? (
          <BoxFill column>
            {matchedCandidates.size ? (
              <BoxFill column>
                <Box margin="20px 0">
                  <Bold black xl>
                    Matched ({matchedCandidates.size})
                  </Bold>
                </Box>
                <BoxFill pb="24px">
                  <MatchedJobsBox>
                    {matchedCandidates.valueSeq().map(candidate => (
                      <MatchedCandidate key={candidate.id} candidate={candidate} closeJobDeal={() => closeJobDeal(candidate.Matched)} />
                    ))}
                  </MatchedJobsBox>
                </BoxFill>
                <Separator />
              </BoxFill>
            ) : null}
            {potentialCandidates.size > 0 ? (
              <BoxCol fill>
                <Box margin="20px 0">
                  <Bold black xl>
                    Potential ({potentialCandidatesQty})
                  </Bold>
                </Box>
                <PotentialCandidateBox>
                  {potentialCandidates.valueSeq() &&
                    sortByDegree(potentialCandidates).map((candidate: any) => (
                      <Box width="32%" mb="2%" key={candidate[1].id}>
                        <PotentialCandidateCard candidate={candidate[1]} match={matchCandidate} />
                      </Box>
                    ))}
                </PotentialCandidateBox>
              </BoxCol>
            ) : null}
            {pagesQty > query.page && <Button height="52px" padding="15px 0" type="load" onClick={fetchNextPage} title="Show more candidates" />}
          </BoxFill>
        ) : (
          NoCandidates()
        )}
      </BoxFullFill>
    </BoxCol>
  )
}

const mapStateToProps = (state: AppState) => ({
  isFetching: state.currentInnerPage.getIn(getInInner(['isFetching'])),
  jobPosition: state.currentInnerPage.getIn(getInInner(['item'])),
  matchedCandidates: state.currentInnerPage.getIn(getInInner(['matchedCandidates'])),
  potentialCandidates: state.currentInnerPage.getIn(getInInner(['potentialCandidates'])),
  potentialCandidatesQty: state.currentInnerPage.getIn(getInInner(['potentialCandidatesQty'])),
  pagesQty: state.currentInnerPage.getIn(getInInner(['pagesQty'])),
  query: state.currentInnerPage.getIn(getInInner(['query'])),
})

export default connect(
  mapStateToProps,
  {
    fetchJobWithCandidates: EmployersActions.fetchJobWithCandidates,
    matching: CandidatesActions.match,
    setStatus: EmployersActions.setStatusJobPosition,
    changePipelineStatus: PipelineActions.changePipelineStatus,
  }
)(withRouter(JobPositionInner))
