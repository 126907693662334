import React from 'react'
import styled from 'styled-components'

import { bytesToSize } from 'utils'

const Svg = styled.svg`
  .circle-background,
  .circle-progress {
    fill: none;
  }
  .circle-background {
    stroke: #d8f0e9;
  }
  .circle-progress {
    stroke: #3cb593;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .circle-text {
    font-size: 14px;
    font-weight: normal;
    fill: #9caab8;
  }
`

type PropsCircular = {
  sqSize: number
  percentage: number
  size: number
  strokeWidth: number
}

class CircularProgressBar extends React.Component<PropsCircular> {
  state = {}

  static defaultProps = {
    sqSize: 200,
    percentage: 25,
    strokeWidth: 10,
  }

  render() {
    // Size of the enclosing square
    const sqSize = this.props.sqSize
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * this.props.percentage) / 100

    return (
      <Svg width={this.props.sqSize} height={this.props.sqSize} viewBox={viewBox}>
        <circle
          className="circle-background"
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
        <svg
          x="calc(50% - 15px)"
          y="calc(30%)"
          dy=".3em"
          textAnchor="middle"
          width="30"
          height="25"
          viewBox="0 0 30 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.2709 16.927C19.5376 16.927 19.8042 16.8254 20.0074 
              16.6218C20.4141 16.215 20.4141 15.5556 20.0074 15.1489L15.8412 
              10.9827C15.7928 10.9343 15.7391 10.8905 15.6818 10.8525C15.6558 
              10.8348 15.6271 10.8228 15.5995 10.8077C15.5673 10.79 15.5365 10.7708 
              15.5027 10.7567C15.4678 10.7421 15.4313 10.7338 15.3954 10.7234C15.3662 
              10.7145 15.3381 10.7036 15.3079 10.6973C15.173 10.6702 15.0344 10.6702 14.8995 
              10.6973C14.8693 10.703 14.8412 10.7145 14.812 10.7234C14.7761 10.7338 14.7402 
              10.7421 14.7053 10.7567C14.6709 10.7708 14.6402 10.7905 14.6079 10.8083C14.5808 
              10.8234 14.5521 10.8353 14.5261 10.8525C14.4688 10.8905 14.4152 10.9343 14.3667 
              10.9827L10.2006 15.1489C9.79382 15.5556 9.79382 16.215 10.2006 16.6218C10.4042 
              16.8254 10.6709 16.927 10.9376 16.927C11.2042 16.927 11.4709 16.8254 11.674 
              16.6218L14.0626 14.2333V23.6978C14.0626 24.2728 14.5292 24.7395 15.1042 24.7395C15.6792 24.7395 
              16.1459 24.2728 16.1459 23.6978V14.2333L18.5344 16.6218C18.7376 16.8254 19.0042 16.927 19.2709 16.927Z"
            fill="#3CB593"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.9141 8.58654C24.8427 8.57664 23.6344 8.41206 22.4745 8.58758C22.4479 8.59175 22.4214 
              8.59383 22.3953 8.59383C22.1422 8.59383 21.9203 8.40946 21.8813 8.15112C21.838 7.86675 
              22.0333 7.60112 22.3177 7.55789C23.1151 7.43654 23.9125 7.45581 24.4443 
              7.49331C24.0948 
              3.45581 20.7448 0.260498 16.7109 0.260498C14.2646 0.260498 11.8057 1.49956 10.3125 
              3.45269C11.3302 4.3131 11.9792 5.59696 11.9792 7.03133C11.9792 7.31935 11.7464 7.55216 
              11.4583 7.55216C11.1703 7.55216 10.9375 7.31935 10.9375 7.03133C10.9375 5.74904 10.2703 
              4.62144 9.26615 3.97091C9.14792 3.89435 9.02552 3.82664 8.90052 3.76466C8.86458 3.74696 
              8.82812 3.72873 8.79115 3.71206C8.64948 3.64748 8.50469 3.59019 8.35625 3.54487C8.33802 
              3.53966 8.31927 3.53602 8.30104 3.53081C8.16615 3.49175 8.02917 3.46206 7.8901 
              3.43862C7.84948 3.43185 7.80938 3.4256 7.76875 3.41987C7.61146 3.39956 7.4526 3.3855 
              7.29167 3.3855C5.28177 3.3855 3.64583 5.02144 3.64583 7.03133C3.64583 7.07404 3.64896 
              7.11623 3.65208 7.15841L3.65833 7.26779L3.65365 7.35112C3.65 7.41883 3.64583 7.48602 
              3.64583 7.55216V7.88029L3.35052 8.02144C1.40885 8.95216 0 11.198 0 13.3615C0 16.4766 
              2.53385 19.0105 5.64844 19.0105H13.0359V16.7485L12.426 17.3584C12.0193 17.7652 11.4859 
              17.9688 10.9526 17.9688C10.4193 17.9688 9.88646 17.7652 9.47917 17.3584C8.66563 16.5449 
              8.66563 15.2256 9.47917 14.4126L13.6458 10.2459C13.7427 10.149 13.8495 10.0626 13.9635 
              9.98602C14.0146 9.95216 14.0703 9.92821 14.1234 9.89904C14.1891 9.8631 14.2526 9.823 
              14.3224 9.79435C14.3911 9.76571 14.463 9.74956 14.5339 9.72873C14.5932 9.71154 14.65 
              9.68862 14.7109 9.67612C14.9802 9.62248 15.2578 9.62248 15.5271 9.67612C15.5885 9.6881 
              15.6453 9.71102 15.7042 9.72873C15.775 9.74956 15.8469 9.76571 15.9156 9.79435C15.9854 
              9.82352 16.049 9.8631 16.1146 9.89904C16.1677 9.92821 16.2234 9.95164 16.2745 9.98602C16.3885 
              10.0621 16.4953 10.149 16.5922 10.2459L20.7589 14.4126C21.5724 15.2261 21.5724 16.5454 20.7589 
              17.3584C20.3521 17.7652 19.8188 17.9688 19.2854 17.9688C18.7521 17.9688 18.2193 17.7652 17.812 
              17.3584L17.2021 16.7485V19.0105H18.4125C18.4432 19.0105 18.4729 19.0079 18.5031 19.0058L18.6063 
              19.0001L18.7474 19.0058C18.7776 19.0079 18.8073 19.0105 18.838 19.0105H23.9438C26.8234 
              19.0105 29.1667 16.6673 29.1667 13.7876C29.1667 11.2819 27.3385 9.048 24.9141 8.58654Z"
            fill="#3CB593"
          />
        </svg>
        <text x="50%" y="50%" dy=".3em" textAnchor="middle">
          Uploading
        </text>
        <text className="circle-text" x="50%" y="65%" dy=".3em" textAnchor="middle">
          {`${bytesToSize((this.props.size / 100) * this.props.percentage)} of ${bytesToSize(this.props.size)}`}
        </text>
      </Svg>
    )
  }
}

export default CircularProgressBar
