import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { AppState } from 'store/rootReducer'

import { BoxCol, BoxFill, Text } from 'styles'

const Wrapper = styled(BoxFill).attrs({
  spaceBetween: true,
  alignCenter: true,
  mainBC: true,
})<{ isListEmpty: boolean }>`
  padding: ${props => (props.isListEmpty ? '92px 80px 25px 40px' : '40px 80px 80px 80px')};
  transition-duration: 0ms;
`

type Props = {
  title: string
  isListEmpty: boolean
}

const Head: React.FC<Props> = ({ isListEmpty, title }) => {
  const error = useSelector((state: AppState) => state.pipeline.get('error'))

  return (
    <BoxFill>
      <Wrapper isListEmpty={isListEmpty}>
        <BoxCol mb="30px">
          <Text white fz="42px" fw="bold" lh="46px">
            {title}
          </Text>
          {error && <Text red>{error}</Text>}
        </BoxCol>
      </Wrapper>
    </BoxFill>
  )
}

export default Head
