/* tslint:disable:max-line-length */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Dropzone, { ILayoutProps, IInputProps, IPreviewProps, IMeta, getFilesFromEvent, IFileWithMeta, StatusValue } from 'react-dropzone-uploader'
// @ts-ignore
import AvatarEditor from 'react-avatar-editor'

import Api from 'api'

import MessengerActions from 'store/messenger/actions'
import { InputEvent } from 'types/helps'
import { IUploadInputParams } from './types'

import Button from 'components/Button'
import ImageLoader from './imageLoader'

import { checkStatuses } from 'utils'

import { BoxFullFill, BoxCenterContent, Box, Text, Span, Image, BoxFill, GreenBox } from 'styles'
import 'react-dropzone-uploader/dist/styles.css'

const imageScaleDown = (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3043 0.469727H1.69653C0.760871 0.469727 0 1.23035 0 2.16711V12.8353C0 13.771 0.760871 14.5308 1.69653 14.5308H13.3042C14.2399 14.5308 14.9999 13.771 14.9999 12.8353V2.16711C15 1.23035 14.2399 0.469727 13.3043 0.469727ZM9.69976 2.99406C10.6046 2.99406 11.3384 3.72792 11.3384 4.6327C11.3384 5.53748 10.6045 6.27134 9.69976 6.27134C8.79468 6.27134 8.06112 5.53748 8.06112 4.6327C8.06112 3.72792 8.79468 2.99406 9.69976 2.99406ZM12.7722 12.9626H7.49985H2.46237C2.00981 12.9626 1.80825 12.6351 2.01227 12.2312L4.82447 6.66109C5.02824 6.25718 5.41658 6.22112 5.69159 6.5805L8.51936 10.2759C8.79438 10.6354 9.27506 10.6659 9.59321 10.3439L10.285 9.64341C10.6029 9.3214 11.0713 9.36127 11.3307 9.73189L13.122 12.2907C13.381 12.6618 13.2248 12.9626 12.7722 12.9626Z"
      fill="#D5EBFF"
    />
  </svg>
)
const imageScaleUp = (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5129 0.689941H2.48825C1.11594 0.689941 0 1.80552 0 3.17944V18.8262C0 20.1985 1.11594 21.3129 2.48825 21.3129H19.5129C20.8852 21.3129 21.9999 20.1985 21.9999 18.8262V3.17944C22 1.80552 20.8852 0.689941 19.5129 0.689941ZM14.2263 4.3923C15.5534 4.3923 16.6296 5.46862 16.6296 6.79564C16.6296 8.12265 15.5533 9.19897 14.2263 9.19897C12.8989 9.19897 11.823 8.12265 11.823 6.79564C11.823 5.46862 12.8989 4.3923 14.2263 4.3923ZM18.7326 19.0128H10.9998H3.61148C2.94772 19.0128 2.65211 18.5325 2.95133 17.9401L7.07589 9.7706C7.37474 9.1782 7.94432 9.12532 8.34767 9.6524L12.4951 15.0724C12.8984 15.5995 13.6034 15.6444 14.07 15.1721L15.0846 14.1447C15.5509 13.6724 16.2379 13.7309 16.6184 14.2744L19.2457 18.0273C19.6255 18.5717 19.3963 19.0128 18.7326 19.0128Z"
      fill="#2697FF"
    />
  </svg>
)
const MAX_FILE_SIZE_BYTES = 20971520

const Container = styled(BoxFullFill)<{ photoLoaded: any }>`
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  border: ${props => (props.photoLoaded ? 'none !important' : '1px dashed #d2d7da !important')};
  /* min-height: 45vh !important; */
  overflow: hidden !important;
  padding: 1px !important;
`

const FullWidthInput = styled.input`
  width: 100%;
  ::-webkit-slider-runnable-track {
    cursor: pointer;
  }
`
type Props = {
  photo?: string
  setImageUrl?: (image: Blob) => Blob
  onUploadSuccess?: (user: any) => void
  onUpdate?: any
  id: number
  updateButtonText?: string
  paramsForUploading: any
  signatureText?: string
  setSignature: typeof MessengerActions.setSignature
  publicData?: boolean
}

const ImageUploadBlock: React.FC<Props> = ({
  photo,
  onUploadSuccess,
  id,
  onUpdate,
  updateButtonText = 'SAVE CHANGES',
  paramsForUploading,
  signatureText,
  setSignature,
  publicData,
}) => {
  const [error, setError] = useState<null | string>(null)
  const [scale, setScale] = useState(1.2)
  const [previewImage, setPreview] = useState<string | undefined>('')
  const [currentImage, setCurrent] = useState<string | undefined>(photo)
  const [isUpdated, setIsUpdated] = useState(false)

  let editorRef: any
  const setEditorRef = (editor: any) => {
    editorRef = editor
  }

  const handleUpdate = () => {
    if (editorRef) {
      const canvas = editorRef.getImageScaledToCanvas().toDataURL()
      return fetch(canvas)
        .then(res => res.blob())
        .then(blob => {
          paramsForUploading(id)
            .then((params: IUploadInputParams) => {
              const body = new FormData()
              body.append(params.fieldName, blob)
              const objectURL = (URL || webkitURL).createObjectURL(blob)
              setCurrent(objectURL)
              setPreview('')
              if (!publicData) setSignature()
              return Api.post(params.options.url, body)
            })
            .then(() => {
              setIsUpdated(true)
              setTimeout(() => setIsUpdated(false), 3000)
              if (typeof signatureText === 'string') {
                if (!publicData) setSignature()
              }
              if (onUpdate) onUpdate()
            })
        })
    }
    if (typeof signatureText === 'string') {
      if (!publicData) setSignature()
    }
    if (onUpdate) return onUpdate()
  }

  const Layout = ({ input, previews, dropzoneProps, files, extra: { onFiles } }: ILayoutProps) => {
    const photoLoaded = files.length ? checkStatuses(files[0].meta, ['done', 'headers_received']) : false
    return (
      <BoxFullFill column>
        {isUpdated && (
          <BoxFill mb="20px">
            <GreenBox justifyCenter fill>
              <Text>Information successfully updated!</Text>
            </GreenBox>
          </BoxFill>
        )}
        <Container photoLoaded={photoLoaded || currentImage} {...dropzoneProps}>
          {previews && previews.length > 1 ? previews[previews.length - 1] : previews}
          {files.length < 1 && currentImage ? (
            <BoxFullFill>
              <Image style={{ maxHeight: '45vh', maxWidth: '45vw' }} src={currentImage} />
            </BoxFullFill>
          ) : files.length < 1 ? (
            <BoxFill>
              <BoxCenterContent fill>{input}</BoxCenterContent>
            </BoxFill>
          ) : null}
        </Container>
        {photoLoaded || currentImage || error ? (
          <label>
            <Span secondColor>Upload another photo</Span>
            <input
              style={{ display: 'none' }}
              type="file"
              onChange={async e => {
                if (files.length && error) files[0].remove()
                const chosenFiles = await getFilesFromEvent(e)
                /* need ignore cuze wrong types of this func did author of lib, so tslint get error */
                // @ts-ignore
                onFiles(chosenFiles)
              }}
            />
          </label>
        ) : null}
      </BoxFullFill>
    )
  }

  const InputComponent = ({ accept, onFiles }: IInputProps) => (
    <Box column alignCenter>
      <Image src={require('assets/icons/upload.svg')} alt="upload" />
      <Text black>
        Drag&nbsp;{'&'}&nbsp;drop an photo here
        <br />
        or&nbsp;
        <label>
          <Span secondColor>Browse</Span>
          <input
            style={{ display: 'none' }}
            type="file"
            accept={accept}
            onChange={async e => {
              const chosenFiles = await getFilesFromEvent(e)
              /* need ignore cuze wrong types of this func did author of lib, so tslint get error */
              // @ts-ignore
              onFiles(chosenFiles)
            }}
          />
        </label>
        &nbsp;your files
      </Text>
    </Box>
  )

  const handleScale = (e: InputEvent) => {
    setScale(parseFloat(e.target.value))
  }

  const Preview = (data: IPreviewProps) => {
    const { previewUrl, percent, status, size }: IMeta = data.meta
    if (status === 'done' || status === 'headers_received') {
      setPreview(previewUrl)
      return null
    }
    return <ImageLoader percentage={percent} size={size} />
  }

  const onLoadedFile = (file: IFileWithMeta, status: StatusValue) => {
    setError(null)
    if (status === 'rejected_file_type') {
      return setError('Unsupported format')
    }
    if (status === 'error_file_size') {
      return setError('File size more than 20MB')
    }
    if (status === 'done' && onUploadSuccess) {
      onUploadSuccess(JSON.parse(file.xhr!.response))
      return setError(null)
    }
  }

  return (
    <React.Fragment>
      <BoxFullFill column>
        {!previewImage ? (
          <Dropzone
            accept="image/*"
            LayoutComponent={Layout}
            InputComponent={InputComponent}
            PreviewComponent={Preview}
            onChangeStatus={onLoadedFile}
            maxSizeBytes={MAX_FILE_SIZE_BYTES}
            multiple={false}
          />
        ) : (
          <React.Fragment>
            <BoxFill mt="10px">
              <AvatarEditor
                ref={setEditorRef}
                image={previewImage}
                // @ts-ignore
                border={[100, 5]}
                borderRadius={200}
                color={[0, 0, 0, 0.6]}
                scale={scale}
                rotate={0}
                style={{ width: '100%', height: 'auto' }}
              />
            </BoxFill>
            <BoxFill mt="10px">
              <Box mr="5px">{imageScaleDown}</Box>
              <FullWidthInput name="scale" type="range" onChange={handleScale} min="0.8" max="2" step="0.1" value={scale} />
              <Box ml="5px">{imageScaleUp}</Box>
            </BoxFill>
            <label>
              <Span secondColor onClick={() => setPreview('')}>
                Upload another photo
              </Span>
            </label>
          </React.Fragment>
        )}
        <BoxFill column mt="10px" mb="10px">
          {error && <Text red>{error}</Text>}
          <Text s>Supported formats are PNG, JPG. Max file size is 20 MB. Recommended resolution 256x256 pixels</Text>
        </BoxFill>
      </BoxFullFill>
      <BoxFill btDefault justifyRight padding="20px 40px">
        <Box width="172px" height="60px">
          <Button onClick={handleUpdate} title={updateButtonText} />
        </Box>
      </BoxFill>
    </React.Fragment>
  )
}

export default connect(
  null,
  {
    setSignature: MessengerActions.setSignature,
  }
)(ImageUploadBlock)
