import styled from 'styled-components'

import { Bold } from 'styles'

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Left = styled.div``

export const Bottom = styled.div`
  border-top: 1px solid #e3e8f1;
  padding: 20px 0 0 0;
  margin: 20px 0 0 0;
`

export const Balance = styled(Bold).attrs({})``
