import React from 'react'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'

import Button from 'components/Button'

import { Wrap, Top, Close, Docs, Doc, DocTop, DocBottom, DayAndMonth } from './styled'

import close from 'assets/icons/close.svg'

type Props = {
  isOpen: boolean
  closeSaveSearches: () => void
  docs: any
  lists: any
  apply: any
}

const SavedSearch: React.FC<Props> = ({ isOpen, closeSaveSearches, docs, lists, apply }) => {
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const renderDocs =
    docs &&
    docs.map((el: any) => {
      let type = ''
      let cities = ''
      if (el.sectorIds) {
        el.sectorIds.map((item: any) => lists.get('projectTypes').map((el: any) => (+item === el.id ? (type = el.name) : null)))
      }

      if (el.cityIds) {
        el.cityIds.map((item: any) => lists.get('cities').map((el: any) => (+item === el.id ? (cities = el.name) : null)))
      }

      return (
        <Doc key={el.id}>
          <DocTop>
            {el.name && `${el.name}, `}
            {el.jobTitle && `${el.jobTitle}, `}
            {el.companyName && `${el.companyName}, `}
            {cities && `${cities}, `}
            {el.status && `${el.status}, `}
            {type && `${type} `}
          </DocTop>
          <DocBottom>
            <DayAndMonth>{`${new Date(el.createdAt).getDay() + 1} ${month[new Date(el.createdAt).getMonth()]}`}</DayAndMonth>
            <Button width="78px" height="26px" type="secondary" title="Apply" onClick={() => apply(el)} />
          </DocBottom>
        </Doc>
      )
    })

  return (
    <Wrap isOpen={isOpen}>
      <Top>
        Saved searches
        <Close src={close} onClick={() => closeSaveSearches()} />
      </Top>
      <Docs>{renderDocs}</Docs>
    </Wrap>
  )
}
const mapStateToProps = (state: AppState) => ({
  lists: state.lists,
})

export default connect(
  mapStateToProps,
  {}
)(SavedSearch)
