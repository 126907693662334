import React from 'react'
import styled from 'styled-components'

import { BoxCenterContent, Bold } from 'styles'

const NotImageBox = styled(BoxCenterContent).attrs(props => ({
  shadow2: true,
  // bc: props.theme.main.accentColor2,
  br: '50%',
}))``

type PropsNoImage = {
  values: string[]
  width?: string
}

export const NoImage: React.FC<PropsNoImage> = ({ values, width }) => {
  return (
    <NotImageBox width={width} height={width}>
      <Bold accentColor>{values.map(value => value)}</Bold>
    </NotImageBox>
  )
}

NoImage.defaultProps = {
  width: '72px',
}
