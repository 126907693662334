import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ValidationError } from 'yup'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'
import AuthActions from 'store/auth/actions'
import { InputEvent } from 'types/helps'
import { INotValidFieldsErr } from 'types/errors'

import Input from 'components/Input'
import PrimaryButton from 'components/Button'
import { Logo } from 'components/Icons'
import { RenderNotValidFields } from 'components/Others'

import { setPasswordSchema, formatErrors } from 'services/validation'

import { Box, BoxCenterContent, Text, H1 } from 'styles'

type Props = {
  error: INotValidFieldsErr
  setNewPassword: typeof AuthActions.setNewPassword
}

const ResetPassword: React.FC<Props & RouteComponentProps> = ({ history, location, error, setNewPassword }) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState<Partial<{ password: string; confirmPassword: string }>>({})

  const onChangePassword = (e: InputEvent) => setPassword(e.target.value)
  const onChangeConfirmPassword = (e: InputEvent) => setConfirmPassword(e.target.value)

  const onSetNewPassword = () => {
    const HASH = location.search.split('=')[1]
    return setPasswordSchema
      .validate({ password, confirmPassword }, { abortEarly: false })
      .then(() => {
        if (HASH) {
          setNewPassword(HASH, password)
          return history.push('/?redirect=login&resetPassword=true')
        }
        setErrors({ password: 'HASH not found, cannot create new password, please try again' })
      })
      .catch((err: ValidationError) => setErrors(formatErrors(err)))
  }

  return (
    <BoxCenterContent column width="100vw" height="100vh">
      <Box absolute top="7%">
        <Logo type="black" />
      </Box>
      <Box column alignCenter>
        <H1 fw="bold" black>
          Create new password
        </H1>
        <Box mb="40px">
          <Text width="35vw">Please create a strong new password for yuor account.</Text>
        </Box>
        <Box fill>
          <Input error={errors.password} onChange={onChangePassword} value={password} type="password" label="Create password" />
        </Box>
        <Box fill mt="28px">
          <Input error={errors.confirmPassword} onChange={onChangeConfirmPassword} value={confirmPassword} type="password" label="Confirm password" />
        </Box>
        <RenderNotValidFields errors={error} />
        <Box height="60px" fill mt="40px" mb="38px">
          <PrimaryButton onClick={onSetNewPassword} title="RESET PASSWORD" />
        </Box>
      </Box>
    </BoxCenterContent>
  )
}

const mapStateToProps = (state: AppState) => ({
  error: state.auth.get('error'),
})

export default connect(
  mapStateToProps,
  { setNewPassword: AuthActions.setNewPassword }
)(ResetPassword)
