const TEXT_OF_STEP = {
  firstStep: {
    title: 'Enter candidate personal information',
    subTitle: 'Please add as much information about candidate as possible',
    faq: {
      title: 'Do I need to fill in all the fields?',
      text: `Optional fields are not mandatory but very useful to have handy`,
    },
  },
  secondStep: {
    title: 'Fill in candidate professional details',
    subTitle: 'More we know about you and your work history better we can match you with the right role. Tell us all..',
    faq: {
      title: 'Do I need to fill in all the fields?',
      text: `Optional fields are not mandatory but very useful to have handy`,
    },
  },
  thirdStep: {
    title: 'Upload candidate CV',
    subTitle: `We will keep your CV on file so you don't have to upload it each time. 
               You can replace with updated file anytime you wish.`,
    faq: {
      title: 'Please upload your candidates CV',
      text: `By adding your candidates documents you can keep track of everything from your pipeline!`,
    },
  },
  fourStep: {
    title: 'Upload candidate license or licenses (optional)',
    subTitle: `This is not a mandatory field but highly recommended 
               if you have any licence that may just help you to get ahead of other candidates.`,
    faq: {
      title: `Please upload your candidate's licenses`,
      text: `By adding your candidate's licenses you can keep track of everything from your pipeline! `,
    },
  },
  fiveStep: {
    title: 'Upload candidate photo (optional)',
    subTitle: `Candidate photo isn't mandatory to upload but will make your listings looking much nicer if you do`,
    faq: {
      title: `Please upload your candidate's photo`,
      text: `By adding your candidate's photo you can keep track of everything from your pipeline! `,
    },
  },
}

export default function(currentStep: number) {
  switch (currentStep) {
    case 1:
      return TEXT_OF_STEP.firstStep
    case 2:
      return TEXT_OF_STEP.secondStep
    case 3:
      return TEXT_OF_STEP.thirdStep
    case 4:
      return TEXT_OF_STEP.fourStep
    case 5:
      return TEXT_OF_STEP.fiveStep
    default:
      return null
  }
}
