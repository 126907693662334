import { useState, useCallback } from 'react'
import { AsyncStatus } from 'types/helps'
import { Sector } from 'api/sector'

export interface ProjectTypeListItem {
  id: number
  name: string
  jobsCount: number
}

export type Option = {
  value: string
  label: string
  status?: number
  message?: string
}

export function useProjectTypesCRUD(): {
  createProject: (name: string) => Promise<{ label: string; value: string; status?: number; message?: string } | undefined>
  getOptions: () => void
  options: Option[]
  getList: () => void
  list: ProjectTypeListItem[]
  getListStatus: AsyncStatus
  getLinkFormOptions: (id?: string) => void
  linkFormOptions: Option[]
  updateProject: (id: number, name: string) => void
  deleteProject: (id: number) => Promise<void>
} {
  const [getListStatus, setGetListStatus] = useState<AsyncStatus>(AsyncStatus.IDLE)
  const [options, setOptions] = useState<Option[]>([])
  const [linkFormOptions, setLinkFormOptions] = useState<Option[]>([])
  const [list, setList] = useState<ProjectTypeListItem[]>([])

  const createProject = useCallback(async (name: string): Promise<{ label: string; value: string } | undefined> => {
    try {
      const newProject = await Sector.saveNewProjectType({ name })
      return {
        label: newProject.name,
        value: String(newProject.id),
      }
    } catch (e) {
      console.log({ e })
      // return undefined
      return e.response.data
    }
  }, [])

  const getOptions = useCallback(async () => {
    try {
      const projects = await Sector.getOptions()
      setOptions(projects.map(project => ({ label: project.name, value: `${project.id}` })))
    } catch (e) {
      console.log({ e })
    }
  }, [])

  const getLinkFormOptions = useCallback(async (id?: string) => {
    try {
      const projects = await Sector.getLinkFormOptions(id)
      setLinkFormOptions(projects.map(project => ({ label: project.name, value: `${project.id}` })))
    } catch (e) {
      console.log({ e })
    }
  }, [])

  const getList = useCallback(async () => {
    setGetListStatus(AsyncStatus.LOADING)
    try {
      const projectsList = await Sector.getList()
      setGetListStatus(AsyncStatus.SUCCESS)
      setList(projectsList)
    } catch (e) {
      console.log({ e })
      setGetListStatus(AsyncStatus.FAIL)
    }
  }, [])

  const updateProject = useCallback(async (id: number, name: string) => {
    await Sector.update({ id, name })
  }, [])

  const deleteProject = useCallback(async (id: number) => {
    await Sector.delete({ id })
  }, [])

  return {
    createProject,
    getOptions,
    options,
    getList,
    list,
    getListStatus,
    getLinkFormOptions,
    linkFormOptions,
    deleteProject,
    updateProject,
  }
}
