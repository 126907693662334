import React, { useState } from 'react'

import { components, OptionProps } from 'react-select'
import { ISelectListItem } from 'types/forms'
import { IJobPosition } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import InputSelect from 'components/Input/InputSelect'
import CityStateSelect from 'components/Input/cityStateSelect'

import { BoxFill, Image } from 'styles'
import { Select } from 'components/Select'
import { Option, useProjectTypesCRUD } from 'hooks/useProjectTypesCRUD'
import { NoticeProps } from 'react-select/src/components/Menu'
import { useEmployersOptions, EmployerOption } from 'hooks/useEmployersOptions'
import Avatar from 'components/Avatar'

const ICON_DOLLAR = <Image src={require('assets/icons/$.svg')} />

type Props = {
  form: Partial<IJobPosition>
  onChangeField: (e: InputEvent & ISelectListItem, type?: string) => void
  errors: Partial<Record<keyof IJobPosition, string>>
  changeFormField: (name: string, value: any) => void
}

const First: React.FC<Props> = ({ onChangeField, errors, form, changeFormField }) => {
  const onChangePosition = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Position')
  const onChangeCity = (e: InputEvent & ISelectListItem) => onChangeField(e, 'City')
  const onChangeItPackages = (e: InputEvent & ISelectListItem) => onChangeField(e, 'ItPackages')
  const onChangeProjectValues = (e: InputEvent & ISelectListItem) => onChangeField(e, 'ProjectValues')

  const [bgColour, setBgColour] = useState('hsla(163, 44%, 89%, 1)')

  const addNewOptionButtonStyles = {
    width: '100%',
    height: '100%',
    backgroundColor: bgColour,
    borderRadius: 6,
    border: 'none',
    color: 'hsla(163, 50%, 47%, 1)',
    paddingTop: 10,
    paddingBottom: 10,
    cursor: 'pointer',
  }

  React.useLayoutEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const employerId = searchParams.get('company')
    if (employerId) {
      changeFormField('EmployerId', +employerId)
    }
  }, [])

  const { getOptions: getProjects, options: projects, createProject } = useProjectTypesCRUD()
  const { getOptions: getEmployers, employers } = useEmployersOptions()

  React.useEffect(() => {
    getProjects()
    getEmployers()
  }, [])

  const handleOptionAdd = React.useCallback(
    async (props: NoticeProps<Option, true>) => {
      const newOption = await createProject(`${props.selectProps.inputValue}`)
      if (newOption) {
        props.setValue([...(form.Sectors || []), newOption], 'select-option')
        changeFormField('Sectors', [...(form.Sectors || []), newOption])
        getProjects()
      }
    },
    [createProject, form.Sectors]
  )

  const addNewOptionButton: React.FC<NoticeProps<Option, true>> = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <button
          type="button"
          style={addNewOptionButtonStyles}
          onClick={() => handleOptionAdd(props)}
          onMouseEnter={() => setBgColour('hsla(163, 44%, 89%, 0.8)')}
          onMouseLeave={() => setBgColour('hsla(163, 44%, 89%, 1)')}
        >
          {`Add new project "${props.selectProps.inputValue}"`}
        </button>
      </components.NoOptionsMessage>
    )
  }

  const employerValue = React.useMemo(() => {
    return employers.find(item => +item.value === form.EmployerId)
  }, [employers, form.EmployerId])

  const handleEmployerSelectChange = React.useCallback(
    (option: Nullable<Option>) => {
      if (option) {
        changeFormField('EmployerId', +option.value)
      }
    },
    [changeFormField]
  )

  const Menu = (props: any) => {
    const optionSelectedLength = props.getValue().length || 0
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 5 ? props.children : <div style={{ margin: '10px', textAlign: 'center' }}>Max limit achieved</div>}
      </components.Menu>
    )
  }

  return (
    <BoxFill column>
      <BoxFill mb="20px">
        <InputSelect
          value={form.Position}
          error={errors.Position}
          onChange={onChangePosition}
          placeholder="Choose job position title"
          label="Job position title"
          optionsType="jobs"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <Select
          value={form.Sectors}
          error={!!errors.Sectors}
          options={projects}
          components={{ NoOptionsMessage: addNewOptionButton, Menu }}
          onChange={options => changeFormField('Sectors', options)}
          placeholder="Choose project type"
          label="Project type (optional)"
          isMulti
        />
      </BoxFill>
      <BoxFill mb="20px">
        <Select
          value={employerValue}
          error={!!errors.EmployerId}
          options={employers}
          onChange={handleEmployerSelectChange}
          placeholder="Choose employer"
          label="Employer"
          components={{ Option: OptionComponent }}
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.ItPackages}
          isMulti
          onChange={onChangeItPackages}
          placeholder="Choose"
          label="Qualifications / IT Packages (optional)"
          optionsType="itPackages"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.ProjectValues}
          isMulti
          onChange={onChangeProjectValues}
          placeholder="Choose"
          label="Project values (optional)"
          optionsType="projectValues"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <CityStateSelect error={errors.City} value={form.City!} onChange={onChangeCity} />
      </BoxFill>
      <BoxFill mb="20px">
        <Input
          value={form.salary}
          error={errors.salary}
          onChange={onChangeField}
          leftElement={ICON_DOLLAR}
          pl="30px"
          name="salary"
          label="Approx. annual salary"
          type="text"
          pattern="[0-9\s]{0,9}"
        />
      </BoxFill>
    </BoxFill>
  )
}

const OptionComponent: React.FC<OptionProps<EmployerOption, false>> = React.memo(props => {
  const { isSelected, data, label } = props
  if (isSelected) {
    return null
  }
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar item={data} width="36px" textSize="14px" />
        <div style={{ marginLeft: '10px' }}>{label}</div>
      </div>
    </components.Option>
  )
})

export default First
