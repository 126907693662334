import React from 'react'
import styled from 'styled-components'

import { Input, Box, BoxFill, Label, BoxCenterContent, Text } from 'styles'

const Container = styled.div<Props>`
  background-color: #ffffff;
  display: flex;
  position: relative;
  align-items: center;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: ${props => (props.height ? `${props.height}px` : '60px')};
  border-radius: ${props => (props.br ? '6px' : 'none')};
`

const BoxElementLeft = styled(BoxCenterContent).attrs({
  absolute: true,
  left: '15px',
})``

const BoxElementRight = styled(BoxCenterContent).attrs({
  absolute: true,
  right: '15px',
})``

const CharsWrap = styled(BoxFill).attrs({})`
  width: 100%;
  justify-content: space-between;
`

const Chars = styled.p`
  font-size: 13px;
  color: #9caab8;
  padding: 3px 0 0 5px;
`

type Props = {
  placeholder?: string
  rightElement?: JSX.Element
  leftElement?: JSX.Element
  value?: any
  type?: 'number' | 'text' | 'email' | 'file' | 'date' | 'password' | 'tel' | 'time'
  label?: string
  name?: string
  pl?: string
  onBlur?: (val?: any) => void
  onChange?: (val?: any) => void
  disabled?: boolean
  maxLength?: number
  error?: any
  isShowError?: boolean
  widthRightElement?: string
  pattern?: string
  width?: number
  height?: number
  noBorder?: boolean
  br?: boolean
  defaultValue?: string
  chars?: number
}

const InputBox: React.FC<Props> = ({
  onBlur,
  placeholder,
  leftElement,
  rightElement,
  value,
  type,
  label,
  pl,
  onChange,
  disabled,
  name,
  maxLength,
  error,
  isShowError,
  widthRightElement,
  pattern,
  width,
  height,
  noBorder,
  br,
  defaultValue,
  chars,
}) => {
  const renderMessageError = () => {
    return error && !isShowError ? null : (
      <Box pt="5px">
        <Text red s>
          {error}
        </Text>
      </Box>
    )
  }

  return (
    <Box column fill>
      <CharsWrap>
        <Label htmlFor={`${label}`}>{label} </Label>
        {chars === 0 ? <Chars>{`0 left`}</Chars> : chars && <Chars>{`${chars} left`}</Chars>}
      </CharsWrap>
      <Container width={width} height={height} br={br}>
        {leftElement && <BoxElementLeft>{leftElement}</BoxElementLeft>}
        <Input
          pl={pl || '15px'}
          pr={rightElement ? '50px' : '15px'}
          defaultValue={defaultValue}
          onChange={onChange}
          id={label}
          type={type}
          value={value}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          maxLength={maxLength}
          isError={error}
          onBlur={onBlur}
          pattern={pattern}
          noBorder={noBorder}
        />
        {rightElement && <BoxElementRight width={widthRightElement}>{rightElement}</BoxElementRight>}
      </Container>
      {renderMessageError()}
    </Box>
  )
}

InputBox.defaultProps = {
  isShowError: true,
  maxLength: 150,
}

export default InputBox
