import React from 'react'
import { IUser } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'

import { BoxFill } from 'styles'

type Props = {
  onChangeField: (e: InputEvent) => void
  form: IUser & { confirmNewPassword: string }
  errors: IUser & { confirmNewPassword: string }
}

const Step3: React.FC<Props> = ({ form, onChangeField, errors }) => (
  <BoxFill column>
    <BoxFill mb="20px">
      <Input value={form.pass} error={errors.pass} name="pass" onChange={onChangeField} label="Old password" maxLength={50} type="password" />
    </BoxFill>
    <BoxFill mb="20px">
      <Input
        value={form.newPass}
        error={errors.newPass}
        name="newPass"
        onChange={onChangeField}
        label="New password"
        maxLength={50}
        type="password"
      />
    </BoxFill>
    <BoxFill mb="20px">
      <Input
        value={form.confirmNewPassword}
        error={errors.confirmNewPassword}
        name="confirmNewPassword"
        onChange={onChangeField}
        label="Confirm new password"
        maxLength={50}
        type="password"
      />
    </BoxFill>
  </BoxFill>
)

export default Step3
