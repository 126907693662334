import React from 'react'

import config from '../config'

import MessengerActions from 'store/messenger/actions'

import Loader from 'components/Loader'

import { Text } from 'styles'
import {
  PreviewContentWrapper,
  ContactSearchBar,
  // ContactSearch,
  CompanionsList,
  CompanionInfo,
  Companions,
  TextInfo,
  Unreads,
  CompanionName,
  Count,
  CompanionMessage,
  Avatar,
  AvatarBG,
  EmptyList,
  EmptyListHeader,
} from './styles'

import lampImg from 'assets/images/lamp.png'

type Props = {
  list: any
  onToggleDialog: typeof MessengerActions.setUserAsActive
  isFetchingChat: boolean
  currentUser: boolean
}

const PreviewContent: React.FC<Props> = ({ list, onToggleDialog, isFetchingChat, currentUser }) => {
  return isFetchingChat && !list.size ? (
    <Loader height={`${config.height - config.headerHeight}px`} />
  ) : (
    <PreviewContentWrapper>
      <ContactSearchBar>{/* <ContactSearch placeholder="Search messages" /> */}</ContactSearchBar>
      <CompanionsList>
        {list && list.size
          ? list.map((item: any) =>
              item.get('type') === 'candidate' || item.get('type') === 'employerContact' ? (
                <Companions
                  key={item.get('id')}
                  onClick={() => {
                    onToggleDialog(item)
                  }}
                >
                  {item.get('avatar') ? <Avatar src={item.get('avatar')} /> : <AvatarBG>{item.get('name').match(/\b(\w)/g)}</AvatarBG>}
                  <CompanionInfo>
                    <TextInfo>
                      <CompanionName>
                        {item.get('name')}
                        {item.get('type') === 'employerContact' && (
                          <Text textOverflow whiteSpace overflow="hidden" fz="17px" pointer s>
                            {item.get('position') && `・ ${item.get('position')}`}
                          </Text>
                        )}
                      </CompanionName>
                    </TextInfo>
                  </CompanionInfo>
                </Companions>
              ) : (
                <Companions
                  key={item.get('data').get('id')}
                  onClick={() => {
                    onToggleDialog(item)
                  }}
                >
                  {item.get('data').get('Candidate') ? (
                    item
                      .get('data')
                      .get('Candidate')
                      .get('photoFile') ? (
                      <Avatar
                        src={item
                          .get('data')
                          .get('Candidate')
                          .get('photoFile')
                          .get('link')}
                      />
                    ) : (
                      <AvatarBG>
                        {item
                          .get('data')
                          .get('Candidate')
                          .get('firstName')
                          .match(/\b(\w)/g)}
                        {item
                          .get('data')
                          .get('Candidate')
                          .get('lastName')
                          .match(/\b(\w)/g)}
                      </AvatarBG>
                    )
                  ) : (
                    <AvatarBG>
                      {item
                        .get('data')
                        .get('EmployerContact')
                        .get('name')
                        .match(/\b(\w)/g)}
                    </AvatarBG>
                  )}
                  <CompanionInfo>
                    <TextInfo hasUnreads={item.get('newMessagesCount')}>
                      <CompanionName>
                        {(item.get('data').get('EmployerContact') &&
                          item
                            .get('data')
                            .get('EmployerContact')
                            .get('name')) ||
                          `${item
                            .get('data')
                            .get('Candidate')
                            .get('firstName')} ${item
                            .get('data')
                            .get('Candidate')
                            .get('lastName')}`}
                        {item.get('data').get('EmployerContact') && (
                          <Text textOverflow whiteSpace overflow="hidden" fz="17px" pointer s>
                            {item
                              .get('data')
                              .get('EmployerContact')
                              .get('position') &&
                              `・ ${item
                                .get('data')
                                .get('EmployerContact')
                                .get('position')}`}
                          </Text>
                        )}
                      </CompanionName>
                      <CompanionMessage>{item.get('message')}</CompanionMessage>
                    </TextInfo>
                    {item.get('newMessagesCount') ? (
                      <Unreads>
                        <Count>{item.get('newMessagesCount') > 99 ? '99+' : item.get('newMessagesCount')}</Count>
                      </Unreads>
                    ) : null}
                  </CompanionInfo>
                </Companions>
              )
            )
          : !list.length &&
            !currentUser && (
              <EmptyList>
                <EmptyListHeader>No conversations</EmptyListHeader>
                <p>Add a candidate or company contact to start communication</p>
                <img width="100px" height="100px" src={lampImg} alt="no-conversation" />
              </EmptyList>
            )}
      </CompanionsList>
    </PreviewContentWrapper>
  )
}

export default PreviewContent
