import React from 'react'

import { Nothing, NothingTitle, NothingDesc } from './styles'

import nothingFound from 'assets/images/nothing-found.png'

export const NothingFound: React.FC = () => (
  <Nothing>
    <img src={nothingFound} alt="nothing found" />
    <NothingTitle>Nothing found</NothingTitle>
    <NothingDesc>Try to change your search parameters</NothingDesc>
  </Nothing>
)
