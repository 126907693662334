import styled from 'styled-components'

import { Box, BoxFill, Text } from 'styles'

export const BoxHeader = styled(Box).attrs({
  alignCenter: true,
  margin: '0 1vw',
})`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const BoxHeaderWrap = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    img {
      margin: 0 0 0 10px;
    }
  }
`

export const Wrap = styled(BoxFill).attrs({})`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const StepWrap = styled(Box).attrs({})`
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 10px 0 0 20px;
    padding: 35px 0;
  }
`

export const HeaderStepsText = styled(Text)<{ isActive: boolean; isSuccess: boolean }>`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: ${props => (props.isActive ? '#fff' : props.isSuccess ? null : props.theme.main.secondColor)};
`

export const Exit = styled(Text).attrs({})`
  &:hover {
    opacity: 0.8;
  }
`
