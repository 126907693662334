import React from 'react'

import { ISelectListItem } from 'types/forms'
import { InputEvent } from 'types/helps'

import Checkbox from 'components/Checkbox'

import { BoxFill, Text } from 'styles'

type Props = {
  name: string
  title: string
  value?: boolean
  onChange: (e: InputEvent & ISelectListItem, type?: string) => void
}

const InputCheckbox: React.FC<Props> = ({ name, title, value, onChange }) => {
  return (
    <BoxFill alignCenter>
      <Checkbox onChangeField={onChange} value={value} name={name} />
      <Text black>{title}</Text>
    </BoxFill>
  )
}

export default InputCheckbox
