import React from 'react'

import { IUser } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import { LinkedIn } from 'components/Icons'

import { BoxFill } from 'styles'

type Props = {
  form: IUser & { pass: string }
  errors: IUser & { pass: string }
  onChangeField: (e: InputEvent) => void
}

const Step1: React.FC<Props> = ({ form, onChangeField, errors }) => (
  <BoxFill column>
    <BoxFill mb="20px">
      <Input value={form.firstName} error={errors.firstName} name="firstName" onChange={onChangeField} label="First name" maxLength={50} />
    </BoxFill>
    <BoxFill mb="20px">
      <Input value={form.lastName} error={errors.lastName} name="lastName" onChange={onChangeField} label="Last name" maxLength={50} />
    </BoxFill>
    <BoxFill mb="20px">
      <Input
        value={form.linkedIn}
        error={errors.linkedIn}
        onChange={onChangeField}
        name="linkedIn"
        leftElement={<LinkedIn isActive={form.linkedIn} />}
        pl="55px"
        placeholder="Enter the URL"
        label="LinkedIn profile"
      />
    </BoxFill>
    <BoxFill mb="20px">
      <Input value={form.email} error={errors.email} name="email" onChange={onChangeField} label="Email address" maxLength={70} />
    </BoxFill>
    <BoxFill mb="20px">
      <Input
        value={form.pass}
        error={errors.pass}
        name="pass"
        onChange={onChangeField}
        type="password"
        label="Confirm password (if changing email)"
      />
    </BoxFill>
    <BoxFill mb="20px">
      <Input value={form.phone} error={errors.phone} name="phone" onChange={onChangeField} type="tel" label="Phone number" />
    </BoxFill>
  </BoxFill>
)

export default Step1
