import React from 'react'

import { uploadPhotoOfCandidate } from 'api/candidates'

import { ICandidate } from 'types/models'

import ImageUploadBlock from 'components/UploadInput/imageUploadNew'

/* TODO:! Set api for employer, now this just for no errors */
type Props = {
  form: ICandidate
  onUpdate: any
}

const Five: React.FC<Props> = ({ form, onUpdate }) => {
  if (!form) return null
  return <ImageUploadBlock id={form.id} onUpdate={onUpdate} updateButtonText="FINISH" paramsForUploading={uploadPhotoOfCandidate} />
}

export default Five
