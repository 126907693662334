import React from 'react'

import { numberWithCommas } from 'utils'
import { Text } from 'styles'
import theme from 'styles/theme'
import { Top, Left, Bottom, Balance } from './styles'

type Props = {
  total: number
}

const money = false
const Withdraw: React.FC<Props> = ({ total }) => (
  <div>
    <Top>
      <Left>
        <Text color={theme.main.colors.grey3} m left>
          Account balance
        </Text>
        <Balance xl left>
          ${numberWithCommas(total || 0)}
        </Balance>
      </Left>
    </Top>
    {money ? (
      <Bottom>
        <Text s left>
          Money is currently based on RL bank account. You can withdraw it to your XXX-XXX-451 bank account.
        </Text>
      </Bottom>
    ) : null}
  </div>
)

export default Withdraw
