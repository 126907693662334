import { fromJS, Map, List } from 'immutable'

import {
  EmployersTypes,
  FETCH_EMPLOYER_STARTED,
  FETCH_EMPLOYER_SUCCESS,
  FETCH_EMPLOYER_FAIL,
  FETCH_JOB_POSITION,
  FETCH_JOB_POSITION_SUCCESS,
  FETCH_JOB_POSITION_FAIL,
  FETCH_NEXT_PAGE_JOB_POSITION_STARTED,
  RESET_STORE,
  DELETE_JOB_POSITION_SUCCESS,
  CREATE_EMPLOYER_SUCCESS,
  CREATE_JOB_SUCCESS,
  SET_STATUS_JOB_POSITION_SUCCESS,
  DELETE_EMPLOYER_CONTACT_SUCCESS,
  CREATE_EMPLOYER_CONTACT_SUCCESS,
  // CREATE_EMPLOYER_CONTACT_FAIL,
  JOB_POSITION__CHANGE_NOTES_COUNT,
  UPDATE_EMLOYER_STARTED,
  UPDATE_EMLOYER_SUCCESS,
  UPDATE_EMPLOYER_CONTACT_STARTED,
  UPDATE_EMPLOYER_CONTACT_SUCCESS,
  UPDATE_JOB_POSITION_STARTED,
  UPDATE_JOB_POSITION_SUCCESS,
  CLEAR_NOTIFICATION,
  GET_ALL_LIVE_JOBS,
  GET_ALL_LIVE_JOBS_SUCCESS,
  GET_ALL_LIVE_JOBS_FAIL,
} from 'store/employers/actions'

import {
  CandidatesTypes,
  FETCH_CANDIDATE_SUCCESS,
  FETCH_CANDIDATE_STARTED,
  FETCH_CANDIDATE_FAIL,
  MATCH_CANDIDATE_SUCCESS,
  UPDATE_CANDIDATE_STARTED,
  UPDATE_CANDIDATE_SUCCESS,
  CREATE_WORK_PLACE_SUCCESS,
  UPDATE_WORK_PLACE_SUCCESS,
  DELETE_WORK_PLACE_SUCCESS,
  UPLOAD_LICENSE_SUCCESS,
  UPLOAD_PUBLIC_LICENSE_SUCCESS,
  UPDATE_CV_SUCCESS,
  UPDATE_PUBLIC_CV_SUCCESS,
  CREATE_CANDIDATE_SUCCESS,
  CREATE_PUBLIC_CANDIDATE_SUCCESS,
  CREATE_PUBLIC_CANDIDATEWITHOUT_ID_SUCCESS,
  ON_SET_STATUS_CANDIDATE_SUCCESS,
  CLEAR_NOTIFICATION_CANDIDATE,
} from 'store/candidates/actions'

import { ICurrentInnerPage } from 'types/reducerStates'
import { IJobPosition } from 'types/models'

import { convertArrayToMapByIdAsKey } from 'utils'

const setInInner = (path: string[]) => ['innerItem', ...path]
export const getInInner = (path: string[]) => ['innerItem', ...path]

const initialState: ICurrentInnerPage = fromJS({
  isFetching: true,
  item: null,
  contacts: List([]),
  innerItem: {
    isFetching: true,
    item: null,
    query: null,
    pagesQty: null,
    potentialCandidatesQty: null,
    potentialCandidates: Map(),
    matchedCandidates: Map(),
  },
  workPlaces: Map(),
  potentialJobPositions: Map(),
  potentialJobPositionsQty: null,
  matchedPositions: Map(),
  positions: Map(),
  positionsQty: null,
  licenseFiles: Map(),
  CVFiles: Map(),
  pagesQty: null,
  query: null,
  error: null,
  isUpdateData: false,
  liveJobsAllPositions: null,
})

export default (state = initialState, action: EmployersTypes | CandidatesTypes): ICurrentInnerPage => {
  const item = state.get('item')
  const workPlaces = state.get('workPlaces')
  const positions: Map<any, any> = state.get('positions')
  const matchedPositions = state.get('matchedPositions')
  const potentialJobPositions = state.get('potentialJobPositions')
  const matchedCandidates = state.getIn(getInInner(['matchedCandidates']))
  const potentialCandidates = state.getIn(getInInner(['potentialCandidates']))
  let potentialCandidatesQty = state.getIn(getInInner(['potentialCandidatesQty']))

  switch (action.type) {
    case FETCH_CANDIDATE_STARTED:
    case FETCH_EMPLOYER_STARTED:
      return state.set('isFetching', true)
    case FETCH_EMPLOYER_SUCCESS:
      return (
        state
          .set('isFetching', false)
          .set('item', action.payload.employer)
          // .set('matched', action.payload.employer.)
          // .set('positions', state.get('positions').concat(Map(convertArrayToMapByIdAsKey(action.payload.jobPositions)))) // previous work version
          .set('positions', Map(convertArrayToMapByIdAsKey(action.payload.jobPositions)))
          .set('liveJobsAllPositions', action.payload.jobPositions)
          .set('positionsQty', action.payload.jobPositionsQty)
          .set('pagesQty', action.payload.pagesQty)
          .set('query', action.payload.query)
          .set('error', null)
      )
    case FETCH_EMPLOYER_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case JOB_POSITION__CHANGE_NOTES_COUNT:
      return state.set(
        'positions',
        Map(
          convertArrayToMapByIdAsKey(
            state.get('positions').map((elem: IJobPosition) => {
              if (elem.id === action.payload.id) {
                elem.notesCount += action.payload.increase
              }
              return elem
            })
          )
        )
      )
    case FETCH_CANDIDATE_SUCCESS:
      return state
        .set('isFetching', false)
        .set('item', action.payload.candidate)
        .set('matchedPositions', Map(convertArrayToMapByIdAsKey(action.payload.candidate.JobPositions)))
        .set('workPlaces', Map(convertArrayToMapByIdAsKey(action.payload.candidate.WorkPlaces)))
        .set('licenseFiles', Map(convertArrayToMapByIdAsKey(action.payload.candidate.licenseFiles)))
        .set(
          'potentialJobPositions',
          state.get('potentialJobPositions').concat(Map(convertArrayToMapByIdAsKey(action.payload.potentialJobPositions)))
        )
        .set('potentialJobPositionsQty', action.payload.potentialJobPositionsQty)
        .set('pagesQty', action.payload.pagesQty)
        .set('query', action.payload.query)
        .set('error', null)
    case CREATE_CANDIDATE_SUCCESS:
      return state.set('item', action.payload)
    case CREATE_PUBLIC_CANDIDATE_SUCCESS:
      return state.set('item', action.payload)
    case CREATE_PUBLIC_CANDIDATEWITHOUT_ID_SUCCESS:
      return state.set('item', action.payload)
    case CREATE_EMPLOYER_SUCCESS:
      return state.set('item', action.payload)
    case CREATE_JOB_SUCCESS:
      return state.set('positions', positions.set(action.payload.id, action.payload))
    case SET_STATUS_JOB_POSITION_SUCCESS:
      return state.setIn(setInInner(['item']), action.payload)
    case ON_SET_STATUS_CANDIDATE_SUCCESS:
      return state.set('item', { ...item, ...action.payload })
    case MATCH_CANDIDATE_SUCCESS:
      return state
        .set('matchedPositions', matchedPositions.set(action.payload.JobPosition.id, action.payload.JobPosition))
        .set('potentialJobPositions', potentialJobPositions.delete(action.payload.JobPosition.id))
        .set('positions', positions.set(action.payload.JobPosition.id, action.payload.JobPosition))
        .setIn(setInInner(['matchedCandidates']), matchedCandidates.set(action.payload.Candidate.id, action.payload.Candidate))
        .setIn(setInInner(['potentialCandidates']), potentialCandidates.delete(action.payload.Candidate.id))
        .setIn(setInInner(['potentialCandidatesQty']), --potentialCandidatesQty)
    case UPLOAD_LICENSE_SUCCESS:
      return state.set('licenseFiles', Map(convertArrayToMapByIdAsKey(action.payload.licenseFiles)))
    case UPLOAD_PUBLIC_LICENSE_SUCCESS:
      return state.set('licenseFiles', Map(convertArrayToMapByIdAsKey(action.payload.licenseFiles)))
    case UPDATE_CV_SUCCESS:
      return state.set('item', action.payload)
    case UPDATE_PUBLIC_CV_SUCCESS:
      return state.set('item', action.payload)
    case UPDATE_CANDIDATE_STARTED:
      return state.set('isFetching', true)
    case UPDATE_CANDIDATE_SUCCESS:
      return state
        .set('item', action.payload)
        .set('isUpdateData', true)
        .set('isFetching', false)
    case CREATE_WORK_PLACE_SUCCESS:
      return state.set('workPlaces', workPlaces.set(action.payload.id, action.payload))
    case UPDATE_WORK_PLACE_SUCCESS:
      const updated = workPlaces.update((wp: any) => wp.set(action.payload.id, action.payload.workPlace))
      return state.set('workPlaces', updated)
    case DELETE_WORK_PLACE_SUCCESS:
      return state.set('workPlaces', workPlaces.delete(action.payload))
    case FETCH_JOB_POSITION:
      return state.setIn(setInInner(['isFetching']), true)
    case FETCH_JOB_POSITION_SUCCESS:
      Map(convertArrayToMapByIdAsKey(action.payload.jobPosition.Candidates))
      return state
        .setIn(setInInner(['isFetching']), false)
        .setIn(setInInner(['item']), action.payload.jobPosition)
        .setIn(setInInner(['matchedCandidates']), Map(convertArrayToMapByIdAsKey(action.payload.jobPosition.Candidates)))
        .setIn(setInInner(['potentialCandidates']), Map(convertArrayToMapByIdAsKey(action.payload.potentialCandidates)))
        .setIn(setInInner(['potentialCandidatesQty']), action.payload.potentialCandidatesQty)
        .setIn(setInInner(['pagesQty']), action.payload.pagesQty)
        .setIn(setInInner(['query']), action.payload.query)
    case FETCH_JOB_POSITION_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case FETCH_NEXT_PAGE_JOB_POSITION_STARTED:
      return state
        .setIn(setInInner(['isFetching']), false)
        .setIn(
          setInInner(['potentialCandidates']),
          state.getIn(getInInner(['potentialCandidates'])).concat(Map(convertArrayToMapByIdAsKey(action.payload.potentialCandidates)))
        )
        .setIn(setInInner(['potentialCandidatesQty']), action.payload.potentialCandidatesQty)
        .setIn(setInInner(['pagesQty']), action.payload.pagesQty)
        .setIn(setInInner(['query']), action.payload.query)
    case DELETE_JOB_POSITION_SUCCESS:
      return state.set('positions', positions.delete(action.payload))
    case FETCH_CANDIDATE_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case RESET_STORE:
      return initialState
    case DELETE_EMPLOYER_CONTACT_SUCCESS:
      if (state.getIn(['item', 'EmployerContacts'])) {
        return state.setIn(
          ['item', 'EmployerContacts'],
          state.getIn(['item', 'EmployerContacts']).filter((contact: any) => contact.id !== action.payload)
        )
      }
      return state
    case CREATE_EMPLOYER_CONTACT_SUCCESS:
      if (state.getIn(['item', 'EmployerContacts'])) {
        return state.setIn(['item', 'EmployerContacts'], state.getIn(['item', 'EmployerContacts']).concat(action.payload))
      }
      return state
    // case CREATE_EMPLOYER_CONTACT_FAIL:
    // return state.set('errors', action.payload)
    case UPDATE_EMLOYER_STARTED:
      return state.set('isFetching', true)
    case UPDATE_EMLOYER_SUCCESS:
      return state
        .set('item', action.payload)
        .set('isUpdateData', true)
        .set('isFetching', false)
    case UPDATE_EMPLOYER_CONTACT_STARTED:
      return state.set('isFetching', true).setIn(setInInner(['isFetching']), true)
    case UPDATE_EMPLOYER_CONTACT_SUCCESS:
      return state
        .set('isUpdateData', true)
        .set('isFetching', false)
        .setIn(setInInner(['isUpdateData']), true)
        .setIn(setInInner(['isFetching']), false)
    case UPDATE_JOB_POSITION_STARTED:
      return state.setIn(setInInner(['isFetching']), true)
    case UPDATE_JOB_POSITION_SUCCESS:
      return state
        .setIn(setInInner(['isUpdateData']), true)
        .setIn(setInInner(['isFetching']), false)
        .setIn(setInInner(['item']), action.payload.job)
    case CLEAR_NOTIFICATION:
      return state.set('isUpdateData', false).setIn(setInInner(['isUpdateData']), false)
    case CLEAR_NOTIFICATION_CANDIDATE:
      return state.set('isUpdateData', false)
    case GET_ALL_LIVE_JOBS:
      return state.set('isFetching', true).set('liveJobsAllPositions', null)
    case GET_ALL_LIVE_JOBS_SUCCESS:
      return state.set('isFetching', false)
    case GET_ALL_LIVE_JOBS_FAIL:
      return state.set('isFetching', false)
    default:
      return state
  }
}
