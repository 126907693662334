import React from 'react'

import { NoTaskWrap, NoTaskTitle, NoTaskSubTitle } from '../styles'

type Props = {
  done?: boolean
}

export const NoTask: React.FC<Props> = ({ done }) => (
  <NoTaskWrap>
    <img src={require('assets/images/no-tasks.png')} alt="no-tasks" />
    <NoTaskTitle>Your tasks will be here</NoTaskTitle>
    <NoTaskSubTitle>{done ? 'Tasks that were done will be moved here' : 'Start by adding your new task'}</NoTaskSubTitle>
  </NoTaskWrap>
)
