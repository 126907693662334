import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'

import { AppState } from 'store/rootReducer'
import MessengerActions from 'store/messenger/actions'
import EmployersActions from 'store/employers/actions'

import Input from 'components/Input'
import Loader from 'components/Loader'
import PrimaryButton from 'components/Button'
import { Subtitle, Title, ErrorMessage } from '../components/styles'

import theme from 'styles/theme'
import { Box, BoxFill, BoxCol, Label } from 'styles'

type Props = {
  changeInputValue: typeof MessengerActions.changeInputValue
  sendInterview: typeof MessengerActions.sendInterview
  employers: any
  positions: any
  jobPositions: any
  fetchJobsByEmployerId: typeof EmployersActions.fetchJobsByEmployerId
  messageIsFetching: boolean
}

const InterviewModal: React.FC<Props> = ({
  changeInputValue,
  sendInterview,
  employers,
  positions,
  fetchJobsByEmployerId,
  jobPositions,
  messageIsFetching,
}) => {
  const [interviewDate, setInterviewDate] = useState('')
  const [interviewTime, setInterviewTime] = useState('')
  const [errorDate, setErrorDate] = useState('')
  const [companies, setCompanies] = useState<any>([])
  const [positionsArr, setPositionsArr] = useState<any>([])
  const [employerId, setEmployerId] = useState<any>(null)
  const [jobPositionId, setJobPositionId] = useState<any>(null)
  const [currentJobPosition, setCurrentJobPosition] = useState<any>(null)

  useEffect(() => {
    setCompanies([])
    employers.get('allEmployers') &&
      employers.get('allEmployers').forEach((el: any) => {
        setCompanies((companies: any) => [
          ...companies,
          {
            id: el.id,
            label: el.companyName,
            value: el.companyName,
          },
        ])
      })
  }, [employers])

  useEffect(() => {
    setPositionsArr([])
    jobPositions &&
      jobPositions.forEach((el: any) => {
        setPositionsArr((positionsArr: any) => [
          ...positionsArr,
          {
            id: el.id,
            label: el.Position.name,
            value: el.Position.name,
          },
        ])
      })
  }, [jobPositions])

  const onChangeInput = (e: any) => {
    const { name, value } = e.target
    changeInputValue(name, value)
    if (name === 'interviewDate' && new Date(value).getFullYear() > new Date().getFullYear() + 100) {
      setErrorDate('Date must be less than 100 years')
      setInterviewDate('')
    } else if (name === 'interviewDate' && new Date(value) > new Date()) {
      setInterviewDate(value)
      setErrorDate('')
    } else if (name === 'interviewDate') {
      setErrorDate('Date should be future')
      setInterviewDate('')
    }
    if (name === 'interviewTime') setInterviewTime(value)
  }

  const handleSelectCompany = (item?: any) => {
    setPositionsArr([])
    setCurrentJobPosition(null)
    if (item) {
      fetchJobsByEmployerId(item.id)
      setEmployerId(item.id)
    }
  }

  const handleSelectJobPosition = (item?: any) => {
    if (item) {
      setJobPositionId(item.id)
      setCurrentJobPosition(item)
    }
  }

  const customStyles = {
    container: (styles: any) => ({
      ...styles,
      width: '100%',
    }),
    control: (styles: any) => ({
      ...styles,
      border: '1px solid #E3E8F1',
      boxShadow: 'inset 0px 1px 5px rgba(188,196,208,0.2)',
      height: '60px',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: '#9CAAB8',
      fontWeight: 'normal',
      fontSize: '18px',
      fontFamily: 'Mukta',
    }),
  }

  return (
    <>
      <Title xl3 color={theme.main.colors.grey3}>
        Interview appointment
      </Title>
      <Subtitle m>Choose the date and time to send an interview appintment to the candidate</Subtitle>

      {messageIsFetching ? (
        <Loader width="500px" height="500px" />
      ) : (
        <>
          <BoxFill>
            <BoxCol mb="20px" mr="14px" width="100%">
              <Input onChange={onChangeInput} placeholder="Choose date" name="interviewDate" type="date" label="Date" />
              <ErrorMessage>{errorDate && errorDate}</ErrorMessage>
            </BoxCol>
            <BoxFill mb="20px" width="100%">
              <Input onChange={onChangeInput} placeholder="HH : MM am" name="interviewTime" label="Time" type="time" />
            </BoxFill>
          </BoxFill>

          <BoxFill mb="20px" column>
            <Label htmlFor="state">Company</Label>
            <Select
              styles={customStyles}
              isClearable
              isSearchable
              placeholder="Choose a company"
              components={{ IndicatorSeparator: null }}
              options={companies}
              onChange={(item: any) => handleSelectCompany(item)}
            />
          </BoxFill>

          <BoxFill mb="20px" column>
            <Label htmlFor="state">Job Position</Label>
            <Select
              styles={customStyles}
              isClearable
              isSearchable
              value={currentJobPosition || null}
              placeholder="Choose a job position"
              components={{ IndicatorSeparator: null }}
              options={positionsArr}
              onChange={(item: any) => handleSelectJobPosition(item)}
            />
          </BoxFill>

          <Box justifyCenter width="500px">
            <PrimaryButton
              height="50px"
              width="100px"
              onClick={() => sendInterview(employerId, jobPositionId)}
              title="SEND"
              disabled={!interviewDate || !interviewTime || !employerId || !jobPositionId || !currentJobPosition}
            />
          </Box>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    employers: state.employers,
    positions: state.currentInnerPage.get('positions'),
    jobPositions: state.employers.get('jobPositionsInInterviewAppointment'),
    messageIsFetching: state.messenger.get('messageIsFetching'),
  }
}

export default connect(
  mapStateToProps,
  {
    changeInputValue: MessengerActions.changeInputValue,
    sendInterview: MessengerActions.sendInterview,
    fetchJobsByEmployerId: EmployersActions.fetchJobsByEmployerId,
  }
)(InterviewModal)
