import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import PrimaryButton from 'components/Button'
import { Logo } from 'components/Icons'

import { Box, Image, Text, H1 } from 'styles'

const SuccessRequest: React.FC<RouteComponentProps> = ({ history }) => (
  <Box fixed spaceBetween alignCenter column mainBC width="100vw" height="100vh">
    <Box mt="80px">
      <Logo />
    </Box>
    <Box column alignCenter width="460px">
      <H1 lh="34px" fw="bold">
        Your request successfully <br />
        sent! We’ll get back to you ASAP!
      </H1>
      <Box width="460px" mt="10px">
        <Text>
          Thank you for your request! After our manager check your information, you will receive an email with the referral link. Use it to finish
          your registration in our service
        </Text>
      </Box>
      <Box height="60px" width="20vw" mt="40px">
        <PrimaryButton
          onClick={() => {
            window.location.href = 'https://recruiterslife.com/'
          }}
          title="GO BACK TO THE LANDING PAGE"
        />
      </Box>
    </Box>
    <Box>
      <Image width="100%" height="100%" src={require('assets/images/success-request-image.png')} />
    </Box>
  </Box>
)

export default SuccessRequest
