import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { AppState } from 'store/rootReducer'
import SearchActions from 'store/search/action'
import NotesActions from 'store/notes/actions'
import CandidatesActions from 'store/candidates/actions'
import { InputEvent } from 'types/helps'
import { IList, ICity } from 'types/models'

import Loader from 'components/Loader'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Input from 'components/Input'
import InputSelect from 'components/Input/InputSelect'
import JobPositionCard from 'components/ForCandidates&Employers/jobPositionCard'
import JobPositionInner from '../../LiveJobs/JobPositionInner'
import SavedSearch from '../components/SavedSearch'
import { NothingFound } from '../components/NothingFound'

import { Wrap, Container, Block, ButtonBlock, SaveWrap, SavedSearches, SavedThisSearch, ContainerCard, SearchCount } from './styled'

type Props = {
  onChangeInput: (e: InputEvent, type?: string) => void
  onChangeSelect: (e: ICity[] | IList[], type: string) => void
  searchJobs: typeof SearchActions.searchJobs
  saveJobs: typeof SearchActions.saveJobs
  getSearchList: typeof SearchActions.getSearchList
  clearInputValue: typeof SearchActions.clearInputValue
  setValueFromSavedSearch: typeof SearchActions.setValueFromSavedSearch
  onToggleModal: typeof NotesActions.onToggleModal
  search: any
  isFetching: boolean
}

const Jobs: React.FC<Props & RouteComponentProps> = ({
  history,
  onChangeInput,
  onChangeSelect,
  searchJobs,
  saveJobs,
  getSearchList,
  search,
  onToggleModal,
  clearInputValue,
  setValueFromSavedSearch,
  isFetching,
}) => {
  const [currentPositionInnerId, setCurrentPositionInnerId] = useState<boolean | number | string>(false)
  const [currentEmployerId, setCurrentEmployerId] = useState<boolean | number>(false)
  const onChangeCity = (e: ICity[]) => onChangeSelect(e, 'jobsCity')
  const onChangeStatus = (e: IList[]) => onChangeSelect(e, 'jobsStatus')
  const onChangeProjectTypes = (e: IList[]) => onChangeSelect(e, 'jobsProjectTypes')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // const params = new URLSearchParams(location.search)
    // const page = Number(params.get('page')) || 1
    // console.log('this.props.match.params.id');
    searchJobs()
    getSearchList('jobs')
  }, [])

  const handleSaveJobs = () => saveJobs()

  const setJobPositionInnerId = (id: number) => setCurrentPositionInnerId(id)
  const setEmployerId = (id: number) => setCurrentEmployerId(id)
  const addCandidateRoute = () => history.push('/candidates/add')
  const editJobPositionRoute = () => history.push(`/live-jobs/employer/${currentEmployerId}/${currentPositionInnerId}/edit`)
  const hideJobPositionInnerModal = () => {
    setCurrentPositionInnerId(false)
  }

  const handleSaveSearches = () => setIsOpen(true)
  const closeSaveSearches = () => setIsOpen(false)

  const apply = (el: any) => {
    if (el.jobTitle) setValueFromSavedSearch('jobsJobTitle', el.jobTitle)
    if (el.companyName) setValueFromSavedSearch('jobsCompanyName', el.companyName)
    if (el.cityIds) setValueFromSavedSearch('jobsCity', el.cityIds)
    if (el.status) setValueFromSavedSearch('jobsStatus', el.status)
    if (el.sectorIds) setValueFromSavedSearch('jobsProjectTypes', el.sectorIds)
    searchJobs()
    clearInputValue()
    closeSaveSearches()
  }

  const sortByPositionName = (a: any, b: any) => {
    const nameA = a.Position.name.toUpperCase()
    const nameB = b.Position.name.toUpperCase()

    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }

  const renderJobs = () =>
    search
      .get('searchJobs')
      .sort(sortByPositionName)
      .map((data: any) => (
        <JobPositionCard
          key={data.id}
          jobPosition={data}
          setCurrentJobPositionId={setJobPositionInnerId}
          toggleNoteModal={onToggleModal}
          searchCard
          setEmployerId={setEmployerId}
        />
      ))

  const searchCount = search.get('searchJobs')

  return (
    <Container>
      <Wrap>
        <Block minWidth={154}>
          <Input type="text" height={50} placeholder="Job title or keywords" noBorder br onChange={onChangeInput} name="jobsJobTitle" />
        </Block>
        <Block minWidth={142}>
          <Input type="text" height={50} placeholder="Company" noBorder br onChange={onChangeInput} name="jobsCompanyName" />
        </Block>
        <Block width={183} minWidth={183}>
          <InputSelect placeholder="Location" optionsType="cities" isMulti isSearchComponent isCustom onChange={onChangeCity} />
        </Block>
        <Block width={203} minWidth={203}>
          <InputSelect placeholder="Status" optionsType="status" isSearchComponent closedMenu onChange={onChangeStatus} />
        </Block>
        <Block minWidth={213}>
          <InputSelect placeholder="Project type" optionsType="projectTypes" isMulti isSearchComponent isCustom onChange={onChangeProjectTypes} />
        </Block>
        <ButtonBlock>
          <Button width="138px" height="50px" title="Search" type="secondary" onClick={() => searchJobs()} />
        </ButtonBlock>
      </Wrap>
      <SaveWrap>
        <SavedSearches>
          <span onClick={handleSaveSearches}>Saved searches ({search.get('jobs').total})</span>
          {searchCount && searchCount.length > 0 && (
            <SearchCount left s opacity="0.6">{`${
              searchCount.length > 1 ? `${searchCount.length} jobs` : `${searchCount.length} job`
            } in total`}</SearchCount>
          )}
          <SavedSearch isOpen={isOpen} closeSaveSearches={closeSaveSearches} docs={search.get('jobs').docs} apply={apply} />
        </SavedSearches>
        <SavedThisSearch secondColor onClick={() => handleSaveJobs()}>
          Save this search
        </SavedThisSearch>
      </SaveWrap>
      {isFetching ? (
        <Loader height="50vh" withoutBg />
      ) : (
        <>{!search.get('searchJobs').length ? <NothingFound /> : <ContainerCard noMargin>{renderJobs()}</ContainerCard>}</>
      )}
      <Modal isOpen={currentPositionInnerId} onRequestClose={hideJobPositionInnerModal}>
        <JobPositionInner
          id={currentPositionInnerId}
          addCandidateRoute={addCandidateRoute}
          editJobPositionRoute={editJobPositionRoute}
          onClose={hideJobPositionInnerModal}
        />
      </Modal>
    </Container>
  )
}

const mapStateToProps = (state: AppState) => ({
  search: state.search,
  isFetching: state.search.get('isFetching'),
})

export default connect(
  mapStateToProps,
  {
    searchJobs: SearchActions.searchJobs,
    saveJobs: SearchActions.saveJobs,
    getSearchList: SearchActions.getSearchList,
    setStatusCandidate: CandidatesActions.setStatusCandidate,
    onToggleModal: NotesActions.onToggleModal,
    setValueFromSavedSearch: SearchActions.setValueFromSavedSearch,
    clearInputValue: SearchActions.clearInputValue,
  }
)(withRouter(Jobs))
