import { Map } from 'immutable'
import { IMeta } from 'react-dropzone-uploader'

import { IMatchStatuses } from 'types/models'

/**
 * func for format currency like 12000 -> 12,000
 * 160000 -> 160,000; 1200 -> 1,200
 *
 * @param n number to format
 */
export function formatCurrency(n: number) {
  return n && n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function smoothScroll() {
  const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
  if (currentScroll > 0) {
    window.requestAnimationFrame(smoothScroll)
    window.scrollTo(0, currentScroll - currentScroll / 5)
  }
}

/**
 * func for adding http for links
 *
 * @param link string - link
 */
export const getValidUrl = (url = '') => {
  // @ts-ignore
  let newUrl = window.decodeURIComponent(url)
  newUrl = newUrl.trim().replace(/\s/g, '')
  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `http://${newUrl}`
  }
  return newUrl
}

/**
 * func for filtering object paramters
 *
 * @param  obj   {object}  Object for filtering
 * @param  keys  {array}   Needed keys
 * @return       {object}  Filtered object
 */
export function filterByKeys(obj: any, keys: string[]) {
  const filtered: any = {}
  keys.forEach(key => {
    if (obj.hasOwnProperty(key)) {
      filtered![key] = obj[key]
    }
  })
  return filtered
}

/**
 * func for adding commas for number
 */
export function numberWithCommas(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * func for convert bytes
 */
export function bytesToSize(bytes: number | null): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  if (bytes) {
    const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)))
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
  }
  return ''
}

/**
 * func for check statuses of file, return true if one of statuses is match
 *
 * @param file file for check statuses
 * @param statuses array of statuses
 */
export const checkStatuses = (file: IMeta, statuses: string[]) => {
  return statuses.some((status: string) => status === file.status)
}

/**
 * return candidate id that did't complete creating before
 */
export const getDraftCandidateId = () => localStorage.getItem('draftCandidateId')

/**
 * delete draft candidate id creating before
 */
export const deleteDraftCandidateId = () => localStorage.removeItem('draftCandidateId')

/**
 * convert array to Immutable map, use id as key
 */
export const convertArrayToMapByIdAsKey = (arr: any[]): Map<number, any> =>
  arr
    ? arr.reduce((acc: Map<number, any>, item: any) => {
        return acc.set(item.id, item)
      }, Map())
    : Map()

export const getIconOfFileByType = (value: string) => {
  switch (value) {
    case 'application/pdf':
      return require('assets/icons/pdf.svg')
    case 'image/jpeg':
      return require('assets/icons/jpg.svg')
    case 'image/png':
      return require('assets/icons/png.svg')
    case 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return require('assets/icons/doc.svg')
    default:
      return null
  }
}

const STATUSES = {
  1: 'Candidate is matched',
  2: 'Candidate agreed to submital',
  3: 'CV is sent',
  4: 'Employer accepted the candidate',
  5: 'Interview arranged',
  6: 'Placement approved',
  7: 'Invoice downloaded',
}

export const getCandidateStatus = (status: IMatchStatuses) => STATUSES[status]

export const formateDateToYYYYMMDD = (days: number) => {
  const d: number | string = new Date().getTime() + 86400000 * days
  const local = new Date(d).toLocaleDateString().split('.')
  const day = local[0]
  const month = local[1]
  const year = local[2]
  return `${year}-${month}-${day}`
}

export const formateDateToYYYYMMDDByStringDate = (date: string) => {
  return ((new Date(date).getTime() - new Date().getTime()) / 86400000).toFixed()
}

export const formatAMPM = (date: any) => {
  let hours = +date.split(':')[0]
  let minutes: number | string = +date.split(':')[1]
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  return hours + ':' + minutes + ' ' + ampm
}

export const sortByFirstAndLastName = (a: any, b: any) => {
  const firstA = a.firstName.toLowerCase()
  const firstB = b.firstName.toLowerCase()
  if (firstA > firstB) {
    return 1
  } else if (firstA < firstB) {
    return -1
  } else {
    const lastA = a.lastName.toLowerCase()
    const lastB = b.lastName.toLowerCase()
    if (lastA > lastB) {
      return 1
    } else if (lastA < lastB) {
      return -1
    } else {
      return 0
    }
  }
}

export const sortByCreatedAt = (a: any, b: any) => {
  const firstA = new Date(a.createdAt)
  const firstB = new Date(b.createdAt)
  if (firstA < firstB) {
    return 1
  } else if (firstA > firstB) {
    return -1
  } else {
    return 0
  }
}
