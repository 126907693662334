import React from 'react'
import styled from 'styled-components'

import { ExpandArrow } from 'components/Icons'

import { Box, BoxFill, BoxCol, Text, Span, Image } from 'styles'

export const StatusContainer = styled(Box).attrs({})`
  &:hover .statusBox {
    display: block;
  }
  &:hover .arrow-box {
    transform: rotate(180deg);
    svg path {
      fill: #2697ff;
    }
  }
`

export const BoxStatus = styled(BoxFill).attrs({
  alignCenter: true,
  spaceBetween: true,
  padding: '10px 5px',
})``

export const StatusWrapper = styled(BoxCol).attrs({
  absolute: true,
  whiteBC: true,
  smooth: true,
  shadow2: true,
  display: 'none',
  width: '100px',
  top: '25px',
  padding: '10px',
})``

type Props = {
  titles?: string[]
  hired: any
  onActive?: () => void
  onClose?: () => void
}

const StatusDropDown: React.FC<Props> = ({ titles = ['Active', 'Hired'], hired, onActive, onClose }) => (
  <StatusContainer>
    <Box>
      <Text>Status:</Text>
    </Box>
    <StatusWrapper className="statusBox">
      <BoxStatus onClick={onActive}>
        <Text accentColor pointer>
          {titles[0]}
        </Text>
        {!hired && <Image src={require('assets/icons/bCheckbox.svg')} />}
      </BoxStatus>
      <BoxStatus onClick={onClose}>
        <Text red pointer>
          {titles[1]}
        </Text>
        {hired && <Image src={require('assets/icons/bCheckbox.svg')} />}
      </BoxStatus>
    </StatusWrapper>
    <Box>
      <Box ml="3px">
        <Span>{hired ? <Span red>{titles[1]}</Span> : titles[0]}</Span>
      </Box>
      <Box ml="2px">
        <ExpandArrow className="arrow-box" fill="#9CAAB8" />
      </Box>
    </Box>
  </StatusContainer>
)

StatusDropDown.defaultProps = {
  titles: ['Active', 'Hired'],
}

export default StatusDropDown
