import React from 'react'
import styled from 'styled-components'
import Swal from 'sweetalert2'

import EditMenu from 'components/EditMenu'
import Button from 'components/Button'
import { Trash } from 'components/Icons'

import { BoxFill, Box, Text, H2, GreenBox } from 'styles'

const Wrapper = styled(BoxFill).attrs({
  spaceBetween: true,
  alignCenter: true,
  mainBC: true,
  padding: '40px 80px 80px',
})``

const DeleteText = styled(Text).attrs({})`
  &:hover {
    opacity: 0.8;
  }
`

type Props = {
  texts: {
    title: string
    subTitle?: string
    deleteBtnText: string
    viewItemBtnText?: string
    deleteItemBtnText?: string
  }
  steps: string[]
  forms: any
  errors?: any
  customComponent?: any
  currentStep: number
  isUpdated?: boolean | number // todo need remove
  editItemData: any
  onUpdate: any
  onDelete: any
  isShowSaveButton?: boolean
  onChangeField: (e: any, type?: string) => void
  setStep: (id: number) => void
  viewEditingItem?: () => void
  setNewPhoto?: (newPhoto: Blob) => Blob
  isUpdateData?: boolean
  errorAuth?: string
}

/* TODO:! REBUILD COMPOnent, to much params, transfer most of this to this component inside */

const EditFrame: React.FC<Props> = ({
  texts,
  steps,
  forms,
  currentStep,
  errors,
  isUpdated, // todo need remove
  editItemData,
  onUpdate,
  onDelete,
  onChangeField,
  setStep,
  viewEditingItem,
  customComponent,
  setNewPhoto,
  isShowSaveButton,
  isUpdateData,
}) => {
  const Form = forms.default[currentStep]
  const deleteItem = () => {
    Swal.fire({
      title: `Are you sure you want to <br/> delete ${texts.subTitle || ''}?`,
      html: 'All job positions of this client will be deleted <br/> and you won’t be able to undo this.',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonColor: '#F95565',
      confirmButtonText: `DELETE ${texts.title.toUpperCase()}`,
    }).then(result => {
      if (result.value) onDelete()
    })
  }

  return (
    <BoxFill column minHeight="100vh">
      <Wrapper>
        <Box column>
          <Text white fz="42px" fw="bold" lh="46px">
            Edit {texts.title}
          </Text>
          {texts.subTitle && <Text>Editing {texts.subTitle}</Text>}
        </Box>
        <Box allPointer>
          <Box alignCenter>
            <DeleteText onClick={deleteItem} pointer red>
              {texts.deleteBtnText}
            </DeleteText>
            <Box ml="8px">
              <Trash red width="10.43" height="14" />
            </Box>
          </Box>
        </Box>
      </Wrapper>
      <BoxFill padding="0 80px" mt="-50px">
        <BoxFill smooth flex={1} mr="30px" whiteBC>
          <EditMenu
            options={steps}
            currentStep={currentStep}
            btnText={texts.viewItemBtnText}
            viewEditingItem={viewEditingItem}
            changeEditStep={setStep}
          />
        </BoxFill>
        <BoxFill smooth column flex={2.3} whiteBC>
          {customComponent ? (
            customComponent
          ) : (
            <BoxFill column padding="30px 40px">
              <BoxFill mb="20px" spaceBetween>
                <H2 black fw="bold">
                  Edit {steps[currentStep].toLocaleLowerCase()}
                </H2>
              </BoxFill>
              {/* todo need remove */}
              {isUpdated && (
                <BoxFill mb="20px">
                  <GreenBox justifyCenter fill>
                    <Text>Information successfully updated!</Text>
                  </GreenBox>
                </BoxFill>
              )}
              {isUpdateData && (
                <BoxFill mb="20px">
                  <GreenBox justifyCenter fill>
                    <Text>Information successfully updated!</Text>
                  </GreenBox>
                </BoxFill>
              )}
              <Form form={editItemData} errors={errors} onChangeField={onChangeField} setNewPhoto={setNewPhoto} />
            </BoxFill>
          )}
          {isShowSaveButton && (
            <BoxFill btDefault justifyRight padding="20px 40px">
              <Box width="172px" height="60px">
                <Button onClick={onUpdate} title="SAVE CHANGES" />
              </Box>
            </BoxFill>
          )}
        </BoxFill>
      </BoxFill>
    </BoxFill>
  )
}

EditFrame.defaultProps = {
  isShowSaveButton: true,
}

export default EditFrame
