import React from 'react'
import styled from 'styled-components'

import { Box, BoxFill, BoxCol, Text, Span, Bold } from 'styles'

export const FormHeader: React.FC<{ title: string }> = ({ title }) => (
  <Box mb="10px">
    <Bold black l>
      {title}
    </Bold>
  </Box>
)

export const FormRow: React.FC<{ title: string; value: string }> = ({ title, value }) => (
  <InfoBox>
    <TextNoWrap>{title}</TextNoWrap>
    <TextEllipsisContainer>
      <Text right black>
        {value}
      </Text>
    </TextEllipsisContainer>
  </InfoBox>
)

export const BG = styled(BoxFill).attrs({
  mainBC: true,
  absolute: true,
  height: '190px',
  zIndex: '1',
})``

export const Wrapper = styled(BoxFill).attrs({
  mt: '20px',
  padding: '0 80px',
  zIndex: '2',
})``

export const EmployerInfoBox = styled(BoxCol).attrs({
  maxWidth: '30vw',
  padding: '20px',
  alignCenter: true,
  relative: true,
  smooth: true,
  whiteBC: true,
  flex: 1,
  mr: '30px',
})``

export const CompanyInfo = styled(BoxCol).attrs(props => ({
  padding: '20px 0',
  mt: '20px',
  bb: `1px solid ${props.theme.main.borderInputColor}`,
  bt: `1px solid ${props.theme.main.borderInputColor}`,
}))``

export const InfoBox = styled(BoxFill).attrs({
  spaceBetween: true,
  mb: '8px',
  overflow: 'hidden',
})``

export const TextNoWrap = styled(Text).attrs({
  whiteSpace: true,
})``

export const TextEllipsisContainer = styled(Box).attrs({
  ml: '5px',
  overflow: 'hidden',
})``

export const TextEllipsis = styled(Text).attrs({
  textOverflow: true,
  black: true,
  whiteSpace: true,
  overflow: 'hidden',
})``

export const SpanEllipsis = styled(Span).attrs({
  textOverflow: true,
  overflow: 'hidden',
  whiteSpace: true,
})`
  &:hover {
    opacity: 0.8;
  }
`

export const AddNotes = styled(Text)`
  text-transform: uppercase;
  &:hover {
    opacity: 0.8;
  }
`
