import { createAction, ActionsUnion, IQueryParams } from 'types/helps'
import { IDataEmployersLiveJobs, IDataEmployerWithPagedJobPositions, IDataJobPositionWithPagedPotentialCandidates } from 'types/responseData'
import { IJobPosition, IEmployer, IEmployerContact } from 'types/models'
import { IError, INotValidFieldsErr } from 'types/errors'

export const FETCH_ALL_EMPLOYERS_STARTED = 'FETCH_ALL_EMPLOYERS_STARTED'
export const FETCH_ALL_EMPLOYERS_SUCCESS = 'FETCH_ALL_EMPLOYERS_SUCCESS'
export const FETCH_ALL_EMPLOYERS_FAIL = 'FETCH_ALL_EMPLOYERS_FAIL'

export const FETCH_EMPLOYER_STARTED = 'FETCH_EMPLOYER_STARTED'
export const FETCH_EMPLOYER_SUCCESS = 'FETCH_EMPLOYER_SUCCESS'
export const FETCH_EMPLOYER_FAIL = 'FETCH_EMPLOYER_FAIL'

export const CREATE_EMPLOYER_STARTED = 'CREATE_EMPLOYER_STARTED'
export const CREATE_EMPLOYER_SUCCESS = 'CREATE_EMPLOYER_SUCCESS'
export const CREATE_EMPLOYER_FAIL = 'CREATE_EMPLOYER_FAIL'

export const CREATE_EMPLOYER_CONTACT_STARTED = 'CREATE_EMPLOYER_CONTACT_STARTED'
export const CREATE_EMPLOYER_CONTACT_SUCCESS = 'CREATE_EMPLOYER_CONTACT_SUCCESS'
export const CREATE_EMPLOYER_CONTACT_FAIL = 'CREATE_EMPLOYER_CONTACT_FAIL'

export const UPDATE_EMPLOYER_CONTACT_STARTED = 'UPDATE_EMPLOYER_CONTACT_STARTED'
export const UPDATE_EMPLOYER_CONTACT_SUCCESS = 'UPDATE_EMPLOYER_CONTACT_SUCCESS'
export const UPDATE_EMPLOYER_CONTACT_FAIL = 'UPDATE_EMPLOYER_CONTACT_FAIL'

export const DELETE_EMPLOYER_CONTACT_STARTED = 'DELETE_EMPLOYER_CONTACT_STARTED'
export const DELETE_EMPLOYER_CONTACT_SUCCESS = 'DELETE_EMPLOYER_CONTACT_SUCCESS'
export const DELETE_EMPLOYER_CONTACT_FAIL = 'DELETE_EMPLOYER_CONTACT_FAIL'

export const CREATE_EMPLOYER_AND_EMPLOYER_CONTACT_STARTED = 'CREATE_EMPLOYER_AND_EMPLOYER_CONTACT_STARTED'

export const UPDATE_EMLOYER_STARTED = 'UPDATE_EMLOYER_STARTED'
export const UPDATE_EMLOYER_SUCCESS = 'UPDATE_EMLOYER_SUCCESS'
export const UPDATE_EMLOYER_FAIL = 'UPDATE_EMLOYER_FAIL'

export const DELETE_EMLOYER_STARTED = 'DELETE_EMLOYER_STARTED'
export const DELETE_EMLOYER_SUCCESS = 'DELETE_EMLOYER_SUCCESS'
export const DELETE_EMLOYER_FAIL = 'DELETE_EMLOYER_FAIL'

export const CREATE_JOB_STARTED = 'CREATE_JOB_STARTED'
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS'
export const CREATE_JOB_FAIL = 'CREATE_JOB_FAIL'

export const FETCH_JOB_POSITION = 'FETCH_JOB_POSITION'
export const FETCH_NEXT_PAGE_JOB_POSITION_STARTED = 'FETCH_NEXT_PAGE_JOB_POSITION_STARTED'
export const FETCH_JOB_POSITION_SUCCESS = 'FETCH_JOB_POSITION_SUCCESS'
export const FETCH_JOB_POSITION_FAIL = 'FETCH_JOB_POSITION_FAIL'

export const SET_STATUS_JOB_POSITION = 'SET_STATUS_JOB_POSITION'
export const SET_STATUS_JOB_POSITION_SUCCESS = 'SET_STATUS_JOB_POSITION_SUCCESS'
export const SET_STATUS_JOB_POSITION_FAIL = 'SET_STATUS_JOB_POSITION_FAIL'

export const UPDATE_JOB_POSITION_STARTED = 'UPDATE_JOB_POSITION_STARTED'
export const UPDATE_JOB_POSITION_SUCCESS = 'UPDATE_JOB_POSITION_SUCCESS'
export const UPDATE_JOB_POSITION_FAIL = 'UPDATE_JOB_POSITION_FAIL'

export const DELETE_JOB_POSITION_STARTED = 'DELETE_JOB_POSITION_STARTED'
export const DELETE_JOB_POSITION_SUCCESS = 'DELETE_JOB_POSITION_SUCCESS'
export const DELETE_JOB_POSITION_FAIL = 'DELETE_JOB_POSITION_FAIL'

export const RESET_STORE = 'RESET_STORE'

export const EMPLOYER__CHANGE_NOTES_COUNT = 'EMPLOYER__CHANGE_NOTES_COUNT'

export const JOB_POSITION__CHANGE_NOTES_COUNT = 'JOB_POSITION__CHANGE_NOTES_COUNT'

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

export const FETCH_JOBS_BY_EMPLOYER_ID = 'FETCH_JOBS_BY_EMPLOYER_ID'
export const FETCH_JOBS_BY_EMPLOYER_ID_SUCCESS = 'FETCH_JOBS_BY_EMPLOYER_ID_SUCCESS'
export const FETCH_JOBS_BY_EMPLOYER_ID_FAIL = 'FETCH_JOBS_BY_EMPLOYER_ID_FAIL'

export const GET_ALL_LIVE_JOBS = 'GET_ALL_LIVE_JOBS'
export const GET_ALL_LIVE_JOBS_SUCCESS = 'GET_ALL_LIVE_JOBS_SUCCESS'
export const GET_ALL_LIVE_JOBS_FAIL = 'GET_ALL_LIVE_JOBS_FAIL'

export const GET_PUBLIC_EMPLOYERS_CONTACTS = 'GET_PUBLIC_EMPLOYERS_CONTACTS'
export const GET_PUBLIC_EMPLOYERS_CONTACTS_SUCCESS = 'GET_PUBLIC_EMPLOYERS_CONTACTS_SUCCESS'
export const GET_PUBLIC_EMPLOYERS_CONTACTS_FAIL = 'GET_PUBLIC_EMPLOYERS_CONTACTS_FAIL'

export const ADD_PUBLIC_JOB_POSITION = 'ADD_PUBLIC_JOB_POSITION'
export const ADD_PUBLIC_JOB_POSITION_SUCCESS = 'ADD_PUBLIC_JOB_POSITION_SUCCESS'
export const ADD_PUBLIC_JOB_POSITION_FAIL = 'ADD_PUBLIC_JOB_POSITION_FAIL'

export const ADD_PUBLIC_JOB_POSITION_WITHOUT_ID = 'ADD_PUBLIC_JOB_POSITION_WITHOUT_ID'
export const ADD_PUBLIC_JOB_POSITION_WITHOUT_ID_SUCCESS = 'ADD_PUBLIC_JOB_POSITION_WITHOUT_ID_SUCCESS'
export const ADD_PUBLIC_JOB_POSITION_WITHOUT_ID_FAIL = 'ADD_PUBLIC_JOB_POSITION_WITHOUT_ID_FAIL'

export type EmployersTypes = ActionsUnion<typeof EmployersActions>

const EmployersActions = {
  fetchEmployers: (query: IQueryParams) => createAction(FETCH_ALL_EMPLOYERS_STARTED, query),
  fetchEmployersSuccess: (data: IDataEmployersLiveJobs) => createAction(FETCH_ALL_EMPLOYERS_SUCCESS, data),
  fetchEmployersFail: (error: IError) => createAction(FETCH_ALL_EMPLOYERS_FAIL, error),

  fetchEmployer: (employerId: number, page?: number, perPage?: number) => createAction(FETCH_EMPLOYER_STARTED, { employerId, page, perPage }),
  fetchEmployerSuccess: (data: IDataEmployerWithPagedJobPositions) => createAction(FETCH_EMPLOYER_SUCCESS, data),
  fetchEmployerFail: (error: IError) => createAction(FETCH_EMPLOYER_FAIL, error),

  createEmployer: (employer: Partial<IEmployer>) => createAction(CREATE_EMPLOYER_STARTED, employer),
  createEmployerSuccess: (employer: IEmployer) => createAction(CREATE_EMPLOYER_SUCCESS, employer),
  createEmployerFail: (error: INotValidFieldsErr) => createAction(CREATE_EMPLOYER_FAIL, error),

  createEmployerContact: (employerId: number, contact: Partial<IEmployerContact>) =>
    createAction(CREATE_EMPLOYER_CONTACT_STARTED, { employerId, contact }),
  createEmployerContactSuccess: (contact: IEmployerContact) => createAction(CREATE_EMPLOYER_CONTACT_SUCCESS, contact),
  createEmployerContactFail: (error: INotValidFieldsErr) => createAction(CREATE_EMPLOYER_CONTACT_FAIL, error),

  updateEmployerContact: (contactId: number, contact: Partial<IEmployerContact>) =>
    createAction(UPDATE_EMPLOYER_CONTACT_STARTED, { contactId, contact }),
  updateEmployerContactSuccess: () => createAction(UPDATE_EMPLOYER_CONTACT_SUCCESS),

  deleteEmployerContact: (contactId: number) => createAction(DELETE_EMPLOYER_CONTACT_STARTED, contactId),
  deleteEmployerContactSuccess: (contactId: number) => createAction(DELETE_EMPLOYER_CONTACT_SUCCESS, contactId),
  deleteEmployerContactFail: (error: any) => createAction(DELETE_EMPLOYER_CONTACT_FAIL, error),

  createEmployerAndContact: (employer: Partial<IEmployer>, contact: Partial<IEmployerContact>) =>
    createAction(CREATE_EMPLOYER_AND_EMPLOYER_CONTACT_STARTED, { employer, contact }),

  updateEmployer: (id: number, employer: Partial<IEmployer>) => createAction(UPDATE_EMLOYER_STARTED, { id, employer }),
  updateEmployerSuccess: (data: any) => createAction(UPDATE_EMLOYER_SUCCESS, data),
  updateEmployerFail: (error: any) => createAction(UPDATE_EMLOYER_FAIL, error),

  deleteEmployer: (id: number) => createAction(DELETE_EMLOYER_STARTED, id),
  deleteEmployerSuccess: () => createAction(DELETE_EMLOYER_SUCCESS),
  deleteEmployerFail: (error: any) => createAction(DELETE_EMLOYER_FAIL, error),

  createJob: (employerId: number, job: Partial<IJobPosition>, contact?: undefined | Partial<IEmployerContact>) =>
    createAction(CREATE_JOB_STARTED, { employerId, job, contact }),
  createJobSuccess: (job: IJobPosition) => createAction(CREATE_JOB_SUCCESS, job),
  createJobFail: (error: INotValidFieldsErr) => createAction(CREATE_JOB_FAIL, error),

  fetchJobWithCandidates: (jobPositionId: number, page?: number, perPage?: number) =>
    createAction(FETCH_JOB_POSITION, { jobPositionId, page, perPage }),
  fetchNextPageJobWithCandidates: (data: IDataJobPositionWithPagedPotentialCandidates) => createAction(FETCH_NEXT_PAGE_JOB_POSITION_STARTED, data),
  fetchJobWithCandidatesSuccess: (data: IDataJobPositionWithPagedPotentialCandidates) => createAction(FETCH_JOB_POSITION_SUCCESS, data),
  fetchJobWithCandidatesFail: (error: IError) => createAction(FETCH_JOB_POSITION_FAIL, error),

  updateJob: (id: number, job: Partial<IJobPosition>) => createAction(UPDATE_JOB_POSITION_STARTED, { id, job }),
  updateJobSuccess: (id: number, job: Partial<IJobPosition>) => createAction(UPDATE_JOB_POSITION_SUCCESS, { id, job }),
  updateJobFail: (error: any) => createAction(UPDATE_JOB_POSITION_FAIL, error),

  setStatusJobPosition: (id: number, value: boolean) => createAction(SET_STATUS_JOB_POSITION, { id, value }),
  setStatusJobPositionSuccess: (jobPosition: IJobPosition) => createAction(SET_STATUS_JOB_POSITION_SUCCESS, jobPosition),
  setStatusJobPositionFail: (error: IError) => createAction(SET_STATUS_JOB_POSITION_FAIL, error),

  deleteJob: (id: number) => createAction(DELETE_JOB_POSITION_STARTED, id),
  deleteJobSuccess: (id: number) => createAction(DELETE_JOB_POSITION_SUCCESS, id),
  deleteJobFail: (error: any) => createAction(DELETE_JOB_POSITION_FAIL, error),

  resetStore: () => createAction(RESET_STORE),

  changeNotesCount: (id: number, increase: number) => createAction(EMPLOYER__CHANGE_NOTES_COUNT, { id, increase }),

  changeNotesCountJobPos: (id: number, increase: number) => createAction(JOB_POSITION__CHANGE_NOTES_COUNT, { id, increase }),

  clearNotification: () => createAction(CLEAR_NOTIFICATION),

  fetchJobsByEmployerId: (id: number) => createAction(FETCH_JOBS_BY_EMPLOYER_ID, id),
  fetchJobsByEmployerIdSuccess: (data: number) => createAction(FETCH_JOBS_BY_EMPLOYER_ID_SUCCESS, data),

  getAllLiveJobs: (page?: number) => createAction(GET_ALL_LIVE_JOBS, page),
  getAllLiveJobsSuccess: (data: any) => createAction(GET_ALL_LIVE_JOBS_SUCCESS, data),
  getAllLiveJobsFail: (error: any) => createAction(GET_ALL_LIVE_JOBS_FAIL, error),

  getPublicEmployersContacts: (id: number) => createAction(GET_PUBLIC_EMPLOYERS_CONTACTS, id),
  getPublicEmployersContactsSuccess: (data: any) => createAction(GET_PUBLIC_EMPLOYERS_CONTACTS_SUCCESS, data),
  getPublicEmployersContactsFail: (error: any) => createAction(GET_PUBLIC_EMPLOYERS_CONTACTS_FAIL, error),

  addPublicJobPosition: (recruiterId: number, employerId: number, job: Partial<IJobPosition>, contact?: undefined | Partial<IEmployerContact>) =>
    createAction(ADD_PUBLIC_JOB_POSITION, { recruiterId, employerId, job, contact }),
  addPublicJobPositionSuccess: () => createAction(ADD_PUBLIC_JOB_POSITION_SUCCESS),
  addPublicJobPositionFail: (error: INotValidFieldsErr) => createAction(ADD_PUBLIC_JOB_POSITION_FAIL, error),

  addPublicJobPositionWithoutId: (employerId: number, job: Partial<IJobPosition>, contact?: undefined | Partial<IEmployerContact>) =>
    createAction(ADD_PUBLIC_JOB_POSITION_WITHOUT_ID, { employerId, job, contact }),
  addPublicJobPositionWithoutIdSuccess: () => createAction(ADD_PUBLIC_JOB_POSITION_WITHOUT_ID_SUCCESS),
  addPublicJobPositionWithoutIdFail: (error: INotValidFieldsErr) => createAction(ADD_PUBLIC_JOB_POSITION_WITHOUT_ID_FAIL, error),
}

export default EmployersActions
