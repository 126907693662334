import React from 'react'
import { Document, Page, Text, View, Image, StyleSheet, Link } from '@react-pdf/renderer'
import get from 'lodash/get'

type Props = {
  userData: any
  candidate: any
  startDate: string
  salary: any
  percent: any
}

const Invoice: React.FC<Props> = ({ userData, candidate, startDate, salary, percent }) => {
  const dd = String(new Date().getDate()).padStart(2, '0')
  const mm = String(new Date().getMonth() + 1).padStart(2, '0')
  const yyyy = new Date().getFullYear()
  const today = dd + '/' + mm + '/' + yyyy
  const candidateLastName = get(candidate, 'Candidate.firstName', '')
  const candidateFirstName = get(candidate, 'Candidate.lastName', '')
  const candidateJobPosition = get(candidate, 'JobPosition.Position.name', '')
  const employerCompanyName = get(candidate, 'JobPosition.Employer.companyName', '')
  const employerCityName = get(candidate, 'JobPosition.Employer.City.name', '')
  const employerStateAbbr = get(candidate, 'JobPosition.Employer.City.State.abbr', '')
  const employerAddress = get(candidate, 'JobPosition.Employer.address', '')
  const employerAbn = get(candidate, 'JobPosition.Employer.abn', '')
  const employerWebsite = get(candidate, 'JobPosition.Employer.website', '')
  const employerPhone = get(candidate, 'JobPosition.Employer.phone', '')
  const employerContactName = get(candidate, 'JobPosition.Employer.contactName', '')
  const employerContactEmail = get(candidate, 'JobPosition.Employer.contactEmail', '')
  const employerContactPhone = get(candidate, 'JobPosition.Employer.contactPhone', '')
  const employerContactPosition = get(candidate, 'JobPosition.Employer.contactPosition', '')
  return (
    <Document>
      <Page orientation="portrait" size="A4">
        <View style={styles.topContainer}>
          <View>
            <Text style={styles.topTitle}>{`Invoice No. ${candidate.id}`}</Text>
          </View>
          <View>
            <Text style={styles.topTitle}>{`Date: ${today}`}</Text>
          </View>
        </View>
        <View style={styles.logoContainer}>
          <View>
            <Image style={styles.picture} src={require('assets/images/Logo-RL_black.png')} />
          </View>
          <View>
            <Text style={styles.title}>{`Recruiter: ${userData.firstName} ${userData.lastName}`}</Text>
            <Text style={styles.title}>{`Sole trade ABN: ${userData.abn}`}</Text>
            <Text style={styles.title}>{`TFN: ${userData.tfn}`}</Text>
            <Text style={styles.title}>{`BSB number: ${userData.bsb}`}</Text>
            <Text style={styles.title}>{`Account number: ${userData.account}`}</Text>
            <Text style={styles.title}>{`P. ${userData.phone}`}</Text>
            <Text style={styles.title}>{`E. ${userData.email}`}</Text>
          </View>
        </View>
        <View>
          <View>
            <View style={styles.headerBlock}>
              <Text>CANDIDATE DETAILS</Text>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Candidate Name</Text>
                <Text style={styles.title}>{`${candidateLastName} ${candidateFirstName}`}</Text>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Job Title</Text>
                <Text style={styles.title}>{candidateJobPosition}</Text>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Start Date</Text>
                <Text style={styles.title}>{startDate}</Text>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Salary</Text>
                <Text style={styles.title}>{salary}</Text>
              </View>
            </View>
          </View>
          <View>
            <View style={styles.headerBlock}>
              <Text>EMPLOYER DETAILS</Text>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Company name</Text>
                <Text style={styles.title}>{employerCompanyName}</Text>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>City/Territory</Text>
                <Text style={styles.title}>{`${employerCityName} ${employerStateAbbr}`}</Text>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>HQ address</Text>
                <Text style={styles.title}>{employerAddress}</Text>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>ABN</Text>
                <Text style={styles.title}>{employerAbn}</Text>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Website link</Text>
                <Link style={styles.titleLink} src={employerWebsite}>
                  <Text>{employerWebsite}</Text>
                </Link>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Office phone</Text>
                <Text style={styles.title}>{employerPhone}</Text>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Business contact name</Text>
                <Text style={styles.title}>{employerContactName}</Text>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Business contact position</Text>
                <Text style={styles.title}>{employerContactPosition}</Text>
              </View>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Business contact email</Text>
                <Text style={styles.title}>{employerContactEmail}</Text>
              </View>
            </View>
            <View style={styles.cadidateLastInfoWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Business phone number</Text>
                <Text style={styles.title}>{employerContactPhone}</Text>
              </View>
            </View>
          </View>
          <View>
            <View style={styles.headerBlock}>
              <Text>FEE AGREEMENT AND CALCULATION</Text>
            </View>
            <View style={styles.cadidateWrapper}>
              <View style={styles.candidateInfo}>
                <Text style={styles.title}>Recruitment Percentage</Text>
                <Text style={styles.title}>{+percent} %</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.coastBlock}>
          <View style={styles.coastInfoWrapper}>
            <View style={styles.coastInfo}>
              <Text>Agreed Fee:</Text>
              <Text style={styles.coastTitle}>{`${((+salary * +percent) / 100).toFixed()} AUD`}</Text>
            </View>
          </View>
        </View>
        <View style={styles.middleBlock}>
          <View style={styles.topInfoBlock}>
            <Text style={styles.middleBlockTitle}>{`After you have sent this document to `}</Text>
            <Text style={styles.middleBlockEmail}>stuart@recruiterslife.com</Text>
            <Text style={styles.middleBlockTitle}>, you will receive commission statement</Text>
          </View>
          <Text style={styles.title}>through email from RECRUITERSLIFE to see details for your income.</Text>
        </View>
        <View style={styles.bottomBlock}>
          <View style={styles.thankfulBlock}>
            <Text style={styles.firstPart}>Thank you for your </Text>
            <Text style={styles.secondPart}>business!</Text>
          </View>
          <View>
            <Text style={styles.poweredBy}>Powered by recruiterslife.com</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#3CB593',
    paddingHorizontal: 25,
    paddingVertical: 10,
  },
  topTitle: {
    fontSize: 14,
    color: 'white',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px 30px',
  },
  picture: {
    width: '60%',
  },
  title: {
    fontSize: 11,
    lineHeight: 1.3,
    padding: '0 0 0 10px',
  },
  titleLink: {
    fontSize: 11,
    color: '#2697FF',
  },
  cadidateWrapper: {
    marginHorizontal: 30,
    paddingTop: 1,
    backgroundColor: 'lightgrey',
  },
  cadidateLastInfoWrapper: {
    marginHorizontal: 30,
    paddingVertical: 1,
    backgroundColor: 'lightgrey',
  },
  candidateInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 6,
    backgroundColor: 'white',
  },
  coastBlock: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '10px 30px',
  },
  coastInfoWrapper: {
    width: '50%',
    paddingBottom: 1,
    backgroundColor: 'lightgrey',
  },
  coastInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 11,
    backgroundColor: 'white',
  },
  coastTitle: {
    color: '#3CB593',
  },
  middleBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 30px',
  },
  topInfoBlock: {
    display: 'flex',
    flexDirection: 'row',
  },

  middleBlockTitle: {
    fontSize: 11,
    marginBottom: 5,
  },
  middleBlockEmail: {
    fontSize: 11,
    color: '#3CB494',
  },
  bottomBlock: {
    backgroundColor: '#0B1424',
    padding: '15px 40px 16px 40px',
    display: 'flex',
    alignItems: 'center',
  },
  thankfulBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },
  firstPart: {
    color: 'white',
  },
  secondPart: {
    color: '#3CB593',
  },
  poweredBy: {
    color: 'lightgrey',
    fontSize: 11,
  },
  headerBlock: {
    backgroundColor: '#3CB593',
    margin: '0 30px',
    padding: '5px 10px',
    color: 'white',
    fontSize: 10,
  },
})

export default Invoice
