import styled from 'styled-components'

import { Box, BoxFill, Text } from 'styles'

export const Wrap = styled(BoxFill).attrs({
  spaceBetween: true,
  alignCenter: true,
  mainBC: true,
  padding: '40px 40px 140px 40px',
})``

export const Green = styled(Text)`
  color: ${props => props.theme.main.secondColor};
  cursor: pointer;
  margin: 0 10px;
  &:hover {
    opacity: 0.8;
  }
`

export const TitleWrap = styled(Box).attrs({
  alignCenter: true,
})``

export const Arrow = styled.img`
  cursor: pointer;
`
