import { call, put, takeLatest, select } from 'redux-saga/effects'

import SearchActions, {
  SEARCH_CANDIDATES,
  SEARCH_JOBS,
  SEARCH_COMPANIES,
  SEARCH_CONTACTS,
  SAVE_CANDIDATES,
  SAVE_JOBS,
  SAVE_COMPANIES,
  SAVE_CONTACTS,
  GET_SEARCH_LIST,
} from './action'

import SearchApi from 'api/search'

import { ICity } from 'types/models'

function* searchCandidatesFlow() {
  try {
    const search = yield select(state => state.search)
    const body = {
      name: search.get('candidatesKeywords') || '',
      jobTitle: search.get('candidatesJobTitle') || '',
      cityIds: (search.get('candidatesCity') && search.get('candidatesCity').map((el: ICity | any) => el.id || el)) || '',
      availability: search.get('candidatesAvailability') ? search.get('candidatesAvailability').value : search.get('candidatesAvailability') || '',
      sectorIds: (search.get('candidatesProjectTypes') && search.get('candidatesProjectTypes').map((el: any) => el.id || el)) || '',
    }
    const { data } = yield call(SearchApi.searchCandidates, body)
    yield put(SearchActions.searchCandidatesSuccess(data))
  } catch (err) {
    console.log('searchCandidatesFlow ERROR', err)
  }
}

function* searchJobsFlow() {
  try {
    const search = yield select(state => state.search)
    const body = {
      jobTitle: search.get('jobsJobTitle') || '',
      companyName: search.get('jobsCompanyName') || '',
      cityIds: (search.get('jobsCity') && search.get('jobsCity').map((el: ICity) => el.id || el)) || '',
      status: search.get('jobsStatus') ? search.get('jobsStatus').value : search.get('jobsStatus') || '',
      sectorIds: (search.get('jobsProjectTypes') && search.get('jobsProjectTypes').map((el: any) => el.id || el)) || '',
    }
    const { data } = yield call(SearchApi.searchJobs, body)
    yield put(SearchActions.searchJobsSuccess(data))
  } catch (err) {
    yield put(SearchActions.searchJobsFail(err))
    console.log('searchCandidatesFlow ERROR', err)
  }
}

function* searchCompaniesFlow() {
  try {
    const search = yield select(state => state.search)
    const body = {
      name: search.get('companiesJobTitle') || '',
      cityIds: (search.get('companiesCity') && search.get('companiesCity').map((el: ICity) => el.id || el)) || '',
      sectorIds: (search.get('companiesProjectTypes') && search.get('companiesProjectTypes').map((el: any) => el.id || el)) || '',
    }
    const { data } = yield call(SearchApi.searchCompanies, body)
    yield put(SearchActions.searchCompaniesSuccess(data))
  } catch (err) {
    console.log('searchCandidatesFlow ERROR', err)
  }
}

function* searchContactsFlow() {
  try {
    const search = yield select(state => state.search)
    const body = {
      name: search.get('contactsKeywords') || '',
      jobTitle: search.get('contactsJobTitle') || '',
      cityIds: (search.get('contactsCity') && search.get('contactsCity').map((el: ICity) => el.id || el)) || '',
      sectorIds: (search.get('contactsProjectTypes') && search.get('contactsProjectTypes').map((el: any) => el.id || el)) || '',
    }
    const { data } = yield call(SearchApi.searchContacts, body)
    yield put(SearchActions.searchContactsSuccess(data))
  } catch (err) {
    console.log('searchCandidatesFlow ERROR', err)
  }
}

function* saveCandidatesFlow() {
  try {
    const search = yield select(state => state.search)
    const body = {
      name: search.get('candidatesKeywords') || '',
      jobTitle: search.get('candidatesJobTitle') || '',
      cityIds: (search.get('candidatesCity') && search.get('candidatesCity').map((el: ICity) => el.id)) || '',
      availability: (search.get('candidatesAvailability') && search.get('candidatesAvailability').value) || '',
      sectorIds: (search.get('candidatesProjectTypes') && search.get('candidatesProjectTypes').map((el: any) => el.id)) || '',
    }
    const { data } = yield call(SearchApi.saveCandidates, body)
    yield put(SearchActions.saveCandidatesSuccess(data))
    yield put(SearchActions.getSearchList('canidates'))
  } catch (err) {
    console.log('searchCandidatesFlow ERROR', err)
  }
}

function* saveJobsFlow() {
  try {
    const search = yield select(state => state.search)
    const body = {
      jobTitle: search.get('jobsJobTitle') || '',
      companyName: search.get('jobsCompanyName') || '',
      cityIds: (search.get('jobsCity') && search.get('jobsCity').map((el: ICity) => el.id)) || '',
      status: (search.get('jobsStatus') && search.get('jobsStatus').value) || '',
      sectorIds: (search.get('jobsProjectTypes') && search.get('jobsProjectTypes').map((el: any) => el.id)) || '',
    }
    const { data } = yield call(SearchApi.saveJobs, body)
    yield put(SearchActions.saveJobsSuccess(data))
    yield put(SearchActions.getSearchList('jobs'))
  } catch (err) {
    console.log('saveJobsFlow ERROR', err)
  }
}

function* saveCompaniesFlow() {
  try {
    const search = yield select(state => state.search)
    const body = {
      name: search.get('companiesJobTitle') || '',
      cityIds: (search.get('companiesCity') && search.get('companiesCity').map((el: ICity) => el.id)) || '',
      sectorIds: (search.get('companiesProjectTypes') && search.get('companiesProjectTypes').map((el: any) => el.id)) || '',
    }
    const { data } = yield call(SearchApi.saveCompanies, body)
    yield put(SearchActions.saveCompaniesSuccess(data))
    yield put(SearchActions.getSearchList('companies'))
  } catch (err) {
    console.log('saveCompaniesFlow ERROR', err)
  }
}

function* saveContactsFlow() {
  try {
    const search = yield select(state => state.search)

    const body = {
      name: search.get('contactsKeywords') || '',
      jobTitle: search.get('contactsJobTitle') || '',
      cityIds: (search.get('contactsCity') && search.get('contactsCity').map((el: ICity) => el.id)) || '',
      sectorIds: (search.get('contactsProjectTypes') && search.get('contactsProjectTypes').map((el: any) => el.id)) || '',
    }
    const { data } = yield call(SearchApi.saveContacts, body)
    yield put(SearchActions.saveContactsSuccess(data))
    yield put(SearchActions.getSearchList('contacts'))
  } catch (err) {
    console.log('saveContactsFlow ERROR', err)
  }
}

function* getSearchListFlow(action: ReturnType<typeof SearchActions.getSearchList>) {
  try {
    const { data } = yield call(SearchApi.getSearchList, action.payload.type)
    yield put(SearchActions.getSearchListSuccess(action.payload.type, data))
  } catch (err) {
    console.log('searchCandidatesFlow ERROR', err)
  }
}

export default function* watchSearch() {
  yield takeLatest(SEARCH_CANDIDATES, searchCandidatesFlow)
  yield takeLatest(SEARCH_JOBS, searchJobsFlow)
  yield takeLatest(SEARCH_COMPANIES, searchCompaniesFlow)
  yield takeLatest(SEARCH_CONTACTS, searchContactsFlow)
  yield takeLatest(SAVE_CANDIDATES, saveCandidatesFlow)
  yield takeLatest(SAVE_JOBS, saveJobsFlow)
  yield takeLatest(SAVE_COMPANIES, saveCompaniesFlow)
  yield takeLatest(SAVE_CONTACTS, saveContactsFlow)
  yield takeLatest(GET_SEARCH_LIST, getSearchListFlow)
}
