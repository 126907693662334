import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import TaskActions from 'store/task/action'
import { ITask } from 'types/models'

import Loader from 'components/Loader'
import Task from '../../Task'
import { NoTask } from '../../NoTask'

type Props = {
  done: {
    count: number
    rows: ITask[]
  }
  isLoading: boolean
  deleteTask: any
  restoreTask: any
}

export const DoneTasks = React.memo<Props>(({ done, isLoading, deleteTask, restoreTask }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(TaskActions.getTaskListByFilter('done'))
  }, [dispatch])

  const renderDone = () => (
    <>
      {done.rows && done.rows.length ? (
        done.rows.map(el => {
          return (
            <Task
              id={el.id}
              key={el.id}
              title={el.title}
              type="done"
              description={el.description}
              dueDate={el.dueDate}
              dueTime={el.dueTime}
              deleteTask={deleteTask}
              restoreTask={restoreTask}
              priority={el.priority}
            />
          )
        })
      ) : (
        <NoTask done />
      )}
    </>
  )

  return <>{isLoading ? <Loader height="400px" /> : renderDone()}</>
})
