import styled from 'styled-components'

import Button from 'components/Button'

import { BoxFill, BoxCol, Box, Text } from 'styles'

interface IProps {
  type?: string
}

export const Wrap = styled(BoxFill).attrs({})`
  border: 1px solid #e3e8f1;
  box-shadow: 0px 2px 6px rgba(231, 236, 245, 0.5);
  border-radius: 6px;
  margin: 0 0 12px 0;
  position: relative;
`

export const TaskLabel = styled(BoxCol).attrs({
  minHeight: '104px',
  padding: '20px 36px 29px 36px',
})<IProps>`
  border-left: 5px solid #eb5757;
  border-radius: 6px 0px 0px 6px;
  border-left: ${({ type }) =>
    type === 'done' ? 'none' : type === 'high' ? '5px solid #EB5757' : type === 'medium' ? '5px solid #F3A200' : '5px solid #219653'};
`

export const Title = styled(Text).attrs({
  fw: 'bold',
})<IProps>`
  color: ${({ type }) => (type === 'done' ? '#9CAAB8' : '#292C38')};
  font-size: 17px;
  text-decoration: ${({ type }) => (type === 'done' ? 'line-through' : 'none')};
  word-break: break-all;
  padding: 0 20px 0 0;
`

export const Description = styled.pre`
  color: #292c38;
  font-size: 14px;
  white-space: pre-wrap;
  word-break: break-all;
`

export const BottomWrap = styled(Box).attrs({
  margin: '11px 0 0 0',
})``

export const Due = styled(Box).attrs({})<IProps>`
  background: #f3f6fa;
  mix-blend-mode: normal;
  border-radius: 6px;
  font-size: 12px;
  color: #74777d;
  text-transform: uppercase;
  padding: 5px 12px 6px 13px;
  position: relative;
  margin: 0 7px 0 0;
`

export const Priority = styled(Box).attrs({})`
  background: #f3f6fa;
  mix-blend-mode: normal;
  border-radius: 6px;
  font-size: 12px;
  color: #74777d;
  text-transform: uppercase;
  padding: 5px 12px 6px 13px;
  position: relative;
`

export const Dots = styled(Box).attrs({})`
  padding: 5px;
  position: absolute;
  cursor: pointer;
`

export const DotsWrap = styled(Box).attrs({})`
  width: 22px;
`

export const Circle = styled(Box).attrs({
  width: '6px',
  height: '6px',
  br: '3px',
  mainBC: true,
  pointer: true,
})``

export const OptionContainer = styled(Box).attrs({
  shadow2: true,
  absolute: true,
  zIndex: '10',
  br: '6px',
  right: '0',
  top: '-25px',
  mt: '10px',
  whiteBC: true,
  width: '170px !important',
  column: true,
})``

export const OptionHeader = styled(BoxFill).attrs(props => ({
  alignCenter: true,
  spaceBetween: true,
  padding: '10px',
  bb: `1px solid ${props.theme.main.borderInputColor}`,
}))``

export const Option = styled(BoxFill)`
  &:hover .statusBox {
    display: block;
  }
  &:hover {
    background-color: #f5f6f8;
  }
`

export const OptionText = styled(Text).attrs({
  pointer: true,
  black: true,
})``

export const BoxStatus = styled(Box).attrs({
  alignCenter: true,
  spaceBetween: true,
  padding: '10px 5px',
})``

export const StatusWrapper = styled(BoxCol).attrs({
  absolute: true,
  whiteBC: true,
  smooth: true,
  shadow2: true,
  display: 'none',
  width: '210px',
  top: '0px',
  left: '-210px',
  padding: '10px',
})``

export const StatusItem = styled(Box).attrs({})`
  width: 100%;
  justify-content: space-between;
`

export const DeleteTask = styled.span`
  color: #f95565;
`

export const HighPriority = styled(Text)`
  color: #eb5757;
`

export const MediumPriority = styled(Text)`
  color: #f3a200;
`

export const AnyTime = styled(Text)`
  color: #219653;
`

export const ModalWrap = styled(BoxCol).attrs({
  width: '625px',
  padding: '20px',
})``

export const NewTask = styled(Text).attrs({
  fw: 'bold',
})`
  margin: 0 0 10px 0;
`

export const NTSubtitle = styled(Text).attrs({})`
  margin: 0 0 32px 0;
`

export const InputBlock = styled(Box).attrs({
  width: '100%',
})`
  margin: 0 0 15px 0;
`

export const TextareaWrap = styled(Box).attrs({
  width: '100%',
  height: '137px',
})`
  margin: 0 0 40px 0;
`

export const CreateTaskWrap = styled(Box).attrs({
  width: '100%',
  margin: '20px 0 10px 0',
})``

export const CreateTask = styled(Button).attrs({
  width: '165px',
  height: '60px',
})`
  margin: 0 auto;
`

export const CharsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const Chars = styled.p`
  font-size: 13px;
  color: #9caab8;
  padding: 3px 0 0 5px;
`
