import { createAction, ActionsUnion } from 'types/helps'
import { ISelectListItem } from 'types/forms'

export const GET_STATES = 'GET_STATES'
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS'
export const GET_STATES_FAIL = 'GET_STATES_FAIL'

export const GET_CITIES = 'GET_CITIES'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_FAIL = 'GET_CITIES_FAIL'

export const GET_JOB_POSITIONS = 'GET_JOB_POSITIONS'
export const GET_JOB_POSITIONS_SUCCESS = 'GET_JOB_POSITIONS_SUCCESS'
export const GET_JOB_POSITIONS_FAIL = 'GET_JOB_POSITIONS_FAIL'

export const GET_PROJECT_TYPES = 'GET_PROJECT_TYPES'
export const GET_PROJECT_TYPES_SUCCESS = 'GET_PROJECT_TYPES_SUCCESS'
export const GET_PROJECT_TYPES_FAIL = 'GET_PROJECT_TYPES_FAIL'

export const GET_PROJECT_TYPES_BY_ID = 'GET_PROJECT_TYPES_BY_ID'
export const GET_PROJECT_TYPES_BY_ID_SUCCESS = 'GET_PROJECT_TYPES_BY_ID_SUCCESS'
export const GET_PROJECT_TYPES_BY_ID_FAIL = 'GET_PROJECT_TYPES_BY_ID_FAIL'

export const GET_IT_PACKAGES = 'GET_IT_PACKAGES'
export const GET_IT_PACKAGES_SUCCESS = 'GET_IT_PACKAGES_SUCCESS'
export const GET_IT_PACKAGES_FAIL = 'GET_IT_PACKAGES_FAIL'

export const GET_PROJECT_VALUE = 'GET_PROJECT_VALUE'
export const GET_PROJECT_VALUE_SUCCESS = 'GET_PROJECT_VALUE_SUCCESS'
export const GET_PROJECT_VALUE_FAIL = 'GET_PROJECT_VALUE_FAIL'

export const GET_PUBLIC_EMPLOYERS = 'GET_PUBLIC_EMPLOYERS'
export const GET_PUBLIC_EMPLOYERS_SUCCESS = 'GET_PUBLIC_EMPLOYERS_SUCCESS'
export const GET_PUBLIC_EMPLOYERS_FAIL = 'GET_PUBLIC_EMPLOYERS_FAIL'

export const GET_PUBLIC_EMPLOYERS_WITHOUT_ID = 'GET_PUBLIC_EMPLOYERS_WITHOUT_ID'
export const GET_PUBLIC_EMPLOYERS_WITHOUT_ID_SUCCESS = 'GET_PUBLIC_EMPLOYERS_WITHOUT_ID_SUCCESS'
export const GET_PUBLIC_EMPLOYERS_WITHOUT_ID_FAIL = 'GET_PUBLIC_EMPLOYERS_WITHOUT_ID_FAIL'

export type ListActionsTypes = ActionsUnion<typeof ListActions>

const ListActions = {
  getStates: () => createAction(GET_STATES),
  getStatesSuccess: (data: ISelectListItem[]) => createAction(GET_STATES_SUCCESS, data),

  getCities: () => createAction(GET_CITIES),
  getCitiesSuccess: (data: ISelectListItem[]) => createAction(GET_CITIES_SUCCESS, data),

  getJobPositions: () => createAction(GET_JOB_POSITIONS),
  getJobPositionsSuccess: (data: ISelectListItem[]) => createAction(GET_JOB_POSITIONS_SUCCESS, data),

  getProjectTypes: () => createAction(GET_PROJECT_TYPES),
  getProjectTypesSuccess: (data: ISelectListItem[]) => createAction(GET_PROJECT_TYPES_SUCCESS, data),

  getProjectTypesById: (recruiterId: number) => createAction(GET_PROJECT_TYPES_BY_ID, recruiterId),
  getProjectTypesByIdSuccess: (data: ISelectListItem[]) => createAction(GET_PROJECT_TYPES_BY_ID_SUCCESS, data),

  getItPackages: () => createAction(GET_IT_PACKAGES),
  getItPackagesSuccess: (data: ISelectListItem[]) => createAction(GET_IT_PACKAGES_SUCCESS, data),

  getProjectValues: () => createAction(GET_PROJECT_VALUE),
  getProjectValuesSuccess: (data: ISelectListItem[]) => createAction(GET_PROJECT_VALUE_SUCCESS, data),

  getPublicEmployers: (recruiterId: number) => createAction(GET_PUBLIC_EMPLOYERS, recruiterId),
  getPublicEmployersSuccess: (data: any) => createAction(GET_PUBLIC_EMPLOYERS_SUCCESS, data),
  getPublicEmployersFail: (error: any) => createAction(GET_PUBLIC_EMPLOYERS_FAIL, error),

  getPublicEmployersWithoutId: () => createAction(GET_PUBLIC_EMPLOYERS_WITHOUT_ID),
  getPublicEmployersWithoutIdSuccess: (data: any) => createAction(GET_PUBLIC_EMPLOYERS_WITHOUT_ID_SUCCESS, data),
  getPublicEmployersWithoutIdFail: (error: any) => createAction(GET_PUBLIC_EMPLOYERS_WITHOUT_ID_FAIL, error),
}

export default ListActions
