/* tslint:disable:max-line-length */
import React from 'react'
import styled from 'styled-components'

import theme from 'styles/theme'
import { IColors } from 'styles/interfaces'
import { Image } from 'styles'

const Svg = styled.svg`
  cursor: pointer;
`

/* TODO:! REBUILD ALL COMPONENTS WITH setColor function, so need delete all props */
function setColor(props: IColors) {
  const { secondColor, greyText, red, text, accentColor, inputLabelTextColor } = theme.main
  if (Object.prototype.hasOwnProperty.call(props, 'white')) return 'white'
  if (Object.prototype.hasOwnProperty.call(props, 'black')) return inputLabelTextColor
  if (Object.prototype.hasOwnProperty.call(props, 'secondColor')) return secondColor
  if (Object.prototype.hasOwnProperty.call(props, 'accentColor')) return accentColor
  if (Object.prototype.hasOwnProperty.call(props, 'grey')) return greyText
  if (Object.prototype.hasOwnProperty.call(props, 'red')) return red
  if (Object.prototype.hasOwnProperty.call(props, 'color')) return props.color
  return text
}

type PaginationArrowProps = {
  fill?: string
  isActive?: boolean
  rotate?: string
}

export const PaginationArrow: React.FC<PaginationArrowProps> = ({ fill, isActive, rotate }) => (
  <div style={{ transform: rotate }}>
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.354819 5.49505L4.65466 9.79476C4.92818 10.0684 5.37165 10.0684 5.64504 9.79476C5.91845 9.52135 5.91845 9.0779 5.64504 8.80451L1.84034 4.99993L5.64493 1.19548C5.91834 0.921955 5.91834 0.478552 5.64493 0.205141C5.37152 -0.0683805 4.92807 -0.0683805 4.65455 0.205141L0.354708 4.50492C0.218002 4.64169 0.149727 4.82075 0.149727 4.99991C0.149727 5.17915 0.218135 5.35835 0.354819 5.49505Z"
        fill={isActive ? theme.main.accentColor : fill}
      />
    </svg>
  </div>
)

PaginationArrow.defaultProps = {
  fill: theme.main.text,
}

type LogoProps = {
  width?: string
  height?: string
  type?: 'black'
}

const BLACK_LOGO = require('assets/images/Logo-RL_black.png')
const WHITE_LOGO = require('assets/images/Logo-RL_white.png')

export const Logo: React.FC<LogoProps> = ({ type, width, height }) => {
  const detectLogoColor = () => {
    switch (type) {
      case 'black':
        return BLACK_LOGO
      default:
        return WHITE_LOGO
    }
  }
  return <Image width={width} height={height} src={detectLogoColor()} />
}

Logo.defaultProps = {
  width: '190',
  height: '32',
}

type ExpandArrowProps = {
  fill?: string
  rotate?: string
  className?: string
}

export const ExpandArrow: React.FC<ExpandArrowProps> = ({ fill, rotate, className }) => (
  <div className={className} style={{ transform: rotate }}>
    <svg x="0" y="0" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.81437 0.248023C9.69076 0.124307 9.54423 0.0625 9.37492 0.0625H0.625047C0.455668 0.0625 0.30924 0.124307 0.185523 0.248023C0.0618069 0.371877 0 0.518305 0 0.687581C0 0.856823 0.0618069 1.00325 0.185523 1.127L4.56048 5.50195C4.68433 5.62567 4.83076 5.68762 5 5.68762C5.16924 5.68762 5.31581 5.62567 5.43942 5.50195L9.81437 1.12697C9.93795 1.00325 10 0.856823 10 0.687547C10 0.518305 9.93795 0.371877 9.81437 0.248023Z"
        fill={fill}
      />
    </svg>
  </div>
)

ExpandArrow.defaultProps = {
  fill: 'white',
}

type SearchProps = {
  fill?: string
}

export const Search: React.FC<SearchProps> = ({ fill }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1996 13.0829L18.7673 17.6506C19.0776 17.9569 19.0776 18.461 18.7673 18.7673C18.4571 19.0776 17.9569 19.0776 17.6467 18.7673L13.079 14.2035C11.6986 15.3357 9.93429 16.0143 8.00714 16.0143C3.58286 16.0143 0 12.4276 0 8.00714C0 3.58673 3.58286 0 8.00714 0C12.4276 0 16.0143 3.58673 16.0143 8.00714C16.0143 9.93429 15.3318 11.7024 14.1996 13.0829ZM1.5898 8.00714C1.5898 11.5435 4.46694 14.4206 8.00714 14.4206C11.5435 14.4206 14.4245 11.5435 14.4245 8.00714C14.4245 4.47082 11.5435 1.5898 8.00714 1.5898C4.46694 1.5898 1.5898 4.47082 1.5898 8.00714Z"
      fill={fill}
    />
  </svg>
)

Search.defaultProps = {
  fill: theme.main.placeholderColor,
}

type LinkedInProps = {
  fill?: string
  isActive?: string | boolean
}

export const LinkedIn: React.FC<LinkedInProps> = ({ fill, isActive }) => (
  <div>
    <svg
      style={{
        borderRadius: '50%',
        border: '2px solid white',
      }}
      width="30"
      height="30"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.53 29.3188C22.5547 29.3188 29.06 22.8135 29.06 14.7888C29.06 6.7641 22.5547 0.258789 14.53 0.258789C6.50531 0.258789 0 6.7641 0 14.7888C0 22.8135 6.50531 29.3188 14.53 29.3188Z"
        fill={isActive ? '#0077B5' : fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9763 6.46729C7.78821 6.46729 7.01092 7.24717 7.01092 8.27182C7.01092 9.27471 7.76568 10.0771 8.93071 10.0771H8.95325C10.1646 10.0771 10.9179 9.27471 10.9179 8.27182C10.8948 7.24717 10.1646 6.46729 8.9763 6.46729ZM23.2116 15.958V21.9484H19.7385V16.3594C19.7385 14.9561 19.237 13.9978 17.9795 13.9978C17.0199 13.9978 16.4498 14.643 16.1981 15.2677C16.1066 15.491 16.0831 15.801 16.0831 16.1142V21.9481H12.6097C12.6097 21.9481 12.6563 12.4823 12.6097 11.5025H16.0833V12.9827C16.08 12.9882 16.0761 12.9936 16.0723 12.9989L16.0723 12.999C16.0679 13.005 16.0636 13.011 16.0603 13.0169H16.0833V12.9827C16.5449 12.2725 17.368 11.2572 19.2135 11.2572C21.4985 11.2572 23.2116 12.7501 23.2116 15.958ZM10.6894 21.9484H7.21735V11.5025H10.6894V21.9484Z"
        fill="white"
      />
    </svg>
  </div>
)

LinkedIn.defaultProps = {
  fill: theme.main.placeholderColor,
}

type TrashProps = {
  width?: string
  height?: string
}

export const Trash: React.FC<IColors & TrashProps> = props => (
  <Svg width={props.width} height={props.height} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.29602 19.0355C2.31859 19.5745 2.76218 20 3.30164 20H12.6979C13.2374 20 13.681 19.5745 13.7035 19.0355L14.3746 4.87012H1.625L2.29602 19.0355ZM10.1253 8.38854C10.1253 8.16283 10.3083 7.97976 10.5342 7.97976H11.188C11.4137 7.97976 11.5969 8.16278 11.5969 8.38854V16.4815C11.5969 16.7073 11.4139 16.8903 11.188 16.8903H10.5342C10.3084 16.8903 10.1253 16.7074 10.1253 16.4815V8.38854ZM7.26406 8.38854C7.26406 8.16283 7.44708 7.97976 7.67284 7.97976H8.32672C8.55239 7.97976 8.7355 8.16278 8.7355 8.38854V16.4815C8.7355 16.7073 8.55254 16.8903 8.32672 16.8903H7.67284C7.44713 16.8903 7.26406 16.7074 7.26406 16.4815V8.38854ZM4.40269 8.38854C4.40269 8.16283 4.58571 7.97976 4.81147 7.97976H5.4654C5.69112 7.97976 5.87419 8.16278 5.87419 8.38854V16.4815C5.87419 16.7073 5.69117 16.8903 5.4654 16.8903H4.81147C4.58576 16.8903 4.40269 16.7074 4.40269 16.4815V8.38854Z"
      fill={setColor(props)}
    />
    <path
      d="M14.8217 1.0303H10.4869V0.21078C10.4869 0.094398 10.3925 0 10.2761 0H5.72408C5.6077 0 5.51335 0.094398 5.51335 0.21078V1.03025H1.17848C0.829629 1.03025 0.546875 1.31305 0.546875 1.66191V3.64617H15.4533V1.66195C15.4533 1.3131 15.1705 1.0303 14.8217 1.0303Z"
      fill={setColor(props)}
    />
  </Svg>
)

Trash.defaultProps = {
  width: '16',
  height: '20',
}
