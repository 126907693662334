import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import MessengerActions from 'store/messenger/actions'
import NotesActions from 'store/notes/actions'
import { IEmployerContact } from 'types/models'

import { BoxCol, BoxFill, Text } from 'styles'
// import { Email } from '../../components/Others'

const ContactCardContainer = styled(BoxCol).attrs(props => ({
  fill: true,
  shadow1: true,
  br: '6px',
  padding: '15px 0 0 0',
  border: `1px solid ${props.theme.main.borderInputColor}`,
}))``

const WrapInfo = styled(BoxFill).attrs({})`
  word-break: break-all;
`

const WrapInfoName = styled(Text).attrs({})`
  max-width: 450px;
  word-break: break-all;
`

const WrapInfoEmail = styled(Text).attrs({})`
  width: 60px;
  margin: 0 10px 0 0;
  word-break: break-all;
`

const WrapInfoPhone = styled(Text).attrs({})`
  max-width: 450px;
  min-width: 132px;
  padding: 0 20px 0 0;
  word-break: break-all;
`
const BottomCard = styled(BoxFill).attrs({})`
  height: 50px;
  border-top: ${props => `1px solid ${props.theme.main.borderInputColor}`};
  align-items: center;
  justify-content: center;
`

const Sep = styled.span`
  width: 1px;
  height: 30px;
  background: ${props => props.theme.main.borderInputColor};
  margin: 0 40px;
`

const Notes = styled.span`
  color: ${props => props.theme.main.colors.primary};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`
const Message = styled.span`
  color: ${props => props.theme.main.accentColor};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`

type Props = {
  contact: IEmployerContact
  addNewCandidateOrEmployer: typeof MessengerActions.addNewCandidateOrEmployer
}

const EmployerContactCard: React.FC<Props> = ({ contact, addNewCandidateOrEmployer }) => {
  let contactId: number | null | undefined
  let contactName: string
  let contactPosiion: string
  let notesCount: number | undefined

  if (contact) {
    contactId = contact.id
    contactName = contact.name
    contactPosiion = contact.position ? contact.position : ''
    notesCount = contact.notesCount
  }

  const addNewEmployerContact = () => {
    if (contact) {
      addNewCandidateOrEmployer('employerContact', contactId, contactName, contactPosiion)
    }
  }

  const renderText = (text: string | undefined = '', symbols: number) => (text && text.length > symbols ? `${text.substr(0, symbols)}...` : text)

  const dispatch = useDispatch()

  const openNotes = useCallback(
    id => {
      dispatch(NotesActions.onToggleModal(true, id, 'employer-contact'))
    },
    [dispatch]
  )

  return (
    <ContactCardContainer mb="10px">
      <WrapInfo alignCenter mb="8px" padding="0 15px">
        <WrapInfoName left black fz="17px">
          {contact.name}
        </WrapInfoName>
        {contact.position ? <Text fz="17px">・{contact.position}</Text> : null}
      </WrapInfo>
      <WrapInfo alignCenter spaceBetween mb="8px" padding="0 15px">
        <WrapInfoEmail left m>
          Email
        </WrapInfoEmail>
        <Text m black>
          {renderText(contact.email, 25)}
        </Text>
      </WrapInfo>
      <WrapInfo alignCenter spaceBetween mb="8px" padding="0 15px">
        <WrapInfoPhone left m>
          Best phone number
        </WrapInfoPhone>
        <Text m black>
          {renderText(contact.phone, 15)}
        </Text>
      </WrapInfo>
      <BottomCard>
        <Notes onClick={() => openNotes(contactId)}>
          {notesCount === 1 ? `${notesCount} note` : notesCount && notesCount ? `${notesCount && notesCount} notes` : 'Add notes'}
        </Notes>
        <>
          <Sep />
        </>
        <Message onClick={() => addNewEmployerContact()}>Message</Message>
      </BottomCard>
    </ContactCardContainer>
  )
}

export default connect(
  null,
  {
    addNewCandidateOrEmployer: MessengerActions.addNewCandidateOrEmployer,
  }
)(EmployerContactCard)
