import * as React from 'react'
import BasicSelect, { NamedProps, OptionTypeBase, Styles } from 'react-select'
import { BoxFill, Label } from 'styles'
import theme from 'styles/theme'

interface Props<IsMulti extends boolean, TOption extends OptionTypeBase> extends NamedProps<TOption, IsMulti> {
  width?: string
  height?: string
  label?: string
  error?: boolean
}

export const Select = <TOption extends OptionTypeBase, IsMulti extends boolean = false>({
  width,
  height,
  label,
  error,
  ...props
}: Props<IsMulti, TOption>) => {
  const styles = React.useMemo(
    (): Styles<TOption, IsMulti> => ({
      container: base => ({
        ...base,
        width: '100%',
      }),
      indicatorSeparator: base => ({
        ...base,
        display: 'none',
      }),
      control: (base, state) => ({
        ...base,
        height: '100%',
        '&:hover': null,
        border: `1px solid ${theme.main.borderInputColor}`,
        borderColor: `${state.isFocused ? theme.main.accentColor : error ? theme.main.red : null} !important`,
        boxShadow: 'inset 0px 1px 5px rgba(188, 196, 208, 0.2)',
      }),
      menu: base => ({ ...base, zIndex: 10, boxShadow: '0px 3px 17px rgba(150, 170, 180, 0.3)' }),
      valueContainer: (base, state) => ({
        ...base,
        width: '100%',
        padding: `${state.selectProps.isSearchComponent ? '7px 8px 6px 8px' : '11px 8px'}`,
        flexWrap: state.selectProps.isCustom ? 'nowrap' : 'wrap',
      }),
      multiValue: base => ({
        ...base,
        borderRadius: '6',
      }),
      multiValueRemove: base => ({
        ...base,
        '&:hover': {
          cursor: 'pointer',
        },
      }),
      placeholder: (base, state) => ({
        ...base,
        fontWeight: 300,
        color: `${state.selectProps.isSearchComponent || state.selectProps.isRelocateComponent ? theme.main.text : theme.main.inputLabelTextColor}`,
        fontSize: `${state.selectProps.isSearchComponent ? '15' : '18'}`,
      }),
    }),
    [theme, error]
  )

  return (
    <BoxFill column width={width} height={height}>
      <Label htmlFor={`${label}`}>{label}</Label>
      <BasicSelect
        styles={styles}
        id={label}
        {...props}
        onInputChange={inputValue => (inputValue.length <= 50 ? inputValue : inputValue.substr(0, 50))}
      />
    </BoxFill>
  )
}
