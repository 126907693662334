import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ValidationError } from 'yup'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'
import EmployersActions from 'store/employers/actions'
import { InputEvent } from 'types/helps'
import { ISelectListItem } from 'types/forms'
import { IEmployer, IEmployerContact } from 'types/models'

import Stepper from 'components/Stepper'
import FormFrame from 'components/FormStepFrame'
import FAQ from 'components/faqSteps'
import FirstStep from './steps/1'
import SecondStep from './steps/2'
import ThirdStep from './steps/3'
import textOfStep from './steps/textOfStep'

import { addEmployerSchemaFirstStep, addEmployerSchemaSecondStep, formatErrors } from 'services/validation'

import { BoxFill, Box } from 'styles'
import theme from 'styles/theme'

const Steps = ['Step 1. Company info', 'Step 2. Client contact details', 'Step 3. Company logo (optional)']

type Props = {
  createEmployerAndContact: typeof EmployersActions.createEmployerAndContact
  employer: IEmployer
}

type State = {
  currentStep: number
  form: Partial<IEmployer>
  contactForm: Partial<IEmployerContact>
  errors: Partial<IEmployer>
}

class AddEmployer extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    currentStep: 1,
    form: {},
    contactForm: {},
    errors: {},
  }
  image: string | null = null

  renderCurrentStep = () => {
    const { currentStep, form, contactForm, errors } = this.state
    const { employer } = this.props
    switch (currentStep) {
      case 1:
        return <FirstStep form={form} errors={errors} onChangeField={this.onChangeFormField} />
      case 2:
        return <SecondStep form={contactForm} errors={errors} onChangeField={this.onChangeContactField} />
      case 3:
        return <ThirdStep form={employer} onUpdate={this.createEmployer} />
      default:
        return null
    }
  }

  nextStep = () => {
    const { currentStep, form, contactForm } = this.state
    const { createEmployerAndContact } = this.props
    const validatorSteps: any = {
      1: { validator: addEmployerSchemaFirstStep, data: form },
      2: { validator: addEmployerSchemaSecondStep, data: contactForm },
    }
    const validator = validatorSteps[currentStep].validator
    validator
      .validate(validatorSteps[currentStep].data, { abortEarly: false })
      .then(() => {
        if (currentStep === 2) createEmployerAndContact(form, contactForm)
        this.setState({ errors: {}, currentStep: ++this.state.currentStep })
      })
      .catch((err: ValidationError) => this.setState({ ...this.state, errors: formatErrors(err) }))
  }

  prevStep = () => this.setState({ currentStep: --this.state.currentStep })

  setImageUrl = (image: string) => (this.image = image)

  onChangeFormField = (e: InputEvent & ISelectListItem, type?: string) => {
    if (type) return this.setState({ form: { ...this.state.form, [type]: e } })
    const { name, value } = e.currentTarget
    this.setState({ form: { ...this.state.form, [name]: value } })
  }

  onChangeContactField = (e: InputEvent & ISelectListItem) => {
    const { name, value } = e.currentTarget
    this.setState({ contactForm: { ...this.state.contactForm, [name]: value } })
  }

  createEmployer = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    const { currentStep } = this.state
    const { history } = this.props
    const isLastStep = currentStep === Steps.length
    const isCratedStep = currentStep === 2
    const goBack = () => history.goBack()
    return (
      <BoxFill bc={theme.main.homeBackground} column>
        <Stepper currentStep={currentStep} steps={Steps} title="Add employer" onExit={goBack} />
        <Box mt="-25vh">
          <FormFrame
            currentStep={currentStep}
            maxStep={Steps.length}
            btnText={isLastStep ? '' : isCratedStep ? 'CREATE EMPLOYER AND CONTINUE' : 'NEXT STEP'}
            title={textOfStep(currentStep)!.title}
            text={textOfStep(currentStep)!.subTitle}
            nextStep={isLastStep ? this.createEmployer : this.nextStep}
            isShowBackStep={currentStep !== 3}
            back={this.prevStep}
            isShowFooter={currentStep !== 3}
          >
            {this.renderCurrentStep()}
          </FormFrame>
          <FAQ title={textOfStep(currentStep)!.faq.title} text={textOfStep(currentStep)!.faq.text} />
        </Box>
      </BoxFill>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  employer: state.currentInnerPage.get('item'),
})

export default connect(
  mapStateToProps,
  { createEmployerAndContact: EmployersActions.createEmployerAndContact }
)(AddEmployer)
