import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'
import {
  IDetectTextAlign,
  IDetectTextColor,
  IDetectBoxBackgroundColor,
  IDetectJustifyContent,
  IDetectAlignItems,
  IDetectBoxPosition,
  IDetectShadow,
  IDetectFontSize,
  IBox,
  IText,
  IInput,
  ISpan,
  ILabel,
  IImage,
  IH1,
} from './interfaces'
import theme from './theme'

const shadow1 = '0px 7px 90px rgba(180, 191, 196, 0.12)'
const shadow2 = '10px 2px 40px rgba(0, 0, 0, 0.01), 0 3px 10px rgba(0, 0, 0, 0.07)'
const BORDER_DEFAULT = '1px solid #E3E8F1'

function detectTextAlign(props: IDetectTextAlign) {
  if (Object.prototype.hasOwnProperty.call(props, 'left')) return 'left'
  if (Object.prototype.hasOwnProperty.call(props, 'right')) return 'right'
  if (Object.prototype.hasOwnProperty.call(props, 'justify')) return 'justify'
  return 'center'
}

function detectTextColor(props: IDetectTextColor, defaultColor?: string) {
  const { secondColor, greyText, red, text, accentColor, inputLabelTextColor } = props.theme.main
  if (Object.prototype.hasOwnProperty.call(props, 'white')) return 'white'
  if (Object.prototype.hasOwnProperty.call(props, 'black')) return inputLabelTextColor
  if (Object.prototype.hasOwnProperty.call(props, 'secondColor')) return secondColor
  if (Object.prototype.hasOwnProperty.call(props, 'accentColor')) return accentColor
  if (Object.prototype.hasOwnProperty.call(props, 'grey')) return greyText
  if (Object.prototype.hasOwnProperty.call(props, 'red')) return red
  if (Object.prototype.hasOwnProperty.call(props, 'color')) return props.color
  return defaultColor || text
}

function detectFontSize(props: IDetectFontSize) {
  const { xxs, xs, s, m, l, xl, xl2, xl3, xl4, xl5 } = props.theme.main.fontSizes
  if (Object.prototype.hasOwnProperty.call(props, 'xxs')) return xxs
  if (Object.prototype.hasOwnProperty.call(props, 'xs')) return xs
  if (Object.prototype.hasOwnProperty.call(props, 's')) return s
  if (Object.prototype.hasOwnProperty.call(props, 'm')) return m
  if (Object.prototype.hasOwnProperty.call(props, 'l')) return l
  if (Object.prototype.hasOwnProperty.call(props, 'xl')) return xl
  if (Object.prototype.hasOwnProperty.call(props, 'xl2')) return xl2
  if (Object.prototype.hasOwnProperty.call(props, 'xl3')) return xl3
  if (Object.prototype.hasOwnProperty.call(props, 'xl4')) return xl4
  if (Object.prototype.hasOwnProperty.call(props, 'xl5')) return xl5
  if (Object.prototype.hasOwnProperty.call(props, 'fz')) return props.fz
  return m
}

function detectBoxBackgroundColor(props: IDetectBoxBackgroundColor) {
  const { backgroundColor, icon, accentColor } = props.theme.main
  if (Object.prototype.hasOwnProperty.call(props, 'mainBC')) return backgroundColor
  if (Object.prototype.hasOwnProperty.call(props, 'whiteBC')) return 'white'
  if (Object.prototype.hasOwnProperty.call(props, 'accentColor')) return accentColor
  if (Object.prototype.hasOwnProperty.call(props, 'iconColor')) return icon
  if (Object.prototype.hasOwnProperty.call(props, 'bc')) return props.bc
  return null
}

function detectJustifyContent(props: IDetectJustifyContent) {
  if (Object.prototype.hasOwnProperty.call(props, 'justifyCenter')) return 'center'
  if (Object.prototype.hasOwnProperty.call(props, 'justifyRight')) return 'flex-end'
  if (Object.prototype.hasOwnProperty.call(props, 'spaceBetween')) return 'space-between'
  if (Object.prototype.hasOwnProperty.call(props, 'spaceAround')) return 'space-around'
  return 'flex-start'
}

function detectShadow(props: IDetectShadow) {
  if (Object.prototype.hasOwnProperty.call(props, 'shadow1')) return shadow1
  if (Object.prototype.hasOwnProperty.call(props, 'shadow2')) return shadow2
  return null
}

function detectAlignItems(props: IDetectAlignItems) {
  if (Object.prototype.hasOwnProperty.call(props, 'alignCenter')) return 'center'
  if (Object.prototype.hasOwnProperty.call(props, 'alignEnd')) return 'flex-end'
  if (Object.prototype.hasOwnProperty.call(props, 'stretch')) return 'stretch'
  if (Object.prototype.hasOwnProperty.call(props, 'baseline')) return 'baseline'
  return 'flex-start'
}

function detectBoxPosition(props: IDetectBoxPosition) {
  if (Object.prototype.hasOwnProperty.call(props, 'relative')) return 'relative'
  if (Object.prototype.hasOwnProperty.call(props, 'fixed')) return 'fixed'
  if (Object.prototype.hasOwnProperty.call(props, 'absolute')) return 'absolute'
  if (Object.prototype.hasOwnProperty.call(props, 'sticky')) return 'sticky'
  return 'static'
}

function detectInputBorderColor(props: any) {
  if (props.noBorder) return 'none'
  // if (Object.prototype.hasOwnProperty.call(props, 'noBorder')) return 'none'
  if (props.isError) return `1px solid ${props.theme.main.red}`

  return `1px solid ${props.theme.main.borderInputColor}`
}

/**
 * @param {string} br - border-radius
 * @param {string} bb - border-bottom
 * @param {string} bt - border-top
 * @param {string} pt - padding-top
 * @param {string} pb - padding-bottom
 * @param {string} pr - padding-right
 * @param {string} pl - padding-left
 * @param {string} mt - margin-top
 * @param {string} mb - margin-bottom
 * @param {string} mr - margin-right
 * @param {string} ml - margin-left
 */

export const Box = styled.div<IBox>`
  margin: ${props => props.margin};
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.ml};
  margin-right: ${props => props.mr};
  padding: ${props => props.padding};
  padding-top: ${props => props.pt};
  padding-bottom: ${props => props.pb};
  padding-right: ${props => props.pr};
  padding-left: ${props => props.pl};
  width: ${props => (props.fill ? '100%' : props.width)};
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  height: ${props => (props.fillHeight ? '100%' : props.height)};
  min-height: ${props => props.minHeight};
  max-height: ${props => props.maxHeight};
  border-radius: ${props => (props.smooth ? '6px' : props.br)};
  border: ${props => (props.defaultBorder ? BORDER_DEFAULT : props.border)};
  border-bottom: ${props => (props.bbDefault ? BORDER_DEFAULT : props.bb)};
  border-top: ${props => (props.btDefault ? BORDER_DEFAULT : props.bt)};
  border-right: ${props => props.borderRight};
  display: ${props => props.display || 'flex'};
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};
  overflow: ${props => props.overflow};
  z-index: ${props => props.zIndex};
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  align-items: ${props => detectAlignItems(props)};
  align-self: ${props => props.alignSelf};
  justify-content: ${props => detectJustifyContent(props)};
  flex: ${props => props.flex};
  background-color: ${props => detectBoxBackgroundColor(props)};
  background: ${props => props.background};
  position: ${props => detectBoxPosition(props)};
  box-shadow: ${props => detectShadow(props)};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  right: ${props => props.right};
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  transform: ${props => (props.heightTransform ? `translateY(${props.heightTransform}vh)` : null)};
  transform: ${props => (props.widthTranform ? `translateX(${props.widthTranform}vw)` : null)};
  transform: ${props => props.transform};
  opacity: ${props => props.opacity};
  transition-duration: ${props => (props.transition ? `${props.transition}ms` : 0)};
  p,
  img,
  div {
    cursor: ${props => (props.allPointer ? 'pointer' : null)};
  }
  ${props =>
    props.notSelectable
      ? `-webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;`
      : null}
`

export const BoxCol = styled(Box)`
  flex-direction: column;
`
export const BoxCenterContent = styled(Box)`
  align-items: center;
  justify-content: center;
`

export const BoxFill = styled(Box)`
  width: 100%;
`

export const BoxFullFill = styled(Box)`
  width: 100%;
  height: 100%;
`

export const BoxFullScreen = styled(Box)`
  width: 100vw;
  height: 100vh;
`

/**
 * @param {string} ls - letter-spacing
 * @param {string} fw - font-weight
 * @param {string} fz - font-size
 * @param {string} lh - line-height
 */

export const Text = styled.p<IText>`
  width: ${props => (props.fill ? '100%' : props.width)};
  font-size: ${props => detectFontSize(props)};
  font-weight: ${props => props.fw || 'normal'};
  letter-spacing: ${props => props.ls};
  text-align: ${props => detectTextAlign(props)};
  color: ${props => detectTextColor(props)};
  white-space: ${props => (props.whiteSpace ? 'nowrap' : null)};
  overflow: ${props => props.overflow};
  text-overflow: ${props => (props.textOverflow ? 'ellipsis' : null)};
  max-width: ${props => props.maxWidth};
  flex: ${props => props.flex};
  line-height: ${props => props.lh};
  cursor: ${props => (props.pointer ? 'pointer' : 'text')};
  word-break: break-word;
  opacity: ${props => (props.opacity ? props.opacity : 1)};
`

export const Textarea = styled.textarea<IText & { noBorder?: boolean; noShadow?: boolean; padding?: string }>`
  width: 100%;
  height: 100%;
  border: ${props => (props.noBorder ? 'none' : '1px solid #E3E8F1')};
  border-radius: 6px;
  padding: ${props => (props.padding ? props.padding : '10px')};
  font-size: 16px;
  box-shadow: ${props => (props.noShadow ? 'none' : 'inset 0px 1px 5px rgba(188, 196, 208, 0.2)')};
  box-sizing: border-box;
  :disabled {
    color: #292c38;
  }
`

export const Bold = styled(Text)<IText>`
  font-weight: bold;
`

export const TextUnselectable = styled(Text)<IText>`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const NavLi = styled(NavLink).attrs(props => ({
  activeStyle: {
    color: props.theme.main.secondColor,
  },
}))`
  letter-spacing: 1px;
  padding: 0 12px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  transition: 200ms;
  :hover {
    color: ${props => props.theme.main.secondColor};
  }
`

export const ButtonLink = styled(Link)`
  background-color: ${props => props.theme.main.secondColor};
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 1px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-weight: 500;
`

export const Input = styled.input<IInput>`
  border: ${props => detectInputBorderColor(props)};
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 18px;
  padding: ${props => props.padding};
  padding-top: ${props => props.pt};
  padding-bottom: ${props => props.pb};
  padding-right: ${props => props.pr};
  padding-left: ${props => props.pl};
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 1px 5px rgba(188, 196, 208, 0.2);
  :focus {
    outline: none;
    border: ${props => (props.noBorder ? 'none' : `1px solid ${props.theme.main.accentColor}`)};
  }
  :disabled {
    color: ${props => props.theme.main.text};
  }
  ::placeholder {
    font-family: Mukta;
    font-weight: normal;
    font-size: 18px;
  }
`

/**integrate with backend
 * @param {string} fw - font-weight
 */

export const Span = styled.span<ISpan>`
  color: ${props => detectTextColor(props, theme.main.accentColor)};
  font-weight: ${props => props.fw || 'normal'};
  cursor: ${props => (props.noPointer ? 'text' : 'pointer')};
  white-space: ${props => (props.whiteSpace ? 'nowrap' : null)};
  text-overflow: ${props => (props.textOverflow ? 'ellipsis' : null)};
  overflow: ${props => props.overflow};
`

export const Label = styled.label<ILabel>`
  color: ${props => props.theme.main.inputLabelTextColor};
  font-size: 16px;
  margin-bottom: 5px;
  cursor: ${props => (props.pointer ? 'pointer' : 'text')};
`

export const Image = styled.img<IImage>`
  width: ${props => props.width};
  height: ${props => props.height};
  cursor: ${props => (props.pointer ? 'pointer' : 'text')};
  filter: ${props => (props.active ? 'invert(0.9)' : null)};
  object-fit: ${props => (props.contain ? 'contain' : 'cover')};
  object-position: ${props => (props.contain ? 'center' : 'top')};
`

/**
 * @param {string} fz - font-size
 * @param {string} fw - font-weight
 */

export const H1 = styled.h1<IH1>`
  font-size: ${props => props.fz || '32px'};
  text-align: ${props => detectTextAlign(props)};
  white-space: ${props => (props.whiteSpace ? 'nowrap' : null)};
  font-weight: ${props => props.fw || 'normal'};
  color: ${props => (props.black ? props.theme.main.inputLabelTextColor : '#fff')};
  line-height: ${props => props.lh};
`

export const H2 = styled(H1)`
  font-size: 24px;
`

export const H3 = styled(H1)`
  font-size: 18px;
`

export const H4 = styled(H1)`
  font-size: 16px;
`

export const H5 = styled(H1)`
  font-size: 14px;
`

export const H6 = styled(H1)`
  font-size: 12px;
`

/* ------------- SOME SMALL COMPONENTS */

export const SectorTypeBox = styled(Box).attrs({
  smooth: true,
  padding: '10px 14px',
  bc: '#E3E8F1',
})`
  p {
    color: #292c38;
    white-space: nowrap;
  }
`

export const SectorsTypeBox = styled(Box).attrs({
  smooth: true,
})`
  p {
    color: #292c38;
  }
`

export const GreenBox = styled(SectorTypeBox).attrs({
  bc: '#D8F0E9',
})`
  p {
    color: #3cb593;
  }
`

export const Separator = styled(BoxFill).attrs({
  bc: '#E3E8F1',
  height: '1px',
})``
