import React, { FC, useState, useEffect, useCallback } from 'react'
import { ProjectTypeCard } from './ProjectTypeCard'
import { useProjectTypesCRUD } from 'hooks/useProjectTypesCRUD'
import { AsyncStatus } from 'types/helps'
import Loader from 'components/Loader'
import { H2 } from 'styles'
import { AddProjectModal } from './AddProjectModal'
import styled from 'styled-components'

export const CustomProjectTypes: FC = () => {
  const { list, getList, getListStatus } = useProjectTypesCRUD()
  const [isAddProjectModalOpen, setProjectModalOpen] = useState<boolean>(false)

  const createNewProject = useCallback(() => {
    setProjectModalOpen(true)
  }, [])

  const closeAddProjectModal = useCallback(() => {
    setProjectModalOpen(false)
  }, [])

  useEffect(() => {
    getList()
  }, [])

  // костыль aka fastest way
  const refreshList = useCallback(() => {
    getList()
  }, [])

  return (
    <>
      <div style={{ width: '100%', padding: 40 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <H2 black fw="bold">
            Projects
          </H2>
          <AddNewButton type="button" onClick={createNewProject}>
            Add new project type
          </AddNewButton>
        </div>
        <div style={{ marginTop: 18 }}>
          {getListStatus === AsyncStatus.LOADING && <Loader />}
          {getListStatus === AsyncStatus.SUCCESS && list.length > 0 ? (
            <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              {list.reverse().map(project => (
                <ProjectTypeCard key={project.id} project={project} refreshList={refreshList} />
              ))}
            </div>
          ) : (
            getListStatus !== AsyncStatus.LOADING && (
              <div>
                <p>You don't have any projects created yet</p>
              </div>
            )
          )}
        </div>
      </div>
      <AddProjectModal isOpen={isAddProjectModalOpen} onClose={closeAddProjectModal} refreshList={refreshList} />
    </>
  )
}

const AddNewButton = styled.button`
  background-color: hsla(163, 44%, 89%, 1);
  border-radius: 6px;
  border: none;
  color: hsla(163, 50%, 47%, 1);
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Mukta', sans-serif;
  :hover {
    filter: brightness(90%);
  }
`
