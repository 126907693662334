import { call, put, takeLatest, select, delay } from 'redux-saga/effects'

import TaskActions, { GET_TASK_LIST, GET_TASK_LIST_BY_FILTER, CREATE_TASK, UPDATE_TASK, DELETE_TASK, CLOSE_TASK, RESTORE_TASK } from './action'

import TaskApi from 'api/task'

function* getTaskListFlow() {
  try {
    const { data } = yield call(TaskApi.getTaskList)
    yield put(TaskActions.getTaskListSuccess(data))
  } catch (err) {
    yield put(TaskActions.getTaskListFail(err))
  }
}

function* getTaskListByFilterFlow(action: ReturnType<typeof TaskActions.getTaskListByFilter>) {
  try {
    const { data } = yield call(TaskApi.getTaskListByFilter, action.payload.priority)
    yield put(TaskActions.getTaskListByFilterSuccess(action.payload.priority, data))
  } catch (err) {
    yield put(TaskActions.getTaskListByFilterFail(err))
  }
}

function* createTaskFlow(action: ReturnType<typeof TaskActions.createTask>) {
  try {
    const task = yield select(state => state.task)

    const body = {
      title: task.title,
      description: task.description,
      priority: task.priority,
      dueDate: task.taskDate || null,
      dueTime: task.taskTime || null,
    }

    const { data } = yield call(TaskApi.createTask, body)

    yield put(TaskActions.createTaskSuccess(data))

    switch (action.payload.priority.toLocaleLowerCase()) {
      case 'high priority':
        yield put(TaskActions.getTaskListByFilter('high'))
        break
      case 'medium priority':
        yield put(TaskActions.getTaskListByFilter('medium'))
        break
      case 'any time':
        yield put(TaskActions.getTaskListByFilter('any'))
        break
      default:
        return
    }
  } catch (err) {
    yield put(TaskActions.createTaskFail(err.response.data.notValidFields[0].errorMessage))
  }
}

function* updateTaskFlow(action: ReturnType<typeof TaskActions.updateTask>) {
  try {
    const { id, task, priority } = action.payload
    const body = {
      title: task.title,
      description: task.description,
      priority: task.priority,
      dueDate: task.dueDate,
      dueTime: task.dueTime,
    }

    if (!task.dueDate) delete body.dueDate
    if (!task.dueTime) delete body.dueTime

    const { data } = yield call(TaskApi.updateTask, body, id)

    yield put(TaskActions.getTaskList())
    yield delay(1000)

    switch (priority && priority.toLocaleLowerCase()) {
      case 'high priority':
        yield put(TaskActions.getTaskListByFilter('high'))
        break
      case 'medium priority':
        yield put(TaskActions.getTaskListByFilter('medium'))
        break
      case 'any time':
        yield put(TaskActions.getTaskListByFilter('any'))
        break
      default:
        return
    }
    yield delay(1000)
    yield put(TaskActions.updateTaskSuccess(data))
  } catch (err) {
    yield put(TaskActions.updateTaskFail(err.response.data.notValidFields[0].errorMessage))
  }
}

function* deleteTaskFlow(action: ReturnType<typeof TaskActions.deleteTask>) {
  try {
    const { id, priority } = action.payload
    const { data } = yield call(TaskApi.deleteTask, id)
    switch (priority && priority.toLocaleLowerCase()) {
      case 'high priority':
        yield put(TaskActions.getTaskListByFilter('high'))
        break
      case 'medium priority':
        yield put(TaskActions.getTaskListByFilter('medium'))
        break
      case 'any time':
        yield put(TaskActions.getTaskListByFilter('any'))
        break
      default:
        return
    }
    yield delay(1000)
    yield put(TaskActions.getTaskListByFilter('done'))
    yield delay(1000)
    yield put(TaskActions.deleteTaskSuccess(data))
  } catch (err) {
    yield put(TaskActions.deleteTaskFail(err))
  }
}

function* closeTaskFlow(action: ReturnType<typeof TaskActions.closeTask>) {
  try {
    const { id, priority } = action.payload
    const { data } = yield call(TaskApi.closeTask, id)
    switch (priority.toLocaleLowerCase()) {
      case 'high priority':
        yield put(TaskActions.getTaskListByFilter('high'))
        break
      case 'medium priority':
        yield put(TaskActions.getTaskListByFilter('medium'))
        break
      case 'any time':
        yield put(TaskActions.getTaskListByFilter('any'))
        break
      default:
        return
    }
    yield delay(1000)
    yield put(TaskActions.getTaskListByFilter('done'))
    yield delay(1000)
    yield put(TaskActions.closeTaskSuccess(data))
  } catch (err) {
    yield put(TaskActions.closeTaskFail(err))
  }
}

function* restoreTaskFlow(action: ReturnType<typeof TaskActions.restoreTask>) {
  try {
    const { id, priority } = action.payload
    const { data } = yield call(TaskApi.restoreTask, id)
    switch (priority.toLocaleLowerCase()) {
      case 'high priority':
        yield put(TaskActions.getTaskListByFilter('high'))
        break
      case 'medium priority':
        yield put(TaskActions.getTaskListByFilter('medium'))
        break
      case 'any time':
        yield put(TaskActions.getTaskListByFilter('any'))
        break
      default:
        return
    }
    yield delay(1000)
    yield put(TaskActions.getTaskListByFilter('done'))
    yield delay(1000)
    yield put(TaskActions.restoreTaskSuccess(data))
  } catch (err) {
    yield put(TaskActions.restoreTaskFail(err))
  }
}

export default function* watchTask() {
  yield takeLatest(GET_TASK_LIST, getTaskListFlow)
  yield takeLatest(GET_TASK_LIST_BY_FILTER, getTaskListByFilterFlow)
  yield takeLatest(CREATE_TASK, createTaskFlow)
  yield takeLatest(UPDATE_TASK, updateTaskFlow)
  yield takeLatest(DELETE_TASK, deleteTaskFlow)
  yield takeLatest(CLOSE_TASK, closeTaskFlow)
  yield takeLatest(RESTORE_TASK, restoreTaskFlow)
}
