import React from 'react'

import { ISelectListItem } from 'types/forms'
import { IEmployer } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import CityStateSelect from 'components/Input/cityStateSelect'

import { BoxFill, Text } from 'styles'

type Props = {
  form: Partial<IEmployer>
  onChangeField: (e: InputEvent & ISelectListItem, type?: string) => void
  errors: Partial<IEmployer>
}

const First: React.FC<Props> = ({ onChangeField, errors, form }) => {
  const onChangeCity = (e: InputEvent & ISelectListItem) => onChangeField(e, 'City')
  return (
    <BoxFill column>
      <BoxFill mb="20px">
        <Input value={form.companyName} error={errors.companyName} onChange={onChangeField} name="companyName" label="Company name" />
      </BoxFill>
      <BoxFill mb="20px">
        <CityStateSelect error={errors.City} value={form.City!} onChange={onChangeCity} />
      </BoxFill>
      <BoxFill column mb="20px">
        <Input value={form.address} error={errors.address} onChange={onChangeField} name="address" label="HQ address" />
        <Text fw="300" s>
          The address of the main company office
        </Text>
      </BoxFill>
      <BoxFill mb="20px">
        <Input value={form.abn} error={errors.abn} onChange={onChangeField} name="abn" label="ABN" />
      </BoxFill>
      <BoxFill mb="20px">
        <Input value={form.website} error={errors.website} onChange={onChangeField} name="website" label="Website link (optional)" />
      </BoxFill>
      <BoxFill>
        <Input value={form.phone} error={errors.phone} onChange={onChangeField} name="phone" label="Office phone (optional)" />
      </BoxFill>
    </BoxFill>
  )
}

export default First
