import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Select, { components } from 'react-select'
import { ValidationError } from 'yup'

import { AppState } from 'store/rootReducer'
import SearchActions from 'store/search/action'
import EmployersActions from 'store/employers/actions'
import { IEmployer } from 'types/models'

import NotesModal from 'containers/Notes'
import Loader from 'components/Loader'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Input from 'components/Input'
import ContactCard from './components/ContactCard'

import { addJobPositionSchemaSecondStep, formatErrors } from 'services/validation'
import { smoothScroll } from 'utils'
import noContactsImg from 'assets/images/not-found-contacts.png'

import { Box, BoxCol, BoxFill, Label, Image, Text } from 'styles'

import {
  Wrap,
  Header,
  Title,
  Main,
  NCWrap,
  NCImg,
  NCTitle,
  NCSubtitle,
  ContainerCard,
  ModalWrap,
  NewTask,
  NTSubtitle,
  InputBlock,
  InputTwoBlocks,
  CreateTask,
  CreateTaskWrap,
  NewCompany,
  SelectAvatar,
  ScrollToTop,
  CloseModalContainer,
  Error,
} from './styles'

type ICompanies = {
  id: number
  label: string
  value: string
  avatar?: string
}

type IContactForm = {
  position: string
  name?: string
  email?: string
  phone?: string
}

const Contacts: React.FC<RouteComponentProps> = ({ history }) => {
  const [show, setShow] = useState(false)
  const [companies, setCompanies] = useState<ICompanies[]>([])
  const [contactForm, setContactForm] = useState<IContactForm>({
    position: '',
  })

  const [errors, setErrors] = useState<{
    name?: string
    position?: string
    email?: string
    phone?: string
  }>({})

  const [currentCompany, setCurrentCompany] = useState<ICompanies | null>(null)
  const [showScrollToTop, setScrollToTop] = useState(false)

  const employers = useSelector((state: AppState) => state.employers)
  const contacts = useSelector((state: AppState) => state.search.get('searchContacts'))
  const isFetching = useSelector((state: AppState) => state.search.get('isFetching'))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(SearchActions.clearInputValue())
    dispatch(SearchActions.searchContacts())
  }, [dispatch])

  useEffect(() => {
    setCompanies([])
    employers.get('allEmployers') &&
      employers.get('allEmployers').forEach((el: IEmployer) => {
        setCompanies((companies: ICompanies[]) => [
          ...companies,
          {
            id: el.id,
            label: el.companyName,
            value: el.companyName,
            avatar: el.logoFile && el.logoFile.link,
          },
        ])
      })
  }, [employers])

  window.addEventListener('scroll', () => {
    if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
      setScrollToTop(true)
    } else {
      setScrollToTop(false)
    }
  })

  const noContacts = () => (
    <NCWrap>
      <NCImg src={noContactsImg} />
      <NCTitle>No contacts here</NCTitle>
      <NCSubtitle>
        <span onClick={() => setShow(!show)}>Add a new contact</span> and link it to existing <br /> company or create a new company
      </NCSubtitle>
    </NCWrap>
  )

  const sortContactsByName = (a: any, b: any) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()

    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }

  const renderContacts =
    contacts && contacts.length ? (
      <ContainerCard>
        {contacts.sort(sortContactsByName).map((el: any) => (
          <ContactCard
            id={el.id}
            key={el.id}
            name={el.name}
            position={el.position}
            email={el.email}
            phone={el.phone}
            employer={el.Employer}
            notesCount={el.notesCount}
            publicData={el.public}
          />
        ))}
        {showScrollToTop && <ScrollToTop src={require('assets/icons/scroll-to-top.svg')} onClick={() => smoothScroll()} />}
      </ContainerCard>
    ) : (
      noContacts()
    )

  const customStyles = {
    container: (styles: any) => ({
      ...styles,
      width: '100%',
    }),
    control: (styles: any) => ({
      ...styles,
      border: '1px solid #E3E8F1',
      boxShadow: 'inset 0px 1px 5px rgba(188,196,208,0.2)',
      height: '60px',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: '#9CAAB8',
      fontWeight: 'normal',
      fontSize: '18px',
      fontFamily: 'Mukta',
    }),
    singleValue: (styles: any) => ({
      ...styles,
    }),
  }

  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <Box alignCenter>
        {props.data && props.data.avatar && <SelectAvatar src={props.data.avatar} />}
        <p style={{ marginLeft: '10px' }}>{props.data && props.data.value}</p>
      </Box>
    </components.SingleValue>
  )

  const Option = (props: any) => (
    <components.Option {...props}>
      <Box alignCenter>
        {props.data && props.data.avatar && <SelectAvatar src={props.data.avatar} />}
        <p style={{ marginLeft: '10px' }}>{props.data && props.data.value}</p>
      </Box>
    </components.Option>
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      name,
      value,
      validity: { valid },
    } = e.target
    if (valid) {
      setErrors({})
      setContactForm((prevState: IContactForm) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const addNewContact = useCallback(
    show => {
      setShow(!show)
      dispatch(EmployersActions.getAllLiveJobs())
    },
    [dispatch]
  )

  const handleCreateContact = useCallback(
    (currentCompany, contactForm) => {
      addJobPositionSchemaSecondStep
        .validate(contactForm, { abortEarly: false })
        .then(() => {
          setErrors({})
          dispatch(EmployersActions.createEmployerContact(currentCompany.id, contactForm))
          setShow(false)
          setTimeout(() => {
            dispatch(SearchActions.searchContacts())
          }, 1000)
          setContactForm({
            position: '',
          })
        })
        .catch((err: ValidationError) => setErrors(formatErrors(err)))
    },
    [dispatch]
  )

  const disabled = !currentCompany || Object.keys(errors).length > 0 || !contactForm.email

  const closeModal = () => {
    setShow(false)
    setErrors({})
    setContactForm({
      position: '',
    })
  }

  return (
    <Wrap>
      <Header>
        <BoxCol>
          <Title>Contacts</Title>
          {contacts && contacts.length ? (
            <Text s opacity="0.6">{`${contacts.length > 1 ? `${contacts.length} contacts` : `${contacts.length} contact`} in total`}</Text>
          ) : null}
        </BoxCol>
        <Button width="154px" height="52px" title="ADD NEW CONTACT" onClick={() => addNewContact(show)} />
      </Header>
      <Main>
        <Error>{employers.get('error')}</Error>
        {isFetching ? <Loader withoutBg height="500px" /> : renderContacts}
      </Main>
      <Modal isOpen={show} onRequestClose={() => closeModal()}>
        <ModalWrap>
          <NewTask black xl3>
            New contact
          </NewTask>
          <NTSubtitle left>
            Add your client contact details and choose a company <br /> they work for or create a new company
          </NTSubtitle>

          <BoxFill mb="20px" column>
            <Label htmlFor="state">Company</Label>
            <Select
              components={{ Option, SingleValue, IndicatorSeparator: null }}
              styles={customStyles}
              isClearable
              isSearchable
              placeholder="Choose a company"
              options={companies}
              onChange={(item: any) => setCurrentCompany(item)}
            />
            <NewCompany>
              <span onClick={() => history.push('/live-jobs/add')}>Create a new company</span> if you don’t have added that company to the platofm
              before
            </NewCompany>
          </BoxFill>

          <InputBlock>
            <Input
              name={`name`}
              label="Business contact name"
              placeholder="Full name"
              onChange={handleInputChange}
              pattern=".{0,50}"
              value={contactForm.name}
              error={errors.name}
            />
          </InputBlock>

          <InputBlock>
            <Input
              name="position"
              label="Business contact position (optional)"
              placeholder="e.g. Hiring manager"
              onChange={handleInputChange}
              pattern=".{0,50}"
              value={contactForm.position}
              error={errors.position}
            />
          </InputBlock>

          <InputTwoBlocks>
            <Input
              name={`email`}
              label="Business contact email"
              placeholder="example@mail.com"
              onChange={handleInputChange}
              value={contactForm.email}
              error={errors.email}
            />

            <Input
              name="phone"
              label="Phone number (optional)"
              placeholder="Phone number"
              onChange={handleInputChange}
              pattern=".{0,150}"
              value={contactForm.phone}
              error={errors.phone}
            />
          </InputTwoBlocks>

          <CreateTaskWrap justifyCenter>
            <CreateTask title="CREATE CONTACT" onClick={() => handleCreateContact(currentCompany, contactForm)} disabled={disabled} />
          </CreateTaskWrap>

          <CloseModalContainer onClick={() => setShow(false)}>
            <Image width="14" pointer src={require('assets/icons/close.svg')} />
          </CloseModalContainer>
        </ModalWrap>
      </Modal>
      <NotesModal />
    </Wrap>
  )
}

export default withRouter(Contacts)
