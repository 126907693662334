import React from 'react'
import styled from 'styled-components'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { IQueryParams } from 'types/helps'

import { PaginationArrow } from 'components/Icons'

import { Text, BoxFill, BoxCenterContent } from 'styles'

const BoxPage = styled(BoxCenterContent).attrs({
  br: '6px',
  width: '52px',
  height: '52px',
  margin: '0 4px',
})`
  background-color: ${props => (props.isActive ? props.theme.main.accentColor : '#fff')};
`
const ArrowContainer = styled(BoxCenterContent).attrs({
  pointer: true,
  whiteBC: true,

  br: '6px',
  width: '52px',
  height: '52px',
})``

const BoxPageText = styled(Text)`
  color: ${props => (props.isActive ? '#fff' : null)};
`

type Props = {
  pagesQty: number
  currentPage: number
  fetchPage: (query: IQueryParams) => void
}

const Pagination: React.FC<Props & RouteComponentProps> = ({ location, history, pagesQty, currentPage, fetchPage }) => {
  const pages: number[] = Array.from(Array(pagesQty), (x, index) => index + 1)
  const setPage = (page: number) => history.push(`${location.pathname}?page=${page}`)
  const nextPage = () => {
    if (currentPage <= pagesQty) {
      fetchPage({ page: ++currentPage })
      setPage(currentPage)
    }
  }
  const prevPage = () => {
    if (currentPage > 1) {
      fetchPage({ page: --currentPage })
      setPage(currentPage)
    }
  }
  return (
    <BoxFill justifyCenter pt="40px" pb="70px">
      <ArrowContainer onClick={prevPage} mr="8px">
        <PaginationArrow isActive={currentPage > 1} />
      </ArrowContainer>
      {pages.map((numPage: number) => (
        <BoxPage
          onClick={() => {
            fetchPage({ page: numPage })
            setPage(numPage)
          }}
          isActive={currentPage === numPage}
          pointer
          key={numPage}
        >
          <BoxPageText black isActive={currentPage === numPage} pointer>
            {numPage}
          </BoxPageText>
        </BoxPage>
      ))}
      <ArrowContainer transform="rotate(0.5turn)" onClick={currentPage < pagesQty ? nextPage : () => false} pointer ml="8px">
        <PaginationArrow isActive={currentPage < pagesQty} />
      </ArrowContainer>
    </BoxFill>
  )
}

export default withRouter(Pagination)
