import React from 'react'
import styled from 'styled-components'

import { IEmployersState, ICandidatesState } from 'types/reducerStates'

import Button from 'components/Button'

import { Box, BoxFill, BoxFullFill, Text, H2 } from 'styles'

const Container = styled(BoxFullFill)`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;
  width: 100%;
  padding: 0 80px 50px 80px;
  margin-top: -50px;
`

type Props = {
  items: IEmployersState | ICandidatesState
  renderItems: () => JSX.Element
  image: React.ReactNode
  title: string
  subTitle: string
  buttonText: string
  addItemRoute: () => void
}

const BodyComponent: React.FC<Props> = ({ renderItems, addItemRoute, image, items, title, subTitle, buttonText }) => {
  if (items.get('items').isEmpty()) {
    return (
      <BoxFill>
        <BoxFill column alignCenter>
          <Box mt="112px" mb="40px">
            {image}
          </Box>
          <Box maxWidth="365px">
            <H2 black fw="bold">
              {title}
            </H2>
          </Box>
          <Box mb="30px" mt="10px" maxWidth="350px">
            <Text>{subTitle}</Text>
          </Box>
          <Box mb="120px" width="141px" height="52px">
            <Button onClick={addItemRoute} type="secondary" title={buttonText} />
          </Box>
        </BoxFill>
      </BoxFill>
    )
  }

  return (
    <BoxFill minHeight="50vh">
      <Container>{renderItems()}</Container>
    </BoxFill>
  )
}

export default BodyComponent
