import styled from 'styled-components'

import { BoxCenterContent } from 'styles'

export const NotImageBox = styled(BoxCenterContent).attrs(props => ({
  pointer: true,
  shadow2: true,
  // bc: props.theme.main.accentColor2,
  br: '50%',
}))``

export const NotImageBoxChars = styled(BoxCenterContent).attrs(props => ({
  pointer: true,
  shadow2: true,
  bc: props.theme.main.accentColor2,
  br: '50%',
}))``
