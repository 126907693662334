import styled from 'styled-components'

import config from '../config'

import Modal from 'components/Modal'
import Button from 'components/Button'

import { Bold, Input, Text, BoxCol, Textarea } from 'styles'

interface IAvatarProps {
  mini?: boolean
  user?: boolean
}

export const Preview = styled.div`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  bottom: 0;
  border: 1px solid rgba(224, 224, 224, 0.2);
  border-bottom: none;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 20px;
  transition: top 5s ease-out;
  z-index: 999;
`

export const PreviewContainer = styled.div<{ isOpen?: boolean }>`
  height: ${props => `${props.isOpen ? config.height : config.headerHeight - 2}px`};
  width: ${config.previewWidth}px;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: all 0.5s ease;
`

export const Header = styled.div`
  height: ${config.headerHeight}px;
  padding: 10px 20px;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: #0d1626;
`

export const Close = styled.img`
  width: 14px;
  height: 14px;
`

export const Paperclip = styled.img`
  width: 20px;
  height: 20px;
  padding: 10px 15px;
  cursor: pointer;
  border-right: 1px solid rgba(224, 224, 224, 0.8);
`

export const Minus = styled.img`
  width: 25px;
  height: 25px;
`

export const Send = styled.img`
  width: 20px;
  height: 20px;
  padding: 10px 15px;
  cursor: pointer;
`

export const Cyrcle = styled.div`
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #2697ff;
  border-radius: 100%;
  margin-left: 4px;
`

export const PreviewContentWrapper = styled.div`
  background: white;
  min-height: ${config.height - config.headerHeight}px;
  max-height: ${config.height - config.headerHeight}px;
`

export const ContactSearchBar = styled.div`
  padding: 8px 10px;
`

export const ContactSearch = styled(Input)`
  font-size: 14px;
  padding: 7px 10px;
  ::-webkit-input-placeholder {
    font-size: 14px;
  }
  ::-moz-placeholder {
    font-size: 14px;
  }
  :-ms-input-placeholder {
    font-size: 14px;
  }
  ::placeholder {
    font-size: 14px;
  }
`

export const CompanionsList = styled.div`
  padding: 8px 10px;
  height: ${config.height - config.headerHeight - 45}px;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const Companions = styled.div`
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
`

export const CompanionName = styled.div`
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  display: flex;
`

export const CompanionMessage = styled.div`
  font-size: 12px;
  color: darkgray;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const TextInfo = styled.div<{ hasUnreads?: boolean }>`
  width: ${props => `${props.hasUnreads ? config.previewWidth - 108 : config.previewWidth - 88}px`};
`

export const CompanionInfo = styled.div`
  width: ${config.previewWidth - 98}px;
  justify-content: right;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  margin-bottom: -10px;
  margin-top: -10px;
`

export const Unreads = styled.div`
  display: flex;
  flex: 100%;
  justify-content: flex-end;
  align-items: center;
  text-overflow: ellipsis;
`

export const Count = styled.div`
  font-size: 12px;
  margin-left: 3px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background: #2697ff;
  color: white;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
`

export const Avatar = styled.img<{ mini?: boolean }>`
  width: ${props => `${props.mini ? 30 : 46}px`};
  height: ${props => `${props.mini ? 30 : 46}px`};
  border-radius: 50%;
  margin-right: 10px;
`

export const Dialog = styled.div`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  bottom: 0;
  border: 1px solid rgba(224, 224, 224, 0.2);
  border-bottom: none;
  margin: 0;
  padding: 0;
  position: fixed;
  right: ${config.previewWidth + 40}px;
  transition: top 5s ease-out;
  z-index: 999;
`

export const DialogContainer = styled.div<{ isOpen?: boolean }>`
  height: ${props => `${props.isOpen ? config.height : 0}px`};
  width: ${config.dialogWidth}px;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: all 0.5s ease;

  display: flex;
  flex-direction: column;
`

export const HeaderCompanionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TransparentBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.3);
`

export const MessageInput = styled(Input)`
  box-shadow: none;
  margin-left: 15px;
  font-size: 14px;
`

export const MessageBlock = styled.div`
  display: flex;
  align-items: flex-start;
  :not(:last-child) {
    margin-bottom: 20px;
  }
`

export const MessageInfoBlock = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #292c38;
  div {
    max-width: 500px;
  }
`

export const AttachemntWrapper = styled.a`
  max-width: ${config.dialogWidth - 140}px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 12px;
  margin: 5px 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  a {
    max-width: ${config.dialogWidth - 210}px;
    margin: 0 0 0 10px;
  }
  img {
    max-width: ${config.dialogWidth - 210}px;
  }
`

export const AttachmentInfo = styled.div`
  flex: 100%;
`

export const FileAttachment = styled.img`
  width: 20px;
  height: 20px;
  padding: 9px;
  background: #e61c2e;
  border-radius: 6px;
`

export const AttachmentName = styled.div`
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  margin: 0 0 0 10px;
`

export const Calendar = styled.img`
  width: 20px;
  height: 20px;
  padding: 9px;
  background: #2697ff;
  border-radius: 6px;
  margin-right: 10px;
`

export const Title = styled(Bold).attrs({})``

export const Subtitle = styled(Text).attrs({})`
  margin: 0 0 30px 0;
`

export const AvatarBG = styled.div<IAvatarProps>`
  width: ${props => (props.mini ? '30px' : '45px')};
  height: ${props => (props.mini ? '30px' : '45px')};
  border-radius: 50%;
  border: ${props => (props.user ? '2px solid #3CB593' : '2px solid #2697ff')};
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FileAttachmentDefault = styled.img`
  min-width: 50px;
  max-width: 420px;
`

export const IsNotModal = styled(Modal)``

export const ModalWrap = styled(BoxCol)`
  width: 585px;
  max-width: 1000px;
  height: 390px;
  align-items: center;
  justify-content: center;
`

export const ModalTitle = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #292c38;
  margin: 36px 0 13px 0;
`

export const ModalSubTitle = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 25px 0;
`

export const AddEmail = styled(Button)``

export const EmptyList = styled(BoxCol)`
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  color: #9caab8;
  img {
    margin: 20px 0 0 0;
  }
`

export const EmptyListHeader = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #292c38;
  margin: 0 0 20px 0;
`

export const DialogContentWrapper = styled.div`
  background: white;
  min-height: 50%;
  max-height: 100%;
  height: inherit;
  padding: 8px 20px 0 8px;
  overflow: hidden;
  overflow-y: scroll;
  flex: 1;
`

export const DialogInputWrapper = styled.div`
  display: flex;
  align-items: center;
  max-height: 40%;
  align-items: flex-end;
  border-top: 1px solid rgba(224, 224, 224, 0.8);
  padding: 15px 0 8px 0;
  background: white;
  place-items: flex-end;
`

export const MessageTextarea = styled(Textarea).attrs({})`
  min-height: 38px;
  max-height: 185px;
  padding: 0;
  margin: 0 0 0 14px;
`

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`

export const ModalContainer = styled.div`
  max-width: 800px;
`
