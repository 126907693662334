import React, { useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import SearchActions from 'store/search/action'
import { InputEvent } from 'types/helps'
import { IList, ICity } from 'types/models'
import { ISelectListItem } from 'types/forms'

import Header from './components/Header'
import Candidates from './Candidates'
import Jobs from './Jobs'
import Companies from './Companies'
import Contacts from './Contacts'

import { BoxFill } from 'styles'

const Wrap = styled(BoxFill)`
  margin: -160px 0 0 0;
  padding: 0 40px;
  min-height: calc(100vh - 186px);
`

type Props = {
  changeInputValue: typeof SearchActions.changeInputValue
  changeSelectValue: typeof SearchActions.changeSelectValue
}

const Search: React.FC<Props & RouteComponentProps> = ({ changeInputValue, changeSelectValue, history }) => {
  const fromPage = history.location.state.fromPage.pathname
  let currentPrevPath

  switch (fromPage) {
    case '/live-jobs':
      currentPrevPath = 'Employers'
      break
    case '/live-jobs-new':
      currentPrevPath = 'Live Jobs'
      break
    case '/contacts':
      currentPrevPath = 'Contacts'
      break
    default:
      currentPrevPath = 'Candidates'
  }

  const [currentSearch, setCurrentSearch] = useState(currentPrevPath)

  const onChangeInput = (e: InputEvent & ISelectListItem) => {
    const { name, value } = e.currentTarget
    switch (name) {
      case 'candidatesKeywords':
        changeInputValue('candidatesKeywords', value)
        break
      case 'candidatesJobTitle':
        changeInputValue('candidatesJobTitle', value)
        break
      case 'jobsJobTitle':
        changeInputValue('jobsJobTitle', value)
        break
      case 'jobsCompanyName':
        changeInputValue('jobsCompanyName', value)
        break
      case 'companiesJobTitle':
        changeInputValue('companiesJobTitle', value)
        break
      case 'contactsKeywords':
        changeInputValue('contactsKeywords', value)
        break
      case 'contactsJobTitle':
        changeInputValue('contactsJobTitle', value)
        break
    }
  }

  const onChangeSelect = (e: ICity[] | IList[], type: string) => {
    changeSelectValue(type, e)
  }

  const renderSearch = (currentSearch: string): any => {
    switch (currentSearch) {
      case 'Candidates':
        return <Candidates onChangeInput={onChangeInput} onChangeSelect={onChangeSelect} />
      case 'Live Jobs':
        return <Jobs onChangeInput={onChangeInput} onChangeSelect={onChangeSelect} />
      case 'Employers':
        return <Companies onChangeInput={onChangeInput} onChangeSelect={onChangeSelect} />
      case 'Contacts':
        return <Contacts onChangeInput={onChangeInput} onChangeSelect={onChangeSelect} />
    }
  }

  return (
    <>
      <Header title="Search" lookingFor={currentSearch} setCurrentSearch={setCurrentSearch} />
      <Wrap>{renderSearch(currentSearch)}</Wrap>
    </>
  )
}

export default connect(
  null,
  {
    changeInputValue: SearchActions.changeInputValue,
    changeSelectValue: SearchActions.changeSelectValue,
  }
)(Search)
