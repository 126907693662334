import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'
import SearchActions from 'store/search/action'
import CandidatesActions from 'store/candidates/actions'
import { InputEvent } from 'types/helps'
import { IList, ICity } from 'types/models'

import Input from 'components/Input'
import InputSelect from 'components/Input/InputSelect'
import Button from 'components/Button'
import CandidateCard from 'components/ForCandidates&Employers/candidateCard'
import Loader from 'components/Loader'
import { NothingFound } from '../components/NothingFound'
import SavedSearch from '../components/SavedSearch'

import { formateDateToYYYYMMDD, formateDateToYYYYMMDDByStringDate, sortByFirstAndLastName } from 'utils'

import { Wrap, Container, ButtonBlock, Block, SaveWrap, SavedSearches, SavedThisSearch, ContainerCard, SearchCount } from './styled'

type Props = {
  onChangeInput: (e: InputEvent, type?: string) => void
  onChangeSelect: (e: ICity[] | IList[], type: string) => void
  searchCandidates: typeof SearchActions.searchCandidates
  saveCandidates: typeof SearchActions.saveCandidates
  getSearchList: typeof SearchActions.getSearchList
  setValueFromSavedSearch: typeof SearchActions.setValueFromSavedSearch
  clearInputValue: typeof SearchActions.clearInputValue
  setStatusCandidate: typeof CandidatesActions.setStatusCandidate
  search: any
  isFetching: boolean
  fetchCandidates: typeof CandidatesActions.fetchCandidatesStarted
}

const Candidates: React.FC<Props> = ({
  onChangeInput,
  onChangeSelect,
  setStatusCandidate,
  searchCandidates,
  saveCandidates,
  getSearchList,
  search,
  clearInputValue,
  setValueFromSavedSearch,
  isFetching,
  fetchCandidates,
}) => {
  const onChangeCity = (e: ICity[]) => onChangeSelect(e, 'candidatesCity')
  const onChangeProjectTypes = (e: IList[]) => onChangeSelect(e, 'candidatesProjectTypes')
  const onChangeAvailability = (e: IList[]) => onChangeSelect(e, 'candidatesAvailability')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    clearInputValue()
    searchCandidates()
    getSearchList('canidates')
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const page = Number(params.get('page')) || 1
    fetchCandidates({ page })
  }, [])

  const handleSaveCandidates = () => saveCandidates()

  const handleSaveSearches = () => setIsOpen(true)
  const closeSaveSearches = () => setIsOpen(false)

  const apply = (el: any) => {
    if (el.jobTitle) setValueFromSavedSearch('candidatesJobTitle', el.jobTitle)
    if (el.name) setValueFromSavedSearch('candidatesKeywords', el.name)
    if (el.cityIds) setValueFromSavedSearch('candidatesCity', el.cityIds)
    if (el.availability) {
      const day = +formateDateToYYYYMMDDByStringDate(el.availability)
      setValueFromSavedSearch('candidatesAvailability', formateDateToYYYYMMDD(day))
    }
    if (el.sectorIds) setValueFromSavedSearch('candidatesProjectTypes', el.sectorIds)
    searchCandidates()
    clearInputValue()
    closeSaveSearches()
  }

  const renderCandidates = () =>
    search
      .get('searchCandidates')
      .sort(sortByFirstAndLastName)
      .map((data: any) => {
        return <CandidateCard key={data.id} candidate={data} setStatusCandidate={setStatusCandidate} />
      })

  const searchCount = search.get('searchCandidates')

  return (
    <Container>
      <Wrap fill>
        <Block minWidth={154}>
          <Input type="text" height={50} placeholder="Name or keywords" noBorder br onChange={onChangeInput} name="candidatesKeywords" />
        </Block>
        <Block minWidth={142}>
          <Input type="text" height={50} placeholder="Job title" noBorder br onChange={onChangeInput} name="candidatesJobTitle" />
        </Block>
        <Block width={183} minWidth={183}>
          <InputSelect placeholder="Location" optionsType="cities" isMulti isSearchComponent isCustom onChange={onChangeCity} />
        </Block>
        <Block width={203} minWidth={203}>
          <InputSelect placeholder="Availability" optionsType="searchWeeks" isSearchComponent onChange={onChangeAvailability} />
        </Block>
        <Block minWidth={213}>
          <InputSelect placeholder="Project type" optionsType="projectTypes" isMulti isSearchComponent isCustom onChange={onChangeProjectTypes} />
        </Block>
        <ButtonBlock>
          <Button width="138px" height="50px" title="Search" type="secondary" onClick={() => searchCandidates()} />
        </ButtonBlock>
      </Wrap>
      <SaveWrap>
        <SavedSearches>
          <span onClick={handleSaveSearches}>Saved searches ({search.get('canidates').total})</span>
          {searchCount && searchCount.length > 0 && (
            <SearchCount left s opacity="0.6">{`${
              searchCount.length > 1 ? `${searchCount.length} candidates` : `${searchCount.length} candidate`
            } in total`}</SearchCount>
          )}
          <SavedSearch isOpen={isOpen} closeSaveSearches={closeSaveSearches} docs={search.get('canidates').docs} apply={apply} />
        </SavedSearches>
        <SavedThisSearch secondColor onClick={() => handleSaveCandidates()}>
          Save this search
        </SavedThisSearch>
      </SaveWrap>
      {isFetching ? (
        <Loader height="50vh" withoutBg />
      ) : (
        <>{searchCount.length < 1 ? <NothingFound /> : <ContainerCard>{renderCandidates()}</ContainerCard>}</>
      )}
    </Container>
  )
}

const mapStateToProps = (state: AppState) => ({
  search: state.search,
  isFetching: state.search.get('isFetching'),
})

export default connect(
  mapStateToProps,
  {
    searchCandidates: SearchActions.searchCandidates,
    saveCandidates: SearchActions.saveCandidates,
    getSearchList: SearchActions.getSearchList,
    setStatusCandidate: CandidatesActions.setStatusCandidate,
    setValueFromSavedSearch: SearchActions.setValueFromSavedSearch,
    clearInputValue: SearchActions.clearInputValue,
    fetchCandidates: CandidatesActions.fetchCandidatesStarted,
  }
)(Candidates)
