import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { AppState } from 'store/rootReducer'
import SearchActions from 'store/search/action'
import { InputEvent } from 'types/helps'
import { IList, ICity } from 'types/models'

import NotesModal from 'containers/Notes'
import Loader from 'components/Loader'
import Button from 'components/Button'
import Input from 'components/Input'
import InputSelect from 'components/Input/InputSelect'
import ContactCard from '../components/ContactCard'
import SavedSearch from '../components/SavedSearch'
import { NothingFound } from '../components/NothingFound'

import { Wrap, Container, ButtonBlock, Block, SaveWrap, SavedSearches, SavedThisSearch, ContainerCard, SearchCount } from './styled'

type Props = {
  onChangeInput: (e: InputEvent, type?: string) => void
  onChangeSelect: (e: ICity[] | IList[], type: string) => void
  searchContacts: typeof SearchActions.searchContacts
  saveContacts: typeof SearchActions.saveContacts
  getSearchList: typeof SearchActions.getSearchList
  clearInputValue: typeof SearchActions.clearInputValue
  setValueFromSavedSearch: typeof SearchActions.setValueFromSavedSearch
  search: any
  isFetching: boolean
}

const Contacts: React.FC<Props & RouteComponentProps> = ({
  onChangeInput,
  onChangeSelect,
  searchContacts,
  saveContacts,
  getSearchList,
  search,
  clearInputValue,
  setValueFromSavedSearch,
  isFetching,
}) => {
  const onChangeCity = (e: ICity[]) => onChangeSelect(e, 'contactsCity')
  const onChangeProjectTypes = (e: IList[]) => onChangeSelect(e, 'contactsProjectTypes')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    searchContacts()
    getSearchList('contacts')
  }, [])

  const handleSaveContacts = () => saveContacts()

  const handleSaveSearches = () => setIsOpen(true)
  const closeSaveSearches = () => setIsOpen(false)

  const apply = (el: any) => {
    if (el.name) setValueFromSavedSearch('contactsKeywords', el.name)
    if (el.jobTitle) setValueFromSavedSearch('contactsJobTitle', el.jobTitle)
    if (el.cityIds) setValueFromSavedSearch('contactsCity', el.cityIds)
    if (el.sectorIds) setValueFromSavedSearch('contactsProjectTypes', el.sectorIds)
    searchContacts()
    clearInputValue()
    closeSaveSearches()
  }

  const sortByName = (a: any, b: any) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()

    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }

  const renderContacts = () =>
    search
      .get('searchContacts')
      .sort(sortByName)
      .map((data: any) => {
        return (
          <ContactCard
            id={data.id}
            key={data.id}
            name={data.name}
            position={data.position}
            email={data.email}
            phone={data.phone}
            employer={data.Employer}
            notesCount={data.notesCount}
            publicData={data.public}
          />
        )
      })

  const searchCount = search.get('searchContacts')

  return (
    <Container>
      <Wrap fill>
        <Block minWidth={154}>
          <Input type="text" height={50} placeholder="Name or keywords" noBorder br onChange={onChangeInput} name="contactsKeywords" />
        </Block>
        <Block minWidth={142}>
          <Input type="text" height={50} placeholder="Job title" noBorder br onChange={onChangeInput} name="contactsJobTitle" />
        </Block>
        <Block width={183} minWidth={183}>
          <InputSelect placeholder="Location" optionsType="cities" isMulti isSearchComponent isCustom onChange={onChangeCity} />
        </Block>
        <Block minWidth={213}>
          <InputSelect placeholder="Project type" optionsType="projectTypes" isMulti isSearchComponent isCustom onChange={onChangeProjectTypes} />
        </Block>
        <ButtonBlock>
          <Button width="138px" height="50px" title="Search" type="secondary" onClick={() => searchContacts()} />
        </ButtonBlock>
      </Wrap>
      <SaveWrap>
        <SavedSearches>
          <span onClick={handleSaveSearches}>Saved searches ({search.get('contacts').total})</span>
          {searchCount && searchCount.length > 0 && (
            <SearchCount left s opacity="0.6">{`${
              searchCount.length > 1 ? `${searchCount.length} contacts` : `${searchCount.length} contact`
            } in total`}</SearchCount>
          )}
          <SavedSearch isOpen={isOpen} closeSaveSearches={closeSaveSearches} docs={search.get('contacts').docs} apply={apply} />
        </SavedSearches>
        <SavedThisSearch secondColor onClick={() => handleSaveContacts()}>
          Save this search
        </SavedThisSearch>
      </SaveWrap>
      {isFetching ? (
        <Loader height="50vh" withoutBg />
      ) : (
        <>{!search.get('searchContacts').length ? <NothingFound /> : <ContainerCard>{renderContacts()}</ContainerCard>}</>
      )}
      <NotesModal />
    </Container>
  )
}

const mapStateToProps = (state: AppState) => ({
  search: state.search,
  isFetching: state.search.get('isFetching'),
})

export default connect(
  mapStateToProps,
  {
    searchContacts: SearchActions.searchContacts,
    saveContacts: SearchActions.saveContacts,
    getSearchList: SearchActions.getSearchList,
    setValueFromSavedSearch: SearchActions.setValueFromSavedSearch,
    clearInputValue: SearchActions.clearInputValue,
  }
)(withRouter(Contacts))
