import React, { useState } from 'react'
import styled from 'styled-components'
import { Map } from 'immutable'
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
  IInputProps,
  IPreviewProps,
  getFilesFromEvent,
  IFileWithMeta,
  StatusValue,
} from 'react-dropzone-uploader'

import {
  paramsForUploadPublicLicenseOfCandidate,
  paramsForUploadLicenseOfCandidate,
  paramsForUploadPublicCVOfCandidate,
  paramsForUploadCVOfCandidate,
} from 'api/candidates'

import { IFile } from 'types/models'

import { IUploadInputParams } from 'components/UploadInput/types'
import DocItem from './docItem'

import { getIconOfFileByType, bytesToSize, checkStatuses } from 'utils'

import { BoxFullFill, BoxCenterContent, Box, Text, Span, Image, BoxFill } from 'styles'
import 'react-dropzone-uploader/dist/styles.css'

// const MAX_FILE_SIZE_BYTES = 20971520
const MAX_FILE_SIZE_BYTES = 5000000

const Container = styled(BoxFullFill)<{ isFilesLoaded: boolean; isStartLoadingDocs: boolean }>`
  justify-content: ${props => (props.isStartLoadingDocs ? null : 'center !important')};
  align-items: center !important;
  border-radius: 6px !important;
  border: ${props => (props.isFilesLoaded || props.isStartLoadingDocs ? 'none !important' : '1px dashed #d2d7da !important')};
  min-height: 45vh !important;
  overflow: hidden !important;
  padding: 1px !important;
`
type Props = {
  title: 'CV' | 'license' | 'chatFile'
  id: number
  uploadedFiles?: Map<any, IFile> | any
  multiple?: boolean
  uploadParams?: () => Promise<IUploadInputParams>
  onUploadSuccess?: (file: any) => void
  onDeleteLicense?: (id: number, candidateId: number) => void
  onDeleteCV?: (id: number, candidateId: number) => void
  uploadChatFile?: any
  publicData?: boolean
}
/* TODO:! NEED REFACTORING ALL COMPONENT */
/* TOO DANGEROUSE FOR BUGS! */
/* DONT TRY TO UNDARSTAND HOW THIS FUCKING COMPONENT WORKS */
const UploadInput: React.FC<Props> = ({
  title,
  uploadedFiles,
  id,
  multiple,
  onUploadSuccess,
  onDeleteLicense,
  uploadParams,
  uploadChatFile,
  onDeleteCV,
  publicData,
}) => {
  let accept: any = {}
  if (title === 'license' || title === 'CV') {
    accept['license'] = '.png, .jpg, .jpeg, .pdf'
  }

  const DOC_TYPES = '.doc, .docx, .pdf, .png, .jpg, .jpeg'
  const isFiles = uploadedFiles.size >= 1
  const [error, setError] = useState<null | string>(null)
  const getUploadParams: IDropzoneProps['getUploadParams'] = ({ file }) => {
    /* TODO:! FIX THIS TS-IGNORE */
    // @ts-ignore
    if (title === 'license' && publicData) {
      return paramsForUploadPublicLicenseOfCandidate(id).then((params: IUploadInputParams) => {
        const body = new FormData()
        body.append(params.fieldName, file)
        return Object.assign(params.options, {
          body,
        })
      })
    } else if (title === 'license') {
      return paramsForUploadLicenseOfCandidate(id).then((params: IUploadInputParams) => {
        const body = new FormData()
        body.append(params.fieldName, file)
        return Object.assign(params.options, {
          body,
        })
      })
    } else if (title === 'CV' && publicData) {
      return paramsForUploadPublicCVOfCandidate(id).then((params: IUploadInputParams) => {
        const body = new FormData()
        body.append(params.fieldName, file)
        return Object.assign(params.options, {
          body,
        })
      })
    } else if (title === 'CV') {
      return paramsForUploadCVOfCandidate(id).then((params: IUploadInputParams) => {
        const body = new FormData()
        body.append(params.fieldName, file)
        return Object.assign(params.options, {
          body,
        })
      })
    } else {
      uploadChatFile(file)
      const body = new FormData()
      body.append('chatFile', file)
      const params = {
        url: '/',
      }
      return Object.assign(params, {
        body,
      })
    }
  }

  const textOfButton = () => {
    if (multiple) return 'Upload more files'
    return `Upload another file`
  }

  const onDeleteLicenseFile = (idFile: number, candidateId: number) => {
    onDeleteLicense!(idFile, candidateId)
  }

  const onDeleteCVFile = (idFile: number, candidateId: number) => {
    onDeleteCV!(idFile, candidateId)
  }

  const renderUploadedFiles = () => {
    if (!isFiles) return null
    return (
      <BoxFill column>
        {uploadedFiles.valueSeq().map((file: IFile) => {
          return (
            <BoxFill key={file.id} smooth defaultBorder padding="15px" mb="10px">
              <BoxFill alignCenter>
                <Box mr="10px">
                  <Image width="24px" height="30px" src={getIconOfFileByType(file.mimeType)} />
                </Box>
                <Box column>
                  <Text black fw="500">
                    {file.name}
                  </Text>
                  <Text s lh="14px">
                    {bytesToSize(file.size)}
                  </Text>
                </Box>
                {title === 'CV' && !publicData && (
                  <Box justifyRight flex={1}>
                    <Image onClick={() => onDeleteCVFile(file.id, id)} pointer width="14px" height="17px" src={require('assets/icons/trash.svg')} />
                  </Box>
                )}
                {title === 'license' && !publicData && (
                  <Box justifyRight flex={1}>
                    <Image
                      onClick={() => onDeleteLicenseFile(file.id, id)}
                      pointer
                      width="14px"
                      height="17px"
                      src={require('assets/icons/trash.svg')}
                    />
                  </Box>
                )}
              </BoxFill>
            </BoxFill>
          )
        })}
      </BoxFill>
    )
  }

  const Layout = ({ input, previews, dropzoneProps, files, extra: { onFiles } }: ILayoutProps) => {
    const isFilesLoaded = files.length ? checkStatuses(files[0].meta, ['done', 'headers_received']) : false
    const isStartLoadingDocs = files.length ? checkStatuses(files[0].meta, ['uploading', 'done', 'headers_received']) : false
    return (
      <BoxFullFill column>
        <Container isStartLoadingDocs={isStartLoadingDocs || isFiles} isFilesLoaded={isFilesLoaded} {...dropzoneProps}>
          {previews}
          {files.length || isFiles ? (
            renderUploadedFiles()
          ) : (
            <BoxFill>
              <BoxCenterContent fill>{input}</BoxCenterContent>
            </BoxFill>
          )}
        </Container>
        {isStartLoadingDocs || isFilesLoaded || isFiles ? (
          <label>
            <Span secondColor>{textOfButton()}</Span>
            <input
              style={{ display: 'none' }}
              type="file"
              onChange={async e => {
                const chosenFiles = await getFilesFromEvent(e)
                /* need ignore cuze wrong types of this func did author of lib, so tslint get error */
                // @ts-ignore
                onFiles(chosenFiles)
              }}
            />
          </label>
        ) : null}
      </BoxFullFill>
    )
  }

  const InputComponent = ({ accept, onFiles }: IInputProps) => (
    <Box column alignCenter>
      <Image src={require('assets/icons/upload.svg')} alt="upload" />
      <Text black>
        Drag&nbsp;{'&'}&nbsp;drop an {title} here
        <br />
        or&nbsp;
        <label>
          <Span secondColor>Browse</Span>
          <input
            style={{ display: 'none' }}
            type="file"
            accept={accept}
            onChange={async e => {
              const chosenFiles = await getFilesFromEvent(e)
              /* need ignore cuze wrong types of this func did author of lib, so tslint get error */
              // @ts-ignore
              onFiles(chosenFiles)
            }}
          />
        </label>
        &nbsp;your files
      </Text>
    </Box>
  )

  const onLoadedFile = (file: IFileWithMeta, status: StatusValue) => {
    if (status === 'rejected_file_type') {
      return setError('Unsupported format')
    }
    if (status === 'error_file_size') {
      file.remove()
      return setError('File size more than 5MB')
    }
    if (status === 'done' && onUploadSuccess) {
      onUploadSuccess(JSON.parse(file.xhr!.response))
      return setError(null)
    }
  }

  const Preview = (data: IPreviewProps) => {
    return <DocItem remove={data.fileWithMeta.remove} meta={data.meta} />
  }
  return (
    <BoxFullFill column>
      <Dropzone
        getUploadParams={getUploadParams}
        LayoutComponent={Layout}
        InputComponent={InputComponent}
        PreviewComponent={Preview}
        accept={accept[title] || DOC_TYPES}
        onChangeStatus={onLoadedFile}
        maxSizeBytes={MAX_FILE_SIZE_BYTES}
        maxFiles={10}
      />
      <BoxFill column mt="10px">
        {error && <Text red>{error}</Text>}
        <Text s>{`Supported formats are ${accept[title] || DOC_TYPES}. Max file size is 5 MB`}</Text>
      </BoxFill>
    </BoxFullFill>
  )
}

UploadInput.defaultProps = {
  multiple: false,
  uploadedFiles: Map(),
}

export default UploadInput
