import React from 'react'
import { useSelector } from 'react-redux'

import { AppState } from 'store/rootReducer'

import Header from './components/MainPart/header'
import Body from './components/MainPart/body'
import Board from './components/Board'

import { Image, BoxFullFill } from 'styles'

const Pipeline: React.FC = () => {
  const pipeline = useSelector((state: AppState) => state.pipeline.get('pipeline'))
  return (
    <BoxFullFill column>
      <Header title="Pipeline" isListEmpty={pipeline.length === 0} />
      {pipeline.length === 0 ? (
        <Body
          title="Here will be your list of potential deals"
          subTitle="Match your candidates with job positions and track deal progress in the pipeline"
          image={<Image width="450px" height="140px" src={require('assets/images/empty-pipeline@3x.png')} />}
        />
      ) : (
        <Board />
      )}
    </BoxFullFill>
  )
}

export default Pipeline
