import React, { RefObject } from 'react'
import { Range, getTrackBackground } from 'react-range'

import InputBox from './index'

import { BoxFill, Text } from 'styles'

const STEP = 1
const MIN = 0
const MAX = 50

type Props = {
  value: any
  onChange: (e: any) => void
  refSlider?: RefObject<HTMLInputElement>
  name?: string
  error?: string | number
  isShowError?: boolean
}

const InputSlider: React.FC<Props> = ({ value, onChange, error, isShowError }) => {
  return (
    <BoxFill column>
      <InputBox rightElement={<Text black>years</Text>} value={value || '0'} label="Years of experience" />
      <BoxFill relative maxHeight="4px" zIndex="1" mt="-10px">
        <Range
          step={STEP}
          min={MIN}
          max={MAX}
          values={[Number(value)]}
          onChange={values => onChange(values[0])}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '6px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values: [Number(value)],
                    colors: ['#2697FF', '#D5EBFF'],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '16px',
                width: '16px',
                borderRadius: '50%',
                border: '2px solid #2697FF',
                background: '#ffffff',
                cursor: 'pointer',
              }}
            />
          )}
        />
      </BoxFill>
      {error && !isShowError ? null : (
        <Text red s>
          {error}
        </Text>
      )}
    </BoxFill>
  )
}

InputSlider.defaultProps = {
  isShowError: true,
}

export default InputSlider
