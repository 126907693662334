import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  width: ${props => props.width || '100%'};
  height: 60px;
  border: 1px solid #e3e8f1;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 5px rgba(188, 196, 208, 0.2);
  border-radius: 6px;
  padding: 0 15px;
  font-size: 18px;
  color: #292c38;
`

type Props = {
  name: string
  type: string
  placeholder: string
  width?: string
  pattern?: string
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  value: string
}

const InputMain: React.FC<Props> = ({ name, type, placeholder, width, pattern, onChange, value, onBlur }) => (
  <Input name={name} type={type} placeholder={placeholder} width={width} pattern={pattern} onChange={onChange} onBlur={onBlur} value={value} />
)

export default InputMain
