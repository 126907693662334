import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { AppState } from 'store/rootReducer'
import TaskActions from 'store/task/action'

import Modal from 'components/Modal'
import Input from 'components/Input'
import InputSelect from 'components/Input/InputSelect'
import { ExpandArrow } from 'components/Icons'

import { formatAMPM } from 'utils'

import { Box, BoxCol, BoxFill, Text, Image, Label, Textarea } from 'styles'

import redCheckboxIcon from 'assets/icons/redCheckbox.svg'
import yellowCheckboxIcon from 'assets/icons/yellowCheckbox.svg'
import greenCheckboxIcon from 'assets/icons/greenCheckbox.svg'

import {
  Wrap,
  TaskLabel,
  Title,
  Description,
  BottomWrap,
  Due,
  Priority,
  Dots,
  DotsWrap,
  Circle,
  OptionContainer,
  OptionHeader,
  Option,
  OptionText,
  BoxStatus,
  StatusWrapper,
  StatusItem,
  DeleteTask,
  HighPriority,
  MediumPriority,
  AnyTime,
  ModalWrap,
  NewTask,
  NTSubtitle,
  InputBlock,
  TextareaWrap,
  CreateTaskWrap,
  CreateTask,
  CharsWrap,
  Chars,
} from './styles'

const ICON_CALENDAR = <Image src={require('assets/icons/calendar.svg')} />
const ICON_CLOCK = <Image src={require('assets/icons/clock2.svg')} />

type Props = {
  id: number
  title: string
  type?: string
  description?: string | null
  priority?: string
  dueDate?: string | null
  dueTime?: string | null
  deleteTask: any
  closeTask?: any
  restoreTask?: any
}

const Task: React.FC<Props> = ({ id, title, description, priority, dueDate, dueTime, type, deleteTask, closeTask, restoreTask }) => {
  const [isShowOptions, setIsShowOptions] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const currentDueDate = dueDate !== 'null' && dueDate

  let newTitle = ''
  let newDescription = ''
  let newPriority = ''
  let newDueDate = ''
  let newDueTime = ''

  useSelector((state: AppState) => {
    newTitle = state.task.title
    newDescription = state.task.description
    newPriority = state.task.priority
    newDueDate = state.task.taskDate
    newDueTime = state.task.taskTime
    return state.task
  })

  const [titleChars, setTitleChars] = useState(150 - title.length)
  const currentDesc = description && 1000 - description.length
  const [descriptionChars, setDescriptionChars] = useState((!description && 1000) || currentDesc)

  const dispatch = useDispatch()

  const toggleSetIsShowOptions = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsShowOptions(!isShowOptions)
  }

  const handleUpdateTask = useCallback(
    (newTitle, newDescription, newPriority, newDueDate, newDueTime) => {
      const task = {
        title: newTitle,
        description: newDescription,
        priority: newPriority,
        dueDate: newDueDate,
        dueTime: newDueTime || null,
      }

      dispatch(TaskActions.updateTask(id, task, priority))
      setIsShowModal(false)
      dispatch(TaskActions.clearInput())
    },
    [dispatch, id, priority]
  )

  const handleChangePriority = useCallback(
    value => {
      const task = {
        title,
        description,
        priority: value,
      }
      dispatch(TaskActions.updateTask(id, task, priority))
    },
    [dispatch, id, title, description, priority]
  )

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const {
        name,
        value,
        validity: { valid },
      } = e.target
      if (valid) {
        if (name === 'title') {
          setTitleChars(150 - value.length)
        } else if (name === 'description') {
          setDescriptionChars(1000 - value.length)
        }
        dispatch(TaskActions.changeInput(name, value))
      }
    },
    [dispatch]
  )

  const handleSelectChange = useCallback(
    (e: any) => {
      dispatch(TaskActions.changeSelect(e.value))
    },
    [dispatch]
  )

  const openTaskModal = useCallback(() => {
    dispatch(TaskActions.changeInput('title', title))
    description && dispatch(TaskActions.changeInput('description', description))
    priority && dispatch(TaskActions.changeInput('priority', priority))
    currentDueDate && dispatch(TaskActions.changeInput('taskDate', currentDueDate))
    dueTime && dispatch(TaskActions.changeInput('taskTime', dueTime.substr(0, 5)))
    setIsShowModal(true)
  }, [dispatch, currentDueDate, description, priority, title, dueTime])

  const closeTaskModal = useCallback(() => {
    dispatch(TaskActions.clearInput())
    setIsShowModal(false)
  }, [dispatch])

  return (
    <Wrap>
      <TaskLabel justifyCenter type={type}>
        <Title type={type} left>
          {title}
        </Title>
        <Description>{description}</Description>
        <BottomWrap>
          {currentDueDate && (
            <Due>
              Due {currentDueDate.substr(8, 2)} {month[+currentDueDate.substr(5, 2)]} {dueTime && formatAMPM(dueTime.substr(0, 5))}
            </Due>
          )}
          {priority && <Priority>{priority}</Priority>}
        </BottomWrap>
      </TaskLabel>
      <Dots onClick={toggleSetIsShowOptions} absolute top="24px" right="22px">
        <DotsWrap alignSelf="flex-end" spaceBetween>
          <Circle />
          <Circle />
          <Circle />
        </DotsWrap>
        {!isShowOptions || (
          <OptionContainer>
            <OptionHeader>
              <Box />
              <Text s>Options</Text>
              <Image onClick={toggleSetIsShowOptions} pointer width="10px" src={require('assets/icons/close.svg')} />
            </OptionHeader>
            <BoxFill column>
              {type !== 'done' && (
                <Option>
                  <Box padding="10px">
                    <OptionText onClick={() => openTaskModal()}>Edit task</OptionText>
                  </Box>
                </Option>
              )}
              {type !== 'done' && (
                <Option relative>
                  <BoxFill alignCenter spaceBetween>
                    <StatusItem padding="10px">
                      <OptionText>Change status</OptionText>
                      <ExpandArrow fill="#9CAAB8" rotate="rotate(-90deg)" />
                    </StatusItem>
                  </BoxFill>
                  <StatusWrapper className="statusBox">
                    <BoxStatus>
                      <HighPriority onClick={() => handleChangePriority('High priority')} pointer>
                        High priority
                      </HighPriority>
                      {priority === 'high priority' && <img src={redCheckboxIcon} alt="high priority" />}
                    </BoxStatus>
                    <BoxStatus>
                      <MediumPriority onClick={() => handleChangePriority('Medium priority')} pointer>
                        Medium priority
                      </MediumPriority>
                      {priority === 'medium priority' && <img src={yellowCheckboxIcon} alt="medium priority" />}
                    </BoxStatus>
                    <BoxStatus>
                      <AnyTime onClick={() => handleChangePriority('Any time')} pointer>
                        Any time
                      </AnyTime>
                      {priority === 'any time' && <img src={greenCheckboxIcon} alt="any time" />}
                    </BoxStatus>
                  </StatusWrapper>
                </Option>
              )}
              {type !== 'done' && (
                <Option>
                  <Box padding="10px">
                    <OptionText onClick={() => closeTask(id, priority)}>Close task</OptionText>
                  </Box>
                </Option>
              )}
              {type === 'done' && (
                <Option>
                  <Box padding="10px">
                    <OptionText onClick={() => restoreTask(id, priority)}>Restore task</OptionText>
                  </Box>
                </Option>
              )}
              <Option>
                <Box padding="10px">
                  <OptionText>
                    <DeleteTask onClick={() => deleteTask(id, priority)}>Delete task</DeleteTask>
                  </OptionText>
                </Box>
              </Option>
            </BoxFill>
          </OptionContainer>
        )}
      </Dots>
      <Modal isOpen={isShowModal} hideCloseIcon onRequestClose={() => closeTaskModal()}>
        <ModalWrap>
          <NewTask black xl3>
            Edit task
          </NewTask>
          <NTSubtitle left>Change a task name and priority, also you can set the due date and change the task description if needed</NTSubtitle>
          <InputBlock>
            <Input
              name="title"
              label="Task title"
              placeholder="Task title"
              onChange={handleInputChange}
              chars={titleChars}
              value={newTitle}
              pattern=".{0,150}"
            />
          </InputBlock>
          <InputBlock>
            <InputSelect
              label="Task priority"
              optionsType="priority"
              placeholder="Choose task priority"
              isSearchComponent
              onChange={handleSelectChange}
              defaultValue={priority}
            />
          </InputBlock>
          <CharsWrap>
            <Label>Task description</Label>
            <Chars>{descriptionChars} left</Chars>
          </CharsWrap>
          <TextareaWrap>
            <Textarea name="description" placeholder="Task description" onChange={handleInputChange} value={newDescription} maxLength={1000} />
          </TextareaWrap>

          <InputBlock>
            <BoxCol mb="20px" mr="14px" width="100%">
              <Input
                onChange={handleInputChange}
                placeholder="Choose date"
                name="taskDate"
                type="date"
                label="Due date and time"
                rightElement={ICON_CALENDAR}
                defaultValue={currentDueDate ? currentDueDate : ''}
              />
            </BoxCol>
            <BoxFill mb="20px" mt="27px" width="100%">
              <Input
                onChange={handleInputChange}
                placeholder="HH : MM am"
                name="taskTime"
                type="time"
                rightElement={ICON_CLOCK}
                disabled={!newDueDate && !currentDueDate}
                defaultValue={dueTime ? dueTime.substr(0, 5) : ''}
              />
            </BoxFill>
          </InputBlock>

          <CreateTaskWrap justifyCenter>
            <CreateTask
              title="UPDATE TASK"
              onClick={() => handleUpdateTask(newTitle, newDescription, newPriority, newDueDate, newDueTime)}
              disabled={!title}
            />
          </CreateTaskWrap>
        </ModalWrap>
      </Modal>
    </Wrap>
  )
}

export default Task
