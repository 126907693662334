import React from 'react'

import { ISelectListItem } from 'types/forms'
import { IEmployerContact, IJobPosition } from 'types/models'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'

import { Box, BoxFill } from 'styles'

type Props = {
  form: IJobPosition
  onChangeField: (e: InputEvent & ISelectListItem) => void
  errors: Partial<IEmployerContact>
}

const Second: React.FC<Props> = ({ onChangeField, errors, form }) => {
  return (
    <BoxFill column>
      <BoxFill mb="20px">
        <Box flex={1} column mr="10px">
          <Input
            value={form.EmployerContact!.name || ''}
            error={errors.name}
            onChange={onChangeField}
            name="EmployerContact.name"
            label="Business contact name"
          />
        </Box>
        <Box flex={1} column ml="10px">
          <Input
            value={form.EmployerContact!.position || ''}
            error={errors.position}
            onChange={onChangeField}
            name="EmployerContact.position"
            label="Business contact position (optional)"
          />
        </Box>
      </BoxFill>
      <BoxFill mb="20px">
        <Box flex={1} column mr="10px">
          <Input
            value={form.EmployerContact!.email || ''}
            error={errors.email}
            onChange={onChangeField}
            name="EmployerContact.email"
            label="Business contact email"
          />
        </Box>
        <Box flex={1} column ml="10px">
          <Input
            value={form.EmployerContact!.phone || ''}
            error={errors.phone}
            onChange={onChangeField}
            name="EmployerContact.phone"
            label="Best phone number (optional)"
          />
        </Box>
      </BoxFill>
    </BoxFill>
  )
}

export default Second
