import React, { useState } from 'react'
import styled from 'styled-components'

import { Box, BoxFill, Image, Text } from 'styles'

type Props = {
  options: any[]
  toggler: any
  bottom?: string
  title?: string
  isActive?: string | boolean
}

const DropDownWrapper = styled.div`
  position: relative;
`

const OptionHeader = styled(BoxFill).attrs(props => ({
  alignCenter: true,
  spaceBetween: true,
  padding: '10px',
  bb: `1px solid ${props.theme.main.borderInputColor}`,
}))``

const OptionContainer = styled(Box).attrs(({ bottom }: Props) => ({
  shadow2: true,
  absolute: true,
  zIndex: '10',
  br: '6px',
  mt: '10px',
  whiteBC: true,
  width: '170px !important',
  column: true,
  bottom: bottom ? bottom : '75%',
  left: '15px',
}))``

const OptionText = styled(Text).attrs({
  pointer: true,
  black: true,
  left: true,
})`
  color: ${props => props.isActive && props.theme.main.secondColor};
`

const Option = styled(BoxFill)`
  white-space: nowrap;
  cursor: pointer;
  &:hover .statusBox {
    display: block;
  }
  &:hover {
    background-color: #f5f6f8;
  }
`

const DropDown: React.FC<Props> = ({ options, toggler, bottom, title, isActive }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onOptionClick = (callBack: any) => {
    setIsOpen(false)
    callBack()
  }

  return (
    <DropDownWrapper>
      <div
        style={{ display: 'flex' }}
        onClick={() => {
          setIsOpen(true)
        }}
      >
        {toggler}
      </div>
      <OptionContainer display={isOpen ? 'block' : 'none'} bottom={bottom}>
        <OptionHeader>
          <Box />
          <Text s>{title ? title : 'Options'}</Text>
          <Image
            onClick={() => {
              setIsOpen(false)
            }}
            pointer
            width="10px"
            src={require('assets/icons/close.svg')}
          />
        </OptionHeader>
        {options.map(option => (
          <Option
            key={option.name}
            onClick={() => {
              onOptionClick(option.handler)
            }}
          >
            <Box padding="10px">
              <OptionText isActive={isActive === option.name}>{option.name}</OptionText>
            </Box>
          </Option>
        ))}
      </OptionContainer>
    </DropDownWrapper>
  )
}

export default DropDown
