import React from 'react'
import { ProgressWrapper, ProgressInner } from './styles'

type Props = {
  percent: number
}

const Progress: React.FC<Props> = ({ percent }) => {
  return (
    <ProgressWrapper>
      <ProgressInner width={percent > 100 ? 100 : percent} />
    </ProgressWrapper>
  )
}

export default Progress
