import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'

import close from 'assets/icons/close.svg'

Modal.setAppElement('#root')

const customStyles = (withScroll = false, zIndex = '100') => {
  let contentScrollable = {}
  let overlayScrollable = {}
  if (withScroll) {
    contentScrollable = {
      transform: 'translate(-50%, -30vh)',
      overflow: 'scroll',
      marginBottom: '20vh',
    }
    overlayScrollable = {
      overflowY: 'scroll',
    }
  } else {
    contentScrollable = {
      transform: 'translate(-50%, -50%)',
      overflow: 'unset',
    }
  }
  return {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      ...contentScrollable,
    },
    overlay: {
      zIndex,
      backgroundColor: '#62626262',
      ...overlayScrollable,
    },
  }
}

const Close = styled.img<any>`
  width: 14px;
  height: 14px;
  position: absolute;
  top: 23px;
  right: 20px;
  cursor: pointer;
  z-index: 10;
`

type Props = {
  isOpen: any
  hideModal?: () => void
  hideCloseIcon?: boolean
  withScroll?: boolean
  onRequestClose?: () => void | boolean
  zIndex?: string
  maxHeight?: string
}

const ModalComponents: React.FunctionComponent<Props> = ({ children, isOpen, hideModal, hideCloseIcon, onRequestClose, withScroll, zIndex }) => {
  return (
    <Modal
      isOpen={isOpen}
      // onAfterOpen={this.afterOpenModal}
      // onRequestClose={this.closeModal}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      style={customStyles(withScroll, zIndex)}
      contentLabel="Example Modal"
    >
      {hideCloseIcon && <Close onClick={onRequestClose} src={close} />}
      {children}
    </Modal>
  )
}

export default ModalComponents
