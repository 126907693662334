import React from 'react'
import styled from 'styled-components'

import Button from 'components/Button'

import { BoxFill, Text } from 'styles'

const Option = styled(Text)<{ active: boolean }>`
  color: ${props => (props.active ? props.theme.main.secondColor : props.theme.main.inputLabelTextColor)};
  position: relative;
  &:before {
    content: '';
    width: ${props => (props.active ? '4px' : null)};
    height: 50px;
    background: #3cb593;
    border-radius: 0px 6px 6px 0px;
    display: block;
    position: absolute;
    top: -10px;
    left: -20px;
  }
`

type Props = {
  options: string[]
  currentStep: number
  btnText?: string
  changeEditStep: (step: number) => void
  viewEditingItem?: () => void
}

const EditMenu: React.FC<Props> = ({ options, currentStep, btnText, changeEditStep, viewEditingItem }) => {
  return (
    <BoxFill column padding="20px">
      <BoxFill column>
        {options.map((item, i) => (
          <BoxFill key={i} padding="14px 0">
            <Option active={currentStep === i} onClick={() => changeEditStep(i)} l black pointer>
              {item}
            </Option>
          </BoxFill>
        ))}
      </BoxFill>
      {btnText && viewEditingItem && (
        <BoxFill mt="20px" bt="1px solid #E3E8F1" pt="20px">
          <BoxFill>
            <Button type="secondary" title={btnText} height="52px" onClick={viewEditingItem} />
          </BoxFill>
        </BoxFill>
      )}
    </BoxFill>
  )
}

export default EditMenu
