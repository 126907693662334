import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import CandidatesActions from 'store/candidates/actions'
import MessengerActions from 'store/messenger/actions'
import { ICandidate } from 'types/models'

import { ExpandArrow } from 'components/Icons'
import Avatar from 'components/Avatar'

import { formatCurrency } from 'utils'
import { Box, BoxFill, BoxCol, BoxCenterContent, Text, Image } from 'styles'
import messageIcon from 'assets/icons/message.svg'

const Main = styled(BoxFill).attrs({
  smooth: true,
  whiteBC: true,
  relative: true,
})``

const AccentContainer = styled(Box).attrs({
  smooth: true,
  padding: '4px 8px',
})``

const ItemBox = styled(Box).attrs<{ type: 'positions' | 'sectors' }>(props => ({
  smooth: true,
  bc: props.type === 'positions' ? props.theme.main.accentColor2 : props.theme.main.borderInputColor,
  padding: '4px 8px',
  mt: '6px',
}))<{ type: 'positions' | 'sectors' }>`
  p {
    color: ${props => (props.type === 'positions' ? props.theme.main.accentColor : props.theme.main.inputLabelTextColor)};
  }
  :hover .popUpBox {
    display: block;
  }
`
const ItemText = styled(Text).attrs(props => ({
  whiteSpace: true,
  textOverflow: true,
  overflow: 'hidden',
  fz: '12px',
}))``

const PopUpBox = styled(BoxCol).attrs({
  whiteBC: true,
  absolute: true,
  shadow2: true,
  smooth: true,
  zIndex: '10',
  display: 'none',
  padding: '10px',
  top: '40px',
})``

const Circle = styled(Box).attrs({
  width: '6px',
  height: '6px',
  br: '3px',
  mainBC: true,
  margin: '0 1px',
  pointer: true,
})``

const OptionContainer = styled(Box).attrs({
  shadow2: true,
  absolute: true,
  zIndex: '10',
  br: '6px',
  left: '37%',
  mt: '10px',
  whiteBC: true,
  width: '170px !important',
  column: true,
})``

const OptionHeader = styled(BoxFill).attrs(props => ({
  alignCenter: true,
  spaceBetween: true,
  padding: '10px',
  bb: `1px solid ${props.theme.main.borderInputColor}`,
}))``

const Option = styled(BoxFill)`
  &:hover .statusBox {
    display: block;
  }
  &:hover {
    background-color: #f5f6f8;
  }
`

const OptionText = styled(Text).attrs({
  pointer: true,
  black: true,
})``

const BoxStatus = styled(Box).attrs({
  alignCenter: true,
  spaceBetween: true,
  padding: '10px 5px',
})``

const StatusWrapper = styled(BoxCol).attrs({
  absolute: true,
  whiteBC: true,
  smooth: true,
  shadow2: true,
  display: 'none',
  width: '95px',
  top: '0px',
  right: '-95px',
  padding: '8px',
})``

const AvatarBox = styled(Box).attrs({})`
  position: relative;
`

const Envelope = styled(Box).attrs({
  width: '30px',
  height: '30px',
  br: '50%',
  bottom: '-2px',
  right: '-9px',
  zIndex: '5',
})`
  background: #d5ebff;
  border: 2px solid #ffffff;
  &:hover {
    border: 2px solid #d5ebff;
  }
`

type Props = {
  candidate: ICandidate
  setStatusCandidate: typeof CandidatesActions.setStatusCandidate
  addNewCandidateOrEmployer: typeof MessengerActions.addNewCandidateOrEmployer
}

const CandidateCard: React.FC<Props & RouteComponentProps> = ({ history, candidate, setStatusCandidate, addNewCandidateOrEmployer }) => {
  const [isShowOptions, setIsShowOptions] = useState<boolean>(false)
  const toggleSetIsShowOptions = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsShowOptions(!isShowOptions)
  }
  const renderItems = (items: Array<{ name: string }>, type: 'positions' | 'sectors') => {
    const firstItem = items.length ? items[0].name : false
    const secondItem = items.length >= 2 ? items[1].name : false
    const isShowNumberOfItems = items.length > 3
    const maxWidth = secondItem ? '90px' : '100%'
    if (!items.length)
      return (
        <Box>
          <ItemBox type={type}>
            <ItemText>Any</ItemText>
          </ItemBox>
        </Box>
      )
    return (
      <Box>
        {!firstItem || (
          <ItemBox type={type} maxWidth={maxWidth}>
            <ItemText>{firstItem}</ItemText>
          </ItemBox>
        )}
        {!secondItem || (
          <ItemBox type={type} maxWidth={maxWidth} ml="12px">
            <ItemText>{secondItem}</ItemText>
          </ItemBox>
        )}
        {!isShowNumberOfItems || (
          <ItemBox pointer justifyCenter relative type={type} ml="4px">
            <ItemText pointer>{items.length}</ItemText>
            <PopUpBox className="popUpBox">
              {items.slice(2).map(item => (
                <ItemBox key={item.name} type={type}>
                  <ItemText>{item.name}</ItemText>
                </ItemBox>
              ))}
            </PopUpBox>
          </ItemBox>
        )}
      </Box>
    )
  }

  let candidateRoute = ''
  let candidateEditRoute = ''

  if (window.location.pathname === '/search') {
    candidateRoute = `candidates/candidate/${candidate.id}`
    candidateEditRoute = `candidates/candidate/${candidate.id}/edit`
  } else {
    candidateRoute = `${window.location.pathname}/candidate/${candidate.id}`
    candidateEditRoute = `${window.location.pathname}/candidate/${candidate.id}/edit`
  }

  const toCandidateRoute = () => history.push(candidateRoute)
  const toCandidateEditRoute = () => history.push(candidateEditRoute)
  const setActiveCandidateStatus = () => setStatusCandidate(candidate.id, false)
  const setHiredCandidateStatus = () => setStatusCandidate(candidate.id, true)

  let candidateId: number | null
  let candidateFullName = ''
  let candidatePosition = ''
  let candidateAvatar = ''

  if (candidate) {
    candidateId = candidate.id
    candidateFullName = `${candidate.firstName} ${candidate.lastName}`
    candidatePosition = candidate.Positions && candidate.Positions.length ? candidate.Positions[0].name : ''
    candidateAvatar = candidate.photoFile && candidate.photoFile.link
  }

  const addNewCandidate = () => {
    addNewCandidateOrEmployer('candidate', candidateId, candidateFullName, candidatePosition, candidateAvatar)
  }

  const { City } = candidate

  return (
    <Main>
      <BoxFill column alignCenter margin="20px">
        <AvatarBox mb="10px">
          <Avatar item={candidate} onClick={toCandidateRoute} />
          <Envelope absolute justifyCenter alignCenter pointer onClick={() => addNewCandidate()}>
            <img src={messageIcon} alt="message" />
          </Envelope>
        </AvatarBox>
        <Box>
          <Text black fw="500">
            {candidate.public && <Image style={{ margin: '0 2px -2px 0' }} src={require('assets/icons/user-icon.svg')} />} {candidate.firstName}{' '}
            {candidate.lastName}
          </Text>
        </Box>
        <BoxCenterContent>
          <Box pr="6px" borderRight="1px solid #E3E8F1">
            <Text>{City.name.indexOf(City.State.abbr) > 0 ? City.name : `${City.name} ${City.State.abbr}`}</Text>
          </Box>
          <AccentContainer bc="#D8F0E9" ml="10px">
            <Text secondColor s>
              ${formatCurrency(candidate.minSalary)}
            </Text>
          </AccentContainer>
        </BoxCenterContent>
        <BoxCenterContent mt="8px" column>
          <Text black xs>
            Preferable positions
          </Text>
          {renderItems(candidate.Positions, 'positions')}
        </BoxCenterContent>
        <BoxCenterContent mt="8px" column>
          <Text black xs>
            Preferable project types
          </Text>
          {renderItems(candidate.Sectors, 'sectors')}
        </BoxCenterContent>
      </BoxFill>
      {/* TODO:! CREATE ONE COMPONENT REUSABLE (SAME component IN employer card) */}
      <BoxFill onClick={toggleSetIsShowOptions} padding="5px" column absolute top="25px" right="20px">
        <Box alignSelf="flex-end">
          <Circle />
          <Circle />
          <Circle />
        </Box>
        {!isShowOptions || (
          <OptionContainer>
            <OptionHeader>
              <Box />
              <Text s>Options</Text>
              <Image onClick={toggleSetIsShowOptions} pointer width="10px" src={require('assets/icons/close.svg')} />
            </OptionHeader>
            <BoxFill column>
              <Option>
                <Box padding="10px">
                  <OptionText onClick={toCandidateEditRoute}>Edit candidate</OptionText>
                </Box>
              </Option>
              <Option relative>
                <BoxFill alignCenter spaceBetween>
                  <Box padding="10px" width="100%" spaceBetween>
                    <OptionText>Change status</OptionText>
                    <ExpandArrow fill="#9CAAB8" rotate="rotate(-90deg)" />
                  </Box>
                </BoxFill>
                <StatusWrapper className="statusBox">
                  <BoxStatus>
                    <Text onClick={setActiveCandidateStatus} accentColor pointer>
                      Active
                    </Text>
                    {!candidate.hired ? <Image src={require('assets/icons/bCheckbox.svg')} /> : null}
                  </BoxStatus>
                  <BoxStatus>
                    <Text onClick={setHiredCandidateStatus} red pointer>
                      Hired
                    </Text>
                    {candidate.hired ? <Image src={require('assets/icons/bCheckbox.svg')} /> : null}
                  </BoxStatus>
                </StatusWrapper>
              </Option>
            </BoxFill>
          </OptionContainer>
        )}
      </BoxFill>
      {/* ------------------------------------------------------------------ */}
      <Box absolute top="20px" left="20px">
        {candidate.hired ? <Text red>Hired</Text> : <Text accentColor>Active</Text>}
      </Box>
    </Main>
  )
}

export default withRouter(
  connect(
    null,
    {
      addNewCandidateOrEmployer: MessengerActions.addNewCandidateOrEmployer,
    }
  )(CandidateCard)
)
