import styled from 'styled-components'

import { Box, BoxCol, BoxFill, Text } from 'styles'

export const Wrap = styled(BoxCol)`
  background: #fff;
  min-height: 249px;
`

export const Top = styled(BoxFill)`
  align-items: center;
  padding: 20px 20px 23px 20px;
  position: relative;
`

export const Circle = styled(Box).attrs({
  width: '6px',
  height: '6px',
  br: '3px',
  mainBC: true,
  margin: '0 1px',
  pointer: true,
})``

export const OptionContainer = styled(Box).attrs({
  shadow2: true,
  absolute: true,
  zIndex: '10',
  br: '6px',
  right: '0',
  mt: '10px',
  whiteBC: true,
  width: '170px !important',
  column: true,
})``

export const OptionHeader = styled(BoxFill).attrs(props => ({
  alignCenter: true,
  spaceBetween: true,
  padding: '10px',
  bb: `1px solid ${props.theme.main.borderInputColor}`,
}))``

export const Option = styled(BoxFill)`
  &:hover .statusBox {
    display: block;
  }
  &:hover {
    background-color: #f5f6f8;
  }
`

export const OptionText = styled(Text).attrs({
  pointer: true,
  black: true,
})``

export const Name = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #292c38;
  margin: 0 0 0 10px;
  a {
    color: ${props => props.theme.main.inputLabelTextColor};
  }
  &:hover {
    opacity: 0.8;
  }
`

export const Position = styled.p`
  margin: 0 0 0 10px;
`

export const Email = styled(BoxCol)`
  width: 100%;
  min-height: 56px;
  padding: 0 20px 16px 20px;
  font-size: 15px;
  line-height: 20px;
  word-break: break-all;
  span {
    color: #2697ff;
    font-size: 17px;
    line-height: 20px;
    cursor: pointer;
  }
  a {
    color: ${props => props.theme.main.accentColor};
    font-size: 17px;
    line-height: 20px;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`

export const WrapContactsInfo = styled(BoxCol).attrs({})`
  word-break: break-all;
  padding: 0 35px 0 0;
`

export const PhonesWrap = styled(BoxFill).attrs({})`
  justify-content: space-between;
`

export const BottomCard = styled(BoxFill).attrs({})`
  height: 50px;
  border-top: ${props => `1px solid ${props.theme.main.borderInputColor}`};
  align-items: center;
  justify-content: center;
`

export const Sep = styled.span`
  width: 1px;
  height: 30px;
  background: ${props => props.theme.main.colors.grey2};
  margin: 0 48px 0 40px;
`

export const Notes = styled.span`
  color: ${props => props.theme.main.colors.primary};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`
export const Message = styled.span`
  color: ${props => props.theme.main.accentColor};
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`
