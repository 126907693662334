import Api, { API_BASE_URL } from './index'
import { ICandidate, IWorkPlace, ICandidateWithLastWorkPlace } from 'types/models'
import { IDataCandidates, IDataCandidateWithPagedPotentialJobPositions } from 'types/responseData'
import { IQueryParams } from 'types/helps'

import { IUploadInputParams } from 'components/UploadInput/types'

import { getDraftCandidateId } from 'utils'

const CANDIDATE_BASE_URL = `${API_BASE_URL}/user/candidates`

class Candidates {
  /**
   * Gets Candidates by query params
   */

  static getCandidates(query: IQueryParams) {
    return Api.get<IDataCandidates>('/user/candidates/page/', { params: query })
  }

  /**
   * Get Candidate
   */

  static getCandidate(id: number, page?: number, perPage?: number) {
    return Api.get<IDataCandidateWithPagedPotentialJobPositions>(`/user/candidates/${id}/with-paged-potential-job-positions/`, {
      params: { page, perPage },
    })
  }

  /**
   * User's Candidate create
   */

  static createCandidate(candidate: ICandidateWithLastWorkPlace) {
    return Api.post<ICandidate>('/user/candidates/with-last-workplace/', candidate)
  }

  /**
   * User's Candidate create public
   */

  static createPublicCandidate(candidate: ICandidateWithLastWorkPlace, recruiterId: number) {
    return Api.post<ICandidate>(`/public/candidates/${recruiterId}/`, candidate)
  }

  static createPublicCandidateWithoutId(candidate: ICandidateWithLastWorkPlace) {
    return Api.post<ICandidate>('/public/candidates/', candidate)
  }

  /**
   * Updates candidate with required id
   */

  static updateCandidate(id: number, candidate: Partial<ICandidate>) {
    return Api.patch<ICandidate>(`/user/candidates/${id}/`, candidate)
  }

  /**
   * Delete candidate with required id
   */

  static deleteCandidate(id: number) {
    return Api.delete(`/user/candidates/${id}/`)
  }

  /**
   * Change status of candidate (hired or active)
   */

  static setStatusCandidate(id: number, value: boolean) {
    return Api.patch<ICandidate>(`/user/candidates/${id}/`, { hired: value })
  }

  /**
   * Adds or remove candidate to the job position with required status
   * JobPositionId - Integer, Foreign key to job position object
   * CandidateId - Integer, Foreign key to candidate object
   * status - Integer (default is 1). Values from 1 to 6 are used to set the
   * corresponding candidate status which is used in the pipeline.
   * This values are:
   * 1 - CANDIDATE_IS_MATCHED
   * 2 - CANDIDATE_AGREED_TO_SUBMITTAL
   * 3 - CV_IS_SENT
   * 4 - EMPLOYER_ACCEPTED_THE_CANDIDATE
   * 5 - INTERVIEW_ARRANGED
   * 6 - PLACEMENT_APPROVED
   * The special value -1 should be used to remove the match.
   */

  static match(JobPositionId: number, CandidateId: number, status: number = 1) {
    return Api.post('/user/match/candidate-with-job-position/', { JobPositionId, CandidateId, status })
  }

  /**
   * Updates work place with required id if it belongs to user
   */

  static updateWorkPlace(id: number, workPlace: Partial<IWorkPlace>) {
    return Api.patch<IWorkPlace>(`/user/candidates/work-places/${id}/`, workPlace)
  }

  /**
   * Create work place for chosen candidate
   */

  static createWorkPlace(id: number, workPlace: any) {
    return Api.post<IWorkPlace>(`/user/candidates/${id}/work-places/`, workPlace)
  }

  /**
   * Deletes work place with required id if it belongs to user
   */

  static deleteWorkPlace(id: number) {
    return Api.delete<IWorkPlace>(`/user/candidates/work-places/${id}/`)
  }

  /**
   * Upload The candidate photo. Only png, jpeg formats no more than 20MB are allowed
   * and The candidate CV. Only png, jpeg, pdf, word formats no more than 20MB are allowed.
   */

  /* TODO:! NEED CHANGE */

  static async paramsForUploadCVOfCandidate(candidateId?: number): Promise<IUploadInputParams> {
    const id = candidateId || (await getDraftCandidateId())
    return {
      fieldName: 'cvFile',
      options: {
        url: `${CANDIDATE_BASE_URL}/${id}/files/`,
        headers: { Authorization: `Bearer ${Api.getAuthToken()}` },
      },
    }
  }

  static async uploadPhotoOfCandidate(photoFile: Blob, candidateId?: number) {
    const id = candidateId || (await getDraftCandidateId())
    const body = new FormData()
    body.append('photoFile', photoFile)
    return Api.post(`/user/candidates/${id}/files/`, body, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  static async paramsForUploadLicenseOfCandidate(candidateId?: number): Promise<IUploadInputParams> {
    const id = candidateId || (await getDraftCandidateId())
    return {
      fieldName: 'license1',
      options: {
        url: `${CANDIDATE_BASE_URL}/${id}/licenses/`,
        headers: { Authorization: `Bearer ${Api.getAuthToken()}` },
      },
    }
  }
  /* --------------------------------- */

  /**
   * Delete candidate license
   */

  static deleteLicense(id: number) {
    return Api.delete(`/user/candidates/files/licenses/${id}/`)
  }

  /**
   * Delete candidate CV
   */

  static deleteCV(id: number) {
    return Api.delete(`/user/candidates/files/cv/${id}/`)
  }
}

/* TODO:! NEED CHANGE */

export async function paramsForUploadLicenseOfCandidate(candidateId: number): Promise<IUploadInputParams> {
  // const id = candidateId || (await getDraftCandidateId())
  return {
    fieldName: 'file0',
    options: {
      url: `${CANDIDATE_BASE_URL}/${candidateId}/files/licenses/`,
      headers: { Authorization: `Bearer ${Api.getAuthToken()}` },
    },
  }
}

export async function paramsForUploadCVOfCandidate(candidateId: number): Promise<IUploadInputParams> {
  // const id = candidateId || (await getDraftCandidateId())
  return {
    fieldName: 'file',
    options: {
      url: `${CANDIDATE_BASE_URL}/${candidateId}/files/cv/`,
      headers: { Authorization: `Bearer ${Api.getAuthToken()}` },
    },
  }
}

export async function uploadPhotoOfCandidate(/* photoFile: Blob,  */ candidateId: number): Promise<IUploadInputParams> {
  // const id = candidateId || (await getDraftCandidateId())
  // const body = new FormData()
  // body.append('file', photoFile)
  // return Api.post(`/user/candidates/${candidateId}/files/photo/`, body, {
  //   headers: { 'Content-Type': 'multipart/form-data' },
  // })
  return {
    fieldName: 'file',
    options: {
      url: `${CANDIDATE_BASE_URL}/${candidateId}/files/photo/`,
      headers: { Authorization: `Bearer ${Api.getAuthToken()}` },
    },
  }
}

// SET PUBLIC DATA
export async function paramsForUploadPublicCVOfCandidate(candidateId: number): Promise<IUploadInputParams> {
  return {
    fieldName: 'file',
    options: {
      url: `${API_BASE_URL}/public/candidates/${candidateId}/files/cv/`,
      headers: { Authorization: `Bearer ${Api.getAuthToken()}` },
    },
  }
}

export async function paramsForUploadPublicLicenseOfCandidate(candidateId: number): Promise<IUploadInputParams> {
  return {
    fieldName: 'file0',
    options: {
      url: `${API_BASE_URL}/public/candidates/${candidateId}/files/licenses/`,
      headers: { Authorization: `Bearer ${Api.getAuthToken()}` },
    },
  }
}

export async function uploadPublicPhotoOfCandidate(candidateId: number): Promise<IUploadInputParams> {
  return {
    fieldName: 'file',
    options: {
      url: `${API_BASE_URL}/public/candidates/${candidateId}/files/photo/`,
      headers: { Authorization: `Bearer ${Api.getAuthToken()}` },
    },
  }
}

export default Candidates
