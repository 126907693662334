import styled from 'styled-components'
import { Box } from 'styles'

export const NoteContainer = styled(Box).attrs({
  width: '100%',
  bc: '#f5f6f8cc',
  br: '10px',
  mb: '10px',
  padding: '10px 20px',
  column: true,
})``

export const NewNoteContainer = styled(Box).attrs({
  smooth: true,
  bc: '#D8F0E9',
  fill: true,
  padding: '15px 0',
  margin: '30px 0',
  justifyCenter: true,
  pointer: true,
})``

export const PreNote = styled.pre`
  white-space: pre-wrap;
  word-break: break-all;
`
