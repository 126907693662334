import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { AppState } from 'store/rootReducer'
import NotesActions from 'store/notes/actions'
import { INotesState } from 'types/reducerStates'

import ModalComponents from 'components/Modal'
import PrimaryButton from 'components/Button'
import Loader from 'components/Loader'

import { Box, BoxCenterContent, H2, Image, Text, Textarea } from 'styles'
import { NoteContainer, NewNoteContainer, PreNote } from './styles'

const ICON_DOLLAR = <Image src={require('assets/icons/notes.svg')} />

type Props = {
  notes: INotesState
  onToggleModal: typeof NotesActions.onToggleModal
  fetchNotes: typeof NotesActions.fetchNotes
  addNote: typeof NotesActions.addNote
  editNote: typeof NotesActions.editNote
  deleteNote: typeof NotesActions.deleteNote
}

const NotesModal: React.FunctionComponent<Props> = ({ notes, onToggleModal, fetchNotes, addNote, editNote, deleteNote }) => {
  const [editNoteId, setEditNoteId] = useState<number | null>(null)
  const [showTextarea, toggleTextarea] = useState<boolean>(false)
  const [noteText, changeNoteText] = useState<string>('')

  const modalEl = useRef<HTMLHeadingElement>(null)

  const onHideModal = () => {
    setEditNoteId(null)
    onToggleModal(false)
    toggleTextarea(false)
  }
  const setEditMode = (id: number, text: string) => {
    setEditNoteId(id)
    toggleTextarea(true)
    changeNoteText(text)

    if (modalEl && modalEl.current) {
      modalEl.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }
  }
  const cancleMode = () => {
    setEditNoteId(null)
    changeNoteText('')
    toggleTextarea(false)
  }

  useEffect(() => {
    if (notes.get('isShow')) {
      fetchNotes()
    }
  }, [notes.get('isShow')])

  const onSendNote = () => {
    if (editNoteId) {
      editNote(editNoteId, noteText)
    } else {
      addNote(noteText)
    }
    cancleMode()
  }

  const notesList = notes.get('list')

  return (
    <ModalComponents isOpen={notes.get('isShow')} onRequestClose={onHideModal} hideCloseIcon withScroll>
      <BoxCenterContent br="6px" whiteBC column width="600px" mt="15px" mb="15px" padding="0 20px">
        <H2 black fz="40px" fw="500" ref={modalEl}>
          Notes
        </H2>
        {notes.get('showLoading') ? (
          <Box margin="32px 0">
            <Loader height="320px" />
          </Box>
        ) : notesList.length || showTextarea ? (
          <>
            {showTextarea ? (
              <>
                <Box fill height="200px" margin="30px 0 0" column>
                  <Text black>Your notes</Text>
                  <Textarea name="description" maxLength={20000} value={noteText} onChange={e => changeNoteText(e.target.value)} />
                </Box>
                <Box fill spaceBetween margin="30px 0">
                  <Box width="180px">
                    <PrimaryButton height="50px" type="secondary" onClick={cancleMode} title="CANCEL" />
                  </Box>
                  <Box width="180px">
                    <PrimaryButton height="50px" onClick={onSendNote} title={editNoteId ? 'SAVE' : 'ADD'} disabled={!noteText.trim()} />
                  </Box>
                </Box>
              </>
            ) : (
              <NewNoteContainer
                onClick={() => {
                  toggleTextarea(true)
                }}
              >
                <Text secondColor s pointer>
                  Add new note
                </Text>
              </NewNoteContainer>
            )}
            {notesList.map((item: any) => (
              <NoteContainer key={item.id}>
                <Box fill spaceBetween padding="5px 0">
                  <Text>{moment(item.createdAt).format('DD MMM YYYY, hh:mm:ss a')}</Text>
                  <Box>
                    <Text red pointer onClick={() => deleteNote(item.id)} width="50px">
                      Delete
                    </Text>
                    <Text secondColor pointer onClick={() => setEditMode(item.id, item.text)} width="50px">
                      Edit
                    </Text>
                  </Box>
                </Box>
                <PreNote>{item.text}</PreNote>
              </NoteContainer>
            ))}
          </>
        ) : (
          <>
            <Box margin="5px 0 32px 0">{ICON_DOLLAR}</Box>
            <H2 black fz="40px" fw="500">
              You didn’t add notes yet
            </H2>
            <Box margin="5px 0 32px 0">
              <Text width="300px">Add your firts note</Text>
            </Box>
            <Box width="180px" mb="18px">
              <PrimaryButton
                height="50px"
                onClick={() => {
                  toggleTextarea(true)
                }}
                title="ADD NOTE"
              />
            </Box>
          </>
        )}
      </BoxCenterContent>
    </ModalComponents>
  )
}

const mapStateToProps = (state: AppState) => ({
  notes: state.notes,
})

export default connect(
  mapStateToProps,
  {
    onToggleModal: NotesActions.onToggleModal,
    fetchNotes: NotesActions.fetchNotes,
    addNote: NotesActions.addNote,
    editNote: NotesActions.editNote,
    deleteNote: NotesActions.deleteNote,
  }
)(NotesModal)
