import React from 'react'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'
import MessengerActions from 'store/messenger/actions'
import EmployersActions from 'store/employers/actions'
import { InputEvent } from 'types/helps'

import DropDown from 'components/DropDown'
import { DialogInputWrapper, Paperclip, MessageTextarea, Send } from './components/styles'

import paperclip from 'assets/icons/paperclip.svg'
import sendIcon from 'assets/icons/send.svg'
import sendBlue from 'assets/icons/send-blue.svg'

type Props = {
  message: string
  sendMessage: typeof MessengerActions.sendMessage
  setModalType: typeof MessengerActions.setModalType
  changeInputValue: typeof MessengerActions.changeInputValue
  getAllLiveJobs: typeof EmployersActions.getAllLiveJobs
  clearInputValue: typeof MessengerActions.clearInputValue
  isOpenDialog: boolean
  chatUserType: string
}

const DialogContent: React.FC<Props> = ({
  message,
  changeInputValue,
  sendMessage,
  setModalType,
  getAllLiveJobs,
  clearInputValue,
  isOpenDialog,
  chatUserType,
}) => {
  const onChangeInput = (e: InputEvent) => {
    e.target.style.height = '5px'
    e.target.style.height = e.target.scrollHeight + 'px'
    const { name, value } = e.currentTarget
    changeInputValue(name, value)
  }

  const send = (e: any) => {
    if (message.length && isOpenDialog) {
      sendMessage()
      e.preventDefault()
      clearInputValue()
      let messageTextarea: any = document.getElementById('MessageTextarea')
      messageTextarea.style.height = '38px'
    }
  }

  return (
    <DialogInputWrapper>
      <DropDown
        options={
          chatUserType === 'employerContact'
            ? [
                {
                  name: 'Upload a file',
                  handler: () => {
                    setModalType('candidate')
                  },
                },
              ]
            : [
                {
                  name: 'Interview appointment',
                  handler: () => {
                    setModalType('interview')
                    getAllLiveJobs()
                  },
                },
                {
                  name: 'Upload a file',
                  handler: () => {
                    setModalType('candidate')
                  },
                },
              ]
        }
        toggler={<Paperclip src={paperclip} />}
      />
      <MessageTextarea noBorder noShadow id="MessageTextarea" placeholder="Your message" name="message" value={message} onChange={onChangeInput} />
      {message ? <Send src={sendBlue} onClick={(e: any) => send(e)} /> : <Send src={sendIcon} />}
    </DialogInputWrapper>
  )
}

const mapStateToProps = (state: AppState) => ({
  message: state.messenger.get('message'),
  isOpenDialog: state.messenger.get('isOpenDialog'),
})

export default connect(
  mapStateToProps,
  {
    sendMessage: MessengerActions.sendMessage,
    setModalType: MessengerActions.setModalType,
    changeInputValue: MessengerActions.changeInputValue,
    getAllLiveJobs: EmployersActions.getAllLiveJobs,
    clearInputValue: MessengerActions.clearInputValue,
  }
)(DialogContent)
