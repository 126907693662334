import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios'

export const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL

class Api {
  static tokenName = 'authToken'

  private static instance: Api

  private static authToken: string

  axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    this.axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          if (error.response.status === 403) {
            return localStorage.removeItem('persist:root')
          } else {
            return Promise.reject(error)
          }
        }
      }
    )
  }

  static getInstance() {
    if (!Api.instance) {
      Api.instance = new Api()
    }
    return Api.instance
  }

  static getAxios() {
    return Api.getInstance().axiosInstance
  }

  /**
   * SET token of auth
   *
   * @param {string} token
   */

  static setAuthToken(token: string) {
    this.authToken = token
    Api.getInstance().axiosInstance.defaults.headers.common.Authorization = `Bearer ${this.authToken}`
  }

  static getAuthToken() {
    return this.authToken
  }

  /**
   * GET
   *
   * @param {string} url
   * @param {object} params
   * @return {AxiosPromise}
   */

  static get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return Api.getAxios().get(url, config)
  }

  /**
   * POST
   *
   * @param {string} url
   * @param {any} data
   * @param {AxiosRequestConfig} config
   * @return {AxiosPromise}
   */

  static post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return Api.getAxios().post(url, data, config)
  }

  static patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
    return Api.getAxios().patch(url, data, config)
  }

  static delete<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return Api.getAxios().delete(url, config)
  }
}

export default Api
