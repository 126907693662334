import Api from './index'
import { IDataPipeline } from 'types/responseData'
import { IInvoiceCreating } from 'types/models'

class Pipeline {
  /**
   * Gets getPipeline
   */
  static getPipeline() {
    return Api.get<IDataPipeline>('/user/pipeline/')
  }

  /**
   * Change status
   */
  static changeStatus(status: string) {
    return Api.post<IDataPipeline>('/user/match/candidate-with-job-position/', status)
  }

  /**
   * Create invoice
   */
  static createInvoice(matchId: number, data: IInvoiceCreating) {
    const { startDate, annualSalary, commissionPercent } = data
    return Api.post(
      `/user/matched/${matchId}/invoices/`,
      JSON.stringify({
        startDate,
        annualSalary: +(+annualSalary).toFixed(2),
        commissionPercent: +(+commissionPercent).toFixed(2),
      })
    )
  }

  /**
   * Send blobfile
   */
  static sendBlobfile(invoiceId: number, blobFile: Blob) {
    const file = new FormData()
    file.append('file', blobFile)
    const options = {
      headers: {
        Authorization: `Bearer ${Api.getAuthToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    }
    return Api.post(`/user/matched/invoices/${invoiceId}/send/`, file, options)
  }
}

export default Pipeline
