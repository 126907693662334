import React, { FC, useState, useCallback, useEffect } from 'react'
import ModalComponents from '../../Modal'
import { H2 } from 'styles'
import InputBox from '../../Input'
import styled from 'styled-components'
import { FormikProvider, useFormik } from 'formik'
import { ProjectTypeListItem, useProjectTypesCRUD } from 'hooks/useProjectTypesCRUD'

interface Props {
  isOpen: boolean
  onClose: () => void
  project: ProjectTypeListItem
  refreshList: () => void
}

interface Values {
  name: string
}

export const EditProjectModal: FC<Props> = ({ isOpen, onClose, project, refreshList }) => {
  const [isDeleteErrorModalOpen, setDeleteErrorModalOpen] = useState<boolean>(false)
  const { updateProject, deleteProject } = useProjectTypesCRUD()
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string>('')
  const [error, setErrors] = useState('')
  const [initialValues, setInitialValues] = useState({ name: project.name || '' })

  const handleSave = useCallback(
    async (values: Values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        await updateProject(project.id, values.name)
        form.resetForm()
        refreshList()
        // fastest way to refresh list
        closeModal()
      } catch (e) {
        refreshList()
        setErrors(e.response.data.message)
      } finally {
        setSubmitting(false)
      }
    },
    [project]
  )

  const form = useFormik({
    initialValues,
    onSubmit: handleSave,
    enableReinitialize: true,
  })

  useEffect(() => {
    setInitialValues({ name: project.name })
  }, [project])

  const closeModal = useCallback(() => {
    if (onClose) {
      onClose()
      setErrors('')
    }
  }, [onClose])

  const openDeleteErrorModal = useCallback(() => {
    onClose()
    setDeleteErrorModalOpen(true)
  }, [])

  const handleDelete = useCallback(async () => {
    try {
      await deleteProject(project.id)
      refreshList()
      onClose()
    } catch (e) {
      console.log({ e })
      if (!e.response) {
        console.log({ e })
        return
      }
      openDeleteErrorModal()
      setDeleteErrorMessage((e.response.data || {}).message || '')
    }
  }, [project])

  const closeDeleteErrorModal = useCallback(() => {
    setDeleteErrorModalOpen(false)
    setDeleteErrorMessage('')
  }, [])

  return (
    <>
      <ModalComponents isOpen={isOpen} onRequestClose={closeModal} hideCloseIcon>
        <ContentWrapper>
          <FormikProvider value={form}>
            <H2 black fw="bold">
              Edit project
            </H2>
            <Form>
              <FormField>
                <Label>Name of project type</Label>
                <InputBox type="text" value={form.values.name} onChange={form.handleChange} name="name" />
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </FormField>
            </Form>
            <ButtonsWrapper>
              <DeleteButton type="button" onClick={handleDelete}>
                delete
              </DeleteButton>
              <SaveButton type="button" onClick={() => form.handleSubmit()}>
                save project type
              </SaveButton>
            </ButtonsWrapper>
          </FormikProvider>
        </ContentWrapper>
      </ModalComponents>

      <ModalComponents isOpen={isDeleteErrorModalOpen} onRequestClose={closeDeleteErrorModal} hideCloseIcon>
        <ContentWrapper>
          <H2 black fw="bold">
            You can’t delete this project
          </H2>
          <Hint>{deleteErrorMessage}</Hint>
        </ContentWrapper>
      </ModalComponents>
    </>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 540px;
  padding: 20px;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 24px;
  width: 100%;
`

const FormField = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #292c38;
  margin-bottom: 10px;
`

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`

const SaveButton = styled.button`
  background: #3cb593;
  border-radius: 6px;
  color: #ffffff;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  padding: 5px;
  border: none;
  height: 52px;
  width: 157px;
  font-family: 'Mukta', sans-serif;
  cursor: pointer;
  :hover {
    filter: brightness(90%);
  }
`

const DeleteButton = styled.button`
  background: #e3e8f1;
  border-radius: 6px;
  color: #292c38;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  padding: 5px;
  border: none;
  height: 52px;
  width: 157px;
  font-family: 'Mukta', sans-serif;
  cursor: pointer;
  :hover {
    filter: brightness(90%);
  }
`

const Hint = styled.div`
  font-family: 'Mukta', sans-serif;
  font-size: 16px;
  text-align: center;
  color: #9caab8;
  margin-top: 14px;
`
