import React from 'react'

import Dropdown from 'components/DropDown'

import { BoxFill, Text } from 'styles'
import { Wrap, Green, TitleWrap, Arrow } from './styled'

type Props = {
  title: string
  lookingFor: string
  setCurrentSearch: any
}

const Header: React.FC<Props> = ({ title, lookingFor, setCurrentSearch }) => {
  return (
    <BoxFill>
      <Wrap>
        <TitleWrap mb="30px">
          <Text white fz="28px" fw="bold" lh="46px">
            {title}
          </Text>
          <Dropdown
            options={[
              {
                name: 'Candidates',
                handler: () => {
                  setCurrentSearch('Candidates')
                },
              },
              {
                name: 'Live Jobs',
                handler: () => {
                  setCurrentSearch('Live Jobs')
                },
              },
              {
                name: 'Employers',
                handler: () => {
                  setCurrentSearch('Employers')
                },
              },
              {
                name: 'Contacts',
                handler: () => {
                  setCurrentSearch('Contacts')
                },
              },
            ]}
            toggler={
              <>
                <Green fz="28px" fw="bold" lh="46px">
                  {lookingFor}
                </Green>
                <Arrow src={require('assets/icons/arrowBottomGreen.svg')} />
              </>
            }
            bottom="-195px"
            title="Search"
            isActive={lookingFor}
          />
        </TitleWrap>
      </Wrap>
    </BoxFill>
  )
}

export default Header
