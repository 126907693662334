import { fromJS, List } from 'immutable'

import {
  PipelineTypes,
  FETCH_PIPELINE,
  FETCH_PIPELINE_SUCCESS,
  FETCH_PIPELINE_FAIL,
  CHANGE_PIPELINE_STATUS,
  CHANGE_PIPELINE_STATUS_SUCCESS,
  CHANGE_PIPELINE_STATUS_FAIL,
  SEND_BLOBFILE,
  SEND_BLOBFILE_SUCCESS,
  SEND_BLOBFILE_FAIL,
} from './actions'

import { IPipelineState } from 'types/reducerStates'

const initialState: IPipelineState = fromJS({
  isFetching: false,
  isFetchingUserData: false,
  error: null,
  pipeline: List([]),
})

export default (state = initialState, action: PipelineTypes): IPipelineState => {
  switch (action.type) {
    case FETCH_PIPELINE:
      return state.set('isFetching', true)
    case FETCH_PIPELINE_SUCCESS:
      return state
        .set('isFetching', false)
        .set('pipeline', action.payload)
        .set('error', null)
    case FETCH_PIPELINE_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case CHANGE_PIPELINE_STATUS:
      return state.set('isFetching', true)
    case CHANGE_PIPELINE_STATUS_SUCCESS:
      return state.set('isFetching', false).set('error', null)
    case CHANGE_PIPELINE_STATUS_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case SEND_BLOBFILE:
      return state.set('isFetching', true)
    case SEND_BLOBFILE_SUCCESS:
      return state.set('isFetching', false).set('error', null)
    case SEND_BLOBFILE_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    default:
      return state
  }
}
