import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import TaskActions from 'store/task/action'
import { ITask } from 'types/models'

import Loader from 'components/Loader'
import Task from '../../Task'
import { NoTask } from '../../NoTask'

type Props = {
  anyTime: {
    count: number
    rows: ITask[]
  }
  isLoading: boolean
  deleteTask: any
  closeTask: any
}

export const AnyTasks = React.memo<Props>(({ anyTime, isLoading, deleteTask, closeTask }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(TaskActions.getTaskListByFilter('any'))
  }, [dispatch])

  const renderAny = () => (
    <>
      {anyTime.rows && anyTime.rows.length ? (
        anyTime.rows.map(el => {
          return (
            <Task
              id={el.id}
              key={el.id}
              title={el.title}
              type="any"
              description={el.description}
              dueDate={el.dueDate}
              dueTime={el.dueTime}
              deleteTask={deleteTask}
              closeTask={closeTask}
              priority="any time"
            />
          )
        })
      ) : (
        <NoTask />
      )}
    </>
  )

  return <>{isLoading ? <Loader height="400px" /> : renderAny()}</>
})
