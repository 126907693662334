import Api from './index'

import { IUser } from 'types/models'
import { IQueryParams } from 'types/helps'

class Admin {
  static getUsers(query: IQueryParams) {
    return Api.get('/manage/users/', { params: query })
  }

  static deleteUser(id: number) {
    return Api.delete(`/manage/users/${id}/`)
  }

  static approveUser(id: number) {
    return Api.post<IUser>(`/manage/users/${id}/approve/`)
  }
}

export default Admin
