import { call, put, takeLatest, select } from 'redux-saga/effects'

import AuthActions, { LOGIN, REFRESH_LOGIN, RESET_PASSWORD, CREATE_USER, UPDATE_USER, SET_NEW_PASSWORD, COMPLETE_REGISTRATION } from './actions'

import Api from 'api'
import AuthApi from 'api/auth'

import { smoothScroll } from 'utils'

const FRONT_BASE_URL = process.env.REACT_APP_FRONT_BASE_URL

function* loginFlow(action: ReturnType<typeof AuthActions.login>) {
  try {
    const { email, pass } = action.payload
    if (!email || !pass) return yield put(AuthActions.loginFail({ message: 'Please fill in your username and password you set up.' }))

    const { data } = yield call(AuthApi.login, email, pass)
    Api.setAuthToken(data.token)
    yield put(AuthActions.loginSuccess(data))
  } catch (err) {
    yield put(AuthActions.loginFail(err.response.data))
  }
}

function* createUserFlow(action: ReturnType<typeof AuthActions.createUser>) {
  try {
    yield call(AuthApi.createUser, action.payload)
    window.location.href = FRONT_BASE_URL + '/success-request'
    yield put(AuthActions.createUserSuccess())
  } catch (err) {
    yield put(AuthActions.createUserFail(err.response.data))
  }
}

function* updateUserFlow(action: ReturnType<typeof AuthActions.updateUser>) {
  try {
    if (!action.payload.user.pass) delete action.payload.user.email
    const { firstName, lastName, linkedIn, email, phone, pass, abn, tfn, bsb, account } = action.payload.user
    let res
    switch (action.payload.type) {
      case 'edit profile':
        res = yield call(AuthApi.updateUser, { firstName, lastName, linkedIn, email, phone, pass, abn, tfn, bsb, account })
        break
      case 'goals':
        res = yield call(AuthApi.updateUser, {
          firstName,
          lastName,
          linkedIn,
          email,
          phone,
          pass,
          abn,
          tfn,
          bsb,
          account,
          weeklyGoal: action.payload.user.weeklyGoal,
          quarterGoal: action.payload.user.quarterGoal,
          monthlyGoal: action.payload.user.monthlyGoal,
          annualGoal: action.payload.user.annualGoal,
        })
        break
      default:
        res = yield call(AuthApi.updateUser, action.payload)
    }
    yield put(AuthActions.updateUserSuccess(res.data))
    smoothScroll()
  } catch (err) {
    yield put(AuthActions.updateUserFail(err.response.data))
  }
}

function* completeRegistrationFlow(action: ReturnType<typeof AuthActions.completeRegistrarion>) {
  try {
    const { data } = yield call(AuthApi.createPasswordAndCompleteRegistration, action.payload.hash, action.payload.pass)
    yield put(AuthActions.completeRegistrarionSuccess(data))
  } catch (err) {
    yield put(AuthActions.completeRegistrarionFail(err.response.data))
  }
}

function* refreshLoginFLow() {
  try {
    const token = yield select(state => state.auth.get('token'))
    if (!token) return yield put(AuthActions.refreshLoginFail())
    yield Api.setAuthToken(token)
    const { data } = yield call(AuthApi.getUser)
    yield put(AuthActions.refreshLoginSuccess({ token, user: data }))
  } catch (err) {
    yield put(AuthActions.refreshLoginFail(err.response.data.message))
  }
}

function* resetPasswordFlow(action: ReturnType<typeof AuthActions.resetPassword>) {
  try {
    yield call(AuthApi.resetPassword, action.payload)
    window.location.href = FRONT_BASE_URL + `/success-reset-password?email=${action.payload}`
  } catch (err) {
    yield put(AuthActions.resetPasswordFail(err.response.data))
  }
}

function* setNewPasswordFlow(action: ReturnType<typeof AuthActions.setNewPassword>) {
  try {
    const { data } = yield call(AuthApi.setNewPassword, action.payload.hash, action.payload.pass)
    yield put(AuthActions.setNewPasswordSuccess(data))
  } catch (err) {
    yield put(AuthActions.resetPasswordFail(err.response.data))
  }
}

export default function* watchAuths() {
  yield takeLatest(LOGIN, loginFlow)
  yield takeLatest(CREATE_USER, createUserFlow)
  yield takeLatest(UPDATE_USER, updateUserFlow)
  yield takeLatest(COMPLETE_REGISTRATION, completeRegistrationFlow)
  yield takeLatest(RESET_PASSWORD, resetPasswordFlow)
  yield takeLatest(SET_NEW_PASSWORD, setNewPasswordFlow)
  yield takeLatest(REFRESH_LOGIN, refreshLoginFLow)
}
