import React, { useState } from 'react'
import { connect } from 'react-redux'

import AuthActions from 'store/auth/actions'
import { INotValidFieldsErr } from 'types/errors'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import PrimaryButton from 'components/Button'
import { RenderNotValidFields } from 'components/Others'

import { Span, Text, H2, Box, BoxFill, GreenBox } from 'styles'
import { Container } from '../styles'

type Props = {
  goToRequest: () => void
  onForgotPassword: () => void
  login: typeof AuthActions.login
  error: INotValidFieldsErr
  isResetPassword: string
}

const LoginScreen: React.FC<Props> = ({ goToRequest, login, error, onForgotPassword, isResetPassword }) => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const onLogin = () => login(email, password)
  const onChangeEmail = (e: InputEvent) => setEmail(e.currentTarget.value)
  const onChangePassword = (e: InputEvent) => setPassword(e.currentTarget.value)

  return (
    <Container>
      <H2 fw="bold" black>
        Log In
      </H2>
      <Box margin="7px 0 30px 0">
        <Text>You can add your email and password below to login!</Text>
      </Box>
      {isResetPassword && (
        <BoxFill mb="15px">
          <GreenBox fill>
            <Text fill>You successfully reset your password!</Text>
          </GreenBox>
        </BoxFill>
      )}
      <BoxFill mb="15px">
        <Input value={email} onChange={onChangeEmail} label="Email address" maxLength={70} />
      </BoxFill>
      <BoxFill>
        <Input value={password} type="password" onChange={onChangePassword} label="Enter your password" />
      </BoxFill>
      <BoxFill justifyRight>
        <Text s pointer fw="500" onClick={onForgotPassword}>
          Forgot password?
        </Text>
      </BoxFill>
      <RenderNotValidFields errors={error} />
      <BoxFill height="60px" margin="20px 0">
        <PrimaryButton onClick={onLogin} title="LOG IN" />
      </BoxFill>
      <Box>
        <Text>
          Do not have an account yet?
          <Span onClick={goToRequest}> Join for free</Span>
        </Text>
      </Box>
    </Container>
  )
}

export default connect(
  null,
  { login: AuthActions.login }
)(LoginScreen)
