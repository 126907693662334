import React from 'react'
import styled from 'styled-components'

import Button from 'components/Button'

import { Box, BoxFill, Text } from 'styles'
/* hide in MVP version */
// import SortBy from '../../../components/Input/sortBy'

const Wrapper = styled(BoxFill).attrs({
  spaceBetween: true,
  alignCenter: true,
  mainBC: true,
})<{ isListEmpty: boolean }>`
  padding: ${props => (props.isListEmpty ? '120px 80px 25px' : '40px 80px 80px')};
  transition-duration: 0ms;
`

/* hide in MVP version */
// const SortIcon = styled(BoxCenterContent).attrs({
//   pointer: true,
//   ml: '14px',
//   br: '6px',
//   width: '52px',
//   height: '52px',
// })`
//   background-color: ${props => props.theme.main.countButtonBackground};
// `

/* hide in MVP version */
// const SortTypes = [
//   'Recent activity',
//   'By name (A-Z)',
//   'By name (Z-A)',
//   'By job positions (ascending)',
//   'By job positions (descending)',
// ]

type Props = {
  /* hide in MVP version */
  // currentSort: string
  // changeSort: (sort: string) => void
  title: string
  isListEmpty: boolean
  numItems: number
  addItemRoute: () => void
}

const Head: React.FC<Props> = ({ addItemRoute, isListEmpty, numItems, title }) => {
  return (
    <BoxFill>
      <Wrapper isListEmpty={isListEmpty}>
        <Box column>
          <Text white fz="42px" fw="bold" lh="46px">
            {title}
          </Text>
          {numItems && <Text s opacity="0.6">{`${numItems} ${title.toLocaleLowerCase()} in total`}</Text>}
        </Box>
        <Box>
          {/* hide in MVP version */}
          {/* {isListEmpty || (
            <Box>
              <Box width="250px" height="52px">
                <SortBy changeSort={changeSort} currentSort={currentSort} sortTypes={SortTypes} />
              </Box>
              <SortIcon>
                <Image pointer src={require('assets/icons/sort.svg')} />
              </SortIcon>
            </Box>
          )} */}
          <Box ml="20px" width="141px" height="52px">
            <Button onClick={addItemRoute} title={`ADD ${title.toUpperCase().substr(0, title.length - 1)}`} />
          </Box>
        </Box>
      </Wrapper>
    </BoxFill>
  )
}

export default Head
