import styled from 'styled-components'

import { Text } from 'styles'

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e3e8f1;
  padding: 0 0 12px 0;
  margin: 0 0 10px 0;
`

export const Bottom = styled.div``

export const TopText = styled(Text).attrs({})`
  font-weight: 500;
  text-align: left;
`

export const BottomText = styled(Text).attrs({})`
  text-align: left;
`
