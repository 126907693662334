import React from 'react'
import styled from 'styled-components'

import { Box, Text, Image } from 'styles'

const Container = styled(Box).attrs({
  ml: '30px',
  whiteBC: true,
  width: '25vw',
  br: '6px',
  column: true,
  padding: '30px',
})`
  @media (max-width: 768px) {
    width: auto;
    margin: 20px;
    padding: 20px;
  }
`

type Props = {
  title: string
  text: string
}

const FAQ: React.FunctionComponent<Props> = ({ title, text }) => (
  <Container>
    <Image width="48px" height="48px" src={require('assets/images/lamp.png')} />
    <Box mt="10px" mb="10px">
      <Box>
        <Text left black fw="bold" xl>
          {title}
        </Text>
      </Box>
    </Box>
    <Text justify>{text}</Text>
  </Container>
)

export default FAQ
