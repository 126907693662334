import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ValidationError } from 'yup'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'
import CandidatesActions from 'store/candidates/actions'
import { ISelectListItem } from 'types/forms'
import { ICandidateWithLastWorkPlace, ICandidate } from 'types/models'
import { InputEvent } from 'types/helps'

import Stepper from 'components/Stepper'
import Alert from 'components/Alert'
import FormFrame from 'components/FormStepFrame'
import FAQ from 'components/faqSteps'
import FirstStep from './steps/1'
import SecondStep from './steps/2'
import ThirdStep from './steps/3'
import FourStep from './steps/4'
import FiveStep from './steps/5'
import textOfStep from './steps/textOfStep'

import { getDraftCandidateId, deleteDraftCandidateId } from 'utils'
import { addCandidateSchemaFirstStep, addCandidateSchemaSecondStep, formatErrors } from 'services/validation'
import theme from 'styles/theme'
import { BoxFill, Box } from 'styles'

const Steps = [
  'Step 1. Personal information',
  'Step 2. Professional details',
  'Step 3. Upload CV',
  'Step 4. Upload licenses (optional)',
  'Step 5. Upload photo (optional)',
]

type Props = {
  candidate: ICandidate
  createCandidate: typeof CandidatesActions.createCandidateStarted
  errors: any
  resetStore: () => void
  setCandidateData: typeof CandidatesActions.setCandidateData
  isFetching: boolean
  day: {
    value: string
    error: string
    get: (value: string) => string
  }
  month: {
    value: string
    error: string
    get: (value: string) => string
  }
  year: {
    value: string
    error: string
    get: (value: string) => string
  }
  weeks: {
    get: (value: string) => string
  }
}

type State = {
  currentStep: number
  form: Partial<ICandidateWithLastWorkPlace>
  errors: any
}

class AddCandidate extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    currentStep: 1,
    form: {
      relocate: false,
      wpCurrent: false,
      experience: 0,
      Positions: [],
      Sectors: [],
      RelocateCity: [],
    },
    errors: {},
  }
  image: any = undefined

  async componentDidMount() {
    this.checkCandidateInDraft()
    this.props.resetStore()
  }

  checkCandidateInDraft = async () => {
    if (getDraftCandidateId())
      Alert({
        titleText: 'You have an incomplete candidate, would you like to continue filling?',
        text: `this function will not work in the MVP version, 
                now in any case a new candidate will be created, 
                in the next releases will be work`,
        confirmButtonText: 'Continue adding candidate',
        cancelButtonText: 'Create new candidate',
        onClose: () => deleteDraftCandidateId(),
      })
  }

  setBlobOfCroppedImage = (image: any) => (this.image = image)

  handleInputChange = (e: any) => {
    const {
      name,
      value,
      validity: { valid },
    } = e.target

    const { setCandidateData } = this.props
    if (valid) setCandidateData(name, value)
  }

  renderCurrentStep = () => {
    const { currentStep, form, errors } = this.state
    const { candidate, day, month, year, setCandidateData } = this.props

    setCandidateData('day', day.get('value'))
    setCandidateData('month', month.get('value'))
    setCandidateData('year', year.get('value'))

    switch (currentStep) {
      case 1:
        return <FirstStep errors={errors} onChangeField={this.onChangeField} form={form} />
      case 2:
        return (
          <SecondStep
            onChangeField={this.onChangeField}
            form={form}
            errors={errors}
            handleInputChange={this.handleInputChange}
            day={day}
            month={month}
            year={year}
            setCandidateData={setCandidateData}
          />
        )
      case 3:
        return <ThirdStep form={candidate} />
      case 4:
        return <FourStep form={candidate} />
      case 5:
        return <FiveStep form={candidate} onUpdate={this.finishAddCandidate} />
      default:
        return null
    }
  }

  nextStep = () => {
    const { currentStep } = this.state
    const { createCandidate } = this.props
    const validatorSteps: any = {
      1: addCandidateSchemaFirstStep,
      2: addCandidateSchemaSecondStep,
    }
    const createCandidateSteps: any = {
      1: (form: any) => form,
      2: createCandidate,
    }

    if (currentStep === 1) {
      if (!this.state.form.email) delete this.state.form.email
    } else if (currentStep > 2) return this.setState({ currentStep: ++this.state.currentStep })

    const validator = validatorSteps[currentStep]
    validator
      .validate(this.state.form, { abortEarly: false })
      .then(() => {
        createCandidateSteps[currentStep](this.state.form)
        this.setState({ errors: {}, currentStep: ++this.state.currentStep })
      })
      .catch((err: ValidationError) => this.setState({ ...this.state, errors: formatErrors(err) }))
  }

  prevStep = () => this.setState({ currentStep: --this.state.currentStep })

  onChangeField = (e: InputEvent & ISelectListItem & number, type?: string) => {
    const { day, month, year } = this.props
    const { form } = this.state
    if (type) return this.setState({ form: { ...form, [type]: e } })
    const {
      name,
      value,
      validity: { valid },
    } = e.currentTarget
    if (e.currentTarget.type === 'checkbox') {
      return this.setState({ form: { ...form, [name]: !form[name as keyof boolean] } })
    }

    if (valid) {
      this.setState({
        form: { ...form, [name]: value, availability: `${year.get('value')}-${month.get('value')}-${day.get('value')}` },
      })
    }
  }

  finishAddCandidate = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    const { currentStep } = this.state
    const { history, isFetching } = this.props
    const isLastStep = currentStep === 5
    const isCreateStep = currentStep === 2
    const goBack = () => history.goBack()
    return (
      <BoxFill bc={theme.main.homeBackground} column>
        <Stepper currentStep={currentStep} steps={Steps} title="Add candidate" onExit={goBack} />
        <Box mt="-25vh">
          <FormFrame
            currentStep={currentStep}
            maxStep={Steps.length}
            btnText={isLastStep ? '' : isCreateStep ? 'CREATE CANDIDATE AND CONTINUE' : 'NEXT STEP'}
            title={textOfStep(currentStep)!.title}
            text={textOfStep(currentStep)!.subTitle}
            nextStep={this.nextStep}
            back={this.prevStep}
            isShowBackStep={currentStep !== 3}
            isFetching={isFetching}
          >
            {this.renderCurrentStep()}
          </FormFrame>
          <FAQ title={textOfStep(currentStep)!.faq.title} text={textOfStep(currentStep)!.faq.text} />
        </Box>
      </BoxFill>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  candidate: state.currentInnerPage.get('item'),
  day: state.candidates.get('day'),
  month: state.candidates.get('month'),
  year: state.candidates.get('year'),
  weeks: state.lists.get('weeks'),
  isFetching: state.candidates.get('isFetching'),
})

export default connect(
  mapStateToProps,
  {
    createCandidate: CandidatesActions.createCandidateStarted,
    resetStore: CandidatesActions.resetStore,
    setCandidateData: CandidatesActions.setCandidateData,
  }
)(AddCandidate)
