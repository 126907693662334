import styled from 'styled-components'

import Button from 'components/Button'

import { BoxCol, Box, Text } from 'styles'

export const WrapMain = styled(BoxCol).attrs({
  width: '100%',
  maxWidth: '70vw',
  minHeight: '316px',
  smooth: true,
  whiteBC: true,
  flex: 1,
  margin: '0 0 70px 0px',
})``

export const WrapTabs = styled.div`
  width: 100%;
  padding: 0 30px 30px 30px;
  position: relative;
`

export const Tasks = styled(Box).attrs({})`
  min-width: 100%;
  justify-content: space-between;
  padding: 0 30px;
`

export const AmountTasks = styled(Text).attrs(props => ({
  color: props.theme.main.colors.grey3,
  fw: 'bold',
}))``

export const AddNewTask = styled.span`
  color: ${props => props.theme.main.colors.primary};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const Priority = styled.div`
  color: #9caab8;
  margin: 0 0 7px 0;
`

export const ModalWrap = styled(BoxCol).attrs({
  width: '625px',
  padding: '20px',
})``

export const NewTask = styled(Text).attrs({
  fw: 'bold',
})`
  margin: 0 0 10px 0;
`

export const NTSubtitle = styled(Text).attrs({})`
  margin: 0 0 32px 0;
`

export const TextareaWrap = styled(Box).attrs({
  width: '100%',
  height: '137px',
})`
  margin: 0 0 40px 0;
`

export const InputBlock = styled(Box).attrs({
  width: '100%',
})`
  margin: 0 0 15px 0;
`

export const CreateTaskWrap = styled(Box).attrs({
  width: '100%',
  margin: '20px 0 10px 0',
})``

export const CreateTask = styled(Button).attrs({
  width: '165px',
  height: '60px',
})`
  margin: 0 auto;
`

export const NoTaskWrap = styled(BoxCol).attrs({
  alignCenter: true,
  justifyCenter: true,
  minHeight: '400px',
})``

export const NoTaskTitle = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #292c38;
  margin: 23px 0 0 0;
`
export const NoTaskSubTitle = styled.span`
  font-size: 16px;
  line-height: 20px;
  color: #9caab8;
  margin: 10px 0 0 0;
`

export const NewTaskLink = styled.span`
  color: #3cb593;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`

export const CharsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const Chars = styled.p`
  font-size: 13px;
  color: #9caab8;
  padding: 3px 0 0 5px;
`

export const ScrollToTop = styled.img`
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
  cursor: pointer;
  border-radius: 50%;
  padding: 9px 8px;
  position: fixed;
  right: 15px;
  bottom: 9%;
  &:hover {
    opacity: 0.8;
  }
`
