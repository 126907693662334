import { call, select, put, takeLatest } from 'redux-saga/effects'

import NotesActions, { NOTES__FETCH_ALL_STARTED, NOTES__ADD_NOTE_STARTED, NOTES__EDIT_NOTE_STARTED, NOTES__DELETE_NOTE_STARTED } from './actions'

import EmployersActions from 'store/employers/actions'
import CandidatesActions from 'store/candidates/actions'
import SearchActions from 'store/search/action'

import NotesApi from 'api/notes'

function* fetchNotesFlow() {
  try {
    const notes = yield select(state => state.notes)
    let data: any
    switch (notes.get('type')) {
      case 'candidate':
        const { data: canidateNotes } = yield call(NotesApi.getCandidateNotes, notes.get('itemId'))
        data = canidateNotes
        break
      case 'employer':
        const { data: employerNotes } = yield call(NotesApi.getEmployerNotes, notes.get('itemId'))
        data = employerNotes
        break
      case 'employer-contact':
        const { data: employerContactNotes } = yield call(NotesApi.getEmployerContactNotes, notes.get('itemId'))
        data = employerContactNotes
        break
      case 'jobPosition':
        const { data: jobPositionNotes } = yield call(NotesApi.getJobPositionNotes, notes.get('itemId'))
        data = jobPositionNotes
        break
    }
    yield put(NotesActions.fetchNotesSuccess(data))
  } catch (err) {}
}

function* addNoteFlow(action: ReturnType<typeof NotesActions.addNote>) {
  try {
    const store = yield select(state => state)
    let data: any
    switch (store.notes.get('type')) {
      case 'candidate':
        const { data: canidateNote } = yield call(NotesApi.postCandidateNotes, store.notes.get('itemId'), {
          text: action.payload,
        })
        data = canidateNote
        yield put(CandidatesActions.changeNotesCount(store.notes.get('itemId'), 1))
        yield put(CandidatesActions.fetchCandidateStarted(store.notes.get('itemId')))
        break
      case 'employer':
        const { data: employerNote } = yield call(NotesApi.postEmployerNotes, store.notes.get('itemId'), {
          text: action.payload,
        })
        data = employerNote
        yield put(EmployersActions.changeNotesCount(store.notes.get('itemId'), 1))
        yield put(EmployersActions.fetchEmployer(store.notes.get('itemId')))
        break
      case 'employer-contact':
        const { data: employerContactNote } = yield call(NotesApi.postEmployerContactNotes, store.notes.get('itemId'), {
          text: action.payload,
        })
        data = employerContactNote
        yield put(EmployersActions.changeNotesCount(store.notes.get('itemId'), 1))
        if (store.currentInnerPage.get('item')) {
          yield put(EmployersActions.fetchEmployer(store.currentInnerPage.get('item').id))
        } else {
          yield put(SearchActions.searchContacts())
        }
        break
      case 'jobPosition':
        const { data: jobPositionNote } = yield call(NotesApi.postJobPositionNotes, store.notes.get('itemId'), {
          text: action.payload,
        })
        yield put(EmployersActions.changeNotesCountJobPos(store.notes.get('itemId'), 1))
        yield put(EmployersActions.fetchEmployer(store.notes.get('itemId')))
        data = jobPositionNote
        break
    }
    yield put(NotesActions.addNoteSuccess(data))
  } catch (err) {}
}

function* editNoteFlow(action: ReturnType<typeof NotesActions.editNote>) {
  try {
    const notes = yield select(state => state.notes)
    let data: any
    switch (notes.get('type')) {
      case 'candidate':
        const { data: canidateNote } = yield call(NotesApi.editCandidateNotes, action.payload.id, {
          text: action.payload.text,
        })
        data = canidateNote
        break
      case 'employer':
        const { data: employerNote } = yield call(NotesApi.editEmployerNotes, action.payload.id, {
          text: action.payload.text,
        })
        data = employerNote
        break
      case 'employer-contact':
        const { data: employerContactNote } = yield call(NotesApi.editEmployerContactNotes, action.payload.id, {
          text: action.payload.text,
        })
        data = employerContactNote
        break
      case 'jobPosition':
        const { data: jobPositionNote } = yield call(NotesApi.editJobPositionNotes, action.payload.id, {
          text: action.payload.text,
        })
        data = jobPositionNote
        break
    }
    yield put(NotesActions.editNoteSuccess(data))
  } catch (err) {}
}

function* deleteNoteFlow(action: ReturnType<typeof NotesActions.deleteNote>) {
  try {
    const store = yield select(state => state)
    switch (store.notes.get('type')) {
      case 'candidate':
        yield call(NotesApi.deleteCandidateNotes, action.payload)
        yield put(CandidatesActions.changeNotesCount(store.notes.get('itemId'), -1))
        yield put(CandidatesActions.fetchCandidateStarted(store.notes.get('itemId')))
        break
      case 'employer':
        yield call(NotesApi.deleteEmployerNotes, action.payload)
        yield put(EmployersActions.changeNotesCount(store.notes.get('itemId'), -1))
        yield put(EmployersActions.fetchEmployer(store.notes.get('itemId')))
        break
      case 'employer-contact':
        yield call(NotesApi.deleteEmployerContactNotes, action.payload)
        yield put(EmployersActions.changeNotesCount(store.notes.get('itemId'), -1))
        if (store.currentInnerPage.get('item')) {
          yield put(EmployersActions.fetchEmployer(store.currentInnerPage.get('item').id))
        } else {
          yield put(SearchActions.searchContacts())
        }
        break
      case 'jobPosition':
        yield call(NotesApi.deleteJobPositionNotes, action.payload)
        yield put(EmployersActions.changeNotesCountJobPos(store.notes.get('itemId'), -1))
        break
    }
    yield put(NotesActions.deleteNoteSuccess(action.payload))
  } catch (err) {}
}

export default function* watchNotes() {
  yield takeLatest(NOTES__FETCH_ALL_STARTED, fetchNotesFlow)
  yield takeLatest(NOTES__ADD_NOTE_STARTED, addNoteFlow)
  yield takeLatest(NOTES__EDIT_NOTE_STARTED, editNoteFlow)
  yield takeLatest(NOTES__DELETE_NOTE_STARTED, deleteNoteFlow)
}
