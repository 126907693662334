import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { AppState } from 'store/rootReducer'
import EmployersActions from 'store/employers/actions'
import NotesActions from 'store/notes/actions'
import { IEmployersState } from 'types/reducerStates'

import NotesModal from 'containers/Notes'
import Header from 'components/ForCandidates&Employers/header'
import EmployerCard from 'components/ForCandidates&Employers/employerCard'
import Body from 'components/ForCandidates&Employers/body'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'

import { BoxFullFill, Image } from 'styles'

/* hide in MVP version */
// type Sort =
//   | 'Recent activity'
//   | 'By name (A-Z)'
//   | 'By name (Z-A)'
//   | 'By job positions (ascending)'
//   | 'By job positions (descending)'

type Props = {
  employers: IEmployersState
  fetchEmployers: typeof EmployersActions.fetchEmployers
  onToggleModal: typeof NotesActions.onToggleModal
  isFetching: boolean
}

const LiveJobs: React.FC<Props & RouteComponentProps> = ({ employers, fetchEmployers, history, location, onToggleModal, isFetching }) => {
  /* hide in MVP version */
  // const [currentSort, setCurrentSort] = useState<Sort>('Recent activity')

  useEffect(() => {
    fetchEmployers({})
  }, [])

  const sortByName = (a: any, b: any) => {
    const nameA = a.companyName.toUpperCase()
    const nameB = b.companyName.toUpperCase()

    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }

  const renderEmployers = () =>
    employers
      .get('items')
      .sort(sortByName)
      .map((data: any) => <EmployerCard key={data.id} employer={data} toggleNoteModal={onToggleModal} />)

  /* hide in MVP version */
  // const changeSort = (sort: any) => setCurrentSort(sort)

  const addEmployerRoute = () => history.push(`${location.pathname}/add`)
  return (
    <BoxFullFill relative column minHeight="90vh">
      <Header
        /* hide in MVP version */
        // currentSort={currentSort}
        // changeSort={changeSort}
        title="Employers"
        addItemRoute={addEmployerRoute}
        isListEmpty={employers.get('items').isEmpty()}
        numItems={employers.get('employersQty')}
      />

      {isFetching ? (
        <Loader withoutBg height="400px" />
      ) : (
        <Body
          items={employers}
          renderItems={renderEmployers}
          addItemRoute={addEmployerRoute}
          title="Here will be your list of employers and related job positions"
          subTitle="Add your first employer and job positions for that employer to start matching your candidates"
          buttonText="ADD EMPLOYER"
          image={<Image width="300px" height="170px" src={require('assets/images/empty-live-jobs.png')} />}
        />
      )}

      {employers.get('pagesQty') <= 1 || (
        <Pagination currentPage={employers.getIn(['query', 'page'])} fetchPage={fetchEmployers} pagesQty={employers.get('pagesQty')} />
      )}
      <NotesModal />
    </BoxFullFill>
  )
}
const mapStateToProps = (state: AppState) => ({
  employers: state.employers,
  currentPage: state.employers.getIn(['query', 'page']),
  pagesQty: state.employers.get('pagesQty'),
  isFetching: state.employers.get('isFetching'),
})

export default connect(
  mapStateToProps,
  {
    fetchEmployers: EmployersActions.fetchEmployers,
    onToggleModal: NotesActions.onToggleModal,
  }
)(LiveJobs)
