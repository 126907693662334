import { ActionsUnion, createAction } from 'types/helps'
import { IError } from 'types/errors'

export const CHANGE_INPUT = 'CHANGE_INPUT'
export const CHANGE_SELECT = 'CHANGE_SELECT'
export const CLEAR_INPUT = 'CLEAR_INPUT'

export const GET_TASK_LIST = 'GET_TASK_LIST'
export const GET_TASK_LIST_SUCCESS = 'GET_TASK_LIST_SUCCESS'
export const GET_TASK_LIST_FAIL = 'GET_TASK_LIST_FAIL'

export const GET_TASK_LIST_BY_FILTER = 'GET_TASK_LIST_BY_FILTER'
export const GET_TASK_LIST_BY_FILTER_SUCCESS = 'GET_TASK_LIST_BY_FILTER_SUCCESS'
export const GET_TASK_LIST_BY_FILTER_FAIL = 'GET_TASK_LIST_BY_FILTER_FAIL'

export const CREATE_TASK = 'CREATE_TASK'
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS'
export const CREATE_TASK_FAIL = 'CREATE_TASK_FAIL'

export const UPDATE_TASK = 'UPDATE_TASK'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL'

export const DELETE_TASK = 'DELETE_TASK'
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS'
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL'

export const CLOSE_TASK = 'CLOSE_TASK'
export const CLOSE_TASK_SUCCESS = 'CLOSE_TASK_SUCCESS'
export const CLOSE_TASK_FAIL = 'CLOSE_TASK_FAIL'

export const RESTORE_TASK = 'RESTORE_TASK'
export const RESTORE_TASK_SUCCESS = 'RESTORE_TASK_SUCCESS'
export const RESTORE_TASK_FAIL = 'CLOSE_TASK_FAIL'

const TaskActions = {
  changeInput: (name: string, value: string) => createAction(CHANGE_INPUT, { name, value }),
  changeSelect: (value: string) => createAction(CHANGE_SELECT, { value }),
  clearInput: () => createAction(CLEAR_INPUT),

  getTaskList: (type?: string) => createAction(GET_TASK_LIST, { type }),
  getTaskListSuccess: (data: any) => createAction(GET_TASK_LIST_SUCCESS, { data }),
  getTaskListFail: (error: IError) => createAction(GET_TASK_LIST_FAIL, error),

  getTaskListByFilter: (priority: string) => createAction(GET_TASK_LIST_BY_FILTER, { priority }),
  getTaskListByFilterSuccess: (priority: string, data: any) => createAction(GET_TASK_LIST_BY_FILTER_SUCCESS, { priority, data }),
  getTaskListByFilterFail: (error: IError) => createAction(GET_TASK_LIST_BY_FILTER_FAIL, error),

  createTask: (priority: string) => createAction(CREATE_TASK, { priority }),
  createTaskSuccess: (data: any) => createAction(CREATE_TASK_SUCCESS, { data }),
  createTaskFail: (error: IError) => createAction(CREATE_TASK_FAIL, error),

  updateTask: (id: number, task: any, priority?: string) => createAction(UPDATE_TASK, { id, task, priority }),
  updateTaskSuccess: (data: any) => createAction(UPDATE_TASK_SUCCESS, { data }),
  updateTaskFail: (error: IError) => createAction(UPDATE_TASK_FAIL, error),

  deleteTask: (id: number, priority: string) => createAction(DELETE_TASK, { id, priority }),
  deleteTaskSuccess: (data: any) => createAction(DELETE_TASK_SUCCESS, { data }),
  deleteTaskFail: (error: IError) => createAction(DELETE_TASK_FAIL, error),

  closeTask: (id: number, priority: string) => createAction(CLOSE_TASK, { id, priority }),
  closeTaskSuccess: (data: any) => createAction(CLOSE_TASK_SUCCESS, { data }),
  closeTaskFail: (error: IError) => createAction(CLOSE_TASK_FAIL, error),

  restoreTask: (id: number, priority: string) => createAction(RESTORE_TASK, { id, priority }),
  restoreTaskSuccess: (data: any) => createAction(RESTORE_TASK_SUCCESS, { data }),
  restoreTaskFail: (error: IError) => createAction(RESTORE_TASK_FAIL, error),
}

export type TaskTypes = ActionsUnion<typeof TaskActions>

export default TaskActions
