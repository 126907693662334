import React from 'react'
import styled from 'styled-components'
import { Droppable } from 'react-beautiful-dnd'

import Task from './Task'

interface ContainerProps {
  columnId: string
}

const ListWrapper = styled.div`
  min-width: 298px;
  margin: 8px 29px 8px 0;
  height: 96%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
`

const Container = styled.div<ContainerProps>`
  margin: 8px 29px 8px 0;
  border: 1px solid lightgrey;
  border-radius: 2px;
  min-width: 298px;
  display: flex;
  flex-direction: column;
  height: auto;
  border: 1px solid #e3e8f1;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(231, 236, 245, 0.5);
  border-radius: 6px;
  padding: 20px;
  min-height: 100px;
  max-height: calc(100vh - 270px);
  overflow: overlay;
  border-top: ${props =>
    props.columnId == '1'
      ? '5px solid #2697ff'
      : props.columnId == '2'
      ? '5px solid #f3a200'
      : props.columnId == '3'
      ? '5px solid #7f60ff'
      : props.columnId == '4'
      ? '5px solid #1caabc'
      : props.columnId == '5'
      ? '5px solid #58c813'
      : '5px solid #3cb593'};
  margin: ${props => props.columnId == '6' && '8px 129px 8px 0'};
`
const Title = styled.p`
  font-family: Mukta;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #292c38;
  padding: 0 0 10px 0;
`
const EmptyColumn = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: #9caab8;
  height: 18px;
  padding: 0 6px 0 0;
  margin: 0 6px 0 0;
`

type Props = {
  column: {
    id: string
    title: string
    taskIds: string[]
  }
  tasks: any
  changePipelineStatus: (JobPositionId: number, CandidateId: number, status: string, interviewDate: string) => void
  userData: any
}

export const Column: React.FC<Props> = ({ column, tasks, changePipelineStatus, userData }) => {
  return (
    <Droppable droppableId={column.id} type="TASK">
      {(provided: any) => (
        <ListWrapper ref={provided.innerRef} {...provided.droppableProps}>
          <Container columnId={column.id}>
            <Title>{`${column.title} (${column.taskIds.length})`}</Title>
            {column.taskIds.length === 0 && <EmptyColumn>You have no candidates on that step</EmptyColumn>}
            {tasks.map((task: any, index: any) => {
              return <Task key={task.id} task={task} index={index} column={column} changePipelineStatus={changePipelineStatus} userData={userData} />
            })}
            {provided.placeholder}
          </Container>
        </ListWrapper>
      )}
    </Droppable>
  )
}
