import React from 'react'
import styled from 'styled-components'

import { Box, BoxFill, BoxCol, Text, Bold, Span } from 'styles'

export const FormHeader: React.FC<{ title: string }> = ({ title }) => (
  <BoxFill mb="10px">
    <Bold black l>
      {title}
    </Bold>
  </BoxFill>
)

export const FormRow: React.FC<{ title: string; value: any }> = ({ title, value }) => (
  <InfoBox>
    <TextNoWrap>{title}</TextNoWrap>
    <TextEllipsisContainer>
      <TextEllipsis>{value}</TextEllipsis>
    </TextEllipsisContainer>
  </InfoBox>
)

type TypeItemProps = {
  text: string
  isSector?: boolean
  padding?: string
  fz?: string
}

export const TypeItem: React.FC<TypeItemProps> = ({ text, isSector, padding, fz }) => (
  <TypeItemBox isSector={isSector} padding={padding}>
    <TypeItemText isSector={isSector} fz={fz}>
      {text}
    </TypeItemText>
  </TypeItemBox>
)

TypeItem.defaultProps = {
  padding: '10px 20px',
}

export const TypeItemBox = styled(Box).attrs<{ isSector?: boolean }>(props => ({
  margin: '3px',
  br: '6px',
  bc: props.isSector ? props.theme.main.borderInputColor : props.theme.main.accentColor2,
}))<{ isSector?: boolean }>``

export const TypeItemText = styled(Text).attrs<{ isSector?: boolean }>(props => ({
  color: props.isSector ? props.theme.main.inputLabelTextColor : props.theme.main.accentColor,
}))<{ isSector?: boolean }>``

export const InfoBox = styled(BoxFill).attrs({
  spaceBetween: true,
  mb: '8px',
  overflow: 'hidden',
})`
  word-break: break-all;
`

export const TextNoWrap = styled(Text).attrs({
  whiteSpace: true,
})`
  margin: 0 10px 0 0;
`

export const TextEllipsisContainer = styled(Box).attrs({
  ml: '5px',
  overflow: 'hidden',
})``

export const TextEllipsis = styled(Text).attrs({
  textOverflow: true,
  black: true,
  whiteSpace: true,
  overflow: 'hidden',
})``

export const SpanEllipsis = styled(Span).attrs({
  textOverflow: true,
  overflow: 'hidden',
  whiteSpace: true,
})``

export const BG = styled(BoxFill).attrs({
  mainBC: true,
  absolute: true,
  height: '190px',
})``

export const Wrapper = styled(BoxFill).attrs({
  mt: '20px',
  zIndex: '1',
  padding: '0 80px',
})``

export const CandidateInfoBox = styled(BoxCol).attrs({
  maxWidth: '30vw',
  padding: '20px',
  alignCenter: true,
  relative: true,
  smooth: true,
  whiteBC: true,
  flex: 1,
  mr: '30px',
})``

// old version
// export const MatchedJobsBox = styled(BoxFill)`
//   display: grid;
//   grid-gap: 10px;
//   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
//   border: 1px solid red;
// `

export const MatchedJobsBox = styled(BoxFill)`
  display: flex;
  flex-wrap: wrap;
`

export const MatchedJobCardWrap = styled.div`
  width: 48%;
  max-width: 250px;
  margin: 0 15px 20px 0;
`

export const WorkPlacesBox = styled(BoxFill)`
  margin-top: 20px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
`

export const Separator = styled(BoxFill).attrs({
  bc: '#E3E8F1',
  height: '1px',
  margin: '20px 0',
})``

export const AddNotes = styled(Text)`
  text-transform: uppercase;
`
export const Message = styled.span`
  color: #2697ff;
  margin: 0 0 0 5px;
`

export const NotAddJobPosition = styled(BoxCol)`
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px 0;
  img {
    margin: 0 0 21px 0;
  }
  span {
    font-size: 16px;
    line-height: 20px;
    color: #9caab8;
    margin: 0 0 20px 0;
  }
`

export const NotAddJobPositionTitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #292c38;
  margin: 0 0 10px 0;
`

export const ItemBox = styled(Box).attrs({})`
  p {
    font-size: 16px;
    text-decoration: underline;
    color: ${props => props.theme.main.text};
    &:hover {
      text-decoration: none;
    }
  }
  :hover .popUpBox {
    display: block;
    margin-top: 6px;
    div {
      padding: 4px 10px;
      margin-top: 6px;
      background: ${props => props.theme.main.borderInputColor};
      border-radius: 6px;
      p {
        color: ${props => props.theme.main.inputLabelTextColor};
        text-decoration: none;
      }
    }
  }
`
export const ItemText = styled(Text).attrs(props => ({
  whiteSpace: true,
  textOverflow: true,
  overflow: 'hidden',
  fz: '14px',
  padding: '0 8px',
}))``

export const PopUpBox = styled(BoxCol).attrs({
  whiteBC: true,
  absolute: true,
  shadow2: true,
  smooth: true,
  zIndex: '10',
  display: 'none',
  padding: '10px',
  top: '40px',
})``

export const StyledSectorsTypeBox = styled(Box).attrs({})`
  justify-content: flex-end;
  width: 100%;
`

export const Locations = styled(Box).attrs({})`
  color: #9caab8;
`

export const ReadyToRelocate = styled(Box).attrs({})`
  width: 100%;
  justify-content: space-between;
  margin: 0 0 10px 0;
`

export const ReadySpan = styled.span`
  color: #9caab8;
`
