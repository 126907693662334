import React, { useState } from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'
import AuthActions from 'store/auth/actions'
import { INotValidFieldsErr } from 'types/errors'
import { InputEvent, ValidationError } from 'types/helps'

import Input from 'components/Input'
import PrimaryButton from 'components/Button'
import { RenderNotValidFields } from 'components/Others'
import { Logo } from 'components/Icons'

import { resetPasswordSchema } from 'services/validation'

import { Box, BoxCenterContent, Text, H1, Span } from 'styles'

const Container = styled(BoxCenterContent).attrs({
  column: true,
  whiteBC: true,
  width: '100vw',
  height: '100vh',
})``

type Props = {
  error: INotValidFieldsErr
  resetPassword: typeof AuthActions.resetPassword
}

const ForgotPassword: React.FC<Props & RouteComponentProps> = ({ history, resetPassword, error }) => {
  const [email, setEmail] = useState<string>('')
  const [errors, setErrors] = useState<string[]>([])

  const onResetPassword = () => {
    resetPasswordSchema
      .validate(email, { abortEarly: false })
      .then(() => {
        setErrors([])
        resetPassword(email)
      })
      .catch((err: ValidationError) => setErrors(err.errors))
  }
  const onChangeEmail = (e: InputEvent) => setEmail(e.currentTarget.value)
  const goBack = () => history.push('/?redirect=login')
  return (
    <Container>
      <Box absolute top="80px">
        <Logo type="black" />
      </Box>
      <Box column alignCenter>
        <H1 fw="bold" black>
          Forgot your password?
        </H1>
        <Box mt="10px">
          <Text width="35vw">If you forgot your password we will send you a magic link to recover your account.</Text>
        </Box>
        <Box margin="40px 0" fill>
          <Input error={errors[0]} value={email} onChange={onChangeEmail} label="Email address" type="email" maxLength={70} />
        </Box>
        <RenderNotValidFields errors={error} />
        <Box fill height="60px">
          <PrimaryButton onClick={onResetPassword} title="SEND RESET LINK" />
        </Box>
        <Box mt="28px">
          <Span onClick={goBack}>Back to Log In</Span>
        </Box>
      </Box>
    </Container>
  )
}

const mapStateToProps = (state: AppState) => ({
  error: state.auth.get('error'),
})

export default connect(
  mapStateToProps,
  { resetPassword: AuthActions.resetPassword }
)(ForgotPassword)
