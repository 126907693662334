import { ActionsUnion, createAction } from 'types/helps'
import { IError } from 'types/errors'
import { IList, ICandidate, ICity } from 'types/models'

export const CHANGE_INPUT_VALUE = 'CHANGE_VALUE'
export const CHANGE_SELECT_VALUE = 'CHANGE_SELECT_VALUE'
export const SET_VALUE_FROM_SAVED_SEARCH = 'SET_VALUE_FROM_SAVED_SEARCH'
export const CLEAR_INPUT_VALUE = 'CLEAR_INPUT_VALUE'

export const SEARCH_CANDIDATES = 'SEARCH_CANDIDATES'
export const SEARCH_CANDIDATES_SUCCESS = 'SEARCH_CANDIDATES_SUCCESS'
export const SEARCH_CANDIDATES_FAIL = 'SEARCH_CANDIDATES_FAIL'

export const SEARCH_JOBS = 'SEARCH_JOBS'
export const SEARCH_JOBS_SUCCESS = 'SEARCH_JOBS_SUCCESS'
export const SEARCH_JOBS_FAIL = 'SEARCH_JOBS_FAIL'

export const SEARCH_COMPANIES = 'SEARCH_COMPANIES'
export const SEARCH_COMPANIES_SUCCESS = 'SEARCH_COMPANIES_SUCCESS'
export const SEARCH_COMPANIES_FAIL = 'SEARCH_COMPANIES_FAIL'

export const SEARCH_CONTACTS = 'SEARCH_CONTACTS'
export const SEARCH_CONTACTS_SUCCESS = 'SEARCH_CONTACTS_SUCCESS'
export const SEARCH_CONTACTS_FAIL = 'SEARCH_CONTACTS_FAIL'

export const SAVE_CANDIDATES = 'SAVE_CANDIDATES'
export const SAVE_CANDIDATES_SUCCESS = 'SAVE_CANDIDATES_SUCCESS'
export const SAVE_CANDIDATES_FAIL = 'SAVE_CANDIDATES_FAIL'

export const SAVE_JOBS = 'SAVE_JOBS'
export const SAVE_JOBS_SUCCESS = 'SAVE_JOBS_SUCCESS'
export const SAVE_JOBS_FAIL = 'SAVE_JOBS_FAIL'

export const SAVE_COMPANIES = 'SAVE_COMPANIES'
export const SAVE_COMPANIES_SUCCESS = 'SAVE_COMPANIES_SUCCESS'
export const SAVE_COMPANIES_FAIL = 'SAVE_COMPANIES_FAIL'

export const SAVE_CONTACTS = 'SAVE_CONTACTS'
export const SAVE_CONTACTS_SUCCESS = 'SAVE_CONTACTS_SUCCESS'
export const SAVE_CONTACTS_FAIL = 'SAVE_CONTACTS_FAIL'

export const GET_SEARCH_LIST = 'GET_SEARCH_LIST'
export const GET_SEARCH_LIST_SUCCESS = 'GET_SEARCH_LIST_SUCCESS'
export const GET_SEARCH_LIST_FAIL = 'GET_SEARCH_LIST_FAIL'

const SearchActions = {
  changeInputValue: (name: string, value: string) => createAction(CHANGE_INPUT_VALUE, { name, value }),
  changeSelectValue: (name: string, value: ICity[] | IList[]) => createAction(CHANGE_SELECT_VALUE, { name, value }),
  setValueFromSavedSearch: (name: string, value: any) => createAction(SET_VALUE_FROM_SAVED_SEARCH, { name, value }),
  clearInputValue: () => createAction(CLEAR_INPUT_VALUE),

  searchCandidates: () => createAction(SEARCH_CANDIDATES),
  searchCandidatesSuccess: (data: ICandidate[]) => createAction(SEARCH_CANDIDATES_SUCCESS, data),
  searchCandidatesFail: (error: IError) => createAction(SEARCH_CANDIDATES_FAIL, error),

  searchJobs: () => createAction(SEARCH_JOBS),
  searchJobsSuccess: (data: ICandidate[]) => createAction(SEARCH_JOBS_SUCCESS, data),
  searchJobsFail: (error: IError) => createAction(SEARCH_JOBS_FAIL, error),

  searchCompanies: () => createAction(SEARCH_COMPANIES),
  searchCompaniesSuccess: (data: ICandidate[]) => createAction(SEARCH_COMPANIES_SUCCESS, data),
  searchCompaniesFail: (error: IError) => createAction(SEARCH_COMPANIES_FAIL, error),

  searchContacts: () => createAction(SEARCH_CONTACTS),
  searchContactsSuccess: (data: ICandidate[]) => createAction(SEARCH_CONTACTS_SUCCESS, data),
  searchContactsFail: (error: IError) => createAction(SEARCH_CONTACTS_FAIL, error),

  saveCandidates: () => createAction(SAVE_CANDIDATES),
  saveCandidatesSuccess: (data: ICandidate[]) => createAction(SAVE_CANDIDATES_SUCCESS, data),
  saveCandidatesFail: (error: IError) => createAction(SAVE_CANDIDATES_FAIL, error),

  saveJobs: () => createAction(SAVE_JOBS),
  saveJobsSuccess: (data: any) => createAction(SAVE_JOBS_SUCCESS, data),
  saveJobsFail: (error: IError) => createAction(SAVE_JOBS_FAIL, error),

  saveCompanies: () => createAction(SAVE_COMPANIES),
  saveCompaniesSuccess: (data: any) => createAction(SAVE_COMPANIES_SUCCESS, data),
  saveCompaniesFail: (error: IError) => createAction(SAVE_COMPANIES_FAIL, error),

  saveContacts: () => createAction(SAVE_CONTACTS),
  saveContactsSuccess: (data: any) => createAction(SAVE_CONTACTS_SUCCESS, data),
  saveContactsFail: (error: IError) => createAction(SAVE_CONTACTS_FAIL, error),

  getSearchList: (type: string) => createAction(GET_SEARCH_LIST, { type }),
  getSearchListSuccess: (type: string, data: any) => createAction(GET_SEARCH_LIST_SUCCESS, { type, data }),
  getSearchListFail: (error: IError) => createAction(GET_SEARCH_LIST_FAIL, error),
}

export type SearchTypes = ActionsUnion<typeof SearchActions>

export default SearchActions
