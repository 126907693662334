import Api from './index'
import { IDataSearch } from 'types/responseData'

class SearchApi {
  /**
   * Gets search result candidates
   */
  static searchCandidates(data: any) {
    const { name, jobTitle, cityIds, availability, sectorIds } = data
    const search = `/search/candidates/?name=${name}&jobTitle=${jobTitle}&cityIds=[${cityIds}]&availability=${availability}&sectorIds=[${sectorIds}]`
    return Api.post<IDataSearch>(search)
  }
  /**
   * Gets search result jobs
   */
  static searchJobs(data: any) {
    const { jobTitle, companyName, cityIds, status, sectorIds } = data
    const search = `/search/jobs/?jobTitle=${jobTitle}&companyName=${companyName}&cityIds=[${cityIds}]&status=${status}&sectorIds=[${sectorIds}]`
    return Api.post<IDataSearch>(search)
  }
  /**
   * Gets search result companies
   */
  static searchCompanies(data: any) {
    const { name, cityIds, sectorIds } = data
    return Api.post<IDataSearch>(`/search/companies/?name=${name}&cityIds=[${cityIds}]&sectorIds=[${sectorIds}]`)
  }
  /**
   * Gets search result contacts
   */
  static searchContacts(data: any) {
    const { name, jobTitle, cityIds, sectorIds } = data
    return Api.post<IDataSearch>(`/search/contacts/?name=${name}&jobTitle=${jobTitle}&cityIds=[${cityIds}]&sectorIds=[${sectorIds}]`)
  }
  /**
   * Save search result candidates
   */
  static saveCandidates(data: any) {
    const { name, jobTitle, cityIds, availability, sectorIds } = data
    let search
    if (availability) {
      search = `/search/save/?type=canidates&name=${name}&jobTitle=${jobTitle}&cityIds=[${cityIds}]&availability=${availability}&sectorIds=[${sectorIds}]`
    } else {
      search = `/search/save/?type=canidates&name=${name}&jobTitle=${jobTitle}&cityIds=[${cityIds}]&sectorIds=[${sectorIds}]`
    }
    return Api.post<IDataSearch>(search)
  }
  /**
   * Save search result jobs
   */
  static saveJobs(data: any) {
    const { jobTitle, companyName, cityIds, status, sectorIds } = data
    const search = `/search/save/?type=jobs&jobTitle=${jobTitle}&companyName=${companyName}&cityIds=[${cityIds}]&status=${status}&sectorIds=[${sectorIds}]`
    return Api.post<IDataSearch>(search)
  }
  /**
   * Save search result companies
   */
  static saveCompanies(data: any) {
    const { name, cityIds, sectorIds } = data
    return Api.post<IDataSearch>(`/search/save/?type=companies&name=${name}&cityIds=[${cityIds}]&sectorIds=[${sectorIds}]`)
  }
  /**
   * Save search result contacts
   */
  static saveContacts(data: any) {
    const { name, jobTitle, cityIds, sectorIds } = data
    return Api.post<IDataSearch>(`/search/save/?type=contacts&name=${name}&jobTitle=${jobTitle}&cityIds=[${cityIds}]&sectorIds=[${sectorIds}]`)
  }
  /**
   * Get search list
   */
  static getSearchList(type: string) {
    return Api.get<IDataSearch>(`/search/list/${type}?page=1`)
  }
}

export default SearchApi
