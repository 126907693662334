import React from 'react'

import { IUserForm } from 'types/forms'
import { InputEvent } from 'types/helps'

import Input from 'components/Input'
import PrimaryButton from 'components/Button'

import { Span, Text, H2, Box, BoxFill } from 'styles'
import { Container, Header } from '../styles'

type Props = {
  logInScroll: () => void
  nextStep: () => void
  onChangeField: (e: InputEvent) => void
  form: Partial<IUserForm>
  errors: Partial<IUserForm>
}

const Step1: React.FC<Props> = ({ logInScroll, nextStep, form, onChangeField, errors }) => (
  <Container>
    <Header>
      <Span secondColor>Step 1</Span>
      <H2 fw="bold" black>
        Personal information
      </H2>
    </Header>
    <Box margin="5px 0 40px 0">
      <Text>To apply for exclusive access to Recruiters Life please fill in the necessary information below.</Text>
    </Box>
    <BoxFill>
      <Input error={errors.firstName} name="firstName" onChange={onChangeField} value={form.firstName} label="First name" maxLength={50} />
    </BoxFill>
    <BoxFill margin="2% 0">
      <Input error={errors.lastName} name="lastName" onChange={onChangeField} value={form.lastName} label="Last name" maxLength={50} />
    </BoxFill>
    <BoxFill>
      <Input error={errors.phone} name="phone" onChange={onChangeField} value={form.phone} type="tel" label="Phone number" />
    </BoxFill>
    <BoxFill margin="2% 0">
      <Input error={errors.email} name="email" onChange={onChangeField} value={form.email} label="Email address" maxLength={70} />
    </BoxFill>
    <BoxFill spaceBetween alignCenter mt="40px">
      <Span secondColor onClick={logInScroll}>
        Back
      </Span>
      <Box height="60px" width="40%">
        <PrimaryButton onClick={nextStep} title="NEXT STEP" />
      </Box>
    </BoxFill>
  </Container>
)

export default Step1
