export const statuses = [
  {
    id: '1',
    title: 'Candidate is matched',
    color: '#2697FF',
  },
  {
    id: '2',
    title: 'Candidate agreed to submittal',
    color: '#F3A200',
  },
  {
    id: '3',
    title: 'CV is sent',
    color: '#7F60FF',
  },
  {
    id: '4',
    title: 'Employer accepted the candidate',
    color: ' #1CAABC',
  },
  {
    id: '5',
    title: 'Interview arranged',
    color: '#58C813',
  },
  {
    id: '6',
    title: 'Placement approved',
    color: '#3CB593',
  },
]
