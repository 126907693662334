import Api, { API_BASE_URL } from './index'

import { IEmployer, IJobPosition, IEmployerContact } from 'types/models'
import { IDataEmployersLiveJobs, IDataEmployerWithPagedJobPositions, IDataJobPositionWithPagedPotentialCandidates } from 'types/responseData'
import { IQueryParams } from 'types/helps'

import { IUploadInputParams } from 'components/UploadInput/types'

const EMPLOYER_BASE_URL = `${API_BASE_URL}/user/employers`

class Employers {
  /**
   * Gets all live jobs for Live Jobs New screen
   */
  static getAllLiveJobs(page?: number) {
    return Api.get('/user/live-jobs', { params: page })
  }

  /**
   * Gets employers with recent job position for Live Jobs screen
   */
  static getLiveJobs(query: IQueryParams) {
    return Api.get<IDataEmployersLiveJobs>('/user/employers/live-jobs/', { params: query })
  }

  /**
   * Gets employers with recent job position for Live Jobs screen
   */
  static getEmployers(id: number, page?: number, perPage?: number) {
    return Api.get<IDataEmployerWithPagedJobPositions>(`/user/employers/${id}/with-paged-job-positions/`, {
      params: { page, perPage },
    })
  }

  /**
   * Create new employer
   */
  static createEmployer(employer: Partial<IEmployer>) {
    return Api.post<IEmployer>('/user/employers/', employer)
  }

  /**
   * Create new contact for employer
   */
  static createEmployerContact(employerId: number, contact: Partial<IEmployerContact>) {
    return Api.post<IEmployerContact>(`/user/employers/${employerId}/contacts/`, contact)
  }

  /**
   * Edit contact for employer
   */
  static editEmployerContact(contactId: number, contact: Partial<IEmployerContact>) {
    return Api.patch<IEmployerContact>(`/user/employers/contacts/${contactId}/`, contact)
  }

  /**
   * Deletes employer contact with required contact id
   */
  static deleteEmployerContact(contactId: number) {
    return Api.delete(`/user/employers/contacts/${contactId}/`)
  }

  /**
   * Updates employer with required id
   */
  static updateEmployer(id: number, employer: Partial<IEmployer>) {
    return Api.patch(`/user/employers/${id}/`, employer)
  }

  /**
   * Deletes employer with required id
   */
  static deleteEmployer(id: number) {
    return Api.delete(`/user/employers/${id}/`)
  }

  /**
   * Create job position for chosen employer
   */
  static createJobPosition(employerId: number, jobPosition: Partial<IJobPosition>) {
    return Api.post<IJobPosition>(`/user/employers/${employerId}/job-positions/`, jobPosition)
  }

  /**
   * Gets job position with required id
   */
  static getJobPosition(jobPositionId: number) {
    return Api.get<IJobPosition>(`/user/employers/job-positions/${jobPositionId}`)
  }

  /**
   * --user/employers/job-positions/{id}/with-paged-potential-candidates/--
   * Gets selected job position with paged potential candidates
   */
  static getJobPositionWithPotentialCandidates(jobPositionId: number, page?: number, perPage?: number) {
    return Api.get<IDataJobPositionWithPagedPotentialCandidates>(`/user/employers/job-positions/${jobPositionId}/with-paged-potential-candidates/`, {
      params: { page, perPage },
    })
  }

  /**
   * Updates job position with required id if it belongs to user
   */
  static updateJobPosition(id: number, job: Partial<IJobPosition>) {
    return Api.patch(`/user/employers/job-positions/${id}/`, job)
  }

  static setStatusJobPosition(id: number, value: boolean) {
    return Api.patch<IJobPosition>(`/user/employers/job-positions/${id}/`, { closed: value })
  }

  /**
   * Deletes job position with required id if it belongs to user
   */
  static deleteJobPosition(id: number) {
    return Api.delete(`/user/employers/job-positions/${id}/`)
  }

  static getJobsByEmployerId(id: number) {
    return Api.get<IDataEmployerWithPagedJobPositions>(`/user/employers/${id}/with-paged-job-positions/`)
  }

  /**
   * Get public data
   */

  static getPublicEmployersContacts(id: number) {
    return Api.get(`/user/employers/${id}/contacts/`)
  }

  /**
   * Create public job position for chosen employer
   */
  static createPublicJobPosition(jobPosition: Partial<IJobPosition>, recruiterId: number) {
    return Api.post<IJobPosition>(`/public/job-position/${recruiterId}`, jobPosition)
  }

  static createPublicJobPositionWithoutId(jobPosition: Partial<IJobPosition>) {
    return Api.post<IJobPosition>('/public/job-position/', jobPosition)
  }

  /**
   * Create employer public contact
   */

  static createEmployerPublicContact(employerId: number, contact: Partial<IEmployerContact>) {
    return Api.post<IEmployerContact>(`/public/employers/${employerId}/contacts/`, contact)
  }

  static async getEmployersOptions(): Promise<{ id: number; name: string; photo: string }[]> {
    const { data } = await Api.get('/user/employers/')
    return data.map((item: any) => ({
      id: item.id,
      name: item.companyName,
      photo: item.logoFile ? item.logoFile.link : '',
    }))
  }
}

export async function paramsForUploadLogoEmployer(employerId: number): Promise<IUploadInputParams> {
  // const id = candidateId || (await getDraftCandidateId())
  return {
    fieldName: 'logoFile',
    options: {
      url: `${EMPLOYER_BASE_URL}/${employerId}/files/logo/`,
      headers: { Authorization: `Bearer ${Api.getAuthToken()}` },
    },
  }
}

export default Employers
