import moment from 'moment'

const validate = (val: any, rules: any) => {
  let isValid = true
  for (let rule in rules) {
    switch (rule) {
      case 'notEmpty':
        isValid = isValid && notEmptyValidator(val)
        break
      case 'isPastDate':
        isValid = isValid && val && moment(val).isAfter()
        break
      default:
        isValid = true
    }
  }
  return isValid
}

const notEmptyValidator = (val: any) => {
  return val.trim() !== ''
}

export default validate
