import styled from 'styled-components'

import { Bold, Text } from 'styles'

export const Wrap = styled.div`
  width: 100%;
  padding: 20px;
  border-top: 1px solid #e3e8f1;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
`

export const Performance = styled(Bold).attrs({})``

export const GoalsIcon = styled.img`
  display: inline-flex;
  margin: 0 0 -4px 0;
`

export const Goal = styled.div`
  margin: 0 0 13px 0;
`

export const GoalTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
`

export const GoalLeft = styled.div``

export const GLText = styled(Text).attrs({})`
  font-weight: 500;
`

export const Price = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  background: #d8f0e9;
  border-radius: 6px;
  padding: 0 14px;
  color: #3cb593;
`

export const ProgressWrapper = styled.div`
  width: 100%;
  height: 6px;
  background: #f5f6f8;
  border-radius: 6px;
`

export const ProgressInner = styled.div<{ width: number }>`
  width: ${props => props.width || '0'}%;
  height: 6px;
  background: ${props => props.theme.main.secondColor};
  border-radius: 6px;
`

export const GoalsModal = styled.div``
