import { fromJS, List } from 'immutable'

import { DASHBOARD__GET_INVOICES_SUCCESS, DASHBOARD__GET_PERFORMANCE_TRACKER_SUCCESS, DashboardTypes } from './actions'

import { IDashboardState } from 'types/reducerStates'

const initialState: IDashboardState = fromJS({
  isFetching: false,
  performanceTracker: {
    total: 0,
    annual: 0,
    monthly: 0,
    quarter: 0,
    weekly: 0,
  },
  moneyTracker: {
    invoices: List([]),
    invoicesQty: 0,
    pagesQty: 0,
    query: {
      page: 1,
      perPage: 12,
    },
  },
})

export default (state = initialState, action: DashboardTypes): IDashboardState => {
  switch (action.type) {
    case DASHBOARD__GET_PERFORMANCE_TRACKER_SUCCESS:
      return state.set('performanceTracker', fromJS(action.payload))
    case DASHBOARD__GET_INVOICES_SUCCESS:
      return state.set('moneyTracker', fromJS(action.payload))
    default:
      return state
  }
}
