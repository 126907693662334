import { call, put, takeLatest, delay, select } from 'redux-saga/effects'

import MessengerActions, { GET_SIGNATURE, SET_SIGNATURE, MESSENGER__SET_ACTIVE_USER, GET_CHAT_LIST, SEND_MESSAGE, SEND_INTERVIEW } from './actions'

import Messenger from 'api/messenger'

function* getSignatureFlow() {
  try {
    const { data } = yield call(Messenger.getSignature)
    if (data.image || data.text) localStorage.setItem('showAutosignature', 'true')
    yield put(MessengerActions.getSignatureSuccess(data))
  } catch (error) {
    yield put(MessengerActions.getSignatureFail(error))
  }
}

function* setSignatureFlow() {
  try {
    const messenger = yield select(state => state.messenger)
    const text = messenger.get('signatureTextarea')
    const image = messenger.get('signatureImage')
    const signature = { text, image }
    const { data } = yield call(Messenger.setSignature, signature)
    yield put(MessengerActions.setSignatureSuccess(data))
  } catch (error) {
    yield put(MessengerActions.setSignatureFail(error.response.data.notValidFields[0].errorMessage))
  }
}

function* getChatListFlow() {
  try {
    const { data } = yield call(Messenger.getChatList)
    yield put(MessengerActions.getChatListSuccess(data))
  } catch (error) {
    yield put(MessengerActions.getChatListFail(error))
  }
}

function* setActiveUserFlow(action: ReturnType<typeof MessengerActions.setUserAsActive>) {
  try {
    let dialogUserType
    let dialogUserId
    if (action.payload.get('type')) {
      dialogUserType = action.payload.get('type')
      dialogUserId = action.payload.get('id')
    } else {
      dialogUserType = action.payload.get('data').get('chatUserType')
      if (dialogUserType === 'candidate') {
        dialogUserId = action.payload.get('data').get('CandidateId')
      } else {
        dialogUserId = action.payload.get('data').get('EmployerContactId')
      }
    }

    const user = {
      id: `${dialogUserId}`,
      type: dialogUserType,
    }

    const { data } = yield call(Messenger.getMessages, user)
    yield delay(500)
    yield put(MessengerActions.setUserAsActiveSuccess(data.reverse()))
  } catch (error) {
    console.log('setActiveUserFlow: ', error)
  }
}

function* sendMessageFlow() {
  try {
    const messenger = yield select(state => state.messenger)
    const message = messenger.get('message')
    const attachmentChatFile = messenger.get('attachmentChatFile')
    const dialogUser = messenger.get('dialogUser')

    let dialogUserId = dialogUser.get('id')
    let dialogUserType

    const body = new FormData()
    if (message) body.append('text', message)

    if (attachmentChatFile) body.append('attachment', attachmentChatFile)

    if (dialogUser.get('type') === 'candidate') {
      body.append('CandidateId', dialogUserId)
      dialogUserType = 'candidate'
    } else if (dialogUser.get('type') === 'employerContact') {
      body.append('EmployerContactId', dialogUserId)
      dialogUserType = 'employerContact'
    } else if (dialogUser.get('chatUserType') === 'candidate') {
      dialogUserId = dialogUser.get('CandidateId')
      body.append('CandidateId', dialogUser.get('CandidateId'))
      dialogUserType = 'candidate'
    } else if (dialogUser.get('chatUserType') === 'employerContact') {
      dialogUserId = dialogUser.get('EmployerContactId')
      body.append('EmployerContactId', dialogUser.get('EmployerContactId'))
      dialogUserType = 'employerContact'
    }

    yield call(Messenger.sendMessage, body)
    yield put(MessengerActions.clearInputValue())
    yield put(MessengerActions.setModalType('none'))

    const user = {
      id: dialogUserId,
      type: dialogUserType,
    }

    const res = yield call(Messenger.getMessages, user)

    yield delay(500)
    yield put(MessengerActions.setUserAsActiveSuccess(res.data.reverse()))
    yield put(MessengerActions.sendMessageSuccess())
  } catch (error) {
    yield put(MessengerActions.sendMessageFail(error.response.data.message))
  }
}

function* sendInterviewFlow(action: ReturnType<typeof MessengerActions.sendInterview>) {
  try {
    const messenger = yield select(state => state.messenger)
    const dialogUser = messenger.get('dialogUser')
    const dialogUserType = dialogUser.get('chatUserType') || dialogUser.get('type')
    const dialogUserinterviewDate = messenger.get('interviewDate')
    const dialogUserinterviewTime = messenger.get('interviewTime')
    const appointemtData: any = {}

    let dialogUserId
    if (dialogUserType === 'candidate') {
      appointemtData.CandidateId = dialogUser.get('CandidateId') || dialogUser.get('id')
      dialogUserId = dialogUser.get('CandidateId') || dialogUser.get('id')
    }

    appointemtData.date = `${dialogUserinterviewDate} ${dialogUserinterviewTime}`
    appointemtData.JobPositionId = action.payload.jobPositionId
    appointemtData.EmployerId = action.payload.employerId
    appointemtData.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    yield call(Messenger.sendInterview, appointemtData)

    yield put(MessengerActions.setModalType('none'))

    const user = {
      id: `${dialogUserId}`,
      type: dialogUserType,
    }
    const { data } = yield call(Messenger.getMessages, user)
    yield put(MessengerActions.setUserAsActiveSuccess(data.reverse()))
    yield put(MessengerActions.sendInterviewSuccess())
  } catch (error) {
    yield put(MessengerActions.sendInterviewFail(error.response.data.message))
  }
}

export default function* watchMessenger() {
  yield takeLatest(GET_SIGNATURE, getSignatureFlow)
  yield takeLatest(SET_SIGNATURE, setSignatureFlow)
  yield takeLatest(MESSENGER__SET_ACTIVE_USER, setActiveUserFlow)
  yield takeLatest(SEND_MESSAGE, sendMessageFlow)
  yield takeLatest(SEND_INTERVIEW, sendInterviewFlow)
  yield takeLatest(GET_CHAT_LIST, getChatListFlow)
}
