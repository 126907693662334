import React from 'react'

import { paramsForUploadLogoEmployer } from 'api/employers'
import { IEmployer } from 'types/models'

import ImageUploadBlock from 'components/UploadInput/imageUploadNew'

/* TODO:! Set api for employer, now this just for no errors */
type Props = {
  form: IEmployer
  onUpdate: any
}

const Third: React.FC<Props> = ({ form, onUpdate }) => {
  if (!form) return null
  return <ImageUploadBlock id={form.id} onUpdate={onUpdate} updateButtonText="FINISH" paramsForUploading={paramsForUploadLogoEmployer} />
}

export default Third
