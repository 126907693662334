import { ActionsUnion, createAction } from 'types/helps'
import { IError } from 'types/errors'
import { IInvoiceCreating, IPipeline } from 'types/models'

export const FETCH_PIPELINE = 'FETCH_PIPELINE'
export const FETCH_PIPELINE_SUCCESS = 'FETCH_PIPELINE_SUCCESS'
export const FETCH_PIPELINE_FAIL = 'FETCH_PIPELINE_FAIL'

export const CHANGE_PIPELINE_STATUS = 'CHANGE_PIPELINE_STATUS'
export const CHANGE_PIPELINE_STATUS_SUCCESS = 'CHANGE_PIPELINE_STATUS_SUCCESS'
export const CHANGE_PIPELINE_STATUS_FAIL = 'CHANGE_PIPELINE_STATUS_FAIL'

export const SEND_BLOBFILE = 'SEND_BLOBFILE'
export const SEND_BLOBFILE_SUCCESS = 'SEND_BLOBFILE_SUCCESS'
export const SEND_BLOBFILE_FAIL = 'SEND_BLOBFILE_FAIL'

const PipelineActions = {
  fetchPipeline: () => createAction(FETCH_PIPELINE),
  fetchPipelineSuccess: (data: IPipeline[]) => createAction(FETCH_PIPELINE_SUCCESS, data),
  fetchPipelineFail: (error: IError) => createAction(FETCH_PIPELINE_FAIL, error),

  // TODO: cb is hotfix. Need to come up with a more clean solution
  changePipelineStatus: (JobPositionId: number, CandidateId: number, status: string, interviewDate: string, cb?: any) =>
    createAction(CHANGE_PIPELINE_STATUS, { JobPositionId, CandidateId, status, interviewDate, cb }),
  changePipelineStatusSuccess: (data: any) => createAction(CHANGE_PIPELINE_STATUS_SUCCESS, data),
  changePipelineStatusFail: (error: IError) => createAction(CHANGE_PIPELINE_STATUS_FAIL, error),

  sendBlobfile: (matchId: number, data: IInvoiceCreating, blobFile: Blob) => createAction(SEND_BLOBFILE, { matchId, data, blobFile }),
  sendBlobfileSuccess: () => createAction(SEND_BLOBFILE_SUCCESS),
  sendBlobfileFail: (error: IError) => createAction(SEND_BLOBFILE_FAIL, error),
}

export type PipelineTypes = ActionsUnion<typeof PipelineActions>

export default PipelineActions
