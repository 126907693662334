import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { ValidationError } from 'yup'

import CandidateActions from 'store/candidates/actions'
import { InputEvent } from 'types/helps'
import { ISelectListItem } from 'types/forms'
import { IWorkPlace } from 'types/models'

import { Trash } from 'components/Icons'
import Button from 'components/Button'
import Input from 'components/Input'
import InputCheckbox from 'components/Input/inputCheckbox'
import InputSelect from 'components/Input/InputSelect'
import CityStateSelect from 'components/Input/cityStateSelect'

import { workPlace, formatErrors } from 'services/validation'
import { BoxFill, Box, Text, Span } from 'styles'

type Props = {
  work?: Partial<IWorkPlace>
  candidateId: number
  isEditMode?: boolean
  createWorkPlace: (id: number, workplace: Partial<IWorkPlace>) => any
  updateWorkPlace: (id: number, workplace: Partial<IWorkPlace>) => any
  deleteWorkPlace: (id: number) => any
  onCancel: () => void
}

const WorkPlaceForm: React.FC<Props> = ({ work, updateWorkPlace, createWorkPlace, deleteWorkPlace, candidateId, isEditMode, onCancel }) => {
  const [form, onChangeForm] = useState<any>(
    work
      ? {
          companyName: work.companyName,
          companyWebsite: work.companyWebsite,
          Position: work.Position,
          City: work.City,
          current: work.current,
          fromDate: work.fromDate,
          toDate: work.toDate,
        }
      : {
          current: false,
        }
  )
  const [errors, setErrors] = useState<any>({})
  const onChangePrefPosition = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Position')
  const onChangeCity = (e: InputEvent & ISelectListItem) => onChangeField(e, 'City')

  const onUpdateWorkPlace = () => {
    if (!form.toDate) delete form.toDate
    workPlace
      .validate(form, { abortEarly: false })
      .then(() => {
        updateWorkPlace(work!.id!, form)
        setErrors({})
        onCancel()
      })
      .catch((err: ValidationError) => setErrors(formatErrors(err)))
  }
  const onCreateWorkPlace = () => {
    if (!form.toDate) delete form.toDate
    workPlace
      .validate(form, { abortEarly: false })
      .then(() => {
        createWorkPlace(candidateId, form)
        setErrors({})
        onCancel()
      })
      .catch((err: ValidationError) => setErrors(formatErrors(err)))
  }
  const onDeleteWorkPlace = () => {
    deleteWorkPlace(work!.id!)
    onCancel()
  }
  const onChangeField = (e: InputEvent & ISelectListItem, type?: string) => {
    if (type) return onChangeForm({ ...form, [type]: e })
    const { name, value } = e.currentTarget
    if (e.currentTarget.type === 'checkbox') {
      return onChangeForm({ ...form, [name]: !form[name as keyof boolean] })
    }
    onChangeForm({ ...form, [name]: value })
  }

  return (
    <BoxFill defaultBorder column smooth padding="30px" mb="10px">
      <BoxFill>
        <BoxFill mb="20px" mr="14px">
          <Input value={form.companyName} error={errors.companyName} onChange={onChangeField} name="companyName" label="Company name" />
        </BoxFill>
        <BoxFill mb="20px">
          <Input
            value={form.companyWebsite}
            error={errors.companyWebsite}
            onChange={onChangeField}
            name="companyWebsite"
            label="Company webiste (optional)"
          />
        </BoxFill>
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.Position}
          error={errors.Position}
          onChange={onChangePrefPosition}
          placeholder="Choose position title"
          label="Job position"
          optionsType="jobs"
        />
      </BoxFill>
      <BoxFill>
        <CityStateSelect error={errors.City} value={form.City} onChange={onChangeCity} />
      </BoxFill>
      <BoxFill mb="20px">
        <InputCheckbox value={form.current} onChange={onChangeField} title="That is a current workplace" name="current" />
      </BoxFill>
      <BoxFill spaceBetween>
        <Box width="48%">
          <Input
            value={form.fromDate ? moment(form.fromDate).format('YYYY-MM-DD') : null}
            error={errors.fromDate}
            onChange={onChangeField}
            name="fromDate"
            label="From"
            type="date"
          />
        </Box>
        {form.current || (
          <Box width="48%">
            <Input
              value={form.toDate ? moment(form.toDate).format('YYYY-MM-DD') : null}
              error={errors.toDate}
              onChange={onChangeField}
              name="toDate"
              label="To"
              type="date"
            />
          </Box>
        )}
      </BoxFill>
      <BoxFill mt="24px" spaceBetween alignCenter>
        <Box alignCenter>
          <Box width="184px" height="52px" mr="24px">
            <Button
              type="secondary"
              onClick={isEditMode ? onUpdateWorkPlace : onCreateWorkPlace}
              title={isEditMode ? 'SAVE' : 'ADD WORK EXPERIENCE'}
            />
          </Box>
          <Text red pointer onClick={onCancel}>
            Cancel
          </Text>
        </Box>
        {work && (
          <Box alignCenter>
            <Span onClick={onDeleteWorkPlace} red>
              Delete job position
            </Span>
            <Box ml="9px">
              <Trash red />
            </Box>
          </Box>
        )}
      </BoxFill>
    </BoxFill>
  )
}

export default connect(
  null,
  {
    createWorkPlace: CandidateActions.createWorkPlace,
    updateWorkPlace: CandidateActions.updateWorkPlace,
    deleteWorkPlace: CandidateActions.deleteWorkPlace,
  }
)(WorkPlaceForm)
