import { fromJS, List } from 'immutable'

import {
  CREATE_EMPLOYER_FAIL,
  EmployersTypes,
  FETCH_ALL_EMPLOYERS_FAIL,
  FETCH_ALL_EMPLOYERS_STARTED,
  FETCH_ALL_EMPLOYERS_SUCCESS,
  EMPLOYER__CHANGE_NOTES_COUNT,
  FETCH_JOBS_BY_EMPLOYER_ID,
  FETCH_JOBS_BY_EMPLOYER_ID_SUCCESS,
  GET_ALL_LIVE_JOBS,
  GET_ALL_LIVE_JOBS_SUCCESS,
  GET_ALL_LIVE_JOBS_FAIL,
  DELETE_EMPLOYER_CONTACT_STARTED,
  DELETE_EMPLOYER_CONTACT_FAIL,
  CREATE_EMPLOYER_CONTACT_STARTED,
  CREATE_JOB_STARTED,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_FAIL,
  GET_PUBLIC_EMPLOYERS_CONTACTS,
  GET_PUBLIC_EMPLOYERS_CONTACTS_SUCCESS,
  GET_PUBLIC_EMPLOYERS_CONTACTS_FAIL,
  ADD_PUBLIC_JOB_POSITION,
  ADD_PUBLIC_JOB_POSITION_SUCCESS,
  ADD_PUBLIC_JOB_POSITION_FAIL,
  ADD_PUBLIC_JOB_POSITION_WITHOUT_ID,
  ADD_PUBLIC_JOB_POSITION_WITHOUT_ID_SUCCESS,
  ADD_PUBLIC_JOB_POSITION_WITHOUT_ID_FAIL,
} from './actions'

import { IEmployersState } from 'types/reducerStates'
import { IEmployer } from 'types/models'

const initialState: IEmployersState = fromJS({
  isFetching: false,
  query: {
    page: null,
    perPage: null,
  },
  items: List([]),
  employersQty: null,
  pagesQty: null,
  error: null,
  jobPositionsInInterviewAppointment: null,
  allEmployers: null,
  allJobPositions: null,
  publicEmployers: null,
  publicEmployersContacts: null,
  isAddedPublicJobPosition: false,
})

export default (state = initialState, action: EmployersTypes): IEmployersState => {
  switch (action.type) {
    case FETCH_ALL_EMPLOYERS_STARTED:
      return state.set('isFetching', true).set('error', null)
    case FETCH_ALL_EMPLOYERS_SUCCESS:
      return state
        .set('isFetching', false)
        .set('items', List(action.payload.employers))
        .set('query', action.payload.query)
        .set('employersQty', action.payload.employersQty)
        .set('pagesQty', action.payload.pagesQty)
        .set('error', null)
    case FETCH_ALL_EMPLOYERS_FAIL:
      return state.set('isFetching', false).set('error', action.payload)
    case CREATE_EMPLOYER_FAIL:
      return state.set('error', action.payload)
    case CREATE_EMPLOYER_CONTACT_STARTED:
      return state.set('error', null)
    case EMPLOYER__CHANGE_NOTES_COUNT:
      return state.set(
        'items',
        List(
          state.get('items').map((item: IEmployer) => {
            if (item.id === action.payload.id) {
              item.notesCount += action.payload.increase
            }
            return item
          })
        )
      )
    case FETCH_JOBS_BY_EMPLOYER_ID:
      return state.set('isFetching', true).set('jobPositionsInInterviewAppointment', null)
    case FETCH_JOBS_BY_EMPLOYER_ID_SUCCESS:
      return state.set('isFetching', false).set('jobPositionsInInterviewAppointment', action.payload)
    case GET_ALL_LIVE_JOBS:
      return state.set('isFetching', true)
    case GET_ALL_LIVE_JOBS_SUCCESS:
      return state
        .set('isFetching', false)
        .set('allEmployers', action.payload.employers)
        .set('allJobPositions', action.payload.jobPositions)
    case GET_ALL_LIVE_JOBS_FAIL:
      return state.set('isFetching', false)
    case DELETE_EMPLOYER_CONTACT_STARTED:
      return state.set('error', null)
    case DELETE_EMPLOYER_CONTACT_FAIL:
      return state.set('error', action.payload)
    case CREATE_JOB_STARTED:
      return state.set('isFetching', true)
    case CREATE_JOB_SUCCESS:
      return state.set('isFetching', false)
    case CREATE_JOB_FAIL:
      return state.set('error', action.payload).set('isFetching', false)
    case GET_PUBLIC_EMPLOYERS_CONTACTS:
      return state.set('isAddedPublicJobPosition', false)
    case GET_PUBLIC_EMPLOYERS_CONTACTS_SUCCESS:
      return state.set('publicEmployersContacts', action.payload)
    case GET_PUBLIC_EMPLOYERS_CONTACTS_FAIL:
      return state.set('error', action.payload)
    case ADD_PUBLIC_JOB_POSITION:
      return state.set('isAddedPublicJobPosition', false).set('isFetching', true)
    case ADD_PUBLIC_JOB_POSITION_SUCCESS:
      return state.set('isAddedPublicJobPosition', true).set('isFetching', false)
    case ADD_PUBLIC_JOB_POSITION_FAIL:
      return state
        .set('isAddedPublicJobPosition', false)
        .set('isFetching', false)
        .set('error', action.payload)
    case ADD_PUBLIC_JOB_POSITION_WITHOUT_ID:
      return state.set('isAddedPublicJobPosition', false).set('isFetching', true)
    case ADD_PUBLIC_JOB_POSITION_WITHOUT_ID_SUCCESS:
      return state.set('isAddedPublicJobPosition', true).set('isFetching', false)
    case ADD_PUBLIC_JOB_POSITION_WITHOUT_ID_FAIL:
      return state
        .set('isAddedPublicJobPosition', false)
        .set('isFetching', false)
        .set('error', action.payload)
    default:
      return state
  }
}
