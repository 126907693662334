import React, { useEffect, useRef } from 'react'
import { List } from 'immutable'

import config from '../config'

import { IUser } from 'types/models'

import Loader from 'components/Loader'

import { Text } from 'styles'
import {
  Avatar,
  DialogContentWrapper,
  MessageBlock,
  MessageInfoBlock,
  AttachemntWrapper,
  Calendar,
  AttachmentInfo,
  AttachmentName,
  FileAttachment,
  AvatarBG,
  FileAttachmentDefault,
} from './styles'

import docIcon from 'assets/icons/doc.svg'
import pdfIcon from 'assets/icons/pdf.svg'
import calendar from 'assets/icons/calendarWhite.svg'
import paperclip from 'assets/icons/paperclipWhite.svg'
import downloadImg from 'assets/icons/download.svg'

type Props = {
  list: List<any> | null
  user: IUser
}

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const DialogContent: React.FC<Props> = ({ list, user }) => {
  const prevList: List<any> | null | undefined = usePrevious(list)
  useEffect(() => {
    if (list) {
      if (!prevList) {
        scrollToBottom()
      } else {
        if (list!.size !== prevList!.size) {
          scrollToBottom()
        }
      }
    }
  }, [list])

  const myRef: React.RefObject<HTMLInputElement> = useRef(null)
  const scrollToBottom = () => {
    myRef && myRef!.current && myRef!.current!.scrollIntoView!({ block: 'end', behavior: 'smooth' })
  }

  if (list === null) {
    return (
      <MessageBlock>
        <Loader height={`${config.height - config.headerHeight}px`} />
      </MessageBlock>
    )
  }

  let options: any = {
    hourCycle: 'h24',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }

  const getSubstring = (str: string): string => {
    if (typeof str === 'string') {
      const s3 = str.indexOf('.com/')
      const index = str.indexOf('/files')
      if (index > 0) {
        return str.substr(index + 7)
      } else if (s3 > 0) {
        return str.substr(s3 + 5)
      }
    }
    return ''
  }

  const previewAttachment = (attachment: string) => {
    const info = (
      <AttachmentInfo>
        <AttachmentName>{getSubstring(attachment)}</AttachmentName>
      </AttachmentInfo>
    )

    if (attachment.indexOf('.doc') > 0) {
      return (
        <>
          <FileAttachmentDefault width="50px" height="60px" src={docIcon} />
          {info}
        </>
      )
    } else if (attachment.indexOf('.pdf') > 0) {
      return (
        <>
          <FileAttachmentDefault width="50px" height="60px" src={pdfIcon} />
          {info}
        </>
      )
    } else if (attachment.indexOf('.jpg') > 0) {
      return <FileAttachmentDefault src={attachment} />
    } else {
      return (
        <>
          <FileAttachment src={paperclip} />
          {info}
        </>
      )
    }
  }

  return (
    <DialogContentWrapper id="DialogContentWrapper">
      {list.map((item, key) => (
        <MessageBlock key={key}>
          {item.get('status') === 'sent' ? (
            <>
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {user.photoFile ? (
                  <Avatar src={user.photoFile.link} />
                ) : (
                  <AvatarBG user>
                    {user.firstName.match(/\b(\w)/g)}
                    {user.lastName.match(/\b(\w)/g)}
                  </AvatarBG>
                )}
              </pre>

              <MessageInfoBlock>
                <div>
                  <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{item.get('text')}</pre>
                </div>
                {item.get('Appointment') ? (
                  <AttachemntWrapper href="https://calendar.google.com/calendar" target="_blank" rel="noopener noreferrer">
                    <Calendar src={calendar} />
                    <AttachmentInfo>
                      <AttachmentName>Interview appointment</AttachmentName>
                      <Text left whiteSpace fz="17px" xs pointer>
                        {item.get('Appointment').get('date') && new Date(item.get('Appointment').get('date')).toLocaleString(undefined, options)}
                      </Text>
                    </AttachmentInfo>
                  </AttachemntWrapper>
                ) : null}

                {item.get('attachment') && (
                  <AttachemntWrapper>
                    {previewAttachment(item.get('attachment'))}
                    <a href={item.get('attachment')} target="_blank" rel="noopener noreferrer">
                      <img src={downloadImg} alt="download" />
                    </a>
                  </AttachemntWrapper>
                )}

                <Text left whiteSpace fz="17px" xs>
                  {item.get('createdAt') && new Date(item.get('createdAt')).toLocaleString('en-US', options)}
                </Text>
              </MessageInfoBlock>
            </>
          ) : (
            <>
              {item.get('text') || item.get('attachment') || item.get('Appointment') ? (
                <>
                  {item.get('chatUserType') === 'candidate' ? (
                    item.get('Candidate').get('photoFile') ? (
                      <Avatar
                        width="45px"
                        src={item
                          .get('Candidate')
                          .get('photoFile')
                          .get('link')}
                      />
                    ) : (
                      <AvatarBG>
                        {item
                          .get('Candidate')
                          .get('firstName')
                          .match(/\b(\w)/g)}
                        {item
                          .get('Candidate')
                          .get('lastName')
                          .match(/\b(\w)/g)}
                      </AvatarBG>
                    )
                  ) : (
                    <AvatarBG>
                      {item
                        .get('EmployerContact')
                        .get('name')
                        .match(/\b(\w)/g)}
                    </AvatarBG>
                  )}

                  <MessageInfoBlock>
                    <div>
                      <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{item.get('text')}</pre>
                    </div>

                    {item.get('Appointment') && (
                      <AttachemntWrapper href="https://calendar.google.com/calendar" target="_blank" rel="noopener noreferrer">
                        <Calendar src={calendar} />
                        <AttachmentInfo>
                          <AttachmentName>Interview appointment</AttachmentName>
                          <Text left whiteSpace fz="17px" xs pointer>
                            {item.get('Appointment').get('date') && new Date(item.get('Appointment').get('date')).toLocaleString('en-US', options)}
                          </Text>
                        </AttachmentInfo>
                      </AttachemntWrapper>
                    )}

                    {item.get('attachment') && (
                      <AttachemntWrapper>
                        {previewAttachment(item.get('attachment'))}
                        <a href={item.get('attachment')} target="_blank" rel="noopener noreferrer">
                          <img src={downloadImg} alt="attachment" />
                        </a>
                      </AttachemntWrapper>
                    )}

                    <Text left whiteSpace fz="17px" xs>
                      {item.get('createdAt') && new Date(item.get('createdAt')).toLocaleString('en-US', options)}
                    </Text>
                  </MessageInfoBlock>
                </>
              ) : null}
            </>
          )}
        </MessageBlock>
      ))}
      <div ref={myRef} />
    </DialogContentWrapper>
  )
}

export default DialogContent
