import React from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { ValidationError } from 'yup'
import styled from 'styled-components'

import AuthActions from 'store/auth/actions'
import { AppState } from 'store/rootReducer'
import { InputEvent } from 'types/helps'
import { IUserForm } from 'types/forms'
import { INotValidFieldsErr } from 'types/errors'

import { Logo } from 'components/Icons'
import StartScreen from './components/start'
import LoginScreen from './components/login'
import Step1 from './components/signUpStep1'
import Step2 from './components/signUpStep2'
import * as sliders from './components/sliders'

import { signUpSchemaFirstStep, signUpSchemaFinishRegistration, formatErrors } from 'services/validation'

import { Box, BoxCenterContent } from 'styles'

const RightContainer = styled(BoxCenterContent).attrs({
  height: '400vh',
  transition: '1000',
  whiteBC: true,
  column: true,
})`
  overflow: hidden;
`

const LeftContainer = styled(BoxCenterContent).attrs({
  width: '65vw',
  height: '100vh',
  alignCenter: true,
  column: true,
  mainBC: true,
})``

const INIT_SCROLL_DISTANCE = -100
const LOGIN_SCROLL_POSITION = 0
const STEP1_SCROLL_POSITION = -200
const STEP2_SCROLL_POSITION = -300

const INIT_SLIDER_POSITION = 97.5
const SLIDER_STEP1_POSITION = 32.5
const SLIDER_STEP2_POSITION = -32.5
const LOGIN_SLIDER_POSITION = -97.5

type SLIDER_POSITIONS = {
  [key: string]: number
}

const SLIDER_POSITIONS: SLIDER_POSITIONS = {
  '-100': INIT_SLIDER_POSITION,
  '0': LOGIN_SLIDER_POSITION,
  '-200': SLIDER_STEP1_POSITION,
  '-300': SLIDER_STEP2_POSITION,
}

type Props = {
  isLoggedIn: boolean
  isFetching: boolean
  error: INotValidFieldsErr
  createUser: typeof AuthActions.createUser
  loginFail: typeof AuthActions.loginFail
  successRegistered: boolean
}

type State = {
  currentScroll: number
  form: Partial<IUserForm>
  errors: Partial<IUserForm>
}

export class SignIn extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    currentScroll: this.props.successRegistered ? LOGIN_SCROLL_POSITION : INIT_SCROLL_DISTANCE,
    form: {
      experience: 0,
    },
    errors: {},
  }

  componentDidMount() {
    const toLoginRedirect = window.location.search.split('=')[1]
    toLoginRedirect && this.scrollToLogin()
  }

  scrollTo = (scroll: number) => {
    this.props.loginFail({ message: '' })
    this.setState({ currentScroll: scroll })
  }

  scrollToLogin = () => this.scrollTo(LOGIN_SCROLL_POSITION)

  scrollToStep1 = () => this.scrollTo(STEP1_SCROLL_POSITION)

  scrollToStep2 = () =>
    signUpSchemaFirstStep
      .validate(this.state.form, { abortEarly: false })
      .then(() => {
        this.setState({ errors: {} })
        this.scrollTo(STEP2_SCROLL_POSITION)
      })
      .catch((err: ValidationError) => this.setState({ ...this.state, errors: formatErrors(err) }))

  onChangeField = (e: InputEvent | any, type?: string) => {
    if (type) return this.setState({ form: { ...this.state.form, [type]: e } })
    const { name, value } = e.currentTarget
    this.setState({ form: { ...this.state.form, [name]: value } })
  }

  onForgotPassword = () => {
    this.props.loginFail({ message: '' })
    this.props.history.push('/forgot-password')
  }

  finishSignUp = () =>
    signUpSchemaFinishRegistration
      .validate(this.state.form, { abortEarly: false })
      .then(() => this.props.createUser(this.state.form))
      .catch((err: ValidationError) => this.setState({ ...this.state, errors: formatErrors(err) }))

  render() {
    const { currentScroll, form, errors } = this.state
    const { isLoggedIn, isFetching, history, error } = this.props
    const { initSlider, step1Slider, step2Slider, loginSlider } = sliders
    if (isFetching) return null
    if (isLoggedIn) {
      const { state } = history.location
      const { pathname, search } = state ? state.from : history.location
      return <Redirect to={state ? `${pathname}${search}` : '/dashboard'} />
    }
    const isResetPassword = window.location.search.split('=')[2]
    return (
      <Box fixed>
        <LeftContainer>
          <Box mt="30px">
            <Logo />
          </Box>
          <Box transition="1000" width="260vw" widthTranform={SLIDER_POSITIONS[currentScroll]}>
            {initSlider}
            {step1Slider}
            {step2Slider}
            {loginSlider}
          </Box>
        </LeftContainer>
        <RightContainer heightTransform={currentScroll}>
          <LoginScreen error={error} onForgotPassword={this.onForgotPassword} goToRequest={this.scrollToStep1} isResetPassword={isResetPassword} />
          <StartScreen logInScroll={this.scrollToLogin} nextStep={this.scrollToStep1} />
          <Step1 errors={errors} form={form} onChangeField={this.onChangeField} logInScroll={this.scrollToLogin} nextStep={this.scrollToStep2} />
          <Step2
            errors={errors}
            errorCreateUser={error}
            form={form}
            onChangeField={this.onChangeField}
            finishSignUp={this.finishSignUp}
            backStep={this.scrollToStep1}
          />
        </RightContainer>
      </Box>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.auth.get('isLoggedIn'),
  successRegistered: state.auth.get('successRegistered'),
  isFetching: state.auth.get('isFetching'),
  error: state.auth.get('error'),
})

export default connect(
  mapStateToProps,
  { createUser: AuthActions.createUser, loginFail: AuthActions.loginFail }
)(SignIn)
