import React, { useState } from 'react'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'
import MessengerActions from 'store/messenger/actions'

import PrimaryButton from 'components/Button'
import DocsUploadInput from 'components/UploadInput/docsUploadInput'
import Loader from 'components/Loader'
import { Subtitle, Title, ModalContainer } from '../components/styles'

import theme from 'styles/theme'
import { Box, BoxFill, Text } from 'styles'

type Props = {
  changeInputValue: typeof MessengerActions.changeInputValue
  sendMessage: typeof MessengerActions.sendMessage
  messageIsFetching: boolean
  error: string
}

const CandidateModal: React.FC<Props> = ({ changeInputValue, sendMessage, messageIsFetching, error }) => {
  const [isUploadFile, setIsUploadFile] = useState(false)
  const handleUploadFile = (file: any) => {
    changeInputValue('attachmentChatFile', file)
    setIsUploadFile(true)
  }

  return (
    <ModalContainer>
      <Title xl3 color={theme.main.colors.grey3}>
        Attach candidate
      </Title>
      <Subtitle m>Choose the candidate from the list below to attach it to the message</Subtitle>
      <Text red>{error}</Text>
      {messageIsFetching ? (
        <Loader width="500px" height="500px" />
      ) : (
        <>
          <BoxFill mb="20px" column>
            <DocsUploadInput id={1} title="chatFile" uploadChatFile={handleUploadFile} />
          </BoxFill>
          <Box justifyCenter maxWidth="1000px" minWidth="500px">
            <PrimaryButton disabled={!isUploadFile} height="50px" width="100px" onClick={sendMessage} title="SEND" />
          </Box>
        </>
      )}
    </ModalContainer>
  )
}

const mapStateToProps = (state: AppState) => ({
  dialogUser: state.messenger.get('dialogUser'),
  messageIsFetching: state.messenger.get('messageIsFetching'),
  error: state.messenger.get('error'),
})

export default connect(
  mapStateToProps,
  {
    changeInputValue: MessengerActions.changeInputValue,
    sendMessage: MessengerActions.sendMessage,
  }
)(CandidateModal)
