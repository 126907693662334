import React from 'react'
import moment from 'moment'

import { ISelectListItem } from 'types/forms'
import { InputEvent } from 'types/helps'
import { ICandidate } from 'types/models'

import Input from 'components/Input'
import { LinkedIn } from 'components/Icons'
import InputSlider from 'components/Input/inputSlider'
import InputSelect from 'components/Input/InputSelect'

import WorkPlaces from './workPlaces'

import { BoxFill, Textarea, Image, Text, Box, H3 } from 'styles'

const ICON_CALENDAR = <Image src={require('assets/icons/calendar.svg')} />
const ICON_DOLLAR = <Image src={require('assets/icons/$.svg')} />

type Props = {
  form: ICandidate
  errors: ICandidate
  onChangeField: (e: InputEvent & ISelectListItem, type?: string) => void
}

const Second: React.FC<Props> = ({ form, errors, onChangeField }) => {
  const onChangePostion = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Positions')
  const onChangeProjectTypes = (e: InputEvent & ISelectListItem) => onChangeField(e, 'Sectors')
  const onChangeExperience = (e: InputEvent & ISelectListItem) => onChangeField(e, 'experience')
  const onChangeItPackages = (e: InputEvent & ISelectListItem) => onChangeField(e, 'ItPackages')
  const onChangeProjectValues = (e: InputEvent & ISelectListItem) => onChangeField(e, 'ProjectValues')

  return (
    <BoxFill column>
      <BoxFill mb="20px">
        <InputSelect value={form.Positions} isMulti onChange={onChangePostion} placeholder="Any" label="Job Titles they hire" optionsType="jobs" />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.Sectors}
          isMulti
          onChange={onChangeProjectTypes}
          placeholder="Any"
          label="Preferable project types"
          optionsType="projectTypes"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.ItPackages}
          isMulti
          onChange={onChangeItPackages}
          placeholder="Choose"
          label="Qualifications / IT Packages (optional)"
          optionsType="itPackages"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSelect
          value={form.ProjectValues}
          isMulti
          onChange={onChangeProjectValues}
          placeholder="Choose"
          label="Project values (optional)"
          optionsType="projectValues"
        />
      </BoxFill>
      <BoxFill mb="20px">
        <InputSlider value={String(form.experience)} onChange={onChangeExperience} />
      </BoxFill>
      <BoxFill mb="20px">
        <Input
          value={form.minSalary}
          error={errors.minSalary}
          onChange={onChangeField}
          leftElement={ICON_DOLLAR}
          pl="30px"
          name="minSalary"
          label="Min. annual salary"
          type="number"
        />
      </BoxFill>
      <BoxFill column mb="20px">
        <Input
          value={moment(form.availability).format('YYYY-MM-DD')}
          error={errors.availability}
          onChange={onChangeField}
          name="availability"
          label="Availability"
          type="date"
          rightElement={ICON_CALENDAR}
        />
        <Text left s>
          Please select a date when the candidate can start working. This will simplify the job search.
        </Text>
      </BoxFill>
      <BoxFill mb="20px">
        <Input
          value={form.linkedIn}
          error={errors.linkedIn}
          onChange={onChangeField}
          name="linkedIn"
          leftElement={<LinkedIn isActive={form.linkedIn} />}
          pl="55px"
          placeholder="Enter the URL"
          label="LinkedIn profile (optional)"
        />
      </BoxFill>
      <BoxFill mb="30px" column>
        <Text black>Candidate description (optional)</Text>
        <BoxFill height="115px" mb="15px">
          <Textarea value={form.addInfo} onChange={onChangeField} name="addInfo" maxLength={2000} />
        </BoxFill>
        <BoxFill height="15px" />
        <Text s>2000 characters maximum</Text>
      </BoxFill>
      <Box column mb="20px">
        <H3 fw="bold" black>
          Work experience
        </H3>
      </Box>
      <WorkPlaces candidateId={form.id} />
    </BoxFill>
  )
}

export default Second
