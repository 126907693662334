import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { DragDropContext } from 'react-beautiful-dnd'
import styled from 'styled-components'
import _ from 'lodash'

import { AppState } from 'store/rootReducer'
import PipelineActions from 'store/pipeline/actions'
import { IPipelineState } from 'types/reducerStates'

// import Loader from 'components/Loader'
import { Column } from './Column'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  overflow: auto;
  height: calc(100vh - 202px);
  margin: -84px 0 0 0;
  padding: 0 80px;
`

interface Tasks {
  [key: string]: {
    id: string
    JobPositionId: number
    CandidateId: number
    interviewDate: string | null
  }
}

interface Columns {
  [key: string]: {
    id: string
    title: string
    taskIds: string[]
  }
}

type Props = {
  fetchPipeline: typeof PipelineActions.fetchPipeline
  changePipelineStatus: typeof PipelineActions.changePipelineStatus
  pipeline: IPipelineState
  userData: any
}

type State = {
  candidates: Tasks
  columns: Columns
  columnOrder: string[]
}

class Board extends React.Component<Props & RouteComponentProps & State | any> {
  state: State = {
    candidates: {},
    columns: {
      '1': {
        id: '1',
        title: 'Candidate is matched',
        taskIds: [],
      },
      '2': {
        id: '2',
        title: 'Candidate agreed to submittal',
        taskIds: [],
      },
      '3': {
        id: '3',
        title: 'CV is sent',
        taskIds: [],
      },
      '4': {
        id: '4',
        title: 'Employer accepted the candidate',
        taskIds: [],
      },
      '5': {
        id: '5',
        title: 'Interview arranged',
        taskIds: [],
      },
      '6': {
        id: '6',
        title: 'Placement approved',
        taskIds: [],
      },
    },
    columnOrder: ['1', '2', '3', '4', '5', '6'],
  }

  componentDidMount() {
    const { fetchPipeline } = this.props
    fetchPipeline()
  }

  componentDidUpdate(prevProps: any) {
    const { pipeline } = this.props
    const { columnOrder, columns } = this.state
    if (pipeline.get('pipeline') !== prevProps.pipeline.get('pipeline')) {
      const arrayToObject = (array: { reduce: (arg0: (obj: any, item: any) => any, arg1: {}) => void }) =>
        array.reduce((obj, item) => {
          if (item && +item.status === 7) {
            item['column'] = item.status - 1
            item['createdInvoice'] = true
          } else {
            item['column'] = item.status
            item['createdInvoice'] = false
          }
          item['itemId'] = item['id']
          item['id'] = `${item.CandidateId}-${item.JobPositionId}`
          obj[`${item.CandidateId}-${item.JobPositionId}`] = item
          return obj
        }, {})
      const peopleObject = arrayToObject(pipeline.get('pipeline'))

      pipeline.get('pipeline').map(() => {
        columnOrder.map((columnId: string) => {
          const column = columns[columnId]
          const newArray = pipeline.get('pipeline').filter((el: any) => +el.column === +column.id)
          const getId = _.sortBy(newArray, 'status').map((el: any) => `${el.CandidateId}-${el.JobPositionId}`)
          this.setState((prevState: any) => {
            return {
              columns: {
                ...prevState.columns,
                [column.id]: {
                  ...prevState.columns[column.id],
                  taskIds: getId,
                },
              },
            }
          })
        })
      })

      this.setState({ candidates: peopleObject })
    }
  }

  onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result
    const { columns, candidates } = this.state
    const { changePipelineStatus, fetchPipeline } = this.props

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const start = columns[source.droppableId]
    const finish = columns[destination.droppableId]

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      }

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn,
        },
      }

      this.setState(newState)
      return
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds)
    startTaskIds.splice(source.index, 1)
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    }

    const finishTaskIds = Array.from(finish.taskIds)
    finishTaskIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    }

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    }

    const { JobPositionId, CandidateId, interviewDate } = candidates[draggableId]
    changePipelineStatus(JobPositionId, CandidateId, newFinish.id, interviewDate, fetchPipeline)
    this.setState(newState)
  }

  onCloseDeal = (JobPositionId: number, CandidateId: number, status: string, interviewDate: string) => {
    const { changePipelineStatus, fetchPipeline } = this.props
    changePipelineStatus(JobPositionId, CandidateId, status, interviewDate, fetchPipeline)
  }

  render() {
    const { columns, candidates, columnOrder } = this.state
    const {
      userData,
      // pipeline
    } = this.props

    // return pipeline.get('isFetching') ? (
    //   <Loader withoutBg height="690px" />
    // ) : (
    return (
      Object.keys(candidates).length > 0 && (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Container>
            {columnOrder.map(columnId => {
              const column = columns[columnId]
              const tasks = column.taskIds.map(taskId => candidates[taskId])
              return <Column key={column.id} column={column} tasks={tasks} changePipelineStatus={this.onCloseDeal} userData={userData} />
            })}
          </Container>
        </DragDropContext>
      )
    )
    // )
  }
}

const mapStateToProps = (state: AppState) => ({
  pipeline: state.pipeline,
  userData: state.auth.get('user'),
})

export default connect(
  mapStateToProps,
  {
    fetchPipeline: PipelineActions.fetchPipeline,
    changePipelineStatus: PipelineActions.changePipelineStatus,
  }
)(Board)
