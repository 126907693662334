import React from 'react'

import { ISelectListItem } from 'types/forms'
import { InputEvent } from 'types/helps'

type Props = {
  name: string
  value?: boolean
  onChangeField: (e: InputEvent & ISelectListItem, type?: string) => void
}

const Checkbox: React.FC<Props> = ({ name, value, onChangeField }) => {
  return (
    <div style={{ position: 'relative', marginRight: '8px' }}>
      <input id="trigger" name={name} onChange={onChangeField} checked={value} type="checkbox" />
      <img src={require('assets/icons/successCheckboxV2.svg')} alt="checkbox" />
    </div>
  )
}

export default Checkbox
