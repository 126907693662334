import React, { useState } from 'react'
import { connect } from 'react-redux'

import MessengerActions from 'store/messenger/actions'
import { ISignature } from 'types/models'
import { InputEvent } from 'types/helps'

import Button from 'components/Button'
import Loader from 'components/Loader'

import { BoxFill, H2, Textarea, Text } from 'styles'
import { Label, SignatureImg, UplaodSignatureInput, UploadText, BottomBlock, Error } from './styles'

import paperclipIcon from 'assets/icons/paperclip.svg'

type Props = {
  form: any
  title: string
  changeInputValue: typeof MessengerActions.changeInputValue
  getSignature: typeof MessengerActions.getSignature
  signature: ISignature
  signatureIsFetching: boolean
  signatureTextarea: string
  setSignature: typeof MessengerActions.setSignature
  error: string
  clearSignatureError: typeof MessengerActions.clearSignatureError
}

const Five: React.FC<Props> = ({
  form,
  title,
  signature,
  changeInputValue,
  signatureTextarea,
  signatureIsFetching,
  setSignature,
  error,
  clearSignatureError,
}) => {
  const [currentImg, setCurrentImg] = useState(false)
  const onChangeInput = (e: InputEvent) => {
    const { name, value } = e.currentTarget
    changeInputValue(name, value)
  }

  const previewFile = (e: any) => {
    clearSignatureError()
    setCurrentImg(true)
    const { name, files } = e.currentTarget
    changeInputValue(name, files[0])
    let preview: any = document.getElementById('signatureImage')
    const file = files[0]
    const reader = new FileReader()

    reader.onloadend = () => (preview.src = reader.result)
    if (file.type === 'image/jpeg' || file.type === 'image/x-png') {
      reader.readAsDataURL(file)
    } else {
      preview.src = paperclipIcon
    }
  }

  return (
    <>
      <BoxFill column bbDefault padding="30px 40px 40px">
        <BoxFill mb="20px" spaceBetween>
          <H2 black fw="bold">
            {title}
          </H2>
        </BoxFill>
        {signatureIsFetching ? (
          <Loader height="57vh" />
        ) : (
          <>
            <BoxFill column height="130px" mb="20px">
              <Label htmlFor="signature">Signature text</Label>
              <Textarea
                name="signatureTextarea"
                id="signature"
                maxLength={2000}
                placeholder="Add your signature text here"
                value={signatureTextarea !== 'null' ? signatureTextarea : ''}
                onChange={onChangeInput}
              />
            </BoxFill>
            <BoxFill column>
              <Error s red>
                {error}
              </Error>
              <Label>Signature image</Label>
              <SignatureImg
                id="signatureImage"
                src={signature && signature.image}
                show={Boolean(signature && signature.image !== 'undefined') || (currentImg && !error)}
              />
              <UplaodSignatureInput name="signatureImage" type="file" accept="image/x-png, image/jpeg" onChange={previewFile} />
              <UploadText>
                {(signature && signature.image !== 'undefined') || (currentImg && !error) ? 'Upload another image' : 'Upload image'}
              </UploadText>
              <Text s>Supported formats are PNG, JPG. Max file size is 5 MB.</Text>
            </BoxFill>
          </>
        )}
      </BoxFill>
      <BottomBlock>
        <Button width="172px" height="60px" title="SAVE CHANGES" onClick={() => setSignature()} />
      </BottomBlock>
    </>
  )
}

export default connect(
  null,
  {
    getSignature: MessengerActions.getSignature,
    changeInputValue: MessengerActions.changeInputValue,
    setSignature: MessengerActions.setSignature,
    clearSignatureError: MessengerActions.clearSignatureError,
  }
)(Five)
