import React, { FC, useState, useCallback } from 'react'
import ModalComponents from '../../Modal'
import { H2 } from 'styles'
import styled from 'styled-components'
import InputBox from '../../Input'
import { FormikProvider, useFormik } from 'formik'

import { useProjectTypesCRUD } from 'hooks/useProjectTypesCRUD'

interface Props {
  isOpen: boolean
  onClose: () => void
  refreshList: () => void
}

interface Values {
  name: string
}

const initialValues: Values = {
  name: '',
}

export const AddProjectModal: FC<Props> = ({ isOpen, onClose, refreshList }) => {
  const [errors, setErrors] = useState('')
  const { createProject } = useProjectTypesCRUD()

  const handleSubmit = useCallback(async (values: Values, { setSubmitting }) => {
    setSubmitting(true)
    setErrors('')
    try {
      const response = await createProject(values.name)
      if (response && response.status === 400 && response.message) {
        setErrors(response.message)
      } else {
        refreshList()
        form.resetForm()
        onClose()
      }
    } catch (e) {
      // todo: show error
    } finally {
      setSubmitting(false)
    }
  }, [])

  const form = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })

  const closeModal = useCallback(() => {
    onClose()
    setErrors('')
  }, [])

  return (
    /* why hideCloseIcon works like "show close icon"??? */
    <ModalComponents isOpen={isOpen} onRequestClose={closeModal} hideCloseIcon>
      <ContentWrapper>
        <FormikProvider value={form}>
          <H2 black fw="bold">
            Add new project
          </H2>
          <Form>
            <FormField>
              <Label>Name of project type</Label>
              <InputBox type="text" name="name" onChange={form.handleChange} value={form.values.name} />
            </FormField>
            <div>{errors && <p style={{ color: 'red' }}>{errors}</p>}</div>
          </Form>
          <SubmitButton type="button" onClick={() => form.handleSubmit()}>
            Add project type
          </SubmitButton>
        </FormikProvider>
      </ContentWrapper>
    </ModalComponents>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 540px;
  padding: 20px;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 24px;
  width: 100%;
`

const FormField = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #292c38;
  margin-bottom: 10px;
`

const SubmitButton = styled.button`
  background: #3cb593;
  border-radius: 6px;
  color: #ffffff;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  padding: 18px;
  border: none;
  width: fit-content;
  cursor: pointer;
  :hover {
    filter: brightness(90%);
  }
`
