import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ValidationError } from 'yup'
import styled from 'styled-components'

import { AppState } from 'store/rootReducer'
import CandidatesActions from 'store/candidates/actions'
import { ICandidateWithLastWorkPlace } from 'types/models'
import { InputEvent } from 'types/helps'
import { ISelectListItem } from 'types/forms'

import Stepper from 'components/Stepper'
import FormFrame from 'components/FormStepFrame'
import FAQ from 'components/faqSteps'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Loader from 'components/Loader'
import FirstStep from './steps/1'
import SecondStep from './steps/2'
import ThirdStep from './steps/3'
import FourStep from './steps/4'
import FiveStep from './steps/5'
import textOfStep from './steps/textOfStep'

import { addPublicCandidateSchemaFirstStep, addCandidateSchemaSecondStep, formatErrors } from 'services/validation'

import { BoxFill, Box } from 'styles'
import theme from 'styles/theme'
import { ModalTitle, ModalSubTitle, BottonWrap, LoaderWrap } from './styles'

const Wrap = styled(Box).attrs({})`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Steps = [
  'Step 1. Personal information',
  'Step 2. Professional details',
  'Step 3. Upload CV',
  'Step 4. Upload licenses (optional)',
  'Step 5. Upload photo (optional)',
]

const AddCandidateByLink: React.FC = () => {
  const dispatch = useDispatch()
  const candidate = useSelector((state: AppState) => state.currentInnerPage.get('item'))
  const candidates = useSelector((state: AppState) => state.candidates)
  const day = candidates.get('day')
  const month = candidates.get('month')
  const year = candidates.get('year')
  const isFetching = useSelector((state: AppState) => state.candidates.get('isFetching'))
  const [isShowModal, setIsShowModal] = useState(false)
  const recruiterId = window.location.pathname.substring(23)

  const [currentStep, setCurrentStep] = useState(1)
  const [form, setForm] = useState<Partial<ICandidateWithLastWorkPlace>>({
    relocate: false,
    wpCurrent: false,
    experience: 0,
    Positions: [],
    Sectors: [],
    RelocateCity: [],
  })

  const [errors, setErrors] = useState({})

  const createCandidate = useCallback(
    candidate => {
      if (recruiterId) {
        dispatch(CandidatesActions.createPublicCandidate(candidate, recruiterId))
      } else {
        dispatch(CandidatesActions.createPublicCandidateWithoutId(candidate))
      }
    },
    [dispatch]
  )

  const setCandidateData = useCallback(
    (name: any, value: any): any => {
      dispatch(CandidatesActions.setCandidateData(name, value))
    },
    [dispatch]
  )

  const prevStep = () => setCurrentStep(currentStep - 1)

  const nextStep = () => {
    const validatorSteps: any = {
      1: addPublicCandidateSchemaFirstStep,
      2: addCandidateSchemaSecondStep,
    }
    const createCandidateSteps: any = {
      1: (form: any) => form,
      2: (form: any) => createCandidate(form),
    }

    if (currentStep === 1) {
      if (!form.email) delete form.email
    } else if (currentStep > 2) return setCurrentStep(currentStep + 1)

    const validator = validatorSteps[currentStep]
    validator
      .validate(form, { abortEarly: false })
      .then(() => {
        createCandidateSteps[currentStep](form)
        setErrors({})
        setCurrentStep(currentStep + 1)
      })
      .catch((err: ValidationError) => setErrors(formatErrors(err)))
  }

  const onChangeField = (e: InputEvent & ISelectListItem & number, type?: string) => {
    if (type) return setForm({ ...form, [type]: e })
    const {
      name,
      value,
      validity: { valid },
    } = e.currentTarget
    if (e.currentTarget.type === 'checkbox') {
      return setForm({
        ...form,
        [name]: !form[name as keyof boolean],
      })
    }

    if (valid) {
      setForm({
        ...form,
        [name]: value,
        availability: `${year.get('value')}-${month.get('value')}-${day.get('value')}`,
      })
    }
  }

  const handleInputChange = (e: any) => {
    const {
      name,
      value,
      validity: { valid },
    } = e.target

    if (valid) setCandidateData(name, value)
  }

  const renderCurrentStep = () => {
    setCandidateData('day', day.get('value'))
    setCandidateData('month', month.get('value'))
    setCandidateData('year', year.get('value'))

    switch (currentStep) {
      case 1:
        return <FirstStep errors={errors} onChangeField={onChangeField} form={form} />
      case 2:
        return (
          <SecondStep
            onChangeField={onChangeField}
            form={form}
            errors={errors}
            handleInputChange={handleInputChange}
            day={day}
            month={month}
            year={year}
            setCandidateData={setCandidateData}
            recruiterId={recruiterId}
          />
        )
      case 3:
        return <ThirdStep form={candidate} />
      case 4:
        return <FourStep form={candidate} />
      case 5:
        return <FiveStep form={candidate} onUpdate={() => setIsShowModal(true)} />
      default:
        return null
    }
  }

  const addAnotherJob = () => {
    setIsShowModal(false)
    window.location.reload()
  }

  const isLastStep = currentStep === 5
  const isCreateStep = currentStep === 2

  return (
    <BoxFill bc={theme.main.homeBackground} column>
      <Stepper currentStep={currentStep} steps={Steps} title="Add candidate" />
      <Wrap mt="-25vh">
        <FormFrame
          currentStep={currentStep}
          maxStep={Steps.length}
          btnText={isLastStep ? '' : isCreateStep ? 'CREATE CANDIDATE AND CONTINUE' : 'NEXT STEP'}
          title={textOfStep(currentStep)!.title}
          text={textOfStep(currentStep)!.subTitle}
          nextStep={nextStep}
          back={prevStep}
          isShowBackStep={currentStep !== 3}
          isFetching={candidates.get('isFetching')}
        >
          {renderCurrentStep()}
        </FormFrame>
        <FAQ title={textOfStep(currentStep)!.faq.title} text={textOfStep(currentStep)!.faq.text} />
      </Wrap>
      {isFetching ? (
        <LoaderWrap>
          <Loader height="400px" withoutBg />
        </LoaderWrap>
      ) : (
        isShowModal && (
          <Modal isOpen={isShowModal} hideCloseIcon onRequestClose={() => addAnotherJob()}>
            <ModalTitle>Your profile has been added to the platform</ModalTitle>
            <ModalSubTitle>We will notify you when the right job comes in</ModalSubTitle>
            <BottonWrap>
              <Button width="198px" height="52px" title="ADD ANOTHER CANDIDATE" onClick={() => addAnotherJob()} />
            </BottonWrap>
          </Modal>
        )
      )}
    </BoxFill>
  )
}

export default AddCandidateByLink
