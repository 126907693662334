import React, { useEffect, useRef } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Api from 'api'

import { AppState } from 'store/rootReducer'
import AuthActions from 'store/auth/actions'

import DashboardMenu from 'components/DashboardMenu'

import theme from 'styles/theme'
import { BoxFill } from 'styles'

const ProtectedRoute: React.FC<any> = ({ component: Component, token, header, refreshLogin, isLoggedIn, ...rest }) => {
  const ref = useRef(false)
  if (!ref.current) {
    if (isLoggedIn) Api.setAuthToken(token)
    ref.current = true
  }
  useEffect(() => {
    refreshLogin()
  }, ['isLoggedIn'])
  return (
    <Route
      {...rest}
      render={props => {
        return isLoggedIn ? (
          <BoxFill bc={theme.main.homeBackground} column>
            {header}
            <Component exact={true} {...props} />
          </BoxFill>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }}
    />
  )
}

ProtectedRoute.defaultProps = {
  header: <DashboardMenu />,
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.auth.get('isLoggedIn'),
  token: state.auth.get('token'),
})

export default connect(
  mapStateToProps,
  { refreshLogin: AuthActions.refreshLogin }
)(ProtectedRoute)
