import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { ValidationError } from 'yup'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'

import { AppState } from 'store/rootReducer'
import EmployersActions from 'store/employers/actions'
import { IEmployer, IEmployerContact } from 'types/models'
import { InputEvent } from 'types/helps'

import EditFrame from 'components/EditFrame'
import Loader from 'components/Loader'
import * as forms from 'components/Forms/EmployerFormSteps'
import Second from 'components/Forms/EmployerFormSteps/2'
import Third from 'components/Forms/EmployerFormSteps/3'

import { filterByKeys } from 'utils'
import { addEmployerSchemaFirstStep, addEmployerSchemaSecondStep, formatErrors } from 'services/validation'

const CONTACT_REQUIRED_FIELDS = ['name', 'position', 'email', 'phone']
const CANDIDATE_STEPS_EDIT = ['Company info', 'Client contact details', 'Company logo']
const SETTING_TEXTS = {
  title: 'employer',
  deleteBtnText: 'Delete employer',
  viewItemBtnText: 'VIEW EMPLOYER',
  deleteItemBtnText: 'Delete contact',
}

type Params = {
  id: number
}

type Props = {
  isFetching: boolean
  match: { params: Params }
  fetchEmployer: typeof EmployersActions.fetchEmployer
  employer: Partial<IEmployer>
  updateEmployer: (id: number, job: Partial<IEmployer>) => void
  deleteEmployer: (id: number) => void
  deleteEmployerContact: (id: number) => void
  updateContact: (id: number, contact: Partial<IEmployerContact>) => void
  createContact: (id: number, contact: Partial<IEmployerContact>) => void
  isUpdateData: boolean
  clearNotification: () => void
  errorFromServer: string | null
}

type State = {
  currentStep: number
  isUpdated: boolean | number
  employer: Partial<IEmployer>
  errors: any
}

class EditEmployer extends React.Component<Props & RouteComponentProps, State> {
  state: State = {
    currentStep: 0,
    isUpdated: false,
    employer: {},
    errors: {},
  }

  componentWillReceiveProps(nextProps: Props & RouteComponentProps) {
    const { employer } = this.props
    const nextEmployer = nextProps.employer
    if (isEmpty(this.state.employer)) {
      this.setState({ employer: nextEmployer })
    } else {
      let EmployerContacts = this.state.employer.EmployerContacts
      // TODO: if contact was removed, removeing contact from local state
      if (employer.EmployerContacts!.length > nextEmployer.EmployerContacts!.length) {
        EmployerContacts = EmployerContacts!.filter(contact => {
          return nextEmployer.EmployerContacts!.some(propsContact => propsContact.id === contact!.id)
        })
        // TODO: if contact was added, add contact to local state
      } else if (employer.EmployerContacts!.length < nextEmployer.EmployerContacts!.length) {
        const addedContact = nextEmployer.EmployerContacts!.filter(contact => {
          return employer.EmployerContacts!.every(propsContact => propsContact.id !== contact!.id)
        })[0]
        EmployerContacts![toNumber(this.state.isUpdated)] = addedContact
      }
      this.setState({
        errors: {},
        employer: {
          ...nextProps.employer,
          EmployerContacts,
        },
      })
    }
  }

  componentWillMount() {
    this.props.fetchEmployer(this.props.match.params.id)
    this.props.clearNotification()
  }

  setStep = (step: number) => {
    this.setState({ currentStep: step })
    this.props.clearNotification()
    this.setState({
      errors: {},
    })
  }

  getComponent = () => {
    const { isUpdated, employer, currentStep, errors } = this.state
    const { isUpdateData, errorFromServer } = this.props
    switch (currentStep) {
      case 1:
        return (
          <Second
            isUpdated={isUpdated}
            title={CANDIDATE_STEPS_EDIT[currentStep]}
            deleteBtnText={SETTING_TEXTS.deleteItemBtnText}
            forms={employer}
            errors={errors}
            onChangeField={this.onChangeField}
            onUpdate={this.onUpdate}
            deleteEmployerContact={this.onDeleteContact}
            addContact={this.onAddNewContact}
            isUpdateData={isUpdateData}
            errorFromServer={errorFromServer}
          />
        )
      case 2:
        return <Third title={CANDIDATE_STEPS_EDIT[currentStep]} form={employer} />
    }
    return undefined
  }

  onUpdate = (data?: any) => {
    let form: any = {}
    const validatorSteps: any = {
      0: addEmployerSchemaFirstStep,
      1: addEmployerSchemaSecondStep,
    }
    const validator = validatorSteps[this.state.currentStep]
    switch (this.state.currentStep) {
      case 0:
        form = this.state.employer
        break
      case 1:
        form = data
        break
    }
    validator
      .validate(form, { abortEarly: false })
      .then(() => {
        const newState: any = { errors: {} }
        switch (this.state.currentStep) {
          case 0:
            this.props.updateEmployer(this.props.match.params.id, form)
            break
          case 1:
            const newEmployer = { ...this.state.employer }
            newState.isUpdated = form.index
            newState.employer = newEmployer
            if (form.id) {
              set(newEmployer, `EmployerContacts[${form.index}].wasChanged`, false)
              this.props.updateContact(form.id, filterByKeys(form, CONTACT_REQUIRED_FIELDS))
            } else {
              if (!form.phone) delete form.phone
              this.props.createContact(this.props.match.params.id, filterByKeys(form, CONTACT_REQUIRED_FIELDS))
            }
            break
        }
        this.setState(newState, () => setTimeout(() => this.setState({ isUpdated: false }), 3000))
      })
      .catch((err: ValidationError) => {
        const errors: any = formatErrors(err)
        if (this.state.currentStep === 1) errors.index = form.index
        this.setState({ errors })
      })
  }

  onDelete = () => {
    this.props.deleteEmployer(this.props.match.params.id)
    this.props.history.push('/live-jobs')
  }

  onChangeField = (e: InputEvent, type?: string) => {
    if (type) return this.setState({ employer: { ...this.state.employer, [type]: e } })
    const { name, value } = e.currentTarget
    const newEmployer = { ...this.state.employer }
    set(newEmployer, name, value)
    if (this.state.currentStep === 1) {
      set(newEmployer, `${name.substr(0, name.lastIndexOf('.'))}.wasChanged`, true)
    }
    this.setState({ employer: newEmployer })
  }

  onAddNewContact = () => {
    const newEmployer = { ...this.state.employer }
    newEmployer.EmployerContacts!.push({ name: '', position: '', email: '', phone: '' })
    this.setState({ employer: newEmployer, errors: {} })
  }

  onDeleteContact = (key: number, id?: number) => {
    if (id) {
      this.props.deleteEmployerContact(id)
    } else {
      const newEmployer = { ...this.state.employer }
      newEmployer.EmployerContacts = newEmployer.EmployerContacts!.filter((contact, contactKey) => contactKey !== key)
      this.setState({ employer: newEmployer })
    }
  }

  render() {
    const { isFetching, history, isUpdateData } = this.props
    const { isUpdated, employer, currentStep, errors } = this.state
    const customComponent = this.getComponent()
    const viewEditingItem = () => history.push(`/live-jobs/employer/${this.props.match.params.id}`)
    if (isFetching || !employer) return <Loader />

    return (
      <EditFrame
        texts={{
          ...SETTING_TEXTS,
          subTitle: employer.companyName,
        }}
        steps={CANDIDATE_STEPS_EDIT}
        forms={forms}
        errors={errors}
        isUpdated={isUpdated}
        editItemData={employer}
        currentStep={currentStep}
        setStep={this.setStep}
        onUpdate={this.onUpdate}
        onDelete={this.onDelete}
        onChangeField={this.onChangeField}
        viewEditingItem={viewEditingItem}
        isShowSaveButton={currentStep < 1}
        customComponent={customComponent}
        isUpdateData={isUpdateData}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  isUpdateData: state.currentInnerPage.get('isUpdateData'),
  isFetching: state.currentInnerPage.get('isFetching'),
  employer: state.currentInnerPage.get('item'),
  errorFromServer: state.employers.get('error'),
})

export default connect(
  mapStateToProps,
  {
    fetchEmployer: EmployersActions.fetchEmployer,
    updateEmployer: EmployersActions.updateEmployer,
    deleteEmployer: EmployersActions.deleteEmployer,
    deleteEmployerContact: EmployersActions.deleteEmployerContact,
    updateContact: EmployersActions.updateEmployerContact,
    createContact: EmployersActions.createEmployerContact,
    clearNotification: EmployersActions.clearNotification,
  }
)(EditEmployer)
