import React from 'react'
import styled from 'styled-components'

import theme from 'styles/theme'
import { Text, Bold, Image } from 'styles'

const IMG_NO_TRAKER = <Image src={require('assets/images/money-tracker.png')} />

const Wrap = styled.div`
  width: 100%;
  min-height: 444px;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled(Bold).attrs({})`
  margin: 20px 0 0 0;
`

const Empty: React.FC = () => (
  <Wrap>
    {IMG_NO_TRAKER}
    <Title xl3 color={theme.main.colors.grey3}>
      Your money tracker will be here
    </Title>
    <Text m>
      Send your first invoice and track money income
      <br /> directly on the dashboard
    </Text>
  </Wrap>
)

export default Empty
