import { createAction, ActionsUnion } from 'types/helps'
import { IError } from 'types/errors'
import { INote } from 'types/models'

export const NOTES__FETCH_ALL_STARTED = 'NOTES__FETCH_ALL_STARTED'
export const NOTES__FETCH_ALL_SUCCESS = 'NOTES__FETCH_ALL_SUCCESS'
export const NOTES__FETCH_ALL_FAIL = 'NOTES__FETCH_ALL_FAIL'

export const NOTES__ADD_NOTE_STARTED = 'NOTES__ADD_NOTE_STARTED'
export const NOTES__ADD_NOTE_SUCCESS = 'NOTES__ADD_NOTE_SUCCESS'
export const NOTES__ADD_NOTE_FAIL = 'NOTES__ADD_NOTE_FAIL'

export const NOTES__EDIT_NOTE_STARTED = 'NOTES__EDIT_NOTE_STARTED'
export const NOTES__EDIT_NOTE_SUCCESS = 'NOTES__EDIT_NOTE_SUCCESS'
export const NOTES__EDIT_NOTE_FAIL = 'NOTES__EDIT_NOTE_FAIL'

export const NOTES__DELETE_NOTE_STARTED = 'NOTES__DELETE_NOTE_STARTED'
export const NOTES__DELETE_NOTE_SUCCESS = 'NOTES__DELETE_NOTE_SUCCESS'
export const NOTES__DELETE_NOTE_FAIL = 'NOTES__DELETE_NOTE_FAIL'

export const NOTES__TOGGLE_MODAL = 'NOTES__TOGGLE_MODAL'

export type NotesActionsTypes = ActionsUnion<typeof NotesActions>

const NotesActions = {
  fetchNotes: () => createAction(NOTES__FETCH_ALL_STARTED),
  fetchNotesSuccess: (data: INote[]) => createAction(NOTES__FETCH_ALL_SUCCESS, data),
  fetchNotesFail: (error: IError) => createAction(NOTES__FETCH_ALL_FAIL, error),

  addNote: (text: string) => createAction(NOTES__ADD_NOTE_STARTED, text),
  addNoteSuccess: (data: INote) => createAction(NOTES__ADD_NOTE_SUCCESS, data),
  addNoteFail: (error: IError) => createAction(NOTES__ADD_NOTE_FAIL, error),

  editNote: (id: number, text: string) => createAction(NOTES__EDIT_NOTE_STARTED, { text, id }),
  editNoteSuccess: (data: INote) => createAction(NOTES__EDIT_NOTE_SUCCESS, data),
  editNoteFail: (error: IError) => createAction(NOTES__EDIT_NOTE_FAIL, error),

  deleteNote: (id: number) => createAction(NOTES__DELETE_NOTE_STARTED, id),
  deleteNoteSuccess: (id: number) => createAction(NOTES__DELETE_NOTE_SUCCESS, id),
  deleteNoteFail: (error: IError) => createAction(NOTES__DELETE_NOTE_FAIL, error),

  onToggleModal: (isShow?: boolean, id?: number, type?: 'employer' | 'jobPosition' | 'candidate' | 'employer-contact' | null) =>
    createAction(NOTES__TOGGLE_MODAL, { isShow, id, type }),
}

export default NotesActions
