import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Draggable } from 'react-beautiful-dnd'
import Swal from 'sweetalert2'
import moment from 'moment'

import { statuses } from './config'

import { IUser } from 'types/models'

import CreateInvoiceModal from 'containers/CreateInvoiceModal'
import Input from 'components/Input'
import ModalComponents from 'components/Modal'

import { Box, BoxFill, Image } from 'styles'
import {
  Container,
  Top,
  Title,
  DotWrapper,
  Dots,
  Center,
  Company,
  Price,
  Bottom,
  Avatar,
  NoImageBlock,
  Name,
  Create,
  DownloadedBlock,
  OptionsPopUp,
  PopUpWrapper,
  OptionsBlock,
  TopBlock,
  TopTitle,
  CloseIcon,
  SeparatorBlock,
  BottomBlock,
  ChangeStatus,
  CloseDeal,
  StatusBlock,
  StatusWrapper,
  StatusBox,
  Status,
  Checkbox,
  CloseChanges,
} from './styled'

import dots from 'assets/icons/dots.svg'

const ICON_CLOSE = <Image pointer src={require('assets/icons/close.svg')} />
const ICON_ARROW_RIGHT = <Image width="10px" height="10px" src={require('assets/icons/right.svg')} />
const ICON_SUCCESS_CHECKBOX = <Image src={require('assets/icons/statusSuccess.svg')} />

type Props = {
  task: {
    id: string
    JobPosition: any
    Candidate: any
    JobPositionId: number
    CandidateId: number
    column: string
    interviewDate: string
    photoFile: any
    itemId: number
    createdInvoice?: boolean
  }
  column: {
    id: string
    title: string
    taskIds: string[]
  }
  index: any
  changePipelineStatus: (JobPositionId: number, CandidateId: number, status: string, interviewDate: string) => void
  userData: IUser
}

class Task extends React.Component<Props & RouteComponentProps> {
  state = {
    isOpen: false,
    isOpenOption: false,
    isOpenConfirmation: false,
    optionVerticalPosition: 0,
    optionHorizontalPosition: 0,
    isHovering: false,
    isHoveringDeal: false,
    interviewDateState: this.props.task.interviewDate ? this.props.task.interviewDate.slice(0, this.props.task.interviewDate.indexOf('T')) : '',
  }

  wrapperRef: any

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node: any) => {
    this.wrapperRef = node
  }

  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isOpenOption: false,
      })
    }
  }

  checkUserPaymentDetailsForeCreateInvoice = () => {
    const { userData } = this.props
    return userData.abn && userData.account && userData.bsb && userData.tfn
  }

  createInvoice = () => {
    if (this.checkUserPaymentDetailsForeCreateInvoice()) return this.setState({ isOpen: true })
    Swal.fire({
      title: `Please fill your payment details`,
      html: '',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonColor: '#3CB593',
      confirmButtonText: 'Ok',
    }).then(result => {
      if (result.value) this.props.history.push('edit-profile?step=1')
    })
  }

  openOptions = (e: any) => {
    if (this.state.isOpenOption) {
      return
    } else {
      this.setState({
        isOpenOption: true,
        optionHorizontalPosition: e.clientX,
        optionVerticalPosition: e.clientY,
      })
    }
  }

  closeOptions = () => {
    this.setState({
      isOpenOption: false,
    })
  }

  handleMouseHoverChangeStatus = () => {
    this.setState((prevState: any) => ({
      isHovering: !prevState.isHovering,
    }))
  }

  handleMouseHoverCloseDeal = () => {
    this.setState((prevState: any) => ({
      isHoveringDeal: !prevState.isHoveringDeal,
    }))
  }

  onChangeField = (e: any) => {
    const interviewDate = e.target.value
    this.setState({ interviewDateState: interviewDate })
  }

  onLeaveInput = () => {
    const { interviewDateState } = this.state
    if (moment(interviewDateState).isAfter()) {
      const { changePipelineStatus, task } = this.props
      changePipelineStatus(task.JobPositionId, task.CandidateId, task.column, interviewDateState)
    } else {
      this.setState({
        interviewDateState: this.props.task.interviewDate ? this.props.task.interviewDate.slice(0, this.props.task.interviewDate.indexOf('T')) : '',
      })
    }
  }

  changeStatus = (statusId: string) => {
    const { changePipelineStatus, task } = this.props
    changePipelineStatus(task.JobPositionId, task.CandidateId, statusId, task.interviewDate)
  }

  toggleConfiramtion = () => {
    this.setState({ isOpenConfirmation: !this.state.isOpenConfirmation })
  }

  closeModal = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const { isHovering, isHoveringDeal, isOpenOption, optionVerticalPosition, optionHorizontalPosition, isOpenConfirmation, isOpen } = this.state
    const { task, index, column, userData, history } = this.props

    return (
      userData && (
        <>
          <Draggable draggableId={task.id} index={index}>
            {(provided, snapshot) => {
              return (
                <Container {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                  <Top>
                    <Title onClick={() => history.push(`live-jobs/employer/${task.JobPosition.EmployerId}/${task.JobPosition.id}/edit`)}>
                      {task.JobPosition.Position.name}
                    </Title>
                    <DotWrapper onClick={this.openOptions}>
                      <Dots src={dots} />
                    </DotWrapper>
                  </Top>
                  <Center>
                    {task.JobPosition.Employer && task.JobPosition.Employer.companyName && (
                      <Company onClick={() => history.push(`live-jobs/employer/${task.JobPosition.EmployerId}`)}>
                        {task.JobPosition.Employer.companyName}
                      </Company>
                    )}
                    <Price>${task.Candidate.minSalary}</Price>
                  </Center>
                  <Bottom onClick={() => history.push(`candidates/candidate/${task.Candidate.id}/edit`, { step: 4 })}>
                    {task.Candidate.photoFile ? (
                      <Avatar src={task.Candidate.photoFile.link} />
                    ) : (
                      <NoImageBlock values={[task.Candidate.firstName[0].toUpperCase(), task.Candidate.lastName[0].toUpperCase()]} />
                    )}
                    <Name>{`${task.Candidate.firstName} ${task.Candidate.lastName}`}</Name>
                  </Bottom>
                  {column.id == '5' && (
                    <Input
                      value={this.state.interviewDateState}
                      onChange={this.onChangeField}
                      name="interviewDate"
                      type="date"
                      onBlur={this.onLeaveInput}
                    />
                  )}
                  {column.id == '6' && !task.createdInvoice && <Create title="CREATE INVOICE" onClick={this.createInvoice} type="lightGreen" />}
                  {column.id == '6' && task.createdInvoice && <DownloadedBlock>INVOICE DOWNLOADED</DownloadedBlock>}
                </Container>
              )
            }}
          </Draggable>
          <CreateInvoiceModal task={task} isOpen={isOpen} closeModal={this.closeModal} />
          <OptionsPopUp
            isOpen={isOpenOption}
            optionVerticalPosition={optionVerticalPosition}
            optionHorizontalPosition={optionHorizontalPosition}
            isFirstColumnId={this.props.column.id == '1'}
            isLastColumnId={this.props.column.id == '6'}
            ref={this.setWrapperRef}
            isHovering={isHovering}
          >
            <PopUpWrapper>
              <OptionsBlock isFirstColumnId={this.props.column.id == '1'}>
                <TopBlock>
                  <TopTitle>Options</TopTitle>
                  <CloseIcon onClick={this.closeOptions}>{ICON_CLOSE}</CloseIcon>
                </TopBlock>
                <SeparatorBlock />
                <BottomBlock>
                  <ChangeStatus
                    onMouseEnter={this.handleMouseHoverChangeStatus}
                    onMouseLeave={this.handleMouseHoverChangeStatus}
                    isHovering={isHovering}
                  >
                    Change status
                    {ICON_ARROW_RIGHT}
                  </ChangeStatus>
                  <ChangeStatus
                    onMouseEnter={this.handleMouseHoverCloseDeal}
                    onMouseLeave={this.handleMouseHoverCloseDeal}
                    isHovering={isHoveringDeal}
                    onClick={this.toggleConfiramtion}
                  >
                    <CloseDeal>Close deal</CloseDeal>
                  </ChangeStatus>
                </BottomBlock>
              </OptionsBlock>
              {isHovering && (
                <StatusBlock isFirstColumnId={this.props.column.id == '1'}>
                  <StatusWrapper onMouseEnter={this.handleMouseHoverChangeStatus} onMouseLeave={this.handleMouseHoverChangeStatus}>
                    {statuses.map(status => (
                      <StatusBox key={status.id} onClick={() => this.changeStatus(status.id)}>
                        <Status color={status.color}>{status.title}</Status>
                        {status.id == column.id && <Checkbox color={status.color}>{ICON_SUCCESS_CHECKBOX}</Checkbox>}
                      </StatusBox>
                    ))}
                  </StatusWrapper>
                </StatusBlock>
              )}
            </PopUpWrapper>
          </OptionsPopUp>
          {isOpenConfirmation ? (
            <ModalComponents isOpen={isOpenConfirmation} onRequestClose={this.toggleConfiramtion}>
              <BoxFill column>
                <div>Are you sure that you want to close deal?</div>
                <Box alignSelf="flex-end">
                  <ChangeStatus onClick={this.toggleConfiramtion}>
                    <CloseChanges>No</CloseChanges>
                  </ChangeStatus>
                  <ChangeStatus onClick={() => this.changeStatus('-1')}>
                    <CloseDeal>Yes</CloseDeal>
                  </ChangeStatus>
                </Box>
              </BoxFill>
            </ModalComponents>
          ) : null}
        </>
      )
    )
  }
}

export default withRouter(Task)
