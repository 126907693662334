import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { BoxFullScreen, Box } from 'styles'

type Props = {
  isFetching: boolean
}

const NoPage: React.FC<Props & RouteComponentProps> = ({ isFetching, match }) => {
  if (isFetching) return null
  return (
    <BoxFullScreen alignCenter justifyCenter column>
      <h1>Error 404</h1>
      <h4>Page not found</h4>
      <Box mt="10px">
        <a href="/live-jobs">Go to Home page</a>
      </Box>
    </BoxFullScreen>
  )
}

const mapStateToProps = (state: any) => ({
  isFetching: state.auth.get('isFetching'),
})

export default connect(mapStateToProps)(NoPage)
