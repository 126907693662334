export default {
  main: {
    backgroundColor: '#0B1424',
    text: '#9CAAB8',
    placeholderColor: '#9CAAB8',
    greyText: '#64707D',
    borderInputColor: '#E3E8F1',
    inputLabelTextColor: '#292C38',
    secondColor: '#3CB593',
    red: '#F95565',
    darkSecondColor: '#265151',
    icon: '#4A515C',
    dashboardCardsBackground: '#1E2735',
    countButtonBackground: '#303745',
    accentColor: '#2697FF',
    accentColor2: '#D5EBFF',
    homeBackground: '#F5F6F8',
    colors: {
      primary: '#3CB593',
      secondary: '#2697FF',
      red: '#F95565',
      grey: '#9CAAB8',
      grey1: '#64707D',
      grey2: '#E3E8F1',
      grey3: '#292C38',
      grey4: '#0B1424',
    },
    fontSizes: {
      xxs: '10px',
      xs: '12px',
      s: '14px',
      m: '16px',
      l: '18px',
      xl: '20px',
      xl2: '22px',
      xl3: '24px',
      xl4: '26px',
      xl5: '28px',
    },
    uploadInputBorder: {
      successDrag: '#00e676',
      rejectDrag: '#00e676',
      activeDrag: '#6c6',
      defaultBorder: '#E1E3EE',
    },
    buttonsColor: {
      primary: '#3CB593',
      secondary: '#2697FF',
      load: '#E3E8F1',
      lightGreen: '#D8F0E9',
      danger: '#F95565',
    },
  },
}
