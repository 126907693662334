import React from 'react'
import { connect } from 'react-redux'

import { AppState } from 'store/rootReducer'
import MessengerActions from 'store/messenger/actions'

import ModalComponents from 'components/Modal'
import CandidateModal from './modals/CandidateModal'
import InterviewModal from './modals/InterviewModal'
// import JobPositionModal from './modals/JobPositionModal'

type Props = {
  modal: string
  setModalType: typeof MessengerActions.setModalType
  clearError: typeof MessengerActions.clearError
}

const MessengerModal: React.FC<Props> = ({ modal, setModalType, clearError }) => (
  <ModalComponents
    isOpen={modal !== 'none'}
    onRequestClose={() => {
      setModalType('none')
      clearError()
    }}
    zIndex="1500"
    hideCloseIcon
  >
    {modal === 'candidate' && <CandidateModal />}
    {/* {modal === 'jobPosition' && <JobPositionModal />} */}
    {modal === 'interview' && <InterviewModal />}
  </ModalComponents>
)

const mapStateToProps = (state: AppState) => ({
  modal: state.messenger.get('modal'),
})

export default connect(
  mapStateToProps,
  {
    setModalType: MessengerActions.setModalType,
    clearError: MessengerActions.clearError,
  }
)(MessengerModal)
