import { createAction, ActionsUnion, IQueryParams } from 'types/helps'
import { IPerformanceTracker } from 'types/models'
import { IInvoicesSuccess } from 'types/responseData'

export const DASHBOARD__GET_PERFORMANCE_TRACKER = 'DASHBOARD__GET_PERFORMANCE_TRACKER'
export const DASHBOARD__GET_PERFORMANCE_TRACKER_SUCCESS = 'DASHBOARD__GET_PERFORMANCE_TRACKER_SUCCESS'

export const DASHBOARD__GET_INVOICES = 'DASHBOARD__GET_INVOICES'
export const DASHBOARD__GET_INVOICES_SUCCESS = 'DASHBOARD__GET_INVOICES_SUCCESS'

const DashboardActions = {
  getPerformanceTracker: () => createAction(DASHBOARD__GET_PERFORMANCE_TRACKER),
  getPerformanceTrackerSuccess: (data: IPerformanceTracker) => createAction(DASHBOARD__GET_PERFORMANCE_TRACKER_SUCCESS, data),

  getInvoices: (query: IQueryParams) => createAction(DASHBOARD__GET_INVOICES, query),
  getInvoicesSuccess: (data: IInvoicesSuccess) => createAction(DASHBOARD__GET_INVOICES_SUCCESS, data),
}

export type DashboardTypes = ActionsUnion<typeof DashboardActions>
export default DashboardActions
