import React, { useState } from 'react'
import styled from 'styled-components'

import { ICandidate } from 'types/models'

import Avatar from 'components/Avatar'

import { formatCurrency } from 'utils'
import { BoxFill, Box, BoxCol, Text, BoxCenterContent, Image } from 'styles'

const ItemBox = styled(Box).attrs<{ type: 'positions' | 'sectors' }>(props => ({
  smooth: true,
  bc: props.type === 'positions' ? props.theme.main.accentColor2 : props.theme.main.borderInputColor,
  padding: '4px 8px',
  mt: '6px',
}))<{ type: 'positions' | 'sectors' }>`
  p {
    color: ${props => (props.type === 'positions' ? props.theme.main.accentColor : props.theme.main.inputLabelTextColor)};
  }
  :hover .popUpBox {
    display: block;
  }
`
const ItemText = styled(Text).attrs(props => ({
  whiteSpace: true,
  textOverflow: true,
  overflow: 'hidden',
  fz: '12px',
}))``

const PopUpBox = styled(BoxCol).attrs({
  whiteBC: true,
  absolute: true,
  shadow2: true,
  smooth: true,
  zIndex: '10',
  display: 'none',
  padding: '10px',
  top: '40px',
})``

type Props = {
  candidate: ICandidate
  match: (id: number) => void
}

const PotentialCandidateCard: React.FC<Props> = ({ candidate, match }) => {
  const [isShowMoreInfo, setIsShowMoreInfo] = useState(false)
  const toggleIsShowMoreInfo = () => setIsShowMoreInfo(!isShowMoreInfo)
  const matchCandidate = () => match(candidate.id)
  const renderItems = (items: Array<{ name: string }>, type: 'positions' | 'sectors') => {
    const firstItem = items.length ? items[0].name : false
    const secondItem = items.length >= 2 ? items[1].name : false
    const isShowNumberOfItems = items.length > 3
    const maxWidth = secondItem ? '90px' : '100%'
    if (!items.length)
      return (
        <Box>
          <ItemBox type={type}>
            <ItemText>Any</ItemText>
          </ItemBox>
        </Box>
      )
    return (
      <Box>
        {!firstItem || (
          <ItemBox type={type} maxWidth={maxWidth}>
            <ItemText>{firstItem}</ItemText>
          </ItemBox>
        )}
        {!secondItem || (
          <ItemBox type={type} maxWidth={maxWidth} ml="12px">
            <ItemText>{secondItem}</ItemText>
          </ItemBox>
        )}
        {!isShowNumberOfItems || (
          <ItemBox pointer justifyCenter relative type={type} ml="4px">
            <ItemText pointer>{items.length}</ItemText>
            <PopUpBox className="popUpBox">
              {items.slice(2).map(item => (
                <ItemBox key={item.name} type={type}>
                  <ItemText>{item.name}</ItemText>
                </ItemBox>
              ))}
            </PopUpBox>
          </ItemBox>
        )}
      </Box>
    )
  }

  const { City } = candidate
  const city = City && City.name.indexOf(City.State.abbr) > 0 ? City.name : `${City.name} ${City.State.abbr}`

  return (
    <BoxFill allPointer defaultBorder alignCenter smooth column shadow2 whiteBC padding="20px">
      <Box>
        <Box zIndex="5">
          <Avatar item={candidate} width="49px" />
        </Box>
        <BoxCenterContent whiteBC width="49px" height="49px" ml="-10px" zIndex="5" br="50%" shadow2>
          <Text xs black>
            {candidate.complianceDegree}%
          </Text>
        </BoxCenterContent>
      </Box>
      <Box alignCenter maxWidth="100%">
        <Text black whiteSpace textOverflow overflow="hidden">
          {candidate.public && <Image style={{ margin: '3px 2px -2px 0' }} src={require('assets/icons/user-icon.svg')} />} {candidate.firstName}{' '}
          {candidate.lastName}
        </Text>
      </Box>
      <Box>
        <Box pr="6px" borderRight="1px solid #E3E8F1">
          <Text>{city}</Text>
        </Box>
        <Box ml="7px" smooth bc="#D8F0E9" padding="4px 8px">
          <Text xs secondColor>
            ${formatCurrency(candidate.minSalary)}
          </Text>
        </Box>
      </Box>
      {isShowMoreInfo && (
        <BoxCol alignCenter>
          <BoxCenterContent mt="8px" column>
            <Text black xs>
              Preferable positions
            </Text>
            {renderItems(candidate.Positions, 'positions')}
          </BoxCenterContent>
          <BoxCenterContent mt="8px" column>
            <Text black xs>
              Preferable project types
            </Text>
            {renderItems(candidate.Sectors, 'sectors')}
          </BoxCenterContent>
        </BoxCol>
      )}
      <BoxFill onClick={toggleIsShowMoreInfo} margin="8px 0">
        <BoxCenterContent fill smooth bc="#E3E8F1" padding="10px 0">
          <Text black>{isShowMoreInfo ? 'Show less info' : 'Show more info'}</Text>
        </BoxCenterContent>
      </BoxFill>
      <BoxFill>
        <BoxCenterContent onClick={matchCandidate} fill smooth bc="#D8F0E9" padding="10px 0">
          <Text fw="bold" secondColor>
            MATCH
          </Text>
        </BoxCenterContent>
      </BoxFill>
    </BoxFill>
  )
}

export default PotentialCandidateCard
