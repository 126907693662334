import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { AppState } from 'store/rootReducer'
import { IMatchedCandidate } from 'types/models'

import Avatar from 'components/Avatar'
import ModalComponents from 'components/Modal'
import { PaginationArrow } from 'components/Icons'
import { SmallLoader } from 'components/Loader'
import { ChangeStatus, CloseChanges, CloseDeal } from 'containers/Pipeline/components/Board/styled'

import { getCandidateStatus } from 'utils'
import { BoxFill, Box, Text, Span, BoxCenterContent } from 'styles'

const Wrapper = styled(BoxFill).attrs(props => ({
  column: true,
  smooth: true,
  border: `1px solid ${props.theme.main.borderInputColor}`,
}))``

const ShowPipline = styled(Span).attrs({})`
  &:hover {
    opacity: 0.8;
  }
`

const Unmatch = styled(Text).attrs({})`
  &:hover {
    opacity: 0.8;
  }
`

type Props = {
  candidate: IMatchedCandidate
  closeJobDeal: () => void
}

const MatchedCandidateCard: React.FC<Props & RouteComponentProps> = ({ candidate, history, closeJobDeal }) => {
  const [isOpenConfirmation, toggleOpenConfirmation] = useState<boolean>(false)
  const toPipelineRoute = () => history.push('/pipeline')
  const isFetchingPipeline = useSelector((state: AppState) => state.pipeline.get('isFetching'))

  useEffect(() => {
    if (!isFetchingPipeline) {
      toggleOpenConfirmation(false)
    }
  }, [isFetchingPipeline])

  return (
    <Box shadow2 whiteBC smooth>
      <Wrapper>
        <BoxFill padding="15px">
          <Box>
            <Avatar item={candidate} width="38px" />
          </Box>
          <BoxFill column ml="6px">
            <BoxFill spaceBetween alignCenter>
              <Text s fw="500" lh="10px" black>
                {candidate.firstName} {candidate.lastName}
              </Text>
              <Text xs>{moment(candidate.Matched.updatedAt).fromNow()}</Text>
            </BoxFill>
            <BoxFill>
              <Text xs secondColor>
                {getCandidateStatus(candidate.Matched.status)}
              </Text>
            </BoxFill>
          </BoxFill>
        </BoxFill>
        <BoxFill>
          <BoxCenterContent onClick={toPipelineRoute} fill padding="12px 0" bt="1px solid #E3E8F1">
            <Box mr="6px">
              <ShowPipline>Show pipeline</ShowPipline>
            </Box>
            <PaginationArrow rotate="rotate(180deg)" fill="#2697FF" />
          </BoxCenterContent>
          {+candidate.Matched.status === -1 ? null : (
            <BoxCenterContent onClick={() => toggleOpenConfirmation(true)} fill padding="12px 0" bt="1px solid #E3E8F1">
              <Box mr="6px">
                <Unmatch red pointer>
                  Unmatch
                </Unmatch>
              </Box>
            </BoxCenterContent>
          )}
        </BoxFill>
        {isOpenConfirmation ? (
          <ModalComponents isOpen={isOpenConfirmation} onRequestClose={() => toggleOpenConfirmation(false)}>
            <BoxFill column>
              <div>Are you sure you want to unmatch?</div>
              {isFetchingPipeline ? (
                <SmallLoader width="50px" />
              ) : (
                <Box alignSelf="flex-end">
                  <ChangeStatus onClick={() => toggleOpenConfirmation(false)}>
                    <CloseChanges>No</CloseChanges>
                  </ChangeStatus>
                  <ChangeStatus onClick={closeJobDeal}>
                    <CloseDeal>Yes</CloseDeal>
                  </ChangeStatus>
                </Box>
              )}
            </BoxFill>
          </ModalComponents>
        ) : null}
      </Wrapper>
    </Box>
  )
}

export default withRouter(MatchedCandidateCard)
