import styled from 'styled-components'

export const ModalTitle = styled.p`
  font-family: Mukta;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #292c38;
  padding: 20px 30px 14px 30px;
`

export const ModalSubTitle = styled.p`
  font-family: Mukta;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #9caab8;
  margin: 0 0 24px 0;
`

export const BottonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 20px 0;
  text-transform: uppercase;
`

export const LoaderWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`
