import { call, put, takeLatest } from 'redux-saga/effects'

import PipelineActions, { FETCH_PIPELINE, CHANGE_PIPELINE_STATUS, SEND_BLOBFILE } from './actions'
// import EmployersActions from '../employers/actions'

import PipelineApi from 'api/pipeline'

function* fetchPipelineFlow() {
  try {
    const { data } = yield call(PipelineApi.getPipeline)
    yield put(PipelineActions.fetchPipelineSuccess(data))
  } catch (err) {
    if (err.response.data.message) {
      yield put(PipelineActions.fetchPipelineFail(err.response.data.message))
    }
  }
}

function* changePipelineStatusFlow(action: ReturnType<typeof PipelineActions.changePipelineStatus>) {
  try {
    const { JobPositionId, CandidateId, status, interviewDate, cb } = action.payload
    let bodyWithFields = {}
    if (interviewDate) {
      bodyWithFields = {
        JobPositionId: +JobPositionId,
        CandidateId: +CandidateId,
        status: Number(status),
        interviewDate: interviewDate.indexOf('T') < 0 ? interviewDate : interviewDate.slice(0, interviewDate.indexOf('T')),
      }
    } else {
      bodyWithFields = { JobPositionId: +JobPositionId, CandidateId: +CandidateId, status: Number(status) }
    }
    const data = JSON.stringify(bodyWithFields)
    const response = yield call(PipelineApi.changeStatus, data)
    if (cb) {
      yield call(cb)
    }
    yield put(PipelineActions.changePipelineStatusSuccess(response.data))
  } catch (err) {
    if (err.response.data.message) {
      yield put(PipelineActions.changePipelineStatusFail(err.response.data.message))
    }
  }
}

function* sendBlobfileFlow(action: ReturnType<typeof PipelineActions.sendBlobfile>) {
  try {
    const { data, matchId, blobFile } = action.payload
    const { data: invoice } = yield call(PipelineApi.createInvoice, matchId, data)
    yield call(PipelineApi.sendBlobfile, invoice.id, blobFile)
  } catch (err) {
    if (err.response.data.message) {
      yield put(PipelineActions.sendBlobfileFail(err.response.data.message))
    }
  }
}

export default function* watchPipeline() {
  yield takeLatest(FETCH_PIPELINE, fetchPipelineFlow)
  yield takeLatest(CHANGE_PIPELINE_STATUS, changePipelineStatusFlow)
  yield takeLatest(SEND_BLOBFILE, sendBlobfileFlow)
}
