import Api from './index'

class TaskApi {
  static getTaskList() {
    return Api.get('/task')
  }

  static getTaskListByFilter(priority: string) {
    return Api.get(`/task/${priority}/`)
  }

  static createTask(body: any) {
    const data = {
      title: body.title,
      description: body.description,
      priority: body.priority,
      dueDate: body.dueDate,
      dueTime: body.dueTime,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    return Api.post('/task/', data)
  }

  static updateTask(body: any, id: number) {
    const data = {
      title: body.title,
      description: body.description,
      priority: body.priority,
      dueDate: body.dueDate,
      dueTime: body.dueTime,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    return Api.patch(`/task/${id}`, data)
  }

  static deleteTask(id: number) {
    return Api.delete(`/task/${id}`)
  }

  static closeTask(id: number) {
    return Api.get(`/task/${id}/close`)
  }

  static restoreTask(id: number) {
    return Api.get(`/task/${id}/restore`)
  }
}

export default TaskApi
