import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import EmployersActions from 'store/employers/actions'

import Avatar from 'components/Avatar'

import { Wrap, UL, LI, Left, LeflBlock } from './styles'

type Props = {
  allEmployers: any
  allJobsAmount?: number
  fetchEmployer: typeof EmployersActions.fetchEmployer
  getAllLiveJobs: typeof EmployersActions.getAllLiveJobs
  onCompanySelect: (companyId: Nullable<number>) => void
}

const Sidebar: React.FC<Props> = ({ allEmployers, allJobsAmount, fetchEmployer, getAllLiveJobs, onCompanySelect }) => {
  const [active, setActive] = useState(1)
  useEffect(() => {
    if (active === 1) {
      getAllLiveJobs()
    } else {
      fetchEmployer(active)
    }
  }, [active])

  const onElementClick = React.useCallback(
    (id: number) => {
      setActive(id)
      if (id === 1) {
        onCompanySelect(null)
      } else {
        onCompanySelect(id)
      }
    },
    [onCompanySelect]
  )

  return (
    allEmployers && (
      <Wrap>
        <UL>
          <LI active={active === 1} onClick={() => onElementClick(1)}>
            <Left>All Jobs</Left>
            <span>{allJobsAmount || 0}</span>
          </LI>
          {allEmployers &&
            allEmployers.map((el: any) => {
              return (
                el.jobPositionCount > 0 && (
                  <LI key={el.id} active={active === el.id} onClick={() => onElementClick(el.id)}>
                    <LeflBlock>
                      <Avatar item={el} width="36px" />
                      <span>{el.companyName}</span>
                    </LeflBlock>
                    <span>{el.jobPositionCount}</span>
                  </LI>
                )
              )
            })}
        </UL>
      </Wrap>
    )
  )
}

export default connect(
  null,
  {
    fetchEmployer: EmployersActions.fetchEmployer,
    getAllLiveJobs: EmployersActions.getAllLiveJobs,
  }
)(Sidebar)
