import styled from 'styled-components'

import { Box } from 'styles'

export const AddItemIconContainer = styled(Box).attrs({
  pointer: true,
  alignCenter: true,
})``

export const ImageWrapper = styled(Box).attrs({
  width: '28px',
  height: '28px',
  justifyCenter: true,
  alignCenter: true,
  background: 'rgba(60, 181, 147, .2)',
  br: '50%',
})``
