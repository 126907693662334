import styled from 'styled-components'

import { BoxFullFill } from 'styles'

export const Wrap = styled(BoxFullFill).attrs({})`
  align-items: stretch;
`

export const AddJobPositionButtonWrap = styled.div`
  position: absolute;
  top: -92px;
  right: 0;
`

export const ScrollToTop = styled.img`
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
  cursor: pointer;
  border-radius: 50%;
  padding: 9px 8px;
  position: fixed;
  right: 15px;
  bottom: 9%;
  &:hover {
    opacity: 0.8;
  }
`
