import styled from 'styled-components'

import { BoxFill } from 'styles'

export const BG = styled(BoxFill).attrs({
  mainBC: true,
  absolute: true,
  height: '190px',
  zIndex: '1',
})``

export const Wrapper = styled(BoxFill).attrs({
  mt: '20px',
  padding: '0 80px',
  zIndex: '2',
})``
