import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import TaskActions from 'store/task/action'
import { ITask } from 'types/models'

import Task from '../../Task'
import Loader from 'components/Loader'

type Props = {
  highPrioriy: {
    count: number
    rows: ITask[]
  }
  mediumPrioriy: {
    count: number
    rows: ITask[]
  }
  anyTime: {
    count: number
    rows: ITask[]
  }
  deleteTask: any
  closeTask: any
  restoreTask: any
  isLoading: boolean
}

const AllTasks: React.FC<Props> = ({ highPrioriy, mediumPrioriy, anyTime, deleteTask, closeTask, restoreTask, isLoading }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(TaskActions.getTaskList())
  }, [dispatch])

  const renderHigh = () =>
    highPrioriy.rows.map(el => {
      return (
        <Task
          id={el.id}
          key={el.id}
          title={el.title}
          type="high"
          description={el.description}
          dueDate={el.dueDate}
          dueTime={el.dueTime}
          deleteTask={deleteTask}
          closeTask={closeTask}
          priority="high priority"
        />
      )
    })

  const renderMedium = () =>
    mediumPrioriy.rows.map(el => {
      return (
        <Task
          id={el.id}
          key={el.id}
          title={el.title}
          type="medium"
          description={el.description}
          dueDate={el.dueDate}
          dueTime={el.dueTime}
          deleteTask={deleteTask}
          closeTask={closeTask}
          priority="medium priority"
        />
      )
    })

  const renderAny = () =>
    anyTime.rows.map(el => {
      return (
        <Task
          id={el.id}
          key={el.id}
          title={el.title}
          type="any"
          description={el.description}
          dueDate={el.dueDate}
          dueTime={el.dueTime}
          deleteTask={deleteTask}
          closeTask={closeTask}
          priority="any time"
        />
      )
    })

  return (
    <>
      {isLoading ? (
        <Loader height="400px" />
      ) : (
        <>
          {renderHigh()}
          {renderMedium()}
          {renderAny()}
        </>
      )}
    </>
  )
}

export default AllTasks
