import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { IJobPositionsWithMathcedCandidates, IMatched } from 'types/models'

import Avatar from 'components/Avatar'
import { Email } from 'components/Others'
import { PaginationArrow } from 'components/Icons'
import MatchedCandidate from 'components/ForCandidates&Employers/matchedCandidate'

import { formatCurrency } from 'utils'
import { Box, BoxCol, BoxFill, BoxFullFill, Text, Span, Bold, H2, BoxCenterContent, SectorsTypeBox, GreenBox, Separator, Image } from 'styles'

const MatchedJobsBox = styled(BoxFill)`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
`

const ItemBox = styled(Box).attrs(props => ({
  smooth: true,
  bc: props.theme.main.borderInputColor,
  padding: '5px 0px',
}))`
  p {
    color: ${props => props.theme.main.inputLabelTextColor};
    padding: 5px 10px;
  }
  :hover .popUpBox {
    display: block;
    margin-top: 6px;
    div {
      padding: 4px 10px;
      margin-top: 6px;
    }
  }
`
const ItemText = styled(Text).attrs(props => ({
  whiteSpace: true,
  textOverflow: true,
  overflow: 'hidden',
  fz: '14px',
  padding: '0 8px',
}))``

const PopUpBox = styled(BoxCol).attrs({
  whiteBC: true,
  absolute: true,
  shadow2: true,
  smooth: true,
  zIndex: '10',
  display: 'none',
  padding: '10px',
  top: '40px',
})``

const H2Styled = styled(H2).attrs({})`
  img {
    margin: 0 6px -2px 0;
  }
`

const Visit = styled(Span).attrs({})`
  :hover {
    opacity: 0.8;
  }
`

type Props = {
  job?: IJobPositionsWithMathcedCandidates
  match: (JobPositionId: number) => void
  employerRoute: () => void
  onClose: () => void
  closeJobDeal: (matched: IMatched) => void
}

const JobPositionInner: React.FC<Props> = ({ job, match, employerRoute, onClose, closeJobDeal }) => {
  if (!job) return null

  const matchCandidate = () => {
    match(job.id)
    onClose()
  }

  const abbrJob = job.Employer.companyName.split(' ').map(char => char[0])

  const closeJobDealAndRefreshCandidateList = (candidate: any) => {
    closeJobDeal(candidate)
    onClose()
  }

  const renderItems = (items: Array<{ name: string }>) => {
    const firstItem = items.length ? items[0].name : false
    const secondItem = items.length >= 2 ? items[1].name : false
    const isShowNumberOfItems = items.length > 2
    const maxWidth = secondItem ? '200px' : '100%'
    if (!items.length)
      return (
        <Box>
          <ItemBox>
            <ItemText>Any</ItemText>
          </ItemBox>
        </Box>
      )
    return (
      <Box>
        {!firstItem || (
          <ItemBox maxWidth={maxWidth}>
            <ItemText>{firstItem}</ItemText>
          </ItemBox>
        )}
        {!secondItem || (
          <ItemBox maxWidth={maxWidth} ml="12px">
            <ItemText>{secondItem}</ItemText>
          </ItemBox>
        )}
        {!isShowNumberOfItems || (
          <ItemBox pointer justifyCenter relative ml="12px">
            <ItemText pointer>{items.length - 2}</ItemText>
            <PopUpBox className="popUpBox">
              {items.slice(2).map(item => (
                <ItemBox key={item.name}>
                  <ItemText>{item.name}</ItemText>
                </ItemBox>
              ))}
            </PopUpBox>
          </ItemBox>
        )}
      </Box>
    )
  }

  const { City } = job
  const city = City && City.name.indexOf(City.State.abbr) > 0 ? City.name : `${City.name} ${City.State.abbr}`

  return (
    <BoxCol overflow="visible" relative width="70vw">
      <BoxFullFill column overflow="scroll">
        <BoxFill spaceBetween>
          <Box>
            <Box pr="10px">
              <Text>Added {moment(job.createdAt).fromNow()}</Text>
            </Box>
          </Box>
          <Box onClick={employerRoute}>
            <Box mr="5px">
              <Visit>Visit employer page</Visit>
            </Box>
            <PaginationArrow rotate="rotate(180deg)" fill="#2697FF" />
          </Box>
        </BoxFill>
        <BoxFill spaceBetween alignCenter mt="20px">
          <Box>
            <Box mr="10px">
              <Box zIndex="5">
                <Avatar chars={abbrJob} width="49px" />
              </Box>
              <BoxCenterContent whiteBC width="49px" height="49px" ml="-10px" zIndex="5" br="50%" shadow2>
                <Text xs black>
                  {job.complianceDegree}%
                </Text>
              </BoxCenterContent>
            </Box>
            <BoxCol>
              <H2Styled lh="24px" black fw="bold">
                {job.public && <Image src={require('assets/icons/user-icon.svg')} />}
                {job.Position.name}
              </H2Styled>
              <Box mt="5px">
                <Box pr="5px" mr="5px" borderRight="1px solid #E3E8F1">
                  <Text black lh="16px">
                    {job.Employer.companyName}
                  </Text>
                </Box>
                <Text lh="16px">{city}</Text>
              </Box>
            </BoxCol>
          </Box>
          <Box>
            <SectorsTypeBox>{job.Sectors && job.Sectors.length > 0 && <>{renderItems(job.Sectors)}</>}</SectorsTypeBox>
            <GreenBox ml="10px">
              <Text s>${formatCurrency(job.salary)}</Text>
            </GreenBox>
          </Box>
        </BoxFill>
        <BoxFill mt="24px" column>
          <GreenBox onClick={matchCandidate} fill allPointer>
            <Text fill>MATCH</Text>
          </GreenBox>
          <Separator margin="20px 0" />
        </BoxFill>
        <BoxFill column>
          {job.Candidates.length ? (
            <BoxFill column>
              <Box margin="20px 0">
                <Bold black xl>
                  Matched ({job.Candidates.length})
                </Bold>
              </Box>
              <BoxFill pb="24px">
                <MatchedJobsBox>
                  {job.Candidates.map(candidate => (
                    // old version
                    // <MatchedCandidate key={candidate.id} candidate={candidate} closeJobDeal={() => closeJobDeal(candidate.Matched)} />
                    <MatchedCandidate
                      key={candidate.id}
                      candidate={candidate}
                      closeJobDeal={() => closeJobDealAndRefreshCandidateList(candidate.Matched)}
                    />
                  ))}
                </MatchedJobsBox>
              </BoxFill>
              <Separator />
            </BoxFill>
          ) : null}
        </BoxFill>
        <BoxFullFill column>
          <Box margin="10px 0">
            <Text xl black fw="bold">
              Hiring manager info
            </Text>
          </Box>
          <BoxFill pb="24px" bb="1px solid #E3E8F1">
            <Box mr="15px">
              <Text whiteSpace>
                Name:{' '}
                <Span black noPointer>
                  {job.EmployerContact && job.EmployerContact.name}
                </Span>
              </Text>
            </Box>
            {job.EmployerContact && job.EmployerContact.position && (
              <Box mr="15px">
                <Text whiteSpace>
                  Position:{' '}
                  <Span black noPointer>
                    {job.EmployerContact && job.EmployerContact.position}
                  </Span>
                </Text>
              </Box>
            )}
            <Box mr="15px">
              <Text whiteSpace>
                Email: <Email email={job.EmployerContact && job.EmployerContact.email} />
              </Text>
            </Box>
            {job.EmployerContact && job.EmployerContact.phone && (
              <Box>
                <Text whiteSpace>
                  Phone number:{' '}
                  <Span black noPointer>
                    {job.EmployerContact && job.EmployerContact.phone}
                  </Span>
                </Text>
              </Box>
            )}
          </BoxFill>
          {job.description && (
            <BoxFullFill column>
              <Box margin="10px 0">
                <Text xl black fw="bold">
                  Job description
                </Text>
              </Box>
              <BoxFullFill>
                <Text black left>
                  {job.description}
                </Text>
              </BoxFullFill>
            </BoxFullFill>
          )}
        </BoxFullFill>
      </BoxFullFill>
    </BoxCol>
  )
}

export default JobPositionInner
