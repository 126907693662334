import Api, { API_BASE_URL } from './index'

import { IUserForm } from 'types/forms'
import { IUser } from 'types/models'

import { IUploadInputParams } from 'components/UploadInput/types'

class User {
  static getUser() {
    return Api.get<IUser>('/user/profile/')
  }

  static createUser(userData: Partial<IUserForm>) {
    return Api.post<IUser>('/auth/create/', Object.assign(userData, { experience: Number(userData.experience) }))
  }

  static updateUser(user: Partial<IUser>) {
    return Api.patch<IUser>('/user/profile/', user)
  }

  static createPasswordAndCompleteRegistration(hash: string, pass: string) {
    return Api.post<{ user: IUser; token: string }>('/auth/complete-registration/', { hash, pass })
  }

  static login(email: string, pass: string) {
    return Api.post<{ user: IUser; token: string }>('/auth/login/', { email, pass })
  }

  static setNewPassword(hash: string, pass: string) {
    return Api.post<{ user: IUser; token: string }>('/auth/set-new-password/', { hash, pass })
  }

  static resetPassword(email: string) {
    return Api.post<null>('/auth/reset-password/', { email })
  }

  static isEmailExist(email: string) {
    return Api.get('/auth/email-exist/', { params: { email } })
  }

  static deleteUser() {
    const options = {
      headers: {
        Authorization: `Bearer ${Api.getAuthToken()}`,
      },
    }
    return Api.delete('/user/profile/', options)
  }
}

export const getUserByHash = (hash: string) => Api.get<IUser>('/auth/user-by-hash/', { params: { hash } })

export async function paramsForUploadPhotoUser(userid: number): Promise<IUploadInputParams> {
  return {
    fieldName: 'file',
    options: {
      url: `${API_BASE_URL}/user/profile/files/photo/`,
      headers: { Authorization: `Bearer ${Api.getAuthToken()}` },
    },
  }
}

export default User
