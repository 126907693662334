import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import PrimaryButton from 'components/Button'
import { Logo } from 'components/Icons'

import { Box, BoxCenterContent, Text, H1 } from 'styles'

const SuccessResetPassword: React.FC<RouteComponentProps> = ({ location, history }) => {
  const email = location.search.split('=')[1]
  return (
    <BoxCenterContent column whiteBC width="100vw" height="100vh">
      <Box absolute top="7%">
        <Logo type="black" />
      </Box>
      <Box column alignCenter>
        <H1 black lh="34px" fw="bold">
          Success! Check your <br /> email inbox
        </H1>
        <Box maxWidth="360px" mt="10px">
          <Text>You will receive detailed instruction on resetting your password to {email}</Text>
        </Box>
      </Box>
      <Box height="60px" width="15vw" mt="40px">
        <PrimaryButton onClick={() => history.push('/')} title="BACK TO LOG IN" />
      </Box>
    </BoxCenterContent>
  )
}

export default SuccessResetPassword
