import styled from 'styled-components'

import Avatar from 'components/Avatar'

import { BoxCol, BoxFill, BoxFullFill, Text } from 'styles'

interface IBlock {
  width?: number
  minWidth?: number
  maxWidth?: number
}

export const Wrap = styled(BoxFill).attrs({
  spaceBetween: true,
})``

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Block = styled.div<IBlock>`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  min-width: ${props => `${props.minWidth}px`};
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  height: 50px;
  margin: 0 5px;
  &:nth-child(1) {
    margin: 0 5px 0 0;
  }
`

export const ButtonBlock = styled.div`
  width: 138px;
  height: 50px;
  padding: 5px 0 0 0;
  margin: 0 0 0 5px;
`

export const SaveWrap = styled(BoxFill).attrs({
  spaceBetween: true,
  margin: '10px 0 0 0',
  padding: '0 20px 0 0',
})``

export const SavedSearches = styled.div`
  color: ${props => props.theme.main.colors.primary};
  position: relative;
  span {
    cursor: pointer;
    text-decoration-line: underline;
    &:hover {
      opacity: 0.8;
    }
  }
`

export const SavedThisSearch = styled(Text)`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

export const ContainerCard = styled(BoxFullFill).attrs({
  height: 'auto !important',
})`
  display: grid;
  justify-content: stretch;
  grid-gap: 30px;
  width: 100%;
  margin-top: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  height: auto !important;
`

export const CompaniesCard = styled(BoxCol).attrs({})`
  background: #fff;
  min-height: 248px;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`

export const CompanyAvatar = styled(Avatar)`
  box-shadow: 0px 2px 6px rgba(149, 151, 157, 0.14) !important;
`

export const CompanyName = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #292c38;
  margin: 10px 0 6px 0;
`

export const CompanyCity = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #9caab8;
  margin: 0 0 5px 0;
`

export const CompanyWebsite = styled(Text)`
  font-size: 16px;
  line-height: 22px;
  color: #2697ff;
  cursor: pointer;
  word-break: break-word;
  margin: 0 20px 13px 20px;
  a {
    color: #2697ff;
  }
`

export const JobPositions = styled.div`
  background: #e3e8f1;
  border-radius: 6px;
  padding: 8px 22px;
  color: #292c38;
  font-size: 14px;
  line-height: 16px;
`

export const SearchCount = styled(Text).attrs({})`
  margin: 10px 0 -20px 0;
`
