import * as yup from 'yup'
import { ValidationError } from 'yup'
import moment from 'moment'

import UserApi from 'api/auth'

/* func for format errors object to key:error */
export const formatErrors = (err: ValidationError): object => {
  if (!err.inner) return {}
  const errors: any = {}
  err.inner.forEach((error: ValidationError) => {
    errors[error.path] = error.errors[0]
  })
  return errors
}

/* func for format errors object to key:error for server response */
export const formatErrorsForResponse = (errs: any[]) => {
  const errors: any = {}
  errs.forEach((err: any) => {
    errors[err.fieldName] = err!.errorMessage
  })
  return errors
}

/* ------------------------------------------- */

/**
 * check is email already exist
 * @param {message} - error message
 * @param {isExist} - revert function.
 *                    For default func return error if email exist.
 *                    If isExist = false, return no error
 */

function isEmailExist(this: yup.StringSchema, message: string = 'this email is already taken', isExist = true) {
  return this.test('unique-email', message, value =>
    value
      ? UserApi.isEmailExist(value)
          .then(({ data }) => (isExist ? !data.emailExist : data.emailExist))
          .catch(() => true)
      : true
  )
}

yup.addMethod(yup.string, 'isEmailExist', isEmailExist)

/* ------------------------------------------- */

const Errors = {
  notValid: 'Not valid',
  notEmpty: 'Must be non empty',
  onlyLatinSymbols: 'Use only latin symbols',
  onlyNumbers: 'Use only numbers',
  notValidEmail: 'Email not valid',
  onlyNumbersAndLatinSymbols: 'Use only latin symbols and numbers',
  notValidLinkedIn: 'LinkedIn profile not valid',
  passwordError: 'Password must contain lower and upper case character',
}

const textPattern = /^[a-zA-Z\s]+$/
const numericPattern = /^[0-9]+$/
const numericPatternWithSpaces = /^(?=.*\d)[\d ]+$/
const textNumericWithSpecialCharactersPattern = /^[0-9a-zA-Z\s,.\/\\]+$/
// const linkedInPattern = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/
const emailPattern = /^([a-zA-Z0-9_\.-]+\@[\da-z\.-]+\.[a-z\.]{2,6})$/
// const registrationNamePattern = /^[A-Z][a-zA-Z]*$/
const passwordPattern = /^((?=.*[a-z])(?=.*[A-Z]).{6,255})$/

const textSchema = yup
  .string()
  .trim()
  .matches(textPattern, { message: Errors.onlyLatinSymbols, excludeEmptyString: true })
  .max(50)
  .required()

const regName = yup
  .string()
  .trim()
  .test('checkNumbers', 'No numbers', (val: string) => !/\d/.test(val))
  .max(50)
  .min(1)
  .required()

const textNumericSchema = yup
  .string()
  .max(50)
  .required()

const numericSchema = yup.string().matches(numericPattern, Errors.onlyNumbers)

const numericSchemaWithSpaces = yup.string().matches(numericPatternWithSpaces, { message: Errors.onlyNumbers, excludeEmptyString: true })

const emailSchema = yup
  .string()
  .matches(emailPattern, Errors.notValidEmail)
  .email(Errors.notValidEmail)
  .required()

const phoneSchema = yup.string().matches(numericPattern, { message: Errors.onlyNumbers, excludeEmptyString: true })

const linkedInSchema = yup
  .string()
  .url()
  .test(
    'checkProtocol',
    'Please add http:// or https:// for links',
    (val: string | null) => !val || val.indexOf('http://') === 0 || val.indexOf('https://') === 0
  )
  .label('linkedIn')
  .notRequired()
  .nullable()
// .matches(linkedInPattern, { message: Errors.notValidLinkedIn, excludeEmptyString: true })

const object = yup.object().required()

const date = yup
  .date()
  .max(new Date(new Date().setDate(new Date().getDate() - 1)), 'No future date')
  .required()

const textNumericWithSpecialCharactersSchema = yup
  .string()
  .trim()
  .matches(textNumericWithSpecialCharactersPattern, Errors.notValid)
  .max(100)
  .required()

const websiteSchema = yup
  .string()
  .url()
  .test(
    'checkProtocol',
    'Please add http:// or https:// for links',
    (val: string | null) => !val || val.indexOf('http://') === 0 || val.indexOf('https://') === 0
  )
  .label('website')
  .notRequired()
  .nullable()

/* TODO:! rebuild with check equal password  and confirm password*/
export const checkPasswordSchema = yup
  .string()
  .matches(passwordPattern, Errors.passwordError)
  .min(6)
  .max(50)
  .label('Password')
  .required()

/* ------***------***---SIGN UP SCHEMS ---***------***------ */

export const signUpSchemaFirstStep = yup.object().shape({
  firstName: regName.label('First name'),
  lastName: regName.label('Last name'),
  phone: textNumericSchema,
  email: emailSchema
    // @ts-ignore
    .isEmailExist(),
})

export const signUpSchemaSecondStep = yup.object().shape({
  experience: numericSchema.required(),
  linkedIn: linkedInSchema.required(),
})

export const setPasswordSchema = yup.object().shape({
  password: checkPasswordSchema.label('Create password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .label('Confirm password')
    .required(),
})

export const editProfileInformation = yup.object().shape({
  firstName: regName.label('First name'),
  lastName: regName.label('Last name'),
  phone: textNumericSchema.matches(numericPattern, Errors.onlyNumbers),
  email: emailSchema.notRequired(),
})

export const updateProfileInfo = yup
  .mixed()
  .concat(editProfileInformation)
  .concat(signUpSchemaSecondStep)

export const updatePaymentDetails = yup.object().shape({
  abn: textNumericSchema
    .label('ABN')
    .notRequired()
    .nullable(),
  tfn: textNumericSchema
    .label('TFN')
    .notRequired()
    .nullable(),
  bsb: textNumericSchema
    .label('BSB')
    .notRequired()
    .nullable(),
  account: textNumericSchema
    .label('Account')
    .notRequired()
    .nullable(),
})

export const updatePassword = yup.object().shape({
  pass: textNumericSchema.label('Old password'),
  newPass: checkPasswordSchema.label('New password'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPass'), null], 'Passwords must match')
    .label('Confirm password')
    .required(),
})

export const signUpSchemaFinishRegistration = yup.mixed().concat(signUpSchemaSecondStep)

/* -------------------------------------------------------------- */

/* ---***------***------***------***------***------***------***------***------***--- */

/* ------***------***---ADD EMPLOYER SCHEMS ---***------***------ */

export const addEmployerSchemaFirstStep = yup.object().shape({
  // companyName: textNumericSchema.label('Company name'),
  companyName: yup
    .string()
    .nullable()
    .label('Company name')
    .trim()
    .required(),
  City: yup
    .object()
    .label('City')
    .required(),
  address: textNumericWithSpecialCharactersSchema.label('HQ address'),
  abn: numericSchema
    .label('ABN')
    .required()
    .length(11)
    .min(11),
  phone: numericSchemaWithSpaces.nullable(),
  website: websiteSchema,
})

export const addEmployerSchemaSecondStep = yup.object().shape({
  name: textSchema.label('Contact name'),
  position: textSchema
    .label('Contact position')
    .notRequired()
    .nullable(),
  email: emailSchema.label('Contact email'),
  phone: phoneSchema.nullable(),
})

/* ---***------***------***------***------***------***------***------***------***--- */

/* ------***------***---ADD JOB POSITION SCHEMS ---***------***------ */

export const addJobPositionSchemaFirstStep = yup.object().shape({
  Position: object.label('Job position title'),
  // Sector: object.label('Project type'), // old version
  // Sector: yup.array().of(object.label('Project type')),
  Sectors: yup.array().of(object.label('Project type')),
  City: object.label('City'),
  salary: numericSchema.label('Salary').required(),
  Companies: yup.string().label('Companies'),
})

export const addJobPositionSchemaSecondStep = yup.object().shape({
  name: textSchema.label('Hiring manager name'),
  position: textSchema.notRequired().nullable(),
  email: emailSchema.label('Hiring manager email address'),
  phone: phoneSchema.nullable(),
})

export const addJobPositionSchemaThirdStep = yup.object().shape({
  description: yup.string(),
})

export const addPublicJobPositionSchemaFirstStep = yup.object().shape({
  Position: object.label('Job position title'),
  Sectors: yup.array().of(object.label('Project type')),
  City: object.label('City'),
  salary: numericSchema.label('Salary').required(),
  Companies: yup
    .string()
    .label('Companies')
    .required(),
})

/* ---***------***------***------***------***------***------***------***------***--- */

/* ------***------***---ADD CANDIDATE SCHEMS ---***------***------ */
export const addCandidateSchemaFirstStep = yup.object().shape({
  firstName: textNumericSchema.label('First name'),
  lastName: textNumericSchema.label('Last name'),
  birthday: date
    .notRequired()
    .max(new Date(new Date().getFullYear() - 10, new Date().getMonth(), new Date().getDate()))
    .min(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate())),
  City: yup
    .object()
    .label('City')
    .required(),
  email: emailSchema
    .label('Email')
    .notRequired()
    .nullable(),
})

export const addPublicCandidateSchemaFirstStep = yup.object().shape({
  firstName: textNumericSchema.label('First name'),
  lastName: textNumericSchema.label('Last name'),
  birthday: date
    .notRequired()
    .max(new Date(new Date().getFullYear() - 10, new Date().getMonth(), new Date().getDate()))
    .min(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate())),
  City: yup
    .object()
    .label('City')
    .required(),
  email: emailSchema.label('Email').nullable(),
})

/* TODO:! Create concating flow of below types validateion (repeat the same things) */
export const workPlace = yup.object().shape({
  companyName: textNumericSchema.label('Company name').nullable(),
  companyWebsite: yup
    .string()
    .nullable()
    .label('Company webiste'),
  City: yup
    .object()
    .label('City')
    .nullable()
    .required(),
  Position: yup
    .object()
    .label('Job position')
    .nullable()
    .required(),
  current: yup.bool(),
  fromDate: date
    .label('From')
    // .notRequired()
    .nullable(),
  toDate: date
    .label('To')
    .when('current', {
      is: false,
      then: date.min(yup.ref('fromDate'), 'not earlier than From'),
      otherwise: yup
        .date()
        .notRequired()
        .nullable(),
    })
    .notRequired()
    .nullable(),
})

export const addCandidateSecondStepWithoutWorkPlace = yup.object().shape({
  minSalary: numericSchema.label('Min salary').required(),
  availability: yup
    .date()
    .required()
    .typeError('not empty'),
  linkedIn: linkedInSchema,
})

export const addCandidateSchemaSecondStep = yup.object().shape({
  minSalary: numericSchema.label('Min salary').required(),
  availability: yup
    .date()
    .label('Availability')
    .required()
    .min(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
    .max(new Date(new Date().getFullYear() + 10, new Date().getMonth(), new Date().getDate())),
  linkedIn: linkedInSchema,
  wpCurrent: yup.bool(),
  wpCompanyName: yup
    .string()
    .max(50)
    .label('Company name'),
  wpCompanyWebsite: websiteSchema.label('Company webiste'),
  wpCity: yup.object().label('City'),
  wpPosition: yup.object().label('Job position'),
  wpFromDate: date
    .label('From')
    .min(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()))
    .max(new Date())
    .notRequired(),
  wpToDate: date
    .label('To')
    .when('wpFromDate', (wpFromDate: string, schema: any) => {
      return schema!.min(moment(wpFromDate).toDate())
    })
    .notRequired(),
})

/* ---***------***------***------***------***------***------***------***------***--- */

/* ------***------***---EDIT CANDIDATE SCHEMS ---***------***------ */

export const updateEditProfilePhoto = yup.object()

/* ---***------***------***------***------***------***------***------***------***--- */

export const editJobPositionSchemaFirstStep = yup.object().shape({
  Position: object.label('Job position title'),
  Sectors: yup.array().of(object.label('Project type')),
  City: object.label('City'),
  salary: numericSchema.label('Salary').required(),
})

/* ------***------***---  GOALS SCHEME ---***------***------ */

export const correctGoalsScheme = yup.object().shape({
  annualGoal: yup
    .number()
    .test('int', 'Must be integer', val => {
      return val % 1 === 0
    })
    .label('Annual Goal')
    .required(),
  monthlyGoal: yup
    .number()
    .test('int', 'Must be integer', val => {
      return val % 1 === 0
    })
    .label('Monthly Goal')
    .required(),
  quarterGoal: yup
    .number()
    .test('int', 'Must be integer', val => {
      return val % 1 === 0
    })
    .label('Quarter Goal')
    .required(),
  weeklyGoal: yup
    .number()
    .test('int', 'Must be integer', val => {
      return val % 1 === 0
    })
    .label('Weekly Goal')
    .required(),
})

/* ---***------***------***------***------***------***------***------***------***--- */

export const resetPasswordSchema = emailSchema

export const validateField = (name: string, value: string) => {
  switch (name) {
    case 'day':
      if (+value > 31 || +value === 0) return 'Invalid day'
      break
    case 'month':
      if (+value > 12 || +value === 0) return 'Invalid month'
      break
    case 'year':
      if (+value < new Date().getFullYear()) return
      if (+value > new Date().getFullYear() + 10) return `Invalid year maximum ${new Date().getFullYear() + 10}`
      break
    default:
      return ''
  }
  return ''
}
