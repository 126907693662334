import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Box, BoxCol, BoxFill, Image } from 'styles'

export const UserAvatarContainer = styled(Box).attrs({
  margin: '0 10px 0 40px',
  br: '19px',
  border: '2px solid #FFFFFF',
})``

export const ProfileContainer = styled(Box).attrs({
  allPointer: true,
  pointer: true,
  justifyRight: true,
  alignCenter: true,
  relative: true,
  pr: '40px',
})`
  .arrow-box {
    transition: 0.4s ease-out 0.1s;
  }
  &:hover .statusBox {
    visibility: visible;
    opacity: 1;
  }
  &:hover .arrow-box {
    transform: rotate(180deg);
    svg path {
      fill: #2697ff;
    }
  }

  &:hover .user-avatar-container {
    border: '2px solid #2697ff';
  }
`

export const StatusWrapper = styled(BoxCol).attrs({
  absolute: true,
  whiteBC: true,
  smooth: true,
  shadow2: true,
  width: '120px',
  top: '50px',
  zIndex: '999',
})`
  transition: 0.2s ease-in-out 0.3s;
  visibility: hidden;
  opacity: 0;
`

export const BoxStatus = styled(BoxFill).attrs({
  alignCenter: true,
  spaceBetween: true,
  padding: '10px',
})`
  &:hover {
    background-color: #f5f6f8;
    border-radius: 6px;
  }
`
export const Logo = styled(Image).attrs({})`
  cursor: pointer;
`

export const AvatarWrap = styled(Box).attrs({
  alignCenter: true,
})``

export const SearchIcon = styled(Link)`
  position: absolute;
  right: 115px;
  top: 37px;
  z-index: 20;
  &:hover {
    opacity: 0.5;
    color: red;
  }
`
