import styled from 'styled-components'

interface IProps {
  active: boolean
}

export const Wrap = styled.div`
  width: 407px;
  min-height: 100px;
  background: #fff;
  box-shadow: 0px 7px 90px rgba(180, 191, 196, 0.12);
  border-radius: 6px;
  margin: 0 30px 0 0;
`

export const UL = styled.ul`
  width: 407px;
  padding: 20px 0;
  margin: 0;
  list-style: none;
`

export const LI = styled.li<IProps>`
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  min-height: 64px;
  align-items: center;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:before {
    left: 0;
    top: 0;
    content: '';
    display: ${props => (props.active ? 'block' : 'none')};
    height: 100%;
    position: absolute;
    width: 4px;
    background: #3cb593;
    border-radius: 0px 6px 6px 0px;
  }
  span {
    font-weight: ${props => (props.active ? 'bold' : 'normal')};
    color: ${props => (props.active ? '#3CB593' : '#9CAAB8')};
  }
`

export const Left = styled.span`
  font-weight: bold !important;
`

export const LeflBlock = styled.span`
  display: flex;
  align-items: center;
  span {
    margin: 0 0 0 13px;
    font-size: 17px;
    line-height: 22px;
    color: #292c38;
    padding: 0 20px 0 0;
    word-break: break-all;
  }
`
